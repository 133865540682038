export function numberWithCommas(x) {
  return !numberWithCommas ? numberWithCommas : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


export function filterForSelectable(entityset, uuidSet) {
  return uuidSet.map(cUUID => {
    let found = entityset.find(c => c.UUID === cUUID)
    let selected = { label: "", value: "" }
    if (found) {
      selected.label = found.name
      selected.value = cUUID
    } else {
      return null
    }
    return selected
  }).filter(c => c)
}

export function campaignPlacementReady(prevCampaigns, prevPlacements, campaigns, placements) {
  return (prevCampaigns.length !== campaigns.length) || (prevPlacements.length !== placements.length)
}

export function reportReady(prevReport, report, prevCampaigns, prevPlacements, campaigns, placements) {
  return prevReport != report || (prevCampaigns.length !== campaigns.length) || (prevPlacements.length !== placements.length)
}
