const { union, derivations } = require('folktale/adt/union')

const GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE = 'GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE'
const GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE_FAILURE = 'GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE_FAILURE'
const GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE_SUCCESS = 'GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE_SUCCESS'

export const generatePubPrevalByDateCsvReport = () =>
    ({ type: GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE })

export const generatePubPrevalByDateCsvReportSuccess = data =>
    ({ type: GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE_SUCCESS, data })

export const generatePubPrevalByDateCsvReportFailure = error =>
    ({ type: GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE_FAILURE, error })

export const PubPrevalByDateCsvReportState = union('PubPrevalByDateCsvReportState', {
    PubPrevalByDateCsvReportInitial() { return {} }
    , LoadingPubPrevalByDateCsvReport() { return {} }
    , PubPrevalByDateCsvReportLoaded(data) { return { data } }
    , PubPrevalByDateCsvReportError(error) { return { error } }
  })
.derive(derivations.debugRepresentation)
export const { 
    PubPrevalByDateCsvReportInitial,
    LoadingPubPrevalByDateCsvReport, 
    PubPrevalByDateCsvReportLoaded, 
    PubPrevalByDateCsvReportError 
} = PubPrevalByDateCsvReportState

export const pubPrevalByDateCsvReport = (state=PubPrevalByDateCsvReportInitial(), action) => {
    switch(action.type) {
        case GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE:
            return LoadingPubPrevalByDateCsvReport()
        case GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE_FAILURE:
            return PubPrevalByDateCsvReportError(action.error)
        case GENERATE_PUB_PREVAL_CSV_REPORT_BY_DATE_SUCCESS:
            return PubPrevalByDateCsvReportLoaded(action.data)
        default:
            return state
    }
}
