import {
    generateAdqPerformance,
    generateAdqPerformanceFailure,
    generateAdqPerformanceSuccess
} from '../reducers/adqPerformanceReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

const url = getUrlFor("reporting", "adq-performance")
//const url = "http://localhost:9001/adq-performance/"
//const url = "http://localhost:9001/adq-performance-mock/"

export function generateAdqPerformanceThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generateAdqPerformance())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generateAdqPerformanceSuccess(res.data))
            }).catch(e => {
                dispatch(generateAdqPerformanceFailure(`Getting report`))
                exceptionHandling(e, dispatch, "Getting report")
            })
    }
}
