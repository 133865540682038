const SAVE_STATE_OF_ADQ_PERFORMANCE = 'SAVE_STATE_OF_ADQ_PERFORMANCE'
const GENERATE_ADQ_PERFORMANCE = 'GENERATE_ADQ_PERFORMANCE'
const GENERATE_ADQ_PERFORMANCE_FAILURE = 'GENERATE_ADQ_PERFORMANCE_FAILURE'
const GENERATE_ADQ_PERFORMANCE_SUCCESS = 'GENERATE_ADQ_PERFORMANCE_SUCCESS'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_ADQ_PERFORMANCE, data })

export const generateAdqPerformance = () =>
    ({ type: GENERATE_ADQ_PERFORMANCE })

export const generateAdqPerformanceSuccess = data =>
    ({ type: GENERATE_ADQ_PERFORMANCE_SUCCESS, data })

export const generateAdqPerformanceFailure = error =>
    ({ type: GENERATE_ADQ_PERFORMANCE_FAILURE, error })

export const adqPerformanceState = (state = { report: null, isLoading: false, error: null, componentState: null }, action) => {
    switch (action.type) {
        case SAVE_STATE_OF_ADQ_PERFORMANCE:
            return {
                ...state,
                componentState: action.data,
            }
        case GENERATE_ADQ_PERFORMANCE:
            return {
                ...state,
                isLoading: true,
                report: null,
                error: null,
            }
        case GENERATE_ADQ_PERFORMANCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                report: action.data.report,
                slug: action.data.request.slug,
                request: action.data.request,
                error: null,
            }
        case GENERATE_ADQ_PERFORMANCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                report: null,
                error: { message: action.error },
            }
        default:
            return state
    }
}
