const GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT = 'GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT'
const GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT_FAILURE = 'GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT_FAILURE'
const GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT_SUCCESS = 'GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT_SUCCESS'

export const generateBrokeredLeadViewerCsvReport = () =>
    ({ type: GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT })

export const generateBrokeredLeadViewerCsvReportSuccess = data =>
    ({ type: GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT_SUCCESS, data })

export const generateBrokeredLeadViewerCsvReportFailure = error =>
    ({ type: GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT_FAILURE, error })

export const brokeredLeadViewerCsvReportState = (state={report: null,isLoading: false, error: null, componentState: null}, action) => {
    switch (action.type) {
        case GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT:
        return {
            ...state,
            isLoading: true,
            report: null,
            error: null,
        }
        case GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT_SUCCESS:
        return {
            ...state,
            isLoading: false,
            error: null,
        }
        case GENERATE_BROKERED_LEAD_VIEWER_CSV_REPORT_FAILURE:
        return {
            ...state,
            isLoading: false,
            report: null,
            error: {message: action.error},
        }
        default:
        return state
  }
}
