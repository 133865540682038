import Modal from 'react-modal'
import React from 'react'
import { CardTitle, Card, Button, CardActions, Grid, Cell, Checkbox } from 'react-mdl'

export default class AddressValidationModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = { suiteLevel: true }
    }
    handleChange() {
        this.setState({ suiteLevel: !this.state.suiteLevel })
    }
    render() {
        return (
            <Modal
                appElement={this.props.contentSection}
                isOpen={this.props.showDialog}
                onRequestClose={() => this.props.dialogCancel()}
                contentLabel="">
                <Card style={{ maxWidth: "25%" }} shadow={2}>
                    <CardTitle>{this.props.dialogTitle}</CardTitle>
                    <CardActions border>
                        <Grid>
                            <Cell col={12}>{this.props.dialogText}</Cell>
                            <Cell col={12}>      <Checkbox
                                ripple
                                label="Suite Level Validation"
                                checked={this.state.suiteLevel}
                                onChange={() => this.handleChange()}
                            /></Cell>
                            <Cell col={12}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "10px" }}>
                                    <Button id="ok" raised colored onClick={() => this.props.onOk(this.state.suiteLevel)}>
                                        OK
                                    </Button>
                                    <Button id="cancel" raised colored onClick={() => this.props.dialogCancel()}>
                                        No
                                    </Button>
                                </div>
                            </Cell>
                        </Grid>
                    </CardActions>
                </Card>
            </Modal>
        )
    }
}