import {
    startLoadingSessionLookup,
    loadedSessionLookup
} from '../reducers/sessionLookupReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

//const url = "http://localhost:9001/session-lookup/"
//const url = "http://localhost:9001/session-lookup-mock/"
const url = getUrlFor("reporting", "session-lookup")
//const url = getUrlFor("reporting", "session-lookup-mock")

export function loadSessionLookupThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(startLoadingSessionLookup())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(loadedSessionLookup(criteria, res.data))
            }).catch(e => {
                exceptionHandling(e, dispatch, "Getting session lookup")
            })
    }
}
