import React from 'react'
import { Card, Spinner, CardTitle, Grid, CardActions, Cell, List, ListItem, Icon, Tooltip } from 'react-mdl'

export default class PlacementAssociations extends React.Component {
  constructor(props) {
    super(props)
    this.props.loadAssociations()
    this.state = {
      associatedPlacementFilter: null,
      displayPlacementFilterList: false,
    }
  }

  retrievePlacementDisplayData(placementUUID) {
    const placement = this.props.placements.find((p) => p.UUID === placementUUID)
    return `${placement.name} - ${placement.legacyID}`
  }

  retrieveWeekdayName(weekdayCode) {
    const weekdays = { 0: "sun", 1: "mon", 2: "tue", 3: "wed", 4: "thu", 5: "fri", 6: "sat" }
    return weekdays[weekdayCode]
  }

  normalizeDate(timestamp) {
    const effectiveDate = new Date(1000 * timestamp)
    const year = effectiveDate.getFullYear()
    const month = (effectiveDate.getMonth() + 1) < 10 ? `0${effectiveDate.getMonth() + 1}` : `${effectiveDate.getMonth() + 1}`
    const day = effectiveDate.getDate() < 10 ? `0${effectiveDate.getDate()}` : effectiveDate.getDate()
    return `${month}/${day}/${year}`
  }

  normalizeTenthOfCent(isAbsolute, tenthOfCent) {
    const value = isAbsolute ? tenthOfCent / 1000 : tenthOfCent / 10
    const unit = isAbsolute ? '$' : '%'
    return `${unit}${value}`
  }

  normalizeTime(time) {
    const hours = (time.hours < 10) ? `0${time.hours}` : time.hours
    const minutes = (time.minutes < 10) ? `0${time.minutes}` : time.minutes
    return `${hours}:${minutes}`
  }

  displayAssociatedPlacementFilterList(){
    this.setState({
      displayPlacementFilterList: true,
    })
  }

  hideAssociatedCampaignFilterList(){
    this.setState({
      displayPlacementFilterList: false,
    })
  }

  associatedPlacementFilterSelected(placement){
    this.setState({
      associatedPlacementFilter: placement,
      displayPlacementFilterList: false,
    })
  }

  removeAssociatedCampaigFilter(){
    this.setState({
      associatedPlacementFilter: null,
    })
  }

  filterItems(items){
    if (!this.state.associatedPlacementFilter){
      return items
    }
    return items.filter((i) => i.placementUuid === this.state.associatedPlacementFilter.UUID)
  }

  render() {
    let placementMap = {}
    this.props.placements.forEach(p => {
      placementMap[p.UUID] = p
    })

    const associations = this.props.associations.associations
    return (
      <Grid style={{}}>
        <Cell col={12}>
          <Card shadow={2}>
            <CardTitle>Associated placements</CardTitle>
            <CardActions border>
              <List style={{ maxHeight: "550px", overflowY: "scroll" }}>
                {this.props.associations.loading ? <div><Spinner singleColor /></div> :
                 associations.map((association, idx) => {
                  return <ListItem key={idx} onClick={() => this.props.redirect(association.placementUUID)}>
                    <Cell col={10} style={{ color: "rgba(0,0,0, 0.54)" }}>{this.retrievePlacementDisplayData(association.placementUUID)}</Cell>
                    <Tooltip label={"Publisher Placement"}><Cell col={2}><Icon name={association.isBrokered ? "public" : ""} /></Cell></Tooltip>
                  </ListItem>
                }
                )}
              </List>
            </CardActions>
          </Card>
        </Cell>
      </Grid>
    )
  }
}