import {
  setCrud,
  openPlacementManagerLookupList,
  closePlacementManagerLookupList,
} from "../../services"
import FilterList from "../filterList"
import React from "react"
import { connect } from "react-redux"
import { Card, CardActions, CardTitle, Tooltip, IconButton, CardMenu } from "react-mdl"
import PlacementEditor from "./placementEditor.js"

function mapStateToProps(state) {
  return {
    placementManager: state.placementManager,
    placementData: state.placementData,
    campaignData: state.campaignData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    openLookup() {
      dispatch(openPlacementManagerLookupList())
    },
    closeLookup() {
      dispatch(closePlacementManagerLookupList())
    },
    setCrud: function (crud) {
      dispatch(setCrud(crud))
    },
  }
}

class placementManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      placementUUID: null
    }

    this.toggleList = this.toggleList.bind(this)
  }

  setCrudHandler(crudOptions) {
    this.props.setCrud(crudOptions)
  }

  toggleList(isVisible) {
    if (isVisible) {
      this.props.openLookup()
    } else {
      this.props.closeLookup()
    }
  }

  placementSelected(placementUUID, e) {
    if (e.button === 1) {
      window.open("/placementmanager/" + placementUUID, '_blank').focus()
      return
    }
    this.setState({
      placementUUID: placementUUID,
    })
  }

  render() {
    let left = "-400px"
    if (this.props.placementManager.isLookupListOpened) {
      left = "0px"
    }
    const lookupList = this.props.placementData.records.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    return <React.Fragment>
      <Card
        className="mdl-cell mdl-cell--12-col"
        style={{ width: "450px", position: "fixed", zIndex: 100001, left: left }}
        shadow={2}>
        <CardMenu>
          <Tooltip label={<span>{this.props.placementManager.isLookupListOpened ? "Close" : "Open"}</span>}>
            <IconButton
              name={this.props.placementManager.isLookupListOpened ? "arrow_back" : "arrow_forward"}
              onClick={() => this.toggleList(!this.props.placementManager.isLookupListOpened)}
            />
          </Tooltip>
        </CardMenu>
        <CardTitle>
          <h2 className="mdl-card__title-text">Placement List</h2>
        </CardTitle>
        <CardActions border>
          <FilterList
            label="Placement list..."
            selected={this.props.placementManager.placement ? this.props.placementManager.placement.UUID : ''}
            additionalFieldList={["UUID", "legacyID", "legacyCode"]}
            title="Placements"
            selectItem={(p, e) => this.placementSelected(p.UUID, e)}
            items={lookupList}
            checkboxFilters={[item => item.activated, item => item.isBrokered]}
            checkboxFilterLabels={["Is Active", "Is Publisher"]}
            checkboxFilterDefaults={[false, false]}
            getIcon={p => {
              return p.isBrokered
                ? p.activated
                  ? { msg: "Active pub", icon: "public" }
                  : { msg: "Inactive pub", icon: "public_off" }
                : p.activated
                  ? { msg: "Active", icon: "donut_large" }
                  : { msg: "Inactive", icon: "highlight_off" }
            }}
          />
        </CardActions>
      </Card>
      <PlacementEditor 
        {...this.props} 
        placementUUID={this.state.placementUUID} 
        toggleList={this.toggleList} 
        setCrudHandler={(crudOptions) => this.setCrudHandler(crudOptions)}
        dailyCapCounts={{}}
        currentCapCounts={{}}
      ></PlacementEditor>
    </React.Fragment>
  }
}

const PlacementManager = connect(mapStateToProps, mapDispatchToProps)(placementManager)

export default PlacementManager
