import Editor from '../editor'
import React from 'react'
import { connect } from 'react-redux'
import { uploadCakeCreative, setCrud } from '../../services'
import {Grid, Cell, Card, CardActions, CardTitle, CardText, Icon, Button, CardMenu} from 'react-mdl'

function mapStateToProps(state) {
  return {
    campaign: state.campaign,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadCakeCreative: function (creative) {
      dispatch(uploadCakeCreative(creative))
    },
    setCrud: function (crud) {
      dispatch(setCrud(crud))
    }
  }
}

class cake extends React.Component {
  constructor(props) {
    super(props)
    this.state = { cakeCreative: '' }
  }

  saveCakeCreative() {
    this.props.uploadCakeCreative(this.state.cakeCreative)
  }

  clearCakeCreative() {
    this.setState({
      cakeCreative: ''
    })
  }

  updateCreative(c) {
    this.setState({
      cakeCreative: c
    })
  }

  componentDidMount() {
    let crudOptions = {
      onSave: () => { },
      onCancel: () => { },
      onNew: () => { },
      visible: false,
    }
    this.props.setCrud(crudOptions)
  }

  render() {
    return (
      <section>
        <div className="content">
          <Grid>
            <Cell col={12}>
              <Card style={{ minWidth: '640px' }} shadow={2}>
                <CardTitle>CAKE Creative</CardTitle>
                <CardText>
                  <p>Enter the code for your CAKE creative</p>
                </CardText>
                <CardActions border>
                  <Editor
                    code={this.state.cakeCreative}
                    onChange={newHtml => this.updateCreative(newHtml)}
                  />
                </CardActions>
                <CardMenu>
                  <Button
                    ripple
                    colored
                    raised
                    onClick={() => this.saveCakeCreative()}
                  >
                    <Icon name="save" /> Save
                  </Button>
                  <Button
                    ripple
                    colored
                    raised
                    onClick={() => this.clearCakeCreative()}
                  >
                    <Icon name="clear" /> Clear
                  </Button>
                </CardMenu>
              </Card>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }
}

const Cake = connect(mapStateToProps, mapDispatchToProps)(cake)

export default Cake
