const { union, derivations } = require('folktale/adt/union')

const GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE = 'GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE'
const GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE_FAILURE = 'GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE_FAILURE'
const GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE_SUCCESS = 'GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE_SUCCESS'

export const generatePubPrevalByMessageReport = () =>
    ({ type: GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE })

export const generatePubPrevalByMessageReportSuccess = data =>
    ({ type: GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE_SUCCESS, data })

export const generatePubPrevalByMessageReportFailure = error =>
    ({ type: GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE_FAILURE, error })

export const PubPrevalByMessageReportState = union('PubPrevalByMessageReportState', {
    PubPrevalByMessageReportInitial() { return {} }
    , LoadingPubPrevalByMessageReport() { return {} }
    , PubPrevalByMessageReportLoaded(data) { return { data } }
    , PubPrevalByMessageReportError(error) { return { error } }
  })
.derive(derivations.debugRepresentation)
export const { 
    PubPrevalByMessageReportInitial,
    LoadingPubPrevalByMessageReport, 
    PubPrevalByMessageReportLoaded, 
    PubPrevalByMessageReportError 
} = PubPrevalByMessageReportState


export const pubPrevalByMessageReport = (state=PubPrevalByMessageReportInitial(), action) => {
    switch(action.type) {
        case GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE:
            return LoadingPubPrevalByMessageReport()
        case GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE_FAILURE:
            return PubPrevalByMessageReportError(action.error)
        case GENERATE_PUB_PREVAL_REPORT_BY_MESSAGE_SUCCESS:
            return PubPrevalByMessageReportLoaded(action.data)
        default:
            return state
    }
}
