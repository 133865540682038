import {
  generateInternalBudgetThunk,
} from "../../services/internalBudget"

import { saveState } from "../../reducers/internalBudgetReducer"

import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Grid, Cell, Button } from 'react-mdl'
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
  return {
    internalBudget: state.internalBudget,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadInternalBudget: function (criteria, slug) {
      dispatch(generateInternalBudgetThunk(criteria, slug))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
  }
}

const commentStyle = {
  color: "#666",
  paddingBotton: "5px",
  fontSize: "80%",
}

const activeCampaignStyle = {
  color: "green",
}
const inactiveCampaignStyle = {
  color: "red",
}
const inactiveDailyCapCampaignStyle = {
  color: "blue",
}

class internalBudget extends React.Component {
  constructor(props) {
    super(props)

    this.pageSize = 100

    this.state = {
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaign: [],
      selectedPlacements: [],
      newValues: '',
      newValuesReplace: false,
      isLoading: false,
      isCriteriaCollapsed: false,
    }

    if (props.internalBudget.componentState) {
      this.state = props.internalBudget.componentState
    }

    if (props.slug && props.slug.length > 0) {
      let criteria = this.getCriteria()
      this.props.loadInternalBudget(criteria, props.slug)
    }
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      filterUpdated: true,
      startDate: startDate,
      endDate: endDate
    })
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleRunButtonClick() {
    let criteria = this.getCriteria()
    this.props.loadInternalBudget(criteria, this.state.filterUpdated ? "" : this.props.internalBudget.slug)
    this.setState({
      filterUpdated: false,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  getCriteria() {
    return {

    }
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."

    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
          <Grid>
            <Cell col={12}>
              <Collapsible title="Internal Budget" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={11}></Cell>
                  <Cell col={1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  <div className="report">
                    <div>{this.renderReport()}</div>
                  </div>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }


  renderReport() {
    if (this.props.internalBudget.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.internalBudget.error != null) {
      return <div>Error: {this.props.internalBudget.error.message}</div>
    }
    if (this.props.internalBudget.report == null) {
      return <div></div>
    }

    return <React.Fragment>
      <Grid style={{ "width": "100%" }}>
        {
          this.props.internalBudget.slug ? <Cell col={12}>
            <a target="_blank" rel="noreferrer"
              href={window.location.origin + window.location.pathname + `?format=json&report=internal-budget&slug=${this.props.internalBudget.slug}`}>
              Share Report</a>
          </Cell> : null
        }
        <Cell col={12}>{this.renderReportSection(this.props.internalBudget.report.highDeliveryPercentageCampaigns)}</Cell>
        <Cell col={12}><p></p></Cell>
        <Cell col={12}>{this.renderReportSection(this.props.internalBudget.report.expiringSoonCampaigns)}</Cell>
      </Grid>
    </React.Fragment>
  }

  renderReportSection(report) {

    return (
      <table className="report">
        <tr>
          <th colSpan={10} className="th dimension">{report.title}</th>
        </tr>
        <tr>
          <td colSpan={10} className="td dimension">
            <span style={activeCampaignStyle}>Green</span> - active; <span style={inactiveDailyCapCampaignStyle}>blue</span> - inactive due to daily cap</td>
        </tr>
        <tr className="th" key={`viewer.buysheet.header2`}>
          <th className="th dimension">Campaign</th>
          <th className="th dimension">Associations</th>
          <th className="th dimension">Internal<br />Lead Cap</th>
          <th className="th dimension">Optins Left</th>
          <th className="th dimension">Internal Optin<br />Delivery %</th>
          <th className="th dimension">Daily Limit</th>
          <th className="th dimension">Daily Left</th>
          <th className="th dimension">Rate</th>
          <th className="th dimension">Start date</th>
          <th className="th dimension">End date</th>
        </tr>
        <tbody>
          {report.records.length == 0 && <tr className="td" key={`viewer.buysheet.0`}><td className="td dimension" colSpan={10} style={{ textAlign: "center" }}>No records</td></tr>}
          {report.records.map((e, i) => {
            let campaignStype = {}
            if (e.campaignIsActive) {
              if (e.hasDailyCap && e.hitDailyLeadCap) {
                campaignStype = inactiveDailyCapCampaignStyle
              } else {
                campaignStype = activeCampaignStyle
              }
            } else {
              campaignStype = inactiveCampaignStyle
            }
            return (
              <tr className="td" key={`viewer.buysheet.${i}`}>
                <td className="td dimension">
                  {<div style={campaignStype}>{`${e.campaignName} - ${e.campaignShortCode}`}</div>}
                  <div style={commentStyle}>{e.campaignUUID}</div>
                </td>
                <td className="td dimension">
                  <div>{`Non Brokered:${e.nonBrokeredAssociationCount}`}</div>
                  <div>{`Brokered:${e.brokeredAssociationCount}`}</div>
                </td>
                <td className="td metric">{e.internalLeadCap}</td>
                <td className="td metric">{e.internalLeadsToDeliver}</td>
                <td className="td metric">{e.internalLeadsDeliveredPercentageFormatted}</td>
                <td className="td metric">{e.hasDailyLeadCap && e.dailyLeadCap}</td>
                <td className="td metric">{e.hasDailyLeadCap && e.dailyLeadsToDeliver}</td>
                <td className="td metric">${e.rate}</td>
                <td className="td dimension">{e.startDateFormatted}</td>
                <td className="td dimension">{e.endDateFormatted}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

}


const InternalBudget = connect(mapStateToProps, mapDispatchToProps)(internalBudget)

export default InternalBudget
