import React from 'react'
import { Grid, Cell, Card, CardActions, CardTitle, Icon, Button, Switch } from 'react-mdl'
import Editor from '../editor'
import { CustomValidationPopup } from './customValidationPopup'

const exampleScript = `// leadJsonString is a magic variable that injects a json string of the lead
let lead = JSON.parse(leadJsonString);
/*
Available data 
lead.uuid
lead.campaign_uuid
lead.placement_uuid
lead.session_uuid 
lead.created
lead.isTest
lead.legacy_values
lead.marketing_id
lead.metadata
lead.opt_in
lead.questions

lead.user.address1
lead.user.address2
lead.user.city
lead.user.country
lead.user.dob
lead.user.email
lead.user.first_name
lead.user.gender
lead.user.ip
lead.user.last_name
lead.user.local_time
lead.user.phone
lead.user.state
lead.user.title
lead.user.zipcode
*/

console.dir(leadJsonString)
if (lead.user.ip === '108.14.72.125') {
    fetch({ 
      	url: 'https://en9v3f4met8wo.x.pipedream.net', 
      	method: "POST", 
        body: JSON.stringify({ "funStuff" : "a nice thing!" }), 
      	headers: [{"auth-header-thingamajig":"my-cool-header-token"}]
    }).then(data => {
            if (data === 'HTTPBIN is awesome') {
                return {
                    "status": "valid", 
                    "message": "everything went great",
                    "autoCorrectedFields": {
                        "phone": "1+"+lead.user.phone
                    }
                }
            } else {
                return {
                    "status": "invalid",
                    "message": "a horrible bad thing happened"
                }
            }
        });
} else {
    let x = {
        "status": "valid", 
        "message": "everything went great",
      	"autoCorrectedFields": {
         	"phone": "1+"+lead.user.phone,
          	"email": lead.user.email.toLowerCase(),
          	"strng": leadJsonString,
        }
    }
    x
}`

export default class CustomValidation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            enabled: props.customValidation.enabled,
            script: props.customValidation.script,
            uuid: props.customValidation.uuid
        }
    }

    dialogCancel() {
        this.setState({
            showDialog: false,
            dialogTitle: '',
            emailName: '',
        })
    }

    handleShowModal() {
        this.setState({
            showDialog: true,
        })
    }

    handleSaveButton() {
        this.setState({
            newValues: '',
        })
    }

    handleModalOk() {
        this.dialogCancel()
        this.setState({
            newValues: '',
        })
    }

    handleEnabledChanged(val) {
        let script = this.state.script
        if (script.length === 0) {
            script = exampleScript
        }
        this.setState({
            enabled: val,
        })
    }
    render() {
        return (
            <React.Fragment>
                <section ref={t => (this.contentSection = t)}>
                    <div className="content">
                        {this.state.openPopup ? <CustomValidationPopup
                            clearPreview={this.props.clearPreview}
                            parent={this.contentSection}
                            onClose={() => {
                                this.setState({
                                    openPopup: false
                                })
                            }}
                            test={lead => this.props.testCustomValidation(lead)}
                            key={new Date().getMilliseconds()}
                            campaign={this.props.campaign}
                            customValidationPreview={this.props.customValidationPreview} /> : null}
                        <Grid>
                            <Cell col={12}>
                                <Card shadow={2}>
                                    <CardTitle>Custom Validation</CardTitle>
                                    <Button
                                        onClick={() => this.setState({ openPopup: true })}
                                        raised ripple colored>
                                        <Icon name="directions_run" /> Test
                                                    </Button>
                                    <CardActions border>
                                        <Grid>
                                            <Cell col={12}>
                                                <Editor
                                                    style={{ width: "99%" }}
                                                    className="codeFull"
                                                    code={this.state.script || exampleScript}
                                                    mode="javascript"
                                                    onChange={c => {
                                                        this.setState({ script: c })
                                                    }}
                                                    onBlur={() => {
                                                        this.setState(this.state)
                                                    }}
                                                />
                                                <Switch
                                                    onChange={() => this.handleEnabledChanged(!this.state.enabled)}
                                                    checked={this.state.enabled || false}>
                                                    {this.state.enabled ? "Enabled" : "Paused"}
                                                </Switch>
                                                <div className="mdl-cell mdl-cell--12-col">
                                                    <Button
                                                        onClick={() => this.props.save(this.state)}
                                                        raised ripple colored
                                                        disabled={this.props.customValidation.enabled === this.state.enabled && this.props.customValidation.script === this.state.script}>
                                                        <Icon name="save" /> Save
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.setState({ openPopup: true })}
                                                        raised ripple colored>
                                                        <Icon name="directions_run" /> Test
                                                    </Button>
                                                </div>
                                            </Cell>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Cell>
                        </Grid>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
