import {
    generateSingleValidationsReport,
    generateSingleValidationsReportFailure,
    generateSingleValidationsReportSuccess
} from '../reducers/singleValidationsReportReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'
import store from '../store'

const url = getUrlFor("reporting", "single-validation-by-message")
//const url = "http://localhost:9001/single-validation-by-message/"
//const url = "http://reporting.d.adquire.com/single-validation-by-message-mock/"
//const url = "http://localhost:9001/single-validation-by-message-mock/"


export function generateSingleValidationsReportThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generateSingleValidationsReport())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                console.dir(res.data)
                dispatch(generateSingleValidationsReportSuccess(res.data))
            }).catch(e => {
                dispatch(generateSingleValidationsReportFailure(new Error(`Getting single validations report - ${e.statusText}`)))
                exceptionHandling(e, dispatch, "Getting single validations report")
            })
    }
}

export const fetchSingleValidationsCsvReport = (criteria) => {
    return fetch(`${url}?format=csv`, {
        method: "post",
        headers: {
            'Accept': 'application/octet-stream',
            'Authorization': store.getState().jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(criteria)
    }).then(response => {
        if (response.ok && response.status === 200) {
            return response.blob()
        }
        return Promise.reject(new Error(response.statusText))
    })
}
