import React from "react"
import { Grid, Cell } from "react-mdl"
import JSONPretty from 'react-json-pretty'
import { Collapsible } from "../common/components"

const commentStyle = {
  color: "#666",
  paddingBotton: "5px",
  fontSize: "80%"
}

const sectionStyle = {
  border: '1px solid rgba(0, 0, 0, 0.5)', 
}

class SessionDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPrevalidations: this.props.showPrevalidations != null ? this.props.showPrevalidations : true,
      showPostvalidations: this.props.showPostvalidations != null ? this.props.showPostvalidations : true,
    }
  }

  handlePrevalidationToggle() {
    this.setState({
      showPrevalidations: !this.state.showPrevalidations
    })
  }

  handlePostvalidationToggle() {
    this.setState({
      showPostvalidations: !this.state.showPostvalidations
    })
  }

  render() {
    let data = this.props.data
    
    return (
      <div style={sectionStyle}>
        <Grid>
          <Cell className="reportColumn" col={12}>
            <table className="report">
              <thead>
              <tr key={`viewer.sessionDetails.header`}>
                <td className="th">Session UUID</td>
                <td className="th">Created At</td>
                <td className="th">Placement</td>
                <td className="th">Pub Key</td>
                <td className="th">Software</td>
                <td className="th">User Data</td>
                <td className="th">Device</td>
              </tr>
              </thead>
              <tbody>
              <tr key={`viewer.sessionDetails.row.1`}>
                <td className="td">{data.session.sessionUUID}</td>
                <td className="td">{data.session.createdAt}</td>
                <td className="td">
                  {`${data.session.placementLegacyCode} - ${data.session.placementName}`}
                  <div style={commentStyle}>{data.session.placementUUID}</div>
                </td>
                <td className="td">{data.session.pubKey}</td>
                <td className="td">
                  {data.session.softwareName} {data.session.softwareVersion}
                </td>
                <td className="td">
                  <div>Email: {data.session.email}</div>
                  <div>First Name: {data.session.firstName}</div>
                  <div>Last Name: {data.session.lastName}</div>
                  <div>Address1:{data.session.address1}</div>
                  <div>Address2:{data.session.address2}</div>
                  <div>City:{data.session.city}</div>
                  <div>State:{data.session.state}</div>
                  <div>Zip:{data.session.zipcode}</div>
                  <div>Phone: {data.session.phone}</div>
                  <div>DOB: {data.session.DOB}</div>
                  <div>Gender: {data.session.gender}</div>
                  <div>UserIP: {data.session.userIP}</div>
                  <div>Pub Time: {data.session.localTime}</div>
                </td>
                <td className="td">
                  <div>UserAgent: {data.session.deviceUserAgent}</div>
                  <div>Type: {data.session.deviceType}</div>
                  <div>MobileType: {data.session.deviceMobileType}</div>
                  <div>OsName: {data.session.deviceOsName}</div>
                  <div>OsVersion: {data.session.deviceOsVersion}</div>
                  <div>BrowserName: {data.session.deviceBrowserName}</div>
                  <div>BrowserVersion: {data.session.deviceBrowserVersion}</div>
                </td>
              </tr>
              </tbody>
            </table>
          </Cell>
          <Cell col={12}>
            <Grid>
              {this.state.showPrevalidations && <Cell col={12}>
                <Collapsible title={`Pre-validations:`} defaultOpen={true}>
                <div style={sectionStyle}>
                  {this.renderSessionDetailsAssociations(data.prevalidations, true)}
                </div>
                </Collapsible>
              </Cell>}
              {this.state.showPrevalidations && <Cell col={12}>
              <Collapsible title={`Post-validations:`} defaultOpen={true}>
                <div style={sectionStyle}>
                  {this.renderSessionDetailsAssociations(data.postvalidations, false)}
                </div>
                </Collapsible>
              </Cell>}
            </Grid>
          </Cell>
        </Grid>
      </div>
    )
  }

  renderSessionDetailsAssociations(associations, isPreval) {
    if(associations.length == 0){
      return <div>No items</div>
    }

    return (
      <Grid>
        {associations.map((e, i) => {
          return (
            <React.Fragment key={`viewer.sessionDetails.associations.${i}`}>
              <Cell col={12}>
                <div>
                  {`Campaign: ${e.campaignShortCode} - ${e.campaignName} - `}
                  <span style={commentStyle}>{e.campaignUUID}</span>
                </div>
              </Cell>
              <Cell key={`viewer.sessionDetails.associations.optins.${i}`} col={12}>
                {!isPreval && (
                  <Grid>
                    <Cell className="reportColumn" col={12}>
                      <table className="report">
                        <thead>
                        <tr>
                          <th className="td" colSpan={3}>
                            <b>Optin</b>
                          </th>
                        </tr>
                        <tr>
                          <th className="th">Optin UUID</th>
                          <th className="th">Crated At</th>
                          <th className="th">User Data</th>
                        </tr>
                        </thead>
                        <tbody>
                        {e.optins.map((optin, j) => {
                          return (
                            <React.Fragment key={`viewer.sessionDetails.optins.${i}.rowwrapper.${j}`}>
                            <tr key={`viewer.sessionDetails.optins.${i}.row.${j}`}>
                              <td className="td">{optin.optinUUID}</td>
                              <td className="td">{optin.createdAt}</td>
                              <td className="td">
                                <div>Email: {optin.email}</div>
                                <div>
                                  Name: {optin.firstName} {optin.lastName}
                                </div>
                                <div>
                                  Address:
                                  {optin.address1} {optin.address2},{optin.city},{optin.state},{optin.zipcode}
                                </div>
                                <div>Phone: {optin.phone}</div>
                                <div>DOB: {optin.DOB}</div>
                                <div>Gender: {optin.gender}</div>
                                <div>UserIP: {optin.userIP}</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="td" colSpan={3}>
                                <JSONPretty data={optin.optinString}></JSONPretty>
                              </td>
                            </tr>
                            </React.Fragment>
                          )
                        })}
                        </tbody>
                      </table>
                    </Cell>
                  </Grid>
                )}
                <Grid>
                  <Cell className="reportColumn" col={12}>
                    <table className="report">
                      <thead>
                      <tr>
                        <th className="td" colSpan={4}>
                          {isPreval && "Pre-validations"}
                          {!isPreval && "Post-validations"}
                        </th>
                      </tr>
                      <tr>
                        <th className="th">Service Name</th>
                        <th className="th">Message</th>
                        <th className="th">Is Valid</th>
                        <th className="th">Created At</th>
                      </tr>
                      </thead>
                      <tbody>
                      {e.singleValidations.map((val, j) => {
                        return (
                          <tr key={`viewer.sessionDetails.valiations.${isPreval}.${i}.row.${j}`}>
                            <td className="td">{val.validationServiceName}</td>
                            <td className="td">{val.validationMessage}</td>
                            <td className="td">{val.isValid.toString()}</td>
                            <td className="td">{val.createdAt}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Cell>
            </React.Fragment>
          )
        })}
      </Grid>
    )
  }
}

export default SessionDetails
