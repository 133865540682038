import {
  setCrud,
  loadTagsTaxonomy,
  saveTagToMasterList,
  uploadSuppressionList,
  deleteMasterTag,
  loadDarwinGroups,
  loadDarwinAllGroupsMembers,
  retrieveDefaultValidators,
  putDefaultValidator,
  deleteDefaultValidator,
  disableValidator,
  retrieveCampaignsValidationInfo,
  retrieveEmailDomainsToReview,
  isDirty
} from "../../services"

import { retrieveCustomersThunk, saveCustomerThunk, createKeyCloakAccountThunk } from "../../services/customerService"

import React from "react"
import { connect } from "react-redux"

import Tools from "./tools"
import BucketManager from "../bucketManager"
import BadWords from "../badwords"
import BlockedEmailDomains from "../blocked-email-domains"
import BlockedEmails from "../blocked-emails"
import BlockedAreaCodes from "../blocked-areacodes"
import TagTaxonomy from "../tagging/taxonomy"
import Winbacks from "../winbacks"
import DarwinGroups from "../darwin-groups"
import ValidationManagement from "../validation-management"
import ContactManager from "./contactManager.js"
import { Grid, Cell, Tab, Tabs } from "react-mdl"

function mapStateToProps(state) {
  return {
    isDirtyValue: state.isDirty,
    campaigns: state.campaignData.records,
    tagMasterList: state.tagMasterList,
    tagChildrenList: state.tagChildrenList,
    altParentTag: state.altParentTag,
    zips: state.zips,
    badwords: state.badwords,
    campaign: state.campaign,
    creative: state.creative,
    creatives: state.creatives,
    buckets: state.buckets,
    domains: state.blockedEmailDomains,
    user: state.user,
    emails: state.blockedEmails,
    blockedEmailResult: state.blockedEmailResult,
    areaCodes: state.blockedAreaCodes,
    blockedAreaCodeResult: state.blockedAreaCodeResult,
    winbacks: state.winbacks,
    winbackError: state.winbackError,
    winbackCampaigns: state.winbackCampaigns,
    darwinGroups: state.darwinGroups,
    darwinGroup: state.darwinGroup,
    darwinGroupMemberList: state.darwinGroupMemberList,
    darwinAllGroupsMembersList: state.darwingAllGroupsMembersList,
    placements: state.placementData.records,
    uploadingSuppressionListFile: state.uploadingSuppressionListFile,
    uploadSuppresionListData: state.uploadSuppresionListData,
    updateProgress: state.updateProgress,
    validators: state.validators,
    defaultValidators: state.defaultValidators,
    validatorsData: state.validatorsData,
    emailDomainsReview: state.emailDomainsReview,
    keycloakResults: state.keyCloakUserCreate,
    customers: state.customers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadSuppression(emails, campaignUUID) {
      dispatch(uploadSuppressionList(emails, campaignUUID))
    },
    loadTagsTaxonomy() {
      dispatch(loadTagsTaxonomy())
    },
    saveTagToMasterList(value) {
      dispatch(saveTagToMasterList(value))
    },
    deleteTagFromMasterList(value) {
      dispatch(deleteMasterTag(value))
    },
    setCrud(value) {
      dispatch(setCrud(value))
    },
    loadDarwinGroups() {
      dispatch(loadDarwinGroups())
    },
    loadDarwinAllGroupsMembers() {
      dispatch(loadDarwinAllGroupsMembers())
    },
    retrieveDefaultValidators() {
      dispatch(retrieveDefaultValidators())
    },
    putDefaultValidator(name) {
      dispatch(putDefaultValidator(name))
    },
    deleteDefaultValidator(name) {
      dispatch(deleteDefaultValidator(name))
    },
    resync() {
      dispatch(loadDarwinAllGroupsMembers())
    },
    retrieveCampaignsValidationInfo() {
      dispatch(retrieveCampaignsValidationInfo())
    },
    disableValidator(name) {
      dispatch(disableValidator(name))
    },
    retrieveEmailDomainsToReview() {
      dispatch(retrieveEmailDomainsToReview())
    },
    isDirty: function () {
      dispatch(isDirty(true))
    },
    isNotDirty: function () {
      dispatch(isDirty(false))
    },
    saveCustomer: function (customer) {
      dispatch(saveCustomerThunk(customer))
    },
    createKeyCloakAccount: function (placementUUID, customerUUID, contact) {
      dispatch(createKeyCloakAccountThunk(placementUUID, customerUUID, contact))
    },
    retrieveCustomers: function () {
      dispatch(retrieveCustomersThunk())
    }
  }
}

class opsTools extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      tabSeed: Math.random()
    }
    this.setIsTabComponentDirty = isTabComponentDirty => (this.isTabComponentDirty = isTabComponentDirty)
    this.isTabComponentDirty = () => null
  }

  componentDidMount() {
    let crudOptions = {
      onSave: () => { },
      onCancel: () => { },
      onNew: () => { },
      visible: false
    }
    this.props.setCrud(crudOptions)
    this.props.loadTagsTaxonomy()
    this.props.loadDarwinGroups()
    this.props.loadDarwinAllGroupsMembers()
    this.props.retrieveCampaignsValidationInfo()
    this.props.retrieveEmailDomainsToReview()
  }

  checkIsDirty(activeTab) {
    const isDirtyMap = {
      "0": () => this.isTabComponentDirty(),
      "1": () => this.isTabComponentDirty(),
      "2": () => this.isTabComponentDirty(),
      "3": () => this.isTabComponentDirty(),
      "4": () => this.isTabComponentDirty(),
      "5": () => this.isTabComponentDirty(),
      "6": () => this.isTabComponentDirty(),
      "7": () => this.isTabComponentDirty(),
      "8": () => this.props.isDirtyValue,
      "9": () => false,
      "10": () => this.props.isDirtyValue
    }
    return isDirtyMap[activeTab]()
  }

  handleTabChange(tabId) {
    if (
      this.checkIsDirty(this.state.activeTab) &&
      !confirm("You have unsaved changes, are you sure you want to leave?")
    ) {
      this.setState({ tabSeed: Math.random() })
      return
    }
    this.setState({ activeTab: tabId })
  }

  render() {
    let tag = (
      <Grid style={{ height: "100%" }}>
        <Cell col={12}>
          <TagTaxonomy
            tags={this.props.tagMasterList}
            childTags={this.props.tagChildrenList}
            altParentTag={this.props.altParentTag}
            setIsTabComponentDirty={this.setIsTabComponentDirty}
          />
        </Cell>
      </Grid>
    )
    return (
      <div>
        <Tabs
          key={`tabs-${this.state.tabSeed}`}
          activeTab={this.state.activeTab}
          onChange={tabId => this.handleTabChange(tabId)}
          ripple
          style={{ width: "100%" }}>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Tools</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Bad Words</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Demographics</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Tag Management</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Blocked-domains</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Blocked-emails</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Blocked-areacodes</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Winbacks</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Darwin</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Validation management</span>
          </Tab>
          <Tab id="customers-tab" style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Contacts</span>
          </Tab>
        </Tabs>
        <section>
          <div className="content">
            {this.state.activeTab == 0 ? (
              <Tools
                key={this.props.uploadSuppresionListData.progress}
                campaigns={this.props.campaigns}
                uploadingSuppressionListFile={this.props.uploadingSuppressionListFile}
                uploadSuppresionListData={this.props.uploadSuppresionListData}
                updateProgress={this.props.updateProgress}
                zips={this.props.zips}
                setIsTabComponentDirty={this.setIsTabComponentDirty}
              />
            ) : null}
            {this.state.activeTab == 1 ? (
              <BadWords badwords={this.props.badwords} setIsTabComponentDirty={this.setIsTabComponentDirty} />
            ) : null}
            {this.state.activeTab == 2 ? (
              <BucketManager
                campaign={this.props.campaign}
                creative={this.props.creative}
                creatives={this.props.creatives}
                buckets={this.props.buckets}
                setIsTabComponentDirty={this.setIsTabComponentDirty}
              />
            ) : null}
            {this.state.activeTab == 3 ? tag : null}
            {this.state.activeTab == 4 ? (
              <BlockedEmailDomains
                domains={this.props.domains}
                user={this.props.user}
                emailDomainsReview={this.props.emailDomainsReview}
                setIsTabComponentDirty={this.setIsTabComponentDirty}
              />
            ) : null}
            {this.state.activeTab == 5 ? (
              <BlockedEmails
                user={this.props.user}
                emails={this.props.emails}
                blockedEmailResult={this.props.blockedEmailResult}
                setIsTabComponentDirty={this.setIsTabComponentDirty}
              />
            ) : null}
            {this.state.activeTab == 6 ? (
              <BlockedAreaCodes
                user={this.props.user}
                areaCodes={this.props.areaCodes}
                blockedAreaCodeResult={this.props.blockedAreaCodeResult}
                setIsTabComponentDirty={this.setIsTabComponentDirty}
              />
            ) : null}
            {this.state.activeTab == 7 ? (
              <Winbacks
                campaigns={this.props.campaigns}
                winbacks={this.props.winbacks}
                winbackError={this.props.winbackError}
                winbackCampaigns={this.props.winbackCampaigns}
                setIsTabComponentDirty={this.setIsTabComponentDirty}
              />
            ) : null}
            {this.state.activeTab == 8 ? (
              <DarwinGroups
                darwinGroups={this.props.darwinGroups}
                darwinAllGroupsMembers={this.props.darwinAllGroupsMembersList}
                darwinGroup={this.props.darwinGroup}
                members={this.props.darwinGroupMemberList}
                placements={this.props.placements}
                user={this.props.user}
                isDirtyValue={this.props.isDirtyValue}
              />
            ) : null}
            {this.state.activeTab == 9 ? (
              <ValidationManagement
                user={this.props.user}
                validators={this.props.validators}
                defaultCampaignValidators={this.props.defaultValidators}
                resync={this.props.resync}
                putDefaultValidator={this.props.putDefaultValidator}
                deleteDefaultValidator={this.props.deleteDefaultValidator}
                disableValidator={this.props.disableValidator}
                validatorsData={this.props.validatorsData}
              />
            ) : null}
            {this.state.activeTab == 10 ? (
              <ContactManager
                keycloakResults={this.state.keycloakResults}
                placements={this.props.placements}
                customers={this.props.customers}
                setCrud={this.props.setCrud}
                createKeyCloakAccount={this.props.createKeyCloakAccount}
                saveCustomer={this.props.saveCustomer}
                isDirty={this.props.isDirty}
                isNotDirty={this.props.isNotDirty}
                retrieveCustomers={this.props.retrieveCustomers}
                isDirtyValue={this.props.isDirtyValue}
              />
            ) : null}
          </div>
        </section>
      </div>
    )
  }
}
const OpsTools = connect(mapStateToProps, mapDispatchToProps)(opsTools)

export default OpsTools
