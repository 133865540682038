import React from 'react'
import { CellMeasurer } from 'react-virtualized'
import classNames from 'classnames'

class CellRendererByMessage extends React.Component {
        constructor(props) {
                super(props)
        }

        render() {
                let { columnIndex, key, rowIndex, parent, cache } = this.props

                return (<CellMeasurer
                        cache={cache}
                        columnIndex={columnIndex}
                        key={key}
                        parent={parent}
                        rowIndex={rowIndex}>
                        {this.renderContent()}
                </CellMeasurer>)

        }

        renderContent() {
                let { columnIndex, key, rowIndex, style, comp, openSessionPopup } = this.props

                let dataRowNumber = rowIndex - 2
                let dataColumnNumber = columnIndex - 3

                if (rowIndex === 0) {
                        if (columnIndex <= 2) {
                                //first row - dimensions
                                return <div key={key} style={style} className="th dimension">{""}</div>
                        } else {
                                //first row - pivots/metrics
                                if ((columnIndex - 3) % 1 === 0) {
                                        let pivotNumber = (columnIndex - 3) / 1
                                        return (
                                                <div key={key} style={{ ...style, width: style.width * 1 }} className="th pivot">
                                                        {comp.PivotSettings.PivotDimensionValues[pivotNumber]}
                                                </div>
                                        )
                                } else {
                                        return <div style={{ display: 'none' }} />
                                }
                        }
                } else if (rowIndex === 1) {
                        //second row - dimensions
                        if (columnIndex == 0) {
                                return <div key={key} style={style} className="th dimension">{"Placement"}</div>
                        } else if (columnIndex == 1) {
                                return <div key={key} style={style} className="th dimension">{"Campaign"}</div>
                        } else if (columnIndex == 2) {
                                return <div key={key} style={style} className="th dimension">{"Short Code"}</div>
                        } else {
                                //second row - metrics
                                return <div key={key} style={style} className="th metric">{"Failed"}</div>
                        }
                } else {
                        var cellClasses = classNames({
                                'oddrow': rowIndex % 2 === 1,
                        })

                        if (columnIndex == 0) {
                                return (
                                        <div key={key} style={style} className={"td dimension " + cellClasses}>
                                                {comp.RowCluster[dataRowNumber].RowKeys[0].DisplayKey}
                                        </div>
                                )
                        } else if (columnIndex == 1) {
                                return (
                                        <div key={key} style={style} className={"td dimension " + cellClasses}>
                                                {comp.RowCluster[dataRowNumber].RowKeys[2].DisplayKey}
                                        </div>
                                )
                        } else if (columnIndex == 2) {
                                return (
                                        <div key={key} style={style} className={"td dimension " + cellClasses}>
                                                {comp.RowCluster[dataRowNumber].RowKeys[3].DisplayKey}
                                        </div>
                                )
                        } else {
                                let cellStyle = {}
                                let bgColor = comp.RowCluster[dataRowNumber].RowValueSets[0].RowValues[dataColumnNumber].BackgroundColor
                                if (bgColor) {
                                        cellStyle = { backgroundColor: bgColor }
                                }
                                let placementUUID = comp.RowCluster[dataRowNumber].RowKeys[1].DisplayKey
                                let campaignUUID = comp.RowCluster[dataRowNumber].RowKeys[4].DisplayKey
                                
                                //let message = "Lead already exists"
                                let message = comp.PivotSettings.PivotDimensionValues[dataColumnNumber]
                                return (
                                        <div key={key} className={"td metric " + cellClasses} style={Object.assign(cellStyle, style)}>
                                                <div className={"content clickable"} onClick={() => openSessionPopup(campaignUUID, placementUUID, message)}>
                                                        {comp.RowCluster[dataRowNumber].RowValueSets[0].RowValues[dataColumnNumber].DataValue}
                                                </div>
                                        </div>
                                )
                        }

                }
        }
}

export default CellRendererByMessage