const { union, derivations } = require('folktale/adt/union')

const GENERATE_SINGLE_VALIDATIONS_CSV_REPORT = 'GENERATE_SINGLE_VALIDATIONS_CSV_REPORT'
const GENERATE_SINGLE_VALIDATIONS_CSV_REPORT_FAILURE = 'GENERATE_SINGLE_VALIDATIONS_CSV_REPORT_FAILURE'
const GENERATE_SINGLE_VALIDATIONS_CSV_REPORT_SUCCESS = 'GENERATE_SINGLE_VALIDATIONS_CSV_REPORT_SUCCESS'

export const generateSingleValidationsCsvReport = () =>
    ({ type: GENERATE_SINGLE_VALIDATIONS_CSV_REPORT })

export const generateSingleValidationsCsvReportSuccess = data =>
    ({ type: GENERATE_SINGLE_VALIDATIONS_CSV_REPORT_SUCCESS, data })

export const generateSingleValidationsCsvReportFailure = error =>
    ({ type: GENERATE_SINGLE_VALIDATIONS_CSV_REPORT_FAILURE, error })

export const SingleValidationsCsvReportState = union('SingleValidationsCsvReportState', {
    SingleValidationsCsvReportInitial() { return {} }
    , LoadingSingleValidationsCsvReport() { return {} }
    , SingleValidationsCsvReportLoaded(data) { return { data } }
    , SingleValidationsCsvReportError(error) { return { error } }
  })
.derive(derivations.debugRepresentation)
export const { 
    SingleValidationsCsvReportInitial,
    LoadingSingleValidationsCsvReport, 
    SingleValidationsCsvReportLoaded, 
    SingleValidationsCsvReportError 
} = SingleValidationsCsvReportState

export const singleValidationsCsvReport = (state=SingleValidationsCsvReportInitial(), action) => {
    switch(action.type) {
        case GENERATE_SINGLE_VALIDATIONS_CSV_REPORT:
            return LoadingSingleValidationsCsvReport()
        case GENERATE_SINGLE_VALIDATIONS_CSV_REPORT_FAILURE:
            return SingleValidationsCsvReportError(action.error)
        case GENERATE_SINGLE_VALIDATIONS_CSV_REPORT_SUCCESS:
            return SingleValidationsCsvReportLoaded(action.data)
        default:
            return state
    }
}
