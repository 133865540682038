const SAVE_STATE_OF_GENERATE_BUY_SHEET = 'SAVE_STATE_OF_GENERATE_BUY_SHEET'
const GENERATE_BUY_SHEET_REPORT = 'GENERATE_BUY_SHEET_REPORT'
const GENERATE_BUY_SHEET_REPORT_FAILURE = 'GENERATE_BUY_SHEET_REPORT_FAILURE'
const GENERATE_BUY_SHEET_REPORT_SUCCESS = 'GENERATE_BUY_SHEET_REPORT_SUCCESS'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_GENERATE_BUY_SHEET, data })

export const generateBuySheetReport = () =>
    ({ type: GENERATE_BUY_SHEET_REPORT })

export const generateBuySheetReportSuccess = data =>
    ({ type: GENERATE_BUY_SHEET_REPORT_SUCCESS, data })

export const generateBuySheetReportFailure = error =>
    ({ type: GENERATE_BUY_SHEET_REPORT_FAILURE, error })


export const buySheetReportState = (state = { report: null, isLoading: false, error: null, componentState: null }, action) => {
    switch (action.type) {
        case SAVE_STATE_OF_GENERATE_BUY_SHEET:
            return {
                ...state,
                componentState: action.data,
            }
        case GENERATE_BUY_SHEET_REPORT:
            return {
                ...state,
                isLoading: true,
                report: null,
                error: null,
            }
        case GENERATE_BUY_SHEET_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                report: { associations: action.data.Report.Associations },
                slug: action.data.Request.Slug,
                request: action.data.Request,
                error: null,
            }
        case GENERATE_BUY_SHEET_REPORT_FAILURE:
            return {
                ...state,
                isLoading: false,
                report: null,
                error: { message: action.error },
            }
        default:
            return state
    }
}
