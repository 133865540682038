const SAVE_STATE_OF_SINGLE_VALIDATIONS = 'SAVE_STATE_OF_SINGLE_VALIDATIONS'

const SINGLE_VALIDATIONS_START_LOADING = 'SINGLE_VALIDATIONS_START_LOADING'
const SINGLE_VALIDATIONS_LOADED = 'SINGLE_VALIDATIONS_LOADED'
const SINGLE_VALIDATIONS_FAILED = 'SINGLE_VALIDATIONS_FAILED'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_SINGLE_VALIDATIONS, data })

export const startLoadingSingleValidations = () =>
    ({ type: SINGLE_VALIDATIONS_START_LOADING })

export const loadedSingleValidations = (criteria, validations) =>
    ({ type: SINGLE_VALIDATIONS_LOADED, data: {criteria:criteria, validations:validations} })

export const failedSingleValidations = (error) =>
    ({ type: SINGLE_VALIDATIONS_FAILED, data: {error:error} })

const SINGLE_VALIDATIONS_SESSIONS_START_LOADING = 'SINGLE_VALIDATIONS_SESSIONS_START_LOADING'
const SINGLE_VALIDATIONS_SESSIONS_LOADED = 'SINGLE_VALIDATIONS_SESSIONS_LOADED'
const SINGLE_VALIDATIONS_SESSIONS_DIALOG_CLOSED = 'SINGLE_VALIDATIONS_SESSIONS_DIALOG_CLOSED'

export const startLoadingSessions = () =>
    ({ type: SINGLE_VALIDATIONS_SESSIONS_START_LOADING })

export const loadedSessions = (criteria, sessions) =>
    ({ type: SINGLE_VALIDATIONS_SESSIONS_LOADED, data: {criteria:criteria, sessions:sessions} })

export const closedSessionPopup = () =>
    ({ type: SINGLE_VALIDATIONS_SESSIONS_DIALOG_CLOSED})


const initialState = { 
    sessionReport:{
      sessions: null, 
      isLoadingSessions: false,
      showSessionPopup: false,
      criteria: null,
    },
    singleValidationsReport:{
        //validations: null, 
        //error: null,
        //isLoadingValidations: false,
        //criteria: null,
        componentState: null,
      }
   }
export function singleValidationsReportV2(state = initialState, action) {
    switch(action.type) {
      case SAVE_STATE_OF_SINGLE_VALIDATIONS:
            return {
                ...state,
                singleValidationsReport:{
                  ...state.singleValidationsReport,
                  componentState: action.data,
                }
            }    
      case SINGLE_VALIDATIONS_SESSIONS_START_LOADING:
        return {
          ...state,
          sessionReport: {
            ...state.sessionReport,
            isLoadingSessions: true,
            showSessionPopup: true,
            criteria: null,
            sessions: null,
          }
        }
        case SINGLE_VALIDATIONS_SESSIONS_LOADED:
          return {
            ...state,
            sessionReport: {
              ...state.sessionReport,
              isLoadingSessions: false,
              criteria: action.data.criteria,
              sessions: action.data.sessions,
            }
        }
        case SINGLE_VALIDATIONS_SESSIONS_DIALOG_CLOSED:
          return {
            ...state,
            sessionReport: {
              ...state.sessionReport,
              showSessionPopup: false,
              criteria: null,
              sessions: null,
            }
          }

          case SINGLE_VALIDATIONS_START_LOADING:
            return {
              ...state,
              singleValidationsReport: {
                ...state.singleValidationsReport,
                isLoadingValidations: true,
                showSessionPopup: true,
                criteria: null,
                validations: null,
              }
            }
            case SINGLE_VALIDATIONS_LOADED:
              return {
                ...state,
                singleValidationsReport: {
                  ...state.singleValidationsReport,
                  isLoadingValidations: false,
                  criteria: action.data.criteria,
                  validations: action.data.validations,
                }
            }
            case SINGLE_VALIDATIONS_FAILED:
              return {
                ...state,
                singleValidationsReport: {
                  ...state.singleValidationsReport,
                  isLoadingValidations: false,
                  criteria: action.data.criteria,
                  error: action.data.error,
                }
            }

        default:
        return state
    }
  }

  