import {
    generateCampaignQuickStatus,
    generateCampaignQuickStatusFailure,
    generateCampaignQuickStatusSuccess
} from '../reducers/campaignQuickStatusReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

const url = getUrlFor("reporting", "campaign-quick-status")
//const url = "http://localhost:9001/campaign-quick-status/"
//const url = "http://localhost:9001/campaign-quick-status-mock/"


export function generateCampaignQuickStatusThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generateCampaignQuickStatus())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generateCampaignQuickStatusSuccess(res.data))
            }).catch(e => {
                dispatch(generateCampaignQuickStatusFailure(`Getting campaign quick status report - ${e.statusText}`))
                exceptionHandling(e, dispatch, "Getting campaign quick status report")
            })
    }
}
