const SESSION_LOOKUP_START_LOADING = 'SESSION_LOOKUP_START_LOADING'
const SESSION_LOOKUP_LOADED = 'SESSION_LOOKUP_LOADED'

export const startLoadingSessionLookup = () =>
  ({ type: SESSION_LOOKUP_START_LOADING })

export const loadedSessionLookup = (criteria, data) =>
  ({ type: SESSION_LOOKUP_LOADED, data: { criteria: criteria, data: data } })


const initialState = {
  criteria: null,
  data: null,
  isLoading: false,
}
export function sessionLookup(state = initialState, action) {
  switch (action.type) {
    case SESSION_LOOKUP_START_LOADING:
      return {
        ...state,
        isLoading: true,
        criteria: null,
        data: null,
      }
    case SESSION_LOOKUP_LOADED:
      return {
        ...state,
        isLoading: false,
        criteria: action.data.criteria,
        data: action.data.data,
      }
    default:
      return state
  }
}

