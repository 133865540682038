import { 
    loadPhoneVerificationCampaignConfig, 
    loadPhoneVerificationCampaignConfigFailure, 
    loadPhoneVerificationCampaignConfigSuccess } from '../reducers/phoneVerifCampaignConfigReducer'

import { isNotFoundError, getUrlFor, retrieve, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from './'

const phoneVerificationDefaultConfigURL = getUrlFor("phone-verification", "default-configuration")
const phoneVerificationCampaignConfigURL = getUrlFor("phone-verification", "campaign-configurations")

export function savePhoneVerificationCampaignConfigThunk(config) {
    return function (dispatch, getState) {
      return save(getOpts(dispatch, config, "POST", phoneVerificationCampaignConfigURL, null, null, getState(), null, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
      .then(() => {
            dispatch(loadPhoneVerificationCampaignConfigThunk(config.campaignUUID))
        }).catch(e => {
            exceptionHandling(e, dispatch, "Saving phone verification config")
        })
    }
  }

export function loadPhoneVerificationCampaignConfigThunk(campaignUUID) {
    return function (dispatch, getState) {
        dispatch(loadPhoneVerificationCampaignConfig())
        const url = campaignUUID.length == 0 ? phoneVerificationDefaultConfigURL : phoneVerificationCampaignConfigURL
        return retrieve(url, 0, 0, campaignUUID, dispatch, getState())
        .then(res => {
            if (campaignUUID.length == 0){
              dispatch(loadPhoneVerificationCampaignConfigSuccess({campaignUUID: campaignUUID, config: res}))
            }else{
              dispatch(loadPhoneVerificationCampaignConfigSuccess(res))
            }
        }).catch(e => {
            if (isNotFoundError(e)) {
                return retrieve(phoneVerificationDefaultConfigURL, 0, 0, "", dispatch, getState())
                    .then(res => {
                        dispatch(loadPhoneVerificationCampaignConfigSuccess({campaignUUID: campaignUUID, config: res}))  
                    }).catch(e => {
                        dispatch(loadPhoneVerificationCampaignConfigFailure(new Error(`getting campaign (default) phone verification config - ${e.statusText}`)))
                        exceptionHandling(e, dispatch, "Getting phone verification default config")
                    })
            }else{
                dispatch(loadPhoneVerificationCampaignConfigFailure(new Error(`getting campaign phone verification config - ${e.statusText}`)))
                exceptionHandling(e, dispatch, "Getting phone verification config")
            }
        })
    }
}

export const fetchPhoneVerificationCampaignConfigFail = () =>
new Promise((success, failure) => {
    setTimeout(() => {
        failure(new Error('intentional fetchPhoneVerificationCampaignConfigFail'))
    }, 2000)
})

export function loadPhoneVerificationCampaignConfigFailThunk() {
    return function (dispatch) {
        dispatch(loadPhoneVerificationCampaignConfig())
        return fetchPhoneVerificationCampaignConfigFail()
        .then(res => {
            dispatch(loadPhoneVerificationCampaignConfigSuccess(res))
        }).catch(e => {
            dispatch(loadPhoneVerificationCampaignConfigFailure(new Error(`getting campaign phone verification config - ${e.statusText}`)))
            exceptionHandling(e, dispatch)
        })
    }
}
