import {
    generateCampaignQuickStatusThunk,
} from "../../services/campaignQuickStatus"

import { saveState } from "../../reducers/campaignQuickStatusReducer"

import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Grid, Cell, Button } from 'react-mdl'
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
    return {
        campaignQuickStatus: state.campaignQuickStatus,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadQuickStatus: function (criteria, slug) {
            dispatch(generateCampaignQuickStatusThunk(criteria, slug))
        },
        saveState: function (state) {
            dispatch(saveState(state))
        },
    }
}

const commentStyle = {
    color: "#666",
    paddingBotton: "5px",
    fontSize: "80%",
}

class campaignQuickStatus extends React.Component {
    constructor(props) {
        super(props)

        this.pageSize = 100

        this.state = {
            isRunButtonDisabled: false,
            isDownloadButtonDisabled: false,
            selectedCampaign: [],
            selectedPlacements: [],
            newValues: '',
            newValuesReplace: false,
            isLoading: false,
            isCriteriaCollapsed: false,
        }

        if (props.campaignQuickStatus.componentState) {
            this.state = props.campaignQuickStatus.componentState
        }

        if (props.slug && props.slug.length > 0) {
            let criteria = this.getCriteria()
            this.props.loadQuickStatus(criteria, props.slug)
        }
        this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
    }

    componentWillUnmount() {
        this.props.saveState(this.state)
    }

    handleDateRangeCallback(startDate, endDate) {
        this.setState({
            filterUpdated: true,
            startDate: startDate,
            endDate: endDate
        })
    }

    unixToLocalTime(unixTime) {
        if (unixTime == 0) return ""
        return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
    }

    handleRunButtonClick() {
        let criteria = this.getCriteria()
        this.props.loadQuickStatus(criteria, this.state.filterUpdated ? "" : this.props.campaignQuickStatus.slug)
        this.setState({
            isCriteriaCollapsed: true,
            filterUpdated: false,
        })
    }

    onToggle() {
        this.setState({
            isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
        })
    }

    getCriteria() {
        return {

        }
    }

    disableRunButton() {
        this.setState({
            isRunButtonDisabled: true,
        })
    }

    render() {
        if (this.state.isLoading) return "loading ..."

        return (
            <section ref={t => this.contentSection = t}>
                <div className="content">
                    <Grid>
                        <Cell col={12}>
                            <Collapsible title="Campaign Quick Status" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                                <Grid>
                                    <Cell col={11}></Cell>
                                    <Cell col={1}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            id="run-button"
                                                            colored
                                                            raised
                                                            ripple
                                                            onClick={() => this.handleRunButtonClick()}
                                                            disabled={this.state.isRunButtonDisabled}>
                                                            RUN
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Cell>
                                </Grid>
                            </Collapsible>
                        </Cell>
                        <Cell col={12}>
                            <Grid>
                                <Cell col={12}>
                                    <div className="report">
                                        <div>Campaigns over 80% complete.</div>
                                        <div>{this.renderReport()}</div>
                                    </div>
                                </Cell>
                            </Grid>
                        </Cell>
                    </Grid>
                </div>
            </section>
        )
    }


    renderReport() {
        if (this.props.campaignQuickStatus.isLoading) {
            return <div>Loading ...</div>
        }
        if (this.props.campaignQuickStatus.error != null) {
            return <div>Error: {this.props.campaignQuickStatus.error.message}</div>
        }
        if (this.props.campaignQuickStatus.report == null) {
            return <div></div>
        }
        if (this.props.campaignQuickStatus.report.associations.length == 0) {
            return <div>{"No records found"}</div>
        }

        return (
            <React.Fragment>
                <Grid style={{ "width": "100%" }}>
                    {
                        this.props.campaignQuickStatus.slug ? <Cell col={12}>
                            <a target="_blank" rel="noreferrer"
                                href={window.location.origin + window.location.pathname + `?format=json&report=quick-status&slug=${this.props.campaignQuickStatus.slug}`}>
                                Share Report</a>
                        </Cell> : null
                    }
                    <Cell col={12}>
                        <table className="report">
                            <tr>
                                <td colSpan={5} className="td dimension">Please note that the completion percentage is based only on the front-end logic, and it may be reduced after additional filtering performed on the cleaner.</td>
                            </tr>
                            <tr className="th" key={`viewer.buysheet.header2`}>
                                <th className="th dimension">Placement</th>
                                <th className="th dimension">Campaign</th>
                                <th className="th dimension">Leads to Deliver</th>
                                <th className="th dimension">Assoc. Cap</th>
                                <th className="th dimension">Delivered Percentage</th>
                            </tr>
                            <tbody>
                                {this.props.campaignQuickStatus.report.associations.map((e, i) => {
                                    return (
                                        <tr className="td" key={`viewer.buysheet.${i}`}>
                                            <td className="td dimension">
                                                {<div>{`${e.PlacementName} - ${e.PlacementLegacyId}`}</div>}
                                                <div style={commentStyle}>{e.PlacementUUID}</div>
                                            </td>
                                            <td className="td dimension">
                                                {<div>{`${e.CampaignName} - ${e.CampaignShortCode}`}</div>}
                                                <div style={commentStyle}>{e.CampaignUUID}</div>
                                            </td>
                                            <td className="td dimension">{e.AssociationLeadsToDeliver} {e.AssociationIsLegacy && ` (legacy)`}</td>
                                            <td className="td dimension">{e.AssociationCap}</td>
                                            <td className="td dimension">{e.LeadsDeliveredPercentageFormatted}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </Cell>
                </Grid>
            </React.Fragment>
        )
    }
}


const CampaignQuickStatus = connect(mapStateToProps, mapDispatchToProps)(campaignQuickStatus)

export default CampaignQuickStatus