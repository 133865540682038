  import React from 'react'
  import { Icon, Grid, Cell } from 'react-mdl'
  import SessionDetails from "../session-details"
  
  const commentStyle = {
    color: "#666",
    paddingBotton: "5px",
    fontSize: "80%",
  }
  
  export default class RejectionsReport extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        optins: this.props.report.rejectionsSection.records.map((e) => {
          e.rowExpanded = false
          return e
        })
      }
    }

    handleRowToggleClick(i) {
      const optins = this.state.optins
      optins[i].rowExpanded = !optins[i].rowExpanded
      this.setState({
        optins: optins,
      })
    }
  
    handleAllRowsToggleClick() {
      let flag = !this.state.allRowsExpanded
      const optins = this.state.optins.map((e) => {
        e.rowExpanded = flag
        return e
      })
      this.setState({
        optins: optins,
        allRowsExpanded: flag,
      })
    }

    renderValidations(attempts){
      return <ul>
        {attempts.map((attempt, i) => {
            return <React.Fragment key={`viewer.truIntentValidations1.${i}`}>
              {attempt.validations.map((validation, j) =>{
                let validationResult = validation.message
                if(validation.detail.length > 0) validationResult += ` - ${validation.detail}`
                validationResult += ` (${validation.serviceName})`
                return <li key={`viewer.truIntentValidations2.${i}.${j}`}>{validationResult}</li>
              })}
            </React.Fragment>
        })}
      </ul>
    }

    renderAttempts(attempts){
      return <table className="report">
      <thead>
        <tr>
          <th>Attempt No.</th>
          <th>Attempt</th>
          <th>Validations</th>
          <th>Session</th>
        </tr>
      </thead>
      <tbody>
        {attempts.length == 0 && <tr className="td" key={`viewer.truIntentAttempts.row1`}>
          <td className="td metric" colSpan={4} style={{ textAlign: "center" }}>No records found</td>
        </tr>}
        {attempts.map((attempt, i) => {
          return <tr className="td" key={`viewer.truIntentAttempts.${i}`}>
          <td className="td dimension">
            <div>{i+1}.</div>
          </td>
          <td className="td dimension">
            {attempt.createdAt}<div style={commentStyle}>{attempt.optinUUID}</div>
          </td>
          <td className="td dimension">
            {attempt.validations.map((validation, j) =>{
              let validationResult = validation.message
              if(validation.detail.length > 0) validationResult += ` - ${validation.detail}`
              validationResult += ` (${validation.serviceName})`
              return <p key={`viewer.truIntentValidations.${i}.${j}`}>{validationResult}</p>
            })}
          </td>
          <td>
            <SessionDetails data={attempt.sessionDetails} showPrevalidations={false} showPostvalidations={false}></SessionDetails>
          </td>
        </tr>
        })}
      </tbody>
      </table>
    }
  
    render() {

      return (
        <React.Fragment>
          <Grid style={{ "width": "100%" }}>
            <Cell col={12}>
              <table className="report">
                <thead>
                  <tr className="th" key={`viewer.truIntentRejections.header1`}>
                    <th className="th dimension" colSpan={5} style={{ textAlign: "center" }}>{this.props.report.rejectionsSection.title}</th>
                  </tr>
                  <tr className="th" key={`viewer.truIntentRejections.header2`}>
                    <th className="th dimension">No.</th>
                    <th className="th dimension">Campaign</th>
                    <th className="th dimension">Placement</th>
                    <th className="th dimension">Attempts</th>
                    <th className="th dimension">Validations</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.optins.length == 0 && <tr className="td" key={`viewer.truIntentRejections.row1`}>
                    <th className="td metric" colSpan={5} style={{ textAlign: "center" }}>No records found</th>
                  </tr>}
                  {this.state.optins.map((optin, i) => {
                    return (
                      <React.Fragment key={`viewer.truIntentRejections.wrapper.${i}`}>
                        <tr className="td" key={`viewer.truIntentRejections.${i}`}>
                          <td className="td dimension">
                            <div className={"clickable"} onClick={() => this.handleRowToggleClick(i)}>
                            {i+1}.
                              {!optin.rowExpanded && <Icon name="keyboard_arrow_down" />}
                              {optin.rowExpanded && <Icon name="keyboard_arrow_up" />}
                          </div>
                          </td>
                          <td className="td dimension">
                            {optin.campaignShortCode} - {optin.campaignName}
                            <div style={commentStyle}>{optin.campaignUUID}</div>
                          </td>
                          <td className="td dimension">
                          {optin.placementType} - {optin.placementLegacyCode} - {optin.placementName}
                            <div style={commentStyle}>{optin.placementUUID}</div>
                          </td>
                          <td className="td dimension">
                            {optin.attempts.length}
                          </td>
                          <td className="td dimension">
                            {this.renderValidations(optin.attempts)}
                          </td>
                        </tr>
                        {!optin.rowExpanded && <tr><td colSpan={5}></td></tr>}
                        {optin.rowExpanded && <tr>
                          <td className="td" colSpan={1}></td>
                          <td className="td" colSpan={4}>
                            {this.renderAttempts(optin.attempts)}
                          </td>
                        </tr>}
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </Cell>
          </Grid>
        </React.Fragment>
      )
    }
  }
  