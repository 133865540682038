
export function ProcessEmailSuppressionFile(rawText){
    const rows = rawText.split(/\n/)
    const emailIndex = findEmailHeaderIndex(rows[0])
    if (emailIndex >= 0){
      return processWithHeaders(rows.splice(1, rows.length), emailIndex)
    }
    return processWithoutHeaders(rows)
  }
  
  function processWithoutHeaders(rows){
    const values = rows[0].split(',')
    if (values.length === 1){
      return normalizeRows(rows)
    }
    const emailIndex = findEmailIndex(values)
    return processWithHeaders(rows, emailIndex)
  }
  
  function normalizeRows(rows){
    const result = []
    rows.forEach(row => {
      if (row && row.trim().length > 0)
      result.push(normalizeValue(row))
    })
    return result
  }
  
  function findEmailIndex(values){
    for (let index = 0; index < values.length; index++) {
      if (values[index].includes("@")){
        return index
      }
    }
  }
  
  function processWithHeaders(rows, targetIndex){
    const result = []
    for (let index = 0; index < rows.length; index++) {
      const values = rows[index].split(",")
      const value = values[targetIndex]
      if (value && value.trim().length > 0){
        result.push(normalizeValue(value))
      }
    }
    return result
  }
  
  function findEmailHeaderIndex(headerRow){
    const headers = headerRow.split(",")
    for (let index = 0; index < headers.length; index++) {
      const normalizedName = normalizeName(headers[index])
      if (normalizedName.includes("EMAIL")) {
        return index
      }
    }
    return -1
  }
  
  function normalizeName(name){
    return name.replace(/([^a-z0-9]+)/gi, '').toUpperCase()
  }
  
  function normalizeValue(value){
    return value.replace(/'/g, "").replace(/"/g,"").trim()
  }