import { generateAdqPerformanceThunk } from "../../services/adqPerformance"
import { saveState } from "../../reducers/adqPerformanceReducer"
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Grid, Cell, Button } from 'react-mdl'
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import NumberFormat from 'react-number-format'
import { filterForSelectable, reportReady } from '../common/func'
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
  return {
    adqPerformance: state.adqPerformance,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadAdqPerformance: function (criteria, slug) {
      dispatch(generateAdqPerformanceThunk(criteria, slug))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
  }
}

const commentStyle = {
  color: "#666",
  paddingBotton: "5px",
  fontSize: "80%",
}

class adqPerformance extends React.Component {
  constructor(props) {
    super(props)

    this.timeZone = "America/New_York"

    this.state = {
      startDate: moment().startOf("day"),
      endDate: moment(),
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      newValues: '',
      newValuesReplace: false,
      page: 1,
      records: [],
      allRowsExpanded: false,
      isLoading: false,
      isCriteriaCollapsed: false,
    }

    if (props.adqPerformance.componentState) {
      this.state = props.adqPerformance.componentState
    }

    if (props.slug && props.slug.length > 0) {
      let criteria = this.getCriteria()
      this.props.loadAdqPerformance(criteria, props.slug)
    }
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  componentDidUpdate(prevProps) {
    let update = reportReady(
      prevProps.adqPerformance.report, this.props.adqPerformance.report,
      prevProps.campaigns, prevProps.placements,
      this.props.campaigns, this.props.placements)

    if (update) {
      let records = []
      let state = this.state
      if (this.props.adqPerformance.report && this.props.adqPerformance.report.records) {
        records = this.props.adqPerformance.report.records.map((e) => {
          e.rowExpanded = false
          return e
        })
      }
      if (this.props.adqPerformance.request) {
        let criteria = this.getCriteria(this.props.adqPerformance.request)
        state.selectedCampaigns = filterForSelectable(this.props.campaigns, criteria.campaignUUIDs)
        state.selectedPlacements = filterForSelectable(this.props.placements, criteria.placementUUIDs)
        state = { ...state, ...criteria }
        state.startDate = moment(state.startDate)
        state.endDate = moment(state.endDate)
        state.records = records
      }
      this.setState(state)
    }
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      filterUpdated: true,
      startDate: startDate,
      endDate: endDate
    })
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleRunButtonClick() {
    let criteria = this.getCriteria()
    if (!this.state.filterUpdated) {
      this.props.loadAdqPerformance(criteria, this.state.filterUpdated ? "" : this.props.adqPerformance.slug)
    }
    this.setState({
      filterUpdated: false,
      isCriteriaCollapsed: true,
    })
  }

  getCriteria(request) {
    request = request || {}
    return {
      timeZone: request.timeZone || this.timeZone,
      startDate: request.startDate || this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: request.endDate || this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: request.campaignUUIDs || this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: request.placementUUIDs || this.state.selectedPlacements.map(e => e.value),
    }
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true,
    })
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      filterUpdated: true,
      selectedPlacements: placements,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."

    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
          <Grid>
            <Cell col={12}>
              <Collapsible title="ADQ Performance" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={11}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={12}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              filterUpdated: true,
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  {this.renderReport()}
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }


  handleRowToggleClick(i) {
    const records = this.state.records
    records[i].rowExpanded = !records[i].rowExpanded
    this.setState({
      records: records,
    })
  }

  handleAllRowsToggleClick() {
    let flag = !this.state.allRowsExpanded
    const records = this.state.records.map((e) => {
      e.rowExpanded = flag
      return e
    })
    this.setState({
      records: records,
      allRowsExpanded: flag,
    })
  }

  renderReport() {
    if (this.props.adqPerformance.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.adqPerformance.error != null) {
      return <div>Error: {this.props.adqPerformance.error.message}</div>
    }
    if (this.props.adqPerformance.report == null) {
      return <div></div>
    }
    return <div className="report">
      <div>{this.renderReportByCampaign()}</div>
      <br></br>
      <div>{this.renderReportByPlacement()}</div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  }


  renderReportByCampaign() {
    let section = this.props.adqPerformance.report.campaignSection

    section.records.sort((f1, f2) => {
      if (f1.failedLeadPercentage < f2.failedLeadPercentage) return 1
      if (f1.failedLeadPercentage > f2.failedLeadPercentage) return -1
      return 0
    })

    return (
      <React.Fragment>
        <Grid style={{ "width": "100%" }}>
          {this.props.adqPerformance.slug ? <Cell col={12}>
            <a target="_blank" rel="noreferrer"
              href={window.location.origin + window.location.pathname + `?format=json&report=adq-performance&slug=${this.props.adqPerformance.slug}`}>
              Share Report</a>
          </Cell> : null}
          <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.adqPerformance.header1`}>
                  <th className="th dimension" colSpan={12} style={{ textAlign: "center" }}>{section.title}</th>
                </tr>
                <tr className="th" key={`viewer.adqPerformance.header2`}>
                  <th className="th dimension">Campaign</th>
                  <th className="th dimension">Rate</th>
                  <th className="th dimension">Impressions</th>
                  <th className="th dimension">Optins</th>
                  <th className="th dimension">Leads</th>
                  <th className="th dimension">Revenue</th>
                  <th className="th dimension">RPI</th>
                  <th className="th dimension">LA%</th>
                  <th className="th dimension">Est. Client<br></br>Scrub</th>
                  <th className="th dimension">ERPI</th>
                  <th className="th dimension">ERPU</th>
                  <th className="th dimension">Est Rev</th>
                </tr>
              </thead>
              <tbody>
                {section.records.length == 0 && <tr className="td" key={`viewer.adqPerformance.row1`}>
                  <th className="td metric" colSpan={12} style={{ textAlign: "center" }}>No records found</th>
                </tr>}
                {section.records.map((e, i) => {
                  return (
                    <React.Fragment key={`viewer.adqPerformance.wrapper.${i}`}>
                      <tr className="td" key={`viewer.adqPerformance.${i}`}>
                        <td className="td dimension">
                          {e.campaignShortCode} - {e.campaignName}
                          <div style={commentStyle}>{e.campaignUUID}</div>
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.campaignRate} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.impressionCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.optinCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.leadCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.revenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.rpi} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={4} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.campaignLeadAcceptancePercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={0} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.campaignEstimatedClientScrubPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={0} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.erpi} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={5} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.erpu} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={5} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
                <tr className="td" key={`viewer.adqPerformance.byCampaign.totals`}>
                  <td className="td dimension" style={{ textAlign: "right" }} colSpan={2}>
                    Totals:
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.impressionCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.optinCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.leadCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.revenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </td>
                  <td className="td metric">

                  </td>
                  <td className="td metric">

                  </td>
                  <td className="td metric">

                  </td>
                  <td className="td metric">

                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.erpu} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={5} fixedDecimalScale={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </td>
                </tr>
                <tr className="td" key={`viewer.adqPerformance.byCampaign.totals2`} colSpan={1}>
                  <td className="td dimension">
                    Total Sessions: <NumberFormat value={this.props.adqPerformance.report.sessionSection.sessionCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric" colSpan={11}>

                  </td>
                </tr>
              </tbody>
            </table>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }

  renderReportByPlacement() {
    let section = this.props.adqPerformance.report.placementSection

    section.records.sort((f1, f2) => {
      if (f1.failedLeadPercentage < f2.failedLeadPercentage) return 1
      if (f1.failedLeadPercentage > f2.failedLeadPercentage) return -1
      return 0
    })

    return (
      <React.Fragment>
        <Grid style={{ "width": "100%" }}>
          <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.adqPerformance.header1`}>
                  <th className="th dimension" colSpan={11} style={{ textAlign: "center" }}>{section.title}</th>
                </tr>
                <tr className="th" key={`viewer.adqPerformance.header2`}>
                  <th className="th dimension">Placement</th>
                  <th className="th dimension">No of Campaigns</th>
                  <th className="th dimension">Impressions</th>
                  <th className="th dimension">Leads</th>
                  <th className="th dimension">Sessions</th>
                  <th className="th dimension">Total $</th>
                  <th className="th dimension">CPM</th>
                  <th className="th dimension">RPU</th>
                  <th className="th dimension">ECPM</th>
                  <th className="th dimension">ERPU</th>
                  <th className="th dimension">Est Rev</th>
                </tr>
              </thead>
              <tbody>
                {section.records.length == 0 && <tr className="td" key={`viewer.adqPerformance.row1`}>
                  <th className="td metric" colSpan={11} style={{ textAlign: "center" }}>No records found</th>
                </tr>}
                {section.records.map((e, i) => {
                  return (
                    <React.Fragment key={`viewer.adqPerformance.wrapper.${i}`}>
                      <tr className="td" key={`viewer.adqPerformance.${i}`}>
                        <td className="td dimension">
                          {e.placementLegacyCode} - {e.placementName}
                          <div style={commentStyle}>{e.placementUUID}</div>
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.numberOfCampaigns} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.impressionCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.leadCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.sessionCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.revenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.cpm} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.rpu} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={4} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.ecpm} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.erpu} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={4} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
                <tr className="td" key={`viewer.adqPerformance.byPlacement.totals`}>
                  <td className="td dimension" style={{ textAlign: "right" }}>
                    Totals:
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.averageNumberOfCampaigns} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.impressionCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.leadCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.sessionCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.revenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.cpm} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.rpu} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={4} fixedDecimalScale={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.ecpm} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.erpu} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={4} fixedDecimalScale={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </td>
                </tr>
                <tr className="td" key={`viewer.adqPerformance.byPlacement.totals2`} colSpan={1}>
                  <td className="td dimension">
                    Total Sessions: <NumberFormat value={this.props.adqPerformance.report.sessionSection.sessionCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric" colSpan={10}>

                  </td>
                </tr>
              </tbody>
            </table>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }
}


const AdqPerformance = connect(mapStateToProps, mapDispatchToProps)(adqPerformance)

export default AdqPerformance
