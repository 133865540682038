import Modal from 'react-modal'
import React from 'react'
import DummyLead from '../../utils/dummyLead'
import { Grid, Cell, Card, CardActions, CardTitle, CardMenu, Icon, Button } from 'react-mdl'

const lead = { user: {} }
export class CustomValidationPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowingModal: true,
            lead: lead,
        }
        this.handleClose = () => {
            this.state.isShowingModal = false
            this.setState(this.state)
            this.props.clearPreview()
            this.props.onClose()
        }
    }

    render() {
        let message = ""
        let details = ""
        let detailColor = "inherit"
        let isValid = true
        let autoCorrectedFields = []
        if (this.props.customValidationPreview && this.props.customValidationPreview.validationResult) {
            message = this.props.customValidationPreview.validationResult.message
            details = this.props.customValidationPreview.validationResult.details
            isValid = this.props.customValidationPreview.validationResult.isValid
            autoCorrectedFields = this.props.customValidationPreview.validationResult.autoCorrectedFields || []
            detailColor = this.props.customValidationPreview.validationResult.detailColor
        }
        return (
            <Modal
                appElement={this.props.parent}
                isOpen={this.state.isShowingModal}
                onRequestClose={this.handleClose}
                contentLabel="Custom Validation">
                <Card shadow={2}>
                    <CardTitle>Custom Validation</CardTitle>
                    <CardActions border>
                        <Grid>
                            <Cell col={12}>
                                <DummyLead lead={this.state.lead} update={lead => {
                                    this.setState({
                                        lead: lead
                                    })
                                    if (message.length > 0) {
                                        this.props.clearPreview()
                                    }
                                }} />
                            </Cell>
                            <Cell col={3}>
                                {isValid
                                    ? <Icon
                                        name="check_circle"
                                        style={{
                                            fontSize: '48px',
                                            color: '#4caf50',
                                        }}
                                    />
                                    : <Icon
                                        name="highlight_off"
                                        style={{
                                            fontSize: '48px',
                                            color: '#d32f2f',
                                        }}
                                    />}
                            </Cell>
                        </Grid>
                    </CardActions>
                    <CardMenu>
                        <Button
                            raised
                            colored
                            onClick={() => {
                                this.props.test(this.state.lead)
                            }}
                        >
                            Ping
                  </Button>
                    </CardMenu>
                    {message.length === 0 ? null : <Grid>
                        <Cell style={{ "lineHeight": "2px" }} col={12}>
                            <pre>
                                Message: {message}
                            </pre>
                        </Cell>
                        <Cell style={{ "lineHeight": "2px" }} col={12}>
                            <pre style={{ color: detailColor || "inherit" }}>
                                Details: {details}
                            </pre>
                        </Cell>
                        <Cell style={{ "lineHeight": "2px", marginBottom: "-20px" }} col={12}>
                            <pre style={{ fontWeight: "bold", color: detailColor || "inherit" }}>
                                Auto Corrected Fields:
                                </pre>
                        </Cell>
                        <hr
                            style={{
                                color: "black",
                                backgroundColor: "white",
                                height: 5,
                                width: "100%"
                            }}
                        />
                        {Object.keys(autoCorrectedFields).map(v => {
                            return <Cell key={v} style={{ "lineHeight": "2px", marginTop: "0px", marginBottom: "0px" }} col={12}>
                                <pre style={{ color: detailColor || "inherit" }}>
                                    {`${v}: ${this.state.lead.user[v]} -> ${autoCorrectedFields[v]}`}
                                </pre>
                            </Cell>
                        })}
                    </Grid>}
                </Card>
            </Modal >
        )
    }
}
