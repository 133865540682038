import {
    generateTruIntent,
    generateTruIntentFailure,
    generateTruIntentSuccess,

    startLoadingRejectedLeadViewer,
    loadedRejectedLeadViewer,
    closedRejectedLeadViewer,

    startLoadingRejectedRtsViewer,
    loadedRejectedRtsViewer,
    closedRejectedRtsViewer,

} from '../reducers/truIntentReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

const url = getUrlFor("reporting", "tru-intent")
//const url = "http://localhost:9001/tru-intent/"
//const url = "http://localhost:9001/tru-intent-mock/"

export function truIntentThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generateTruIntent())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generateTruIntentSuccess(res.data))
            }).catch(e => {
                dispatch(generateTruIntentFailure(`Getting report`))
                exceptionHandling(e, dispatch, "Getting report")
            })
    }
}

const rejectedUrl = getUrlFor("reporting", "tru-intent-rejected-leads")
//const rejectedUrl = "http://localhost:9001/tru-intent-rejected-leads/"
//const rejectedUrl = "http://localhost:9001/tru-intent-rejected-leads-mock/"

export function truIntentRejectedThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(startLoadingRejectedLeadViewer())
        return save(getOpts(dispatch, criteria, "POST", rejectedUrl, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(loadedRejectedLeadViewer(criteria, res.data))
            }).catch(e => {
                dispatch(closedRejectedLeadViewer(`Getting rejected leads report - ${e.statusText}`))
                exceptionHandling(e, dispatch, "Getting rejected leads report")
            })
    }
}

const rejectedRtsUrl = getUrlFor("reporting", "tru-intent-rejected-rts")
//const rejectedRtsUrl = "http://localhost:9001/tru-intent-rejected-rts/"
//const rejectedRtsUrl = "http://localhost:9001/tru-intent-rejected-rts-mock/"

export function truIntentRejectedRtsThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(startLoadingRejectedRtsViewer())
        return save(getOpts(dispatch, criteria, "POST", rejectedRtsUrl, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(loadedRejectedRtsViewer(criteria, res.data))
            }).catch(e => {
                dispatch(closedRejectedRtsViewer(`Getting rejected rts report - ${e.statusText}`))
                exceptionHandling(e, dispatch, "Getting rejected rts report")
            })
    }
}