import {
  ActionTypes,
  ShowSnack,
  setCrud,
  newCampaign,
  retrieveCampaigns,
  saveCampaign,
  loadTagsTaxonomy,
  loadAssociatedTags,
  resetAssociatedTags,
  retrieveEmailValidation,
  saveEmailValidation,
  retrieveAddressValidationCampaignConfig,
  saveAddressValidationCampaignConfig,
  uploadPriorityRanking,
  retrieveAgentSourceURL,
  openAgent,
  loadCampaignAssociations,
  redirectToPlacement,
  retrieveCampaignCriteria,
  saveCustomValidationConfiguration,
  clearCustomValidationPreview,
  retrieveCustomValidation,
  testCustomValidation,
  retrieveCampaignStats
} from "../../services"

import {
  loadPhoneVerificationCampaignConfigThunk,
  savePhoneVerificationCampaignConfigThunk
} from "../../services/phoneVerifCampaignConfigService"

import { retrieveCustomersThunk } from "../../services/customerService"

import CampaignDetail from "./campaignDetail"
import Creative from "../creative"
import Grouping from "./grouping"
import LeadDeliveryConfigs from "../lead-sender"
import Preping from "./preping/preping"
import CampaignQuestions from "./campaignQuestions"
import TagManager from "../tagging/manager"
import PlacementAssociations from "./associations"
import React from "react"
import { connect } from "react-redux"
import { Grid, Cell, Tabs, Tab, Tooltip} from "react-mdl"
import CustomValidation from "./customValidation"

function mapStateToProps(state) {
  return {
    isDirtyValue: state.isDirty,
    campaign: state.campaign,
    campaigns: state.campaignData.records,
    campaignMap: state.campaignData.recordsMap,
    customValidation: state.customValidation,
    customValidationPreview: state.customValidationPreview,
    questions: state.questions,
    customers: state.customers,
    group: state.group,
    placements: state.placementData.records,
    tags: state.tags,
    associatedTags: state.associatedTags,
    tagMasterList: state.tagMasterList,
    validators: state.validators,
    creative: state.creative,
    creativePreview: state.creativePreview,
    prepingPreview: state.prepingPreview,
    zips: state.zips,
    leadDeliveryConfig: state.leadDeliveryConfig.data,
    leadDeliveryConfigIsLoading: state.leadDeliveryConfig.isLoading,
    leadDeliveryConfigLookups: state.leadDeliveryConfigs,
    leadDeliveryConfigError: state.leadDeliveryConfigError,
    leadDeliveryConfigTest: state.leadDeliveryConfigTest,
    emailValidationConfiguration: state.emailValidationConfiguration,
    addressValidationDefault: state.addressValidationDefaultConfig,
    addressValidation: state.addressValidationCampaignConfig,
    agentSourceURL: state.agentSourceURL,
    user: state.user,
    campaignAssociations: state.campaignAssociations,
    campaignCriteria: state.campaignCriteria,
    itemStats: state.itemStats,
    phoneVerification: state.phoneVerificationCampaignConfig,
    defaultValidators: state.defaultValidators
  }
}
function mapDispatchToProps(dispatch) {
  return {
    ShowSnack(msg) {
      dispatch(ShowSnack(msg))
    },
    update(campaign) {
      dispatch({ type: ActionTypes.CAMPAIGN_UPDATED, data: campaign })
    },
    retrieveCampaigns(uuid, avoidRedirection) {
      dispatch(retrieveCampaigns(uuid, avoidRedirection))
    },
    retrieveCustomValidation(campaignUUID) {
      dispatch(retrieveCustomValidation(campaignUUID))
    },
    saveCampaign(c, callbacks, avoidRedirection) {
      let cb = () => {
        dispatch(retrieveCampaigns(c.UUID, avoidRedirection))
        dispatch(retrieveCampaigns(null, avoidRedirection))
      }
      if (!c.UUID) {
        cb = c => {
          dispatch({ type: ActionTypes.CAMPAIGN_UPDATED, data: c })
          dispatch(retrieveCampaigns(null, avoidRedirection))
        }
      }
      callbacks.push(cb)
      dispatch(saveCampaign(c, callbacks, avoidRedirection))
    },
    newCampaign(defaultValidators) {
      dispatch(resetAssociatedTags())
      dispatch(newCampaign(defaultValidators))
      dispatch(loadPhoneVerificationCampaignConfigThunk(""))
    },
    setCrud(crud) {
      dispatch(setCrud(crud))
    },
    saveCustomValidationConfiguration(config) {
      dispatch(saveCustomValidationConfiguration(config))
    },
    clearCustomValidationPreview() {
      dispatch(clearCustomValidationPreview())
    },
    testCustomValidation(lead) {
      dispatch(testCustomValidation(lead))
    },
    openAgent(placementUUID, user, campaigns) {
      dispatch(openAgent(placementUUID, user, campaigns))
    },
    isDirty: function (data) {
      dispatch({ type: ActionTypes.IS_DIRTY, data: data })
    },
    loadTagsTaxonomy() {
      dispatch(loadTagsTaxonomy())
    },
    loadAssociatedTags(campaignUUID) {
      dispatch(loadAssociatedTags(campaignUUID))
    },
    retrieveEmailValidation(campaignUUID) {
      dispatch(retrieveEmailValidation(campaignUUID))
    },
    saveEmailValidation(configuration) {
      dispatch(saveEmailValidation(configuration))
    },
    onPriorityUploadChange: function (ev) {
      ev.persist()
      dispatch(uploadPriorityRanking(ev))
      return false
    },
    retrieveAgentURL: function () {
      dispatch(retrieveAgentSourceURL())
    },
    retrieveAddressValidation(campaignUUID) {
      dispatch(retrieveAddressValidationCampaignConfig(campaignUUID))
    },
    saveAddressValidation(configuration) {
      dispatch(saveAddressValidationCampaignConfig(configuration))
    },
    loadCampaignAssociations(campaignUUID) {
      dispatch(loadCampaignAssociations(campaignUUID))
    },
    redirectToPlacement(placementUUID) {
      dispatch(redirectToPlacement(placementUUID))
    },
    retrieveCampaignCriteria(campaignUUID) {
      dispatch(retrieveCampaignCriteria(campaignUUID))
    },
    retrieveCampaignStats(campaignUUID) {
      dispatch(retrieveCampaignStats(campaignUUID))
    },
    loadPhoneVerificationCampaignConfigThunk(campaignUUID) {
      dispatch(loadPhoneVerificationCampaignConfigThunk(campaignUUID))
    },
    savePhoneVerification(configuration) {
      dispatch(savePhoneVerificationCampaignConfigThunk(configuration))
    },
    retrieveCustomers() {
      dispatch(retrieveCustomersThunk())
    }
  }
}

const detailsTab = 0
class campaignEditor extends React.Component {
  constructor(props) {
    super(props)

    this.isModal = props.isModal || false

    this.state = {
      activeTab: detailsTab,
      isVisibleCampaignList: true,
      seed: Math.random(),
      tabSeed: Math.random(),
      campaignNotFound: false,
    }
    this.setGetCampaign = getCampaign => {
      this.getCampaign = getCampaign
    }
    this.addSaveCampaignCallBack = cb => {
      this.saveCampaignCallBacks.push(cb)
    }
    this.getCampaign = () => null
    this.saveCampaignCallBacks = []
    this.getSaveCampaignCallBacks = () => this.saveCampaignCallBacks
    this.updateCampaignName = this.updateCampaignName.bind(this)
    this.setIsTabComponentDirty = isTabComponentDirty => (this.isTabComponentDirty = isTabComponentDirty)
    this.isTabComponentDirty = () => null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.campaignUUID !== this.props.campaignUUID) {
      this.campaignSelected(this.props.campaignUUID)
    }
  }

  checkIsDirty(activeTab) {
    const isDirtyMap = {
      "0": () => this.props.isDirtyValue,
      "1": () => this.isTabComponentDirty(),
      "2": () => this.isTabComponentDirty(),
      "3": () => false,
      "4": () => false,
      "5": () => this.props.isDirtyValue,
      "6": () => this.isTabComponentDirty(),
      "7": () => false,
      "8": () => false,
    }
    return isDirtyMap[activeTab]()
  }

  updateCampaignName(name) {
    this.setState({ ...this.state, campaignName: name })
  }

  componentDidMount() {
    let crudOptions = {
      onSave: () => {
        this.props.isDirty(false)
        this.props.saveCampaign(this.getCampaign(), this.getSaveCampaignCallBacks(), this.isModal)
        this.saveCampaignCallBacks = []
      },
      onCancel: () => {
        if (this.props.campaign.UUID) {
          this.props.retrieveCampaigns(this.props.campaign.UUID, this.isModal)
          this.setState({
            seed: Math.random()
          })
        } else {
          this.props.newCampaign()
          this.props.isDirty(false)
        }
      },
      onNew: () => {
        this.props.newCampaign(this.props.defaultValidators)
      },
      visible: true
    }
    this.props.setCrudHandler(crudOptions)
    this.props.isDirty(false)
    if (this.props.match.params.uuid) {
      this.props.retrieveCampaigns(this.props.match.params.uuid, this.isModal)
      this.props.loadAssociatedTags(this.props.match.params.uuid)
      this.props.retrieveEmailValidation(this.props.match.params.uuid)
      this.props.retrieveCustomValidation(this.props.match.params.uuid)
      this.props.retrieveAddressValidation(this.props.match.params.uuid)
      this.props.retrieveCampaignCriteria(this.props.match.params.uuid)
      this.props.loadPhoneVerificationCampaignConfigThunk(this.props.match.params.uuid)
      this.props.retrieveCampaignStats(this.props.match.params.uuid)
    } else if (this.props.campaign.UUID) {
      this.props.loadAssociatedTags(this.props.campaign.UUID)
      this.props.retrieveEmailValidation(this.props.campaign.UUID)
      this.props.retrieveCustomValidation(this.props.campaign.UUID)
      this.props.retrieveAddressValidation(this.props.campaign.UUID)
      this.props.retrieveCampaignCriteria(this.props.campaign.UUID)
      this.props.loadPhoneVerificationCampaignConfigThunk(this.props.campaign.UUID)
      this.props.retrieveCampaignStats(this.props.campaign.UUID)
    }
    this.props.loadTagsTaxonomy()
    this.props.retrieveAgentURL()
    this.props.retrieveCustomers()
  }

  toggleCampaignList() {
    this.setState({
      isVisibleCampaignList: !this.state.isVisibleCampaignList
    })
  }

  campaignSelected(campaign) {
    if (
      this.checkIsDirty(this.state.activeTab) &&
      !confirm("You have unsaved changes, are you sure you want to leave?")
    ) {
      this.setState({
        isVisibleCampaignList: false
      })
      return
    }
    this.props.isDirty(false)
    this.props.retrieveCampaigns(campaign.UUID, this.isModal)
    this.props.loadAssociatedTags(campaign.UUID)
    this.props.retrieveEmailValidation(campaign.UUID)
    this.props.retrieveCustomValidation(campaign.UUID)
    this.props.retrieveAddressValidation(campaign.UUID)
    this.props.loadCampaignAssociations(campaign.UUID)
    this.props.retrieveCampaignCriteria(campaign.UUID)
    this.props.retrieveCampaignStats(campaign.UUID)
    this.props.loadPhoneVerificationCampaignConfigThunk(campaign.UUID)
    this.setState({ ...this.state, isVisibleCampaignList: false, activeTab: detailsTab })
  }

  openAgent() {
    const TEST_PLACEMENT_UUID = "443efa4d-46a7-6d6d-ba7d-faf381ef5482"
    const placementUUID = this.props.placementUUID ? this.props.placementUUID : TEST_PLACEMENT_UUID
    this.props.openAgent(placementUUID, {}, [this.props.campaign.UUID])
  }

  questionCreativeSidebarSelected(q) {
    if (!document.queryCommandSupported("copy")) {
      return
    }
    this.props.ShowSnack(`${q.UUID} copied to your clipboard`)
  }

  isCampaignSelected() {
    return "UUID" in this.props.campaign || "leadCounts" in this.props.campaign
  }

  handleSelectTab(tabIndex) {
    if (
      this.checkIsDirty(this.state.activeTab) &&
      !confirm("You have unsaved changes, are you sure you want to leave?")
    ) {
      this.setState({ activeTab: this.state.activeTab, tabSeed: Math.random() })
      return
    }
    this.props.isDirty(false)
    this.setState({ activeTab: tabIndex })
  }

  isActiveByAssociation() {
    return false
  }

  render() {
    if(!this.props.campaign)return <div>Campaign not found</div>

    const campaignKey = this.props.campaign.UUID ? `${this.props.campaign.UUID}-${this.state.seed}` : "new"
    let campQuestions = this.props.questions.filter(q => q.campaignUuid == this.props.campaign.UUID)
    this.elements = [
      <CampaignDetail
        isDirtyValue={this.props.isDirtyValue}
        key={campaignKey}
        setGetCampaign={this.setGetCampaign}
        addSaveCampaignCallBack={this.addSaveCampaignCallBack}
        campaign={this.props.campaign}
        customers={this.props.customers}
        tags={this.props.tags}
        validators={this.props.validators}
        updateCampaignName={this.updateCampaignName}
        emailValidationConfiguration={this.props.emailValidationConfiguration}
        retrieveEmailValidation={this.props.retrieveEmailValidation}
        saveEmailValidation={this.props.saveEmailValidation}
        addressValidationDefault={this.props.addressValidationDefault}
        addressValidation={this.props.addressValidation}
        retrieveAddressValidation={this.props.retrieveAddressValidation}
        saveAddressValidation={this.props.saveAddressValidation}
        zips={this.props.zips}
        isDirty={this.props.isDirty}
        uploadPriorityRanking={this.props.uploadPriorityRanking}
        campaignCriteria={this.props.campaignCriteria}
        itemStats={this.props.itemStats}
        retrieveCampaignStats={this.props.retrieveCampaignStats}
        phoneVerification={this.props.phoneVerification}
        savePhoneVerification={this.props.savePhoneVerification}
      />,
      <Creative
        key={2}
        item={this.props.campaign}
        creative={this.props.creative}
        creativePreview={this.props.creativePreview}
        selectProp={q => q.UUID}
        additionalFieldList={["UUID"]}
        primaryField={"value"}
        selectItem={q => this.questionCreativeSidebarSelected(q)}
        items={campQuestions}
        onSaveItem={this.props.saveCampaign}
        setIsTabComponentDirty={this.setIsTabComponentDirty}
        previewAgent={() => this.openAgent()}
      />,
      <Grouping
        key={4}
        campaign={this.props.campaign}
        campaigns={this.props.campaigns}
        placements={this.props.placements}
        group={this.props.group}
        saveCampaign={this.props.saveCampaign}
      />,
      <CampaignQuestions
        key={5}
        setGetCampaign={this.setGetCampaign}
        campaign={this.props.campaign}
        questions={this.props.questions}
        validators={this.props.validators}
      />,
      <Preping
        isDirtyValue={this.props.isDirtyValue}
        key={campaignKey}
        setGetCampaign={this.setGetCampaign}
        campaign={this.props.campaign}
        prepingPreview={this.props.prepingPreview}
      />,
      <CustomValidation
        key={8}
        clearPreview={this.props.clearCustomValidationPreview}
        save={config => {
          this.props.saveCustomValidationConfiguration({
            enabled: config.enabled,
            script: config.script,
            uuid: config.uuid,
            campaignUuid: this.props.campaign.UUID
          })
        }}
        testCustomValidation={this.props.testCustomValidation}
        customValidationPreview={this.props.customValidationPreview}
        customValidation={this.props.customValidation}
      />,
      <LeadDeliveryConfigs
        key={7}
        campaign={this.props.campaign}
        campaigns={this.props.campaigns}
        placements={this.props.placements}
        leadDeliveryConfig={this.props.leadDeliveryConfig}
        leadDeliveryConfigIsLoading={this.props.leadDeliveryConfigIsLoading}
        leadDeliveryConfigLookups={this.props.leadDeliveryConfigLookups}
        leadDeliveryConfigError={this.props.leadDeliveryConfigError}
        leadDeliveryConfigTest={this.props.leadDeliveryConfigTest}
        setIsTabComponentDirty={this.setIsTabComponentDirty}
      />,
      <PlacementAssociations
        key={8}
        associations={this.props.campaignAssociations}
        loadAssociations={() => this.props.loadCampaignAssociations(this.props.campaign.UUID)}
        placements={this.props.placements}
        campaigns={this.props.campaigns}
        redirect={this.props.redirectToPlacement}
      />,
      <TagManager
        key={1}
        item={this.props.campaign}
        itemType={"campaign"}
        tags={this.props.tagMasterList}
        associatedTags={this.props.associatedTags}
        setIsTabComponentDirty={this.setIsTabComponentDirty}
      />
    ]

    let defaultName = this.isCampaignSelected() ? "" : "Please select a campaign"
    return (
      <div>
        <Grid style={{ width: "95%", padding: "8px 0px" }}>
          <Cell col={12}>
            <div>
              <Grid style={{ marginTop: "-25px", padding: "8px 0px" }}>
                <Cell col={11}>
                  <Tooltip label={this.props.campaign.isLegacy ? "Legacy campaign" : "New-system campaign"}>
                    <h3 style={this.props.campaign.isArchived ? { fontStyle: "italic", color: "grey" } : {}}>
                      {this.state.campaignName ? this.state.campaignName : defaultName}
                    </h3>
                  </Tooltip>
                </Cell>
              </Grid>
              {this.isCampaignSelected() ? (
                <div>
                  <Tabs
                    key={this.state.tabSeed}
                    activeTab={this.state.activeTab}
                    onChange={tabId => this.handleSelectTab(tabId)}
                    ripple
                    style={{ width: "100%", padding: "0px" }}>
                    <Tab>DETAILS</Tab>
                    <Tab>CREATIVE</Tab>
                    <Tab>GROUPING</Tab>
                    <Tab>QUESTIONS</Tab>
                    <Tab>Pre Ping</Tab>
                    <Tab>CUSTOM VALIDATION</Tab>
                    <Tab>DELIVERY</Tab>
                    <Tab>Associations</Tab>
                    <Tab>TAGS</Tab>
                  </Tabs>
                  <section>
                    <div className="content">{this.elements[this.state.activeTab]}</div>
                  </section>
                </div>
              ) : null}
            </div>
          </Cell>
        </Grid>
      </div>
    )
  }
}

const CampaignEditor = connect(mapStateToProps, mapDispatchToProps)(campaignEditor)

export default CampaignEditor
