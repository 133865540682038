import {
    uploadSuppressionList,
    ShowSnack,
    resetSuppressionListUpload,
  } from '../../services'
import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import FilterList from '../filterList'
import SearchForm from '../zip/searchForm'
import { ProcessEmailSuppressionFile } from '../../utils/csvFileParser'
import {Grid, Cell, Card, CardActions, CardTitle, Icon, Button, ProgressBar} from 'react-mdl'
import { Prompt } from 'react-router'

function mapDispatchToProps(dispatch) {
  return {
    uploadSuppression(emails, campaignUUID, campaignName, fileName) {
      dispatch(uploadSuppressionList(emails, campaignUUID, campaignName, fileName))
    },
    resetSuppressionListUpload(){
      dispatch(resetSuppressionListUpload())
    },
    diplayUploadFileError(){
      dispatch(ShowSnack("Error uploading cvs file, wrong format"))
    }
  }
}

class tools extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            selectedCampaign: null,
            fileName: null,
            fileUploaded: false,
            emails: [],
            showModal: false,
            failedEmails: [],
        }
        this.props.setIsTabComponentDirty(() => !this.isUploadButtonDisabled())
    }

    isClearButtonDisabled(){
      if (this.state.selectedCampaign){
          return false
      }
      return true
    }

    isUploadButtonDisabled(){
      if (this.state.selectedCampaign && this.state.fileUploaded){
          return false
      }
      return true
    }

    selectCampaign(campaign){
      this.setState({
        selectedCampaign: campaign,
      })
    }

    clearSuppression(){
      this.setState({
        selectedCampaign: null,
        emails: [],
      })
    }

    suppressionFileChange(files) {
        let file = files[0]
        var reader = new FileReader()
        let onread = () => {
          try{
            var emails = ProcessEmailSuppressionFile(reader.result)
            if (emails.length === 0){
              throw new Error()
            }
            this.setState({
              fileName: file.name,
              emails: emails,
              fileUploaded: true, 
            })
          }catch(e) {
            this.props.diplayUploadFileError()
          }
        }
        reader.onload = onread
        reader.readAsBinaryString(file)
    }

    handleCloseModal(){
      this.setState({
        showModal: false,
      })
    }

    showFailedEmails(emails){
      this.setState({
        failedEmails: emails,
        showModal: true,
      })
    }

    handleUploadButton(){
      const {emails, selectedCampaign, fileName} = this.state
      this.props.uploadSuppression(emails, selectedCampaign.UUID, selectedCampaign.name, fileName)
      this.setState({
        selectedCampaign: null,
        fileUploaded: false,
      })

    }

    render(){
      return (
        <React.Fragment>
          <Prompt
          when={!this.isUploadButtonDisabled()}
          message='You have unsaved changes, are you sure you want to leave?'
          />
          <Grid>
            <Cell col={6}>
              <Card shadow={2}>
              <CardTitle>Upload Email Suppression</CardTitle>
              <CardActions border>
                <Grid>
                  <Cell col={12}>
                    <span>{this.state.selectedCampaign ? this.state.selectedCampaign.name : 'Please select campaign...'}</span>
                  </Cell>
                  <Cell col={6}>
                    <Button
                      disabled={this.isUploadButtonDisabled()}
                      accent
                      onClick={() => this.handleUploadButton()} >
                      <Icon name="save" />Upload
                    </Button>
                    <Button
                      disabled={this.isClearButtonDisabled()}
                      accent
                      onClick={() => this.clearSuppression()}>
                      <Icon name="cancel" />Clear
                    </Button>
                    <div className="mdl-button mdl-button--primary mdl-button--icon mdl-button--file" >
                      <i className="material-icons">attach_file</i>
                      <input
                        disabled={this.props.uploadingSuppressionListFile}
                        style={{ opacity: 0 }}
                        type="file"
                        id="uploadBtn"
                        onChange={e => this.suppressionFileChange(e.target.files)}
                        accept=".csv, text/csv"
                      />
                    </div>
                    <span>{this.state.fileName}</span>
                  </Cell>
                  <Cell col={6}>
                    {this.props.uploadSuppresionListData.fileName ?
                      <div style={{border: "1px solid #009abf", padding:"15px", boxShadow: "1px 2px 4px rgba(0,0,0,.5)", borderRadius: "1em"}}>
                        {this.props.uploadSuppresionListData.done ?
                        <div>
                          <div style={{display:"flex", alignItems: "center", justifyContent: "space-between", marginRight: "-15px"}}>
                              <div>
                                <div>
                                  <span style={{color: "green"}}>Upload completed: </span>
                                  <strong>{this.props.uploadSuppresionListData.emailsCount}</strong> emails
                                  suppressed to <strong>&quot;{this.props.uploadSuppresionListData.campaignName}&quot;</strong> campaign
                                </div>
                      <div style={{marginTop: "15px"}}>
                        { this.props.uploadSuppresionListData.errors.length > 0 ?
                          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <div>
                              <span>Errors:</span>
                              <span style={{color: "red", marginLeft: "10px"}}>{this.props.uploadSuppresionListData.errors.length}</span>
                            </div>
                            <div ref={t => this.contentSection = t}>
                              <Button
                                raised
                                ripple
                                colored
                                onClick={() => this.showFailedEmails(this.props.uploadSuppresionListData.errors)}>
                                show failed emails
                              </Button>
                              <Modal appElement={this.contentSection}
                                            isOpen={this.state.showModal}
                                            onRequestClose={() => this.handleCloseModal()} >
                                            <Card style={{ maxWidth: "20vw", minWidth: "15vw" }} shadow={2}>
                                              <CardTitle style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                                <div>
                                                  {"Failed emails"}
                                                </div>
                                                <Button
                                                  onClick={() => this.handleCloseModal()}>
                                                  <Icon name="close" />
                                                </Button>
                                              </CardTitle>
                                              <CardActions border>
                                                <div>
                                                  {this.state.failedEmails.map((email, idx) =>
                                                    <div key={idx}>{email}</div>
                                                  )}
                                                </div>
                                    </CardActions>
                                  </Card>
                              </Modal>
                            </div>
                          </div> :
                          <span style={{color: "blue"}}>{"All emails suppressed successfully"}</span>
                                  }
                                </div>
                              </div>
                              <Button
                                onClick={() => this.props.resetSuppressionListUpload()}>
                                <Icon name="close" />
                              </Button>
                            </div>
                        </div> :
                        <div>
                          <div style={{marginBottom: "10px"}}>
                            Uploading <strong>{this.props.uploadSuppresionListData.fileName}</strong> file for&nbsp;
                            <strong>&quot;{this.props.uploadSuppresionListData.campaignName}&quot;</strong> campaign
                          </div>
                          <ProgressBar progress={this.props.updateProgress} indeterminate={false} />
                        </div>
                        }
                      </div> : null
                    }
                  </Cell>
                </Grid>
                <div className="shortFilter">
                  <FilterList
                    label="Campaign list..."
                    selected={this.state.selectedCampaign ? this.state.selectedCampaign.UUID : null}
                    hidePage={true}
                    additionalFieldList={['UUID', 'shortCode']}
                    title="Campaigns"
                    selectItem={c => this.selectCampaign(c)}
                    items={this.props.campaigns}
                  />
                </div>
              </CardActions>
              </Card>
            </Cell>
            <Cell col={6}>
              <CardActions>
                <SearchForm zips={this.props.zips}/>
              </CardActions>
            </Cell>
          </Grid>
        </React.Fragment>
     )
    }
}

const Tools = connect(null, mapDispatchToProps)(tools)

export default Tools