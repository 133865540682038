import {
    generateBrokeredFailedLeads,
    generateBrokeredFailedLeadsFailure,
    generateBrokeredFailedLeadsSuccess,

    startLoadingLeadViewer,
    loadedLeadViewer,
    closedLeadViewer,

} from '../reducers/brokeredFailedLeadsReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

const url = getUrlFor("reporting", "brokered-failed-leads")
//const url = "http://localhost:9001/brokered-failed-leads/"
//const url = "http://localhost:9001/brokered-failed-leads-mock/"

export function generateBrokeredFailedLeadsThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generateBrokeredFailedLeads())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generateBrokeredFailedLeadsSuccess(res.data.request, res.data))
            }).catch(e => {
                dispatch(generateBrokeredFailedLeadsFailure(`Getting brokered failed leads report`))
                exceptionHandling(e, dispatch, "Getting brokered failed leads report")
            })
    }
}

const leadViewerUrl = getUrlFor("reporting", "brokered-lead-viewer")
//const leadViewerUrl = "http://localhost:9001/brokered-lead-viewer/"
//const leadViewerUrl = "http://localhost:9001/brokered-lead-viewer-mock/"

export function leadViewerReportThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(startLoadingLeadViewer())
        return save(getOpts(dispatch, criteria, "POST", leadViewerUrl, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(loadedLeadViewer(criteria, res.data))
            }).catch(e => {
                dispatch(closedLeadViewer(`Getting brokered lead viewer report - ${e.statusText}`))
                exceptionHandling(e, dispatch, "Getting brokered lead viewer report")
            })
    }
}
