import {
    generateBuySheetReport,
    generateBuySheetReportFailure,
    generateBuySheetReportSuccess
} from '../reducers/buySheetReportReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

const url = getUrlFor("reporting", "buy-sheet")
//const url = "http://localhost:9001/buy-sheet/"
//const url = "http://localhost:9001/buy-sheet-mock/"


export function generateBuySheetReportThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generateBuySheetReport())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generateBuySheetReportSuccess(res.data))
            }).catch(e => {
                console.dir(e)
                dispatch(generateBuySheetReportFailure(`Getting buy sheet report - ${e.statusText}`))
                exceptionHandling(e, dispatch, "Getting buy sheet report")
            })
    }
}
