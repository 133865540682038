import {
  loadOptinReviewerReviewables,
  updateOptinReviewerReviewableList,
  updateOptinReviewerStatusCodes,
  updateBulkOptinReviewerStatusCodes,
  closeOptinReviewerSaveDialog
} from '../../services/optinReviewer'

import { saveState } from "../../reducers/optinReviewerReducer"

import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import moment from 'moment'
import Select from 'react-select'
import { Grid, Cell, Card, CardActions, Button, Switch, Icon, Textfield } from 'react-mdl'
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import { Collapsible } from "../common/components"
import { Checkbox } from '@material-ui/core'

function mapStateToProps(state) {
  return {
    optinReviewer: state.optinReviewer,
    campaigns: state.campaignData.records,
    campaignMap: state.campaignData.recordsMap,
    placements: state.placementData.records,
    user: state.user,
    quarantineStats: state.quarantineStats
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadReviewables: function (criteria) {
      dispatch(loadOptinReviewerReviewables(criteria))
    },
    updateReviewableList: function (report) {
      dispatch(updateOptinReviewerReviewableList(report))
    },
    updateStatusCodes: function (requestData) {
      dispatch(updateOptinReviewerStatusCodes(requestData))
    },
    updateBulkStatusCodes: function (reportCriteria, updateCriteria, status, user) {
      dispatch(updateBulkOptinReviewerStatusCodes(reportCriteria, updateCriteria, status, user))
    },
    closeSaveDialog: function () {
      dispatch(closeOptinReviewerSaveDialog())
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
  }
}

class optinReviewer extends React.Component {
  constructor(props) {
    super(props)



    this.testFilterOptions = [
      { label: "All", value: 0 },
      { label: "Live-Only", value: 1 },
      { label: "Test-Only", value: 2 },
    ]
    this.reviewCodes = [
      { label: "Unknown", value: 0, active: true, updateTo: false },
      { label: "Pending Review", value: 1, active: true, updateTo: false },
      { label: "In-Transition", value: 2, active: true, updateTo: false },
      { label: "Accepted", value: 3, active: false, updateTo: true },
      { label: "Rejected", value: 4, active: false, updateTo: true },
    ]
    this.reviewCodesMap = new Map()
    this.reviewCodes.forEach(e => this.reviewCodesMap.set(e.value, e))

    this.validationCodes = [
      { label: "Unknown", value: 0, active: false },
      { label: "Pace Filtering: Inactive Campaign Time Window", value: 10001, active: false },
      { label: "Pace Filtering: Over Campaign Global Cap", value: 10002, active: false },
      { label: "Pace Filtering: Over Campaign Daily Cap", value: 10003, active: false },
      { label: "Pace Filtering: Over Association Daily Cap", value: 10004, active: false },
      { label: "Pace Filtering: Association Day Parting", value: 10005, active: false },
    ]

    this.state = {
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      selectedTestFilter: this.testFilterOptions[0],
      startDate: moment().subtract({ days: 1 }).startOf('day'),
      endDate: moment().endOf('day'),
      newValues: '',
      timeZone: "America/New_York",
      newValuesReplace: false,
      isLoading: false,
      criteriaReviewCodes: this.reviewCodes,
      criteriaValidationCodes: this.validationCodes,
      page: 1,
      isCriteriaCollapsed: false,
      pageSize: 100,
      orderBy: "insertedAt",
      orderByDescending: false,
      isSummaryCollapsed: true,
      numberToUpdate: 0,
    }

    if (props.optinReviewer.componentState) {
      this.state = props.optinReviewer.componentState
    }

    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.optinReviewer.report != this.props.optinReviewer.report && this.props.optinReviewer.report != null) {
      this.setState({
        isSummaryCollapsed: false,
        numberToUpdate: this.props.optinReviewer.report.reviewables.available,
      })
    }
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      startDate: startDate,
      endDate: endDate
    })
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleSaveDialogClose() {
    this.props.closeSaveDialog()
    let criteria = this.getCriteria()
    this.props.loadReviewables(criteria)
  }

  handleRunButtonClick() {
    let page = 1
    let criteria = this.getCriteria(page)
    this.props.loadReviewables(criteria)
    this.setState({
      page: page,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  handleSaveButtonClick() {
    let requests = this.props.optinReviewer.report.reviewables.records
      .filter(e => e.reviewStatus != e.updatedReviewStatus)
      .map(e => {
        return {
          id: e.id,
          reviewStatus: e.updatedReviewStatus,
          note: e.note,
          reviewedBy: this.props.user.name,
          reviewedAt: moment().unix()
        }
      })

    this.props.updateStatusCodes({ records: requests })
  }

  handleReviewablesUpdate(reviewablesReport) {
    this.props.updateReviewableList(reviewablesReport)
  }

  getCriteria() {
    return {
      timeZone: this.state.timeZone,
      rowLimit: this.state.pageSize,
      rowOffset: this.state.rowOffset,
      startDate: this.state.startDate.format('YYYY-MM-DD HH:mm:ss'),
      endDate: this.state.endDate.format('YYYY-MM-DD HH:mm:ss'),
      campaignUUIDs: this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: this.state.selectedPlacements.map(e => e.value),
      reviewCodes: this.state.criteriaReviewCodes.filter(e => e.active).map(e => e.value),
      validationCodes: this.state.criteriaValidationCodes.filter(e => e.active).map(e => e.value),
      testFilter: this.state.selectedTestFilter.value,
      orderBy: this.state.orderBy,
      orderByDescending: this.state.orderByDescending,
    }
  }

  handlePageChange(page) {
    let criteria = this.getCriteria()
    criteria.rowOffset = (page - 1) * this.state.pageSize
    this.props.loadReviewables(criteria)
    this.setState({
      page: page,
    })
  }

  sortReport(orderBy) {
    let orderByDescending = false
    if (this.state.orderBy == orderBy) {
      orderByDescending = !this.state.orderByDescending
    }

    let criteria = this.getCriteria()
    criteria.orderBy = orderBy
    criteria.orderByDescending = orderByDescending

    this.props.loadReviewables(criteria)

    this.setState({
      orderBy: orderBy,
      orderByDescending: orderByDescending,
    })
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true,
    })
  }

  renderSaveResultsModal() {
    if (this.props.optinReviewer.isSaving) {
      return (
        <Grid>
          <Cell col={12}>Saving ...</Cell>
        </Grid>)
    }


    let failed = this.props.optinReviewer.saveResults.records.filter(e => e.failed == true)
    let allCount = this.props.optinReviewer.saveResults.records.length

    var message = `${allCount - failed.length} / ${allCount} Accepted`
    return (
      <Grid>
        <Cell col={12}>
          <h3>Results:</h3>
        </Cell>
        <Cell col={12}>
          {message}
        </Cell>
        <Cell col={12}>
          {failed.length > 0 &&
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.saveResult.header`}>
                  <td className="th dimension">Id</td>
                  <td className="th dimension">Failed</td>
                  <td className="th dimension">Message</td>
                  <td className="th dimension">Details</td>
                </tr>
              </thead>
              <tbody>
                {failed.map((e, i) => {
                  return <tr key={`viewer.saveResult.${i}`} className="td">
                    <td className="td dimension">{e.id}</td>
                    <td className="td dimension">{e.failed}</td>
                    <td className="td dimension">{e.message}</td>
                    <td className="td dimension">{e.details}</td>
                  </tr>
                })}
              </tbody>
            </table>
          }
        </Cell>
      </Grid>
    )
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      selectedPlacements: placements,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."

    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
          <Modal
            appElement={this.props.parent}
            isOpen={this.props.optinReviewer.showSaveDialog}
            onRequestClose={this.handleSaveDialogCancel}
            contentLabel="Save Dialog">
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button onClick={() => this.handleSaveDialogClose()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              {this.props.optinReviewer.showSaveDialog && this.renderSaveResultsModal()}
            </Card>
          </Modal>
          <Grid>
            <Cell col={12}>
              <Collapsible title="Quarantine" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={11}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={4}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={4}>
                        <label className="floatLabel">Test filter</label>
                        <Select
                          isMulti={false}
                          value={this.state.selectedTestFilter}
                          onChange={(s) => {
                            this.setState({
                              selectedTestFilter: s
                            })
                          }}
                          options={this.testFilterOptions}
                        />
                      </Cell>
                      <Cell col={4}>
                        <Textfield
                          floatingLabel
                          pattern="[0-9]*"
                          error="Must be an integer"
                          label="Page size"
                          onChange={e => {
                            this.setState({
                              pageSize: e.target.value,
                            })

                          }}
                          value={this.state.pageSize}
                        />
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                      <Cell col={4} style={{ zIndex: "0" }}>
                        <Grid>
                          <label className="floatLabel">Review Filter</label>
                          {this.state.criteriaReviewCodes.map((e, i) => {
                            return <Cell key={`viewer.${i}.criteriaReviewCodes`} col={12}>
                              <Switch
                                onChange={() => {
                                  const statusCodes = this.state.criteriaReviewCodes
                                  statusCodes[i].active = !statusCodes[i].active
                                  this.setState({
                                    criteriaReviewCodes: statusCodes
                                  })
                                }}
                                checked={e.active}
                              >{e.label}</Switch>
                            </Cell>
                          })}
                        </Grid>
                      </Cell>
                      <Cell col={4} style={{ zIndex: "0" }}>
                        <Grid>
                          <label className="floatLabel">Validation Filter</label>
                          {this.state.criteriaValidationCodes.map((e, i) => {
                            return <Cell key={`viewer.${i}.criteriaValidationCodes`} col={12}>
                              <Switch
                                onChange={() => {
                                  const statusCodes = this.state.criteriaValidationCodes
                                  statusCodes[i].active = !statusCodes[i].active
                                  this.setState({
                                    criteriaValidationCodes: statusCodes
                                  })
                                }}
                                checked={e.active}
                              >{e.label}</Switch>
                            </Cell>
                          })}
                        </Grid>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  <div className="report">
                    <h4 className='reportTitle'>{"Optin Report"}</h4>
                    <div>{this.renderReviewables()}</div>
                  </div>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }
  resetUpdateModalData() {

    this.setState({
      updateModal: false,
      numberToUpdate: 0,
      updateStatus: ""
    })
  }

  compareEffectiveDate(a, b) {
    if (a.effectiveDate < b.effectiveDate) {
      return 1
    }
    if (a.effectiveDate > b.effectiveDate) {
      return -1
    }
    return 0
  }

  mapActive() {
    var found = false
    return (e, i, a) => {
      let scheduled = e.effectiveDate > moment().unix()
      var active = !found && !scheduled && (i + 1 === a.length || this.compareEffectiveDate(a[i], a[i + 1]) < 1)
      found = found || active
      if (active) {
        return e
      }
      return null
    }
  }

  getCurrentCap(campaign) {
    if (!campaign || !campaign.contractedLeadCaps) {
      return 0
    }
    let active = campaign.contractedLeadCaps
      .sort(this.compareEffectiveDate)
      .map(this.mapActive())
      .filter(x => x)
    let current = {}
    if (active.length > 0) {
      current = active[0]
    }
    return current.value
  }


  batchUpdateOnClickHandler(status, numOfLeads, oldestFirst, user) {
    let reportCriteria = this.getCriteria()

    let updateCriteria = this.getCriteria()
    updateCriteria.orderByDescending = !oldestFirst
    updateCriteria.rowOffset = 0
    updateCriteria.rowLimit = numOfLeads

    this.props.updateBulkStatusCodes(reportCriteria, updateCriteria, status, user)
  }

  renderReviewables() {
    if (this.props.optinReviewer.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.optinReviewer.report == null) {
      return <div></div>
    }
    if (this.props.optinReviewer.report.reviewables.records.length == 0) {
      return <div>{"No records found"}</div>
    }

    let updatesCount = this.props.optinReviewer.report.reviewables.records.filter(e => e.reviewStatus != e.updatedReviewStatus).length
    let numberOfPages = Math.ceil(this.props.optinReviewer.report.reviewables.available / this.props.optinReviewer.report.reviewables.limit)

    let campaignsMap = new Map()
    this.props.campaigns.forEach(e => campaignsMap.set(e.UUID, e))

    let placementsMap = new Map()
    this.props.placements.forEach(e => placementsMap.set(e.UUID, e))
    return (
      <React.Fragment>
        <Collapsible title="Summary" defaultOpen={!this.state.isSummaryCollapsed} onToggle={() => {
          this.setState({
            isSummaryCollapsed: !this.state.isSummaryCollapsed,
          })
        }}>

          <Grid style={{ "width": "100%" }}>
            {this.props.optinReviewer.report.summary.records.map((e, i) => {
              let campaign = campaignsMap.get(e.campaignUUID)
              let placement = placementsMap.get(e.placementUUID)

              return <Cell col={2} key={`summary_record_${i}`}>
                <Card shadow={2} >
                  <CardActions border>
                    <div>
                      <b>{campaign ? `${campaign.name} (${campaign.shortCode})` : `NA`}</b>
                    </div>
                    <div>
                      <b>{placement ? `${placement.name} (${placement.legacyID})` : `NA`}</b>
                    </div>
                    <hr />
                    {placement && placement.isBrokered ? <React.Fragment>
                      <div>Associated Cap: {e.associationCap}</div>
                      <div>Leads Delivered: {e.effectiveCapLeadsDelivered}</div>
                      <div>Leads Left: {e.effectiveCapLeadsLeftToDeliver}</div>
                      <div>Associated Daily Cap: {e.associationDailyCap}</div>
                      <div>Daily Delivered: {e.effectiveDailyCapLeadsDelivered}</div>
                      <div>Daily Left: {e.effectiveDailyCapLeadsLeftToDeliver}</div>
                      <hr></hr>
                      <div>
                        <p>Quarantine Reasons</p>
                        <div>{`Exceeded Associated Cap: ${e.rejectCounts.exceededAssociatedCap}`}</div>
                        <div>{`Exceeded Associated Daily Cap: ${e.rejectCounts.exceededAssociatedDailyCap}`}</div>
                        <div>{`Inactive Campaign: ${e.rejectCounts.inactiveCampaign}`}</div>
                        <div>{`Outside of Day Parting: ${e.rejectCounts.dayParted}`}</div>
                        <div>{`Inactive Campaign: ${e.rejectCounts.inactiveCampaign}`}</div>
                        <div>{`Missing Association: ${e.rejectCounts.missingAssociation}`}</div>
                        <div>{`Inactive Association: ${e.rejectCounts.inactiveAssociation}`}</div>
                      </div>
                    </React.Fragment> : <React.Fragment>
                      <div>Effective Cap: {e.effectiveCap}</div>
                      <div className={"subtext"}>Cap: Camp: {e.campaignCap} Assoc: {e.associationCap}</div>
                      <div>Leads Delivered: {e.effectiveCapLeadsDelivered}</div>
                      <div>Leads Left: {e.effectiveCapLeadsLeftToDeliver}</div>
                      <div>Daily Cap: {e.effectiveDailyCap}</div>
                      <div className={"subtext"}>Daily Cap: Camp: {e.campaignDailyCap} Assoc: {e.associationDailyCap}</div>
                      <div>Daily Delivered: {e.effectiveDailyCapLeadsDelivered}</div>
                      <div>Daily Left: {e.effectiveDailyCapLeadsLeftToDeliver}</div>
                      <hr></hr>
                      <div>
                        <p>Quarantine Reasons</p>
                        <div>{`Exceeded Cap: ${e.rejectCounts.exceededCap}`}</div>
                        <div>{`Exceeded Daily Cap: ${e.rejectCounts.exceededDailyCap}`}</div>
                        <div>{`Exceeded Associated Cap: ${e.rejectCounts.exceededAssociatedCap}`}</div>
                        <div>{`Exceeded Associated Daily Cap: ${e.rejectCounts.exceededAssociatedDailyCap}`}</div>
                        <div>{`Outside of Day Parting: ${e.rejectCounts.dayParted}`}</div>
                        <div>{`Inactive Campaign: ${e.rejectCounts.inactiveCampaign}`}</div>
                        <div>{`Missing Association: ${e.rejectCounts.missingAssociation}`}</div>
                        <div>{`Inactive Association: ${e.rejectCounts.inactiveAssociation}`}</div>
                      </div>
                    </React.Fragment>}
                  </CardActions>
                </Card>
              </Cell>
            })}
          </Grid>
        </Collapsible>

        <Grid style={{ "width": "100%" }}>
          <Cell col={2}>
            <Button
              id="save-button"
              colored
              raised
              ripple
              onClick={() => this.handleSaveButtonClick()}
              disabled={updatesCount == 0}
            >
              SAVE
            </Button>
          </Cell>
          <Cell col={12}>
            <div style={{ display: "flex", justifyContent: "space-between", margingBottom: "10px" }}>
              <Button accent onClick={() => this.handlePageChange(1)}>
                <Icon name="first_page" />
              </Button>
              <Button accent onClick={() => {
                this.handlePageChange(this.state.page - (this.state.page <= 1 ? 0 : 1))
              }}>
                <Icon name="chevron_left" />
              </Button>
              <span style={{ paddingTop: "10px" }}>
                ({this.state.page} | {numberOfPages})
              </span>
              <Button accent onClick={() => {
                this.handlePageChange(this.state.page + (this.state.page >= numberOfPages ? 0 : 1))
              }}>
                <Icon name="chevron_right" />
              </Button>
              <Button accent onClick={() => this.handlePageChange(numberOfPages)}>
                <Icon name="last_page" />
              </Button>
            </div>
          </Cell>
          <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.reviewables.header1`}>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                  <th className="th dimension" style={{ color: "black", textAlign: "left" }}>
                    <Grid>
                      <Cell col={12} style={{ backgroundColor: "white", padding: "4px" }}>
                        <div>
                          <Checkbox
                            ripple
                            label="Oldest First"
                            checked={this.state.descendingUpdate}
                            onChange={() => this.setState({ descendingUpdate: !this.state.descendingUpdate })}
                          />Oldest First
                        </div>

                        <div>
                          <Textfield
                            pattern="-?[0-9]*(\.[0-9]+)?"
                            error="Input is not a number!"
                            value={this.state.numberToUpdate}
                            onChange={e => {
                              this.setState({ numberToUpdate: e.target.value })
                            }}
                            label={`# of leads to reprocess (out of ${this.props.optinReviewer.report.reviewables.available})`}
                            floatingLabel
                          />
                        </div>
                      </Cell>
                      <Cell col={6}>
                        <Button
                          style={{ "backgroundColor": "green", "width": "24px", fontSize: "12px" }} colored raised ripple
                          onClick={() => {
                            this.batchUpdateOnClickHandler(3, this.state.numberToUpdate, this.state.descendingUpdate, this.props.user.name)
                          }}>
                          <span style={{ "textAlign": "left", "marginLeft": "-7px", "fontWeight": "bold" }}>Repost</span>
                        </Button>
                      </Cell>
                      <Cell col={6}>
                        <Button
                          style={{ "backgroundColor": "red", "width": "24px", fontSize: "12px" }} colored raised ripple
                          onClick={() => {
                            this.batchUpdateOnClickHandler(4, this.state.numberToUpdate, this.state.descendingUpdate, this.props.user.name)
                          }}>
                          <span style={{ "textAlign": "left", "marginLeft": "-7px", "fontWeight": "bold" }}>Reject</span>
                        </Button>
                      </Cell>
                    </Grid>
                  </th>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                  <th className="th dimension"></th>
                </tr>

              </thead>
              <thead>
                <tr className="th" key={`viewer.reviewables.header2`}>
                  <th className="th dimension">Id</th>
                  <th className="th dimension clickable" onClick={() => this.sortReport("insertedAt")}>
                    Created Time
                    {this.state.orderBy == 'insertedAt' && this.state.orderByDescending && <Icon name="keyboard_arrow_down" />}
                    {this.state.orderBy == 'insertedAt' && !this.state.orderByDescending && <Icon name="keyboard_arrow_up" />}
                  </th>
                  <th className="th dimension">Campaign</th>
                  <th className="th dimension">Placement</th>
                  <th className="th dimension">Metadata</th>
                  <th className="th dimension">Status</th>
                  <th className="th dimension">Update Status</th>
                  <th className="th dimension">Service Name</th>
                  <th className="th dimension">Validation Message</th>
                  <th className="th dimension">Note</th>
                  <th className="th dimension">Reviewed By</th>
                  <th className="th dimension">User Data</th>
                </tr>
              </thead>
              <tbody>
                {this.props.optinReviewer.report.reviewables.records.map((e, i) => {
                  let campaign = campaignsMap.get(e.campaignUUID)
                  let placement = placementsMap.get(e.placementUUID)
                  return (
                    <tr className="td" key={`viewer.reviewables.${i}`}>
                      <td className="td dimension">{e.id}</td>
                      <td className="td dimension">{this.unixToLocalTime(e.insertedAt)}</td>
                      <td className="td dimension">
                        {campaign && <div>{`${campaign.name} - ${campaign.shortCode}`}</div>}
                      </td>
                      <td className="td dimension">
                        {placement && <div>{`${placement.name} - ${placement.legacyID}`}</div>}
                      </td>
                      <td className="td dimension">
                        <div>session:</div>
                        <div>{e.sessionUUID}</div>
                        <div>optin:</div>
                        <div>{e.optinUUID}</div>
                        <div>{this.unixToLocalTime(e.optinCreatedAt)}</div>
                      </td>
                      <td className="td dimension">{this.reviewCodesMap.get(parseInt(e.reviewStatus)).label}</td>
                      <td className="td dimension">
                        {e.reviewStatus == "1" && (e.updatedReviewStatus !== 1 ? <b><i>{this.reviewCodesMap.get(parseInt(e.updatedReviewStatus)).label}</i></b> : <Grid>
                          <Cell col={6}>
                            <Button style={{ "backgroundColor": "green", "width": "24px", fontSize: "12px" }} colored raised ripple onClick={() => {
                              e.updatedReviewStatus = 3
                              this.handleReviewablesUpdate(this.props.optinReviewer.report)
                            }}>
                              <span style={{ "textAlign": "left", "marginLeft": "-7px", "fontWeight": "bold" }}>Repost</span>
                            </Button>
                          </Cell>
                          <Cell col={6}>
                            <Button style={{ "backgroundColor": "red", "width": "24px", fontSize: "12px" }} colored raised ripple onClick={() => {
                              e.updatedReviewStatus = 4
                              this.handleReviewablesUpdate(this.props.optinReviewer.report)
                            }}>
                              <span style={{ "textAlign": "left", "marginLeft": "-7px", "fontWeight": "bold" }}>Reject</span>
                            </Button>
                          </Cell>
                        </Grid>)
                        }
                      </td>
                      <td className="td dimension">{e.validationServiceName}</td>
                      <td className="td dimension">{e.validationMessage}</td>
                      <td className="td dimension">{e.note}</td>
                      <td className="td dimension">
                        <div>{e.reviewedBy}</div>
                        <div>{this.unixToLocalTime(e.reviewedAt)}</div>
                      </td>
                      <td className="td dimension">
                        <div>{`Email:${e.user.email}`}</div>
                        <div>{`IP:${e.user.ip}`}</div>
                        <div>{`First Name:${e.user.firstName}`}</div>
                        <div>{`Last Name:${e.user.lastName}`}</div>
                        <div>{`Phone:${e.user.phone}`}</div>
                        <div>{`Address1:${e.user.address1}`}</div>
                        <div>{`Address2:${e.user.address2}`}</div>
                        <div>{`City:${e.user.city} State:${e.user.state} Zip:${e.user.zipcode} Country:${e.user.country}`}</div>
                        <div>{`Gender:${e.user.gender} Title:${e.user.title}`}</div>
                        <div>{`DOB:${this.unixToLocalTime(e.user.dob)}`}</div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </Cell>
        </Grid>
      </React.Fragment >
    )
  }
}


const OptinReviewer = connect(mapStateToProps, mapDispatchToProps)(optinReviewer)

export default OptinReviewer
