import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { ActionTypes } from '../../../services'
import MapList from '../../maplist'
import PrepingPopup from './prepingPopup'
import { deepClone } from '../../../utils'
import RequestForm from '../../common/requestForm'
import { Card, CardActions, Grid, Cell, Textfield, Button, CardTitle, CardMenu, Switch } from 'react-mdl'
import { Prompt } from 'react-router'


function mapDispatchToProps(dispatch) {
  return {
    campaignUpdated: function () {
      dispatch({ type: ActionTypes.IS_DIRTY, data: true })
    },
  }
}

const checkTypes = ['BODY', 'STATUS', 'HEADER']
const SUCCESS_BODY = 0
const SUCCESS_CODES = 1

class preping extends React.Component {
  constructor(props) {
    super(props)
    const campaign = this.setupCampaign(props.campaign)
    this.state = {
      campaign: campaign,
      openPopup: false,
    }
    this.props.setGetCampaign(() => {
      return this.state.campaign
    })
  }

  setupCampaign(campaign){
    const newCampaign = deepClone(campaign)
    newCampaign.prepingConfiguration = newCampaign.prepingConfiguration || {}
    newCampaign.prepingConfiguration.responseCheckBody = newCampaign.prepingConfiguration.responseCheckBody || []
    newCampaign.prepingConfiguration.requestPreauth = newCampaign.prepingConfiguration.requestPreauth || {}
    newCampaign.prepingConfiguration.requestHeaders = newCampaign.prepingConfiguration.requestHeaders || []
    newCampaign.prepingConfiguration.requestPreauth.requestHeaders = newCampaign.prepingConfiguration.requestPreauth.requestHeaders || []
    newCampaign.prepingConfiguration.responseCheckHeader = newCampaign.prepingConfiguration.responseCheckHeader || []
    newCampaign.prepingConfiguration.responseCheckStatus = newCampaign.prepingConfiguration.responseCheckStatus || []
    newCampaign.prepingConfiguration.validWhenFound = !!newCampaign.prepingConfiguration.validWhenFound
    return newCampaign
  }

  handleRequestVerbChange(selected){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestVerb = selected.value
    this.update(campaign)
  }

  handleRequestUrlChange(value){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestUrl = value 
    this.update(campaign)
  }

  handleRequestBodyChange(body){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestBody = body
    this.update(campaign)
  }

  handleRequestHeaderUpdate(header){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestHeaders.forEach(rh => {
      if (rh.key == header.Key) {
        rh.value = header.Value
      } else {
        campaign.prepingConfiguration.requestHeaders.push(
          { key: header.Key, value: header.Value },
        )
      }
    })
    this.update(campaign)
  }

  handleRequestHeaderDelete(header){
    const campaign = this.state.campaign
    let idx = 0
    campaign.prepingConfiguration.requestHeaders.forEach(rh => {
      if (rh.key == header.Key) {
        campaign.prepingConfiguration.requestHeaders.splice( idx, 1,)
      }
      idx++
    })
    this.update(campaign)
  }

  handleRequestHeaderSave(header){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestHeaders.push(
      { key: header.Key, value: header.Value },
    )
    this.update(campaign)
  }

  handleAuthUserChange(value){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestAuthenticationUser = value
    this.update(campaign)
  }

  handleAuthPassword(value){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestAuthenticationPassword = value
    this.update(campaign)
  }

  handleAuthEnabledChange(){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestAuthenticationEnabled = !this.state.campaign.prepingConfiguration.requestAuthenticationEnabled
    this.update(campaign)
  }

  handleCheckTypeChange(selected){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.responseCheckType = selected.value
    this.update(campaign)
  }

  handleValidWhenFoundChange(){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.validWhenFound = !this.state.campaign.prepingConfiguration.validWhenFound
    this.update(campaign)
  }

  handleSuccessStatusCodeUpdate(statusCode){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.responseCheckStatus = this.state.campaign.prepingConfiguration.responseCheckStatus.map(ch => {
      if (ch == statusCode.Key) {
        ch = statusCode.Value
      }
      return ch
    })
    this.update(campaign)
  }

  handleSuccessStatusCodeDelete(statusCode){
    const campaign = this.state.campaign
    let idx = campaign.prepingConfiguration.responseCheckStatus.indexOf(statusCode.Value)
    campaign.prepingConfiguration.responseCheckStatus.splice(idx, 1)
    this.update(campaign)
  }

  handleSuccessStatusCodeSave(statusCode){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.responseCheckStatus.push(parseInt(statusCode.Value))
    this.update(campaign)
  }

  handleSuccessBodyStringsUpdate(body){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.responseCheckBody = this.state.campaign.prepingConfiguration.responseCheckBody.map(ch => {
      if (ch == body.Key) {
        ch = body.Value
      }
      return ch
    })
    this.update(campaign)
  }

  handleSuccessBodyStringsDelete(body){
    const campaign = this.state.campaign
    let idx = campaign.prepingConfiguration.responseCheckBody.indexOf(
      body.Value,
    )
    campaign.prepingConfiguration.responseCheckBody.splice(idx, 1)
    this.update(campaign)
  }

  handleSuccesBodyStringsSave(body){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.responseCheckBody.push(
      body.Value,
    )
    this.update(campaign)
  }

  handleSuccessHeadeUpdate(header){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.responseCheckHeader = this.state.campaign.prepingConfiguration.responseCheckHeader.map(ch => {
      if (ch.key == header.Key) {
        ch.value = header.Value
      }
      return ch
    })
    this.update(campaign)
  }

  handleSuccessHeaderDelete(header){
    const campaign = this.state.campaign
    let idx = campaign.prepingConfiguration.responseCheckHeader.findIndex(
      h => h.key = header.Key,
    )
    campaign.prepingConfiguration.responseCheckHeader.splice(idx, 1,)
    this.update(campaign)
  }

  handleSuccessHeaderSave(header){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.responseCheckHeader.push(
      { key: header.Key, value: header.Value },
    )
    this.update(campaign)
  }

  handleRequestPreauthVerbChange(verb){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestPreauth.verb = verb.value
    this.update(campaign)
  }

  handleRequestPreauthUrlChange(value){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestPreauth.url = value
    this.update(campaign)
  }

  handleRequestPreauthBodyChange(body){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestPreauth.requestBody = body
    this.update(campaign)
  }

  handleRequestPreauthHeaderUpdate(header){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestPreauth.requestHeaders.forEach(rh => {
      if (rh.key == header.Key) {
        rh.value = header.Value
      } else {
        campaign.prepingConfiguration.requestPreauth.requestHeaders.push(
          { key: header.Key, value: header.Value },
        )
      }
    })
    this.update(campaign)
  }

  handleRequestPreauthHeaderDelete(header){
    const campaign = this.state.campaign
    let idx = campaign.prepingConfiguration.requestPreauth.requestHeaders.findIndex(
      h => h.key = header.Key,
    )
    campaign.prepingConfiguration.requestPreauth.requestHeaders.splice(idx, 1)
    this.update(campaign)
  }

  handleRequestPreauthHeaderSave(header){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestPreauth.requestHeaders.push(
        { key: header.Key, value: header.Value },
    )
    this.update(campaign)
  }

  handlePreauthKeyChange(value){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestPreauth.Key = value
    this.update(campaign)
  }

  handlePreauthExpiration(value){
    const campaign = this.state.campaign
    campaign.prepingConfiguration.requestPreauth.expiration = parseInt(value)
    this.update(campaign)
  }

  update(campaign) {
    this.props.campaignUpdated()
    this.setState({
      campaign: campaign
    })
  }

  sendTestRequest() {
    this.setState({ openPopup: true, campaign: this.state.campaign, reqTime: new Date() })
  }

  render() {
    return (
      <React.Fragment>
        <Prompt
            when={this.props.isDirtyValue}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        <div ref={t => this.contentSection = t}>
          {this.state.openPopup ? <PrepingPopup parent={this.contentSection} onClose={() => {
            this.setState({
              openPopup: false
            })
          }} key={new Date().getMilliseconds()} campaign={this.props.campaign} prepingPreview={this.props.prepingPreview}/> : null}
          <Grid style={{ width: '100%' }}>
            <Cell col={12}>
              <Card shadow={2} style={{ overflow: "visible" }}>
                <CardTitle>Request Settings</CardTitle>
                <CardActions border>
                  <RequestForm
                      requestVerb={this.state.campaign.prepingConfiguration.requestVerb}
                      requestUrl={this.state.campaign.prepingConfiguration.requestUrl}
                      requestBody={this.state.campaign.prepingConfiguration.requestBody}
                      requestHeaders={this.state.campaign.prepingConfiguration.requestHeaders}
                      handleVerbChange={(selected)=> this.handleRequestVerbChange(selected)}
                      handleUrlChange={(e)=>this.handleRequestUrlChange(e.target.value)}
                      handleBodyChange={(body)=>this.handleRequestBodyChange(body)}
                      handleHeaderUpdate={(header)=>this.handleRequestHeaderUpdate(header)}
                      handleHeaderDelete={(header)=>this.handleRequestHeaderDelete(header)}
                      handleHeaderSave={(header)=>this.handleRequestHeaderSave(header)}
                  />
                </CardActions>
                <CardMenu>
                  <Button raised colored onClick={() => this.sendTestRequest()}>
                    Test
                  </Button>
                </CardMenu>
              </Card>
            </Cell>
            <Cell col={6}>
              <Card shadow={2} style={{ overflow: "visible" }}>
                <CardTitle>Response Check</CardTitle>
                <CardActions border>
                  <Grid>
                    <Cell col={6}>
                      <Select
                        placeholder={"Response Check Type"}
                        isMulti={false}
                        value={checkTypes[this.state.campaign.prepingConfiguration.responseCheckType] ? { label: checkTypes[this.state.campaign.prepingConfiguration.responseCheckType], value: this.state.campaign.prepingConfiguration.responseCheckType } : null}
                        onChange={(selected)=>this.handleCheckTypeChange(selected)}
                        options={checkTypes.map((e, idx) => {
                          return { label: e, value: idx }
                        })}
                      />
                    </Cell>
                    <Cell col={6}>
                      <div>
                        <Switch
                          onChange={()=>this.handleValidWhenFoundChange()}
                          checked={
                            this.state.campaign.prepingConfiguration.validWhenFound
                          }
                        >
                          {this.state.campaign.prepingConfiguration.validWhenFound ? "Valid" : "Invalid"}
                        </Switch>
                      </div>
                    </Cell>
                    <Cell col={12}>
                      <div>
                        {this.state.campaign.prepingConfiguration.responseCheckType ==
                          SUCCESS_CODES
                          ? <MapList
                            update={(statusCode)=>this.handleSuccessStatusCodeUpdate(statusCode)}
                            hideKey={true}
                            delete={(statusCode)=>this.handleSuccessStatusCodeDelete(statusCode)}
                            save={(statusCode)=>this.handleSuccessStatusCodeSave(statusCode)}
                            title="Success Codes"
                            items={this.state.campaign.prepingConfiguration.responseCheckStatus.map(
                              e => {
                                return { Value: e, Key: e }
                              },
                            )}
                          />
                          : this.state.campaign.prepingConfiguration.responseCheckType ==
                            SUCCESS_BODY
                            ? <MapList
                              update={(body)=>this.handleSuccessBodyStringsUpdate(body)}
                              hideKey={true}
                              delete={(body)=>this.handleSuccessBodyStringsDelete(body)}
                              save={(body)=>this.handleSuccesBodyStringsSave(body)}
                              title="Success Body Strings"
                              items={this.state.campaign.prepingConfiguration.responseCheckBody.map(
                                e => {
                                  return { Value: e, Key: e }
                                },
                              )}
                            />
                            : <MapList
                              hideKey={false}
                              update={(header)=>this.handleSuccessHeadeUpdate(header)}
                              delete={(header)=>this.handleSuccessHeaderDelete(header)}
                              save={(header)=>this.handleSuccessHeaderSave(header)}
                              title="Success Headers"
                              items={this.state.campaign.prepingConfiguration.responseCheckHeader.map(
                                e => {
                                  return { Key: e.key, Value: e.value }
                                },
                              )}
                            />}
                      </div>
                    </Cell>
                  </Grid>
                </CardActions>
                <CardMenu>
                </CardMenu>
              </Card>
            </Cell>
            <Cell col={6}>
              <Card
                shadow={2}>
                <CardTitle>Basic Authentication</CardTitle>
                <CardActions border>
                  <Textfield
                    floatingLabel
                    label="User"
                    onChange={(e)=>this.handleAuthUserChange(e.target.value)}
                    value={ this.state.campaign.prepingConfiguration.requestAuthenticationUser || '' }
                  />
                  <Textfield
                    floatingLabel
                    label="Password"
                    onChange={(e)=>this.handleAuthPassword(e.target.value)}
                    value={ this.state.campaign.prepingConfiguration.requestAuthenticationPassword || '' }
                  />
                </CardActions>
                <CardMenu>
                  <Switch
                    onChange={()=>this.handleAuthEnabledChange()}
                    checked={ this.state.campaign.prepingConfiguration.requestAuthenticationEnabled || false } >
                    {' '}
                  </Switch>
                </CardMenu>
              </Card>
            </Cell>
            <Cell col={12}>
              <Card shadow={2} style={{ overflow: "visible" }}>
                <CardTitle>Pre-Auth</CardTitle>
                <CardActions border>
                  <RequestForm
                      requestVerb={this.state.campaign.prepingConfiguration.requestPreauth.verb}
                      requestUrl={this.state.campaign.prepingConfiguration.requestPreauth.url}
                      requestBody={this.state.campaign.prepingConfiguration.requestPreauth.requestBody}
                      requestHeaders={this.state.campaign.prepingConfiguration.requestPreauth.requestHeaders}
                      handleVerbChange={(verb)=>this.handleRequestPreauthVerbChange(verb)}
                      handleUrlChange={(e)=>this.handleRequestPreauthUrlChange(e.target.value)}
                      handleBodyChange={(body)=>this.handleRequestPreauthBodyChange(body)}
                      handleHeaderUpdate={(header)=>this.handleRequestPreauthHeaderUpdate(header)}
                      handleHeaderDelete={(header)=>this.handleRequestPreauthHeaderDelete(header)}
                      handleHeaderSave={(header)=>this.handleRequestPreauthHeaderSave(header)}
                  >
                    <Cell col={12}>
                      <Textfield
                        floatingLabel
                        label="key"
                        onChange={(e)=>this.handlePreauthKeyChange(e.target.value)}
                        value={
                          this.state.campaign.prepingConfiguration.requestPreauth.Key || ''
                        }
                      />
                    </Cell>
                    <Cell col={12}>
                      <Textfield
                        pattern="-?[0-9]*(\.[0-9]+)?"
                        error="Input is not a number!"
                        floatingLabel
                        label="key expiration (seconds)"
                        onChange={(e)=>this.handlePreauthExpiration(e.target.value)}
                        value={
                          this.state.campaign.prepingConfiguration.requestPreauth.expiration || ''
                        }
                      />
                    </Cell>
                  </RequestForm>
                </CardActions>
              </Card>
            </Cell>
          </Grid>
        </div>
      </React.Fragment>)
  }
}

const Preping = connect(null, mapDispatchToProps)(preping)

export default Preping
