import {
  isDirty,
} from '../../../services'

import React from "react"
import { connect } from "react-redux"
import Select from 'react-select'
import DatePicker from "react-datepicker"
import FilterList from "../../filterList"
import { Grid, Cell, Card, CardActions, CardTitle, Switch, Textfield, ListItem, Icon, Button, Checkbox, FABButton, CardMenu, List } from 'react-mdl'
import moment from "moment"

function mapDispatchToProps(dispatch) {
  return {
    isDirty: function () {
      dispatch(isDirty(true))
    },
  }
}

class campaignDetails extends React.Component {
  constructor(props) {
    super(props)
    const { campaignRequest } = this.props
    campaignRequest.groupMemberUuids = campaignRequest.groupMemberUuids || []
    campaignRequest.approvedPlacementUuids = campaignRequest.approvedPlacementUuids || []
    this.state = {
      campaignRequest: campaignRequest,
      dayParting: campaignRequest.dayParts.length > 0,
      weekParting: campaignRequest.weekPart.length > 0,
      days: [
        { Name: 'Monday', Day: 1, Value: false },
        { Name: 'Tuesday', Day: 2, Value: false },
        { Name: 'Wednesday', Day: 3, Value: false },
        { Name: 'Thursday', Day: 4, Value: false },
        { Name: 'Friday', Day: 5, Value: false },
        { Name: 'Saturday', Day: 6, Value: false },
        { Name: 'Sunday', Day: 7, Value: false },
      ],
      hour: 0,
      minute: 0,
      end_hour: 5,
      end_minute: 0,
      ampm: "AM",
      end_ampm: "PM"
    }
    this.offerTypes = ["Single Optin", "Double Optin", "Custom Survey"]
    const timeRangeData = this.translateMilitaryTime()
    this.state = Object.assign(this.state, timeRangeData)
  }

  translateMilitaryTime() {
    function split(militaryTime) {
      let minute = militaryTime % 100
      let hour = Math.floor(militaryTime / 100)
      let ampm = "AM"
      if (hour >= 12) {
        ampm = "PM"
        hour -= 12
      }
      return { minute: minute, hour: hour, ampm: ampm }
    }

    if (this.props.campaignRequest.dayParts.length == 0) {
      return {}
    }
    let part = this.props.campaignRequest.dayParts[0]
    let startPart = split(part.start)
    let endPart = split(part.end)
    return {
      minute: startPart.minute,
      hour: startPart.hour,
      ampm: startPart.ampm,
      end_minute: endPart.minute,
      end_hour: endPart.hour,
      end_ampm: endPart.ampm,
    }
  }

  calcDayPart() {
    let hour = this.state.hour + (this.state.ampm == 'AM' ? 0 : 12)
    let start = (hour * 100) + this.state.minute

    let hour_end = this.state.end_hour + (this.state.end_ampm == 'AM' ? 0 : 12)
    let end = (hour_end * 100) + this.state.end_minute

    if (start > end) {
      //error
    }

    return {
      start: start,
      end: end
    }
  }

  handleCampaignRequestValueChange(fieldName, value) {
    this.props.isDirty()
    const campaignRequest = this.state.campaignRequest
    campaignRequest[fieldName] = value
    this.setState({
      campaignRequest: campaignRequest
    })
  }

  handleTimeChange(fieldName, value) {
    if (value.length == 0) {
      this.setState({
        fieldName: ""
      })
      return
    }
    this.props.isDirty()
    const campaignRequest = this.state.campaignRequest
    campaignRequest[0] = this.calcDayPart()
    this.setState({
      [fieldName]: parseInt(value),
      campaignRequest: campaignRequest,
    })
  }

  handleAMPMChange(fieldName, value) {
    this.props.isDirty()
    const campaignRequest = this.state.campaignRequest
    campaignRequest[0] = this.calcDayPart()
    this.setState({
      [fieldName]: value,
      campaignRequest: campaignRequest,
    })
  }

  handleWeekdaysChange(day, index) {
    this.props.isDirty()
    const { campaignRequest, days } = this.state
    days[index].Value = !this.state.days[index].Value
    if (days[index].Value) {
      campaignRequest.weekPart.push(day.Day)
    } else {
      campaignRequest.weekPart = this.state.campaignRequest.weekPart.filter((d) => d != day.Day)
    }
    this.setState({
      days: days,
      campaignRequest: campaignRequest,
    })
  }

  handleSelectCampaign(campaign) {
    this.props.isDirty()
    const campaignRequest = this.state.campaignRequest
    campaignRequest.groupMemberUuids.push(campaign.UUID)
    this.setState({
      adding: false,
      campaignRequest: campaignRequest,
    })
  }

  handleDayPartingToggle() {
    this.props.isDirty()
    this.setState({
      dayParting: !this.state.dayParting
    })
  }

  handleWeekPartingToggle() {
    this.props.isDirty()
    this.setState({
      weekParting: !this.state.weekParting
    })
  }

  render() {
    let listItems = this.state.campaignRequest.groupMemberUuids.map(groupMemberUUID => {
      let campaign = this.props.campaigns.filter(c => c.UUID == groupMemberUUID)[0]
      if (!campaign) {
        return null
      }
      return (
        <ListItem key={campaign.UUID}>
          <Grid>
            <Cell col={10}>
              <span>{campaign.name}</span>
            </Cell>
            <Cell col={2}>
              <Button
                accent
                onClick={() => {
                  this.props.isDirty()
                  const campaignRequest = this.state.campaignRequest
                  campaignRequest.groupMemberUuids = this.state.campaignRequest.groupMemberUuids.filter(e => e !== groupMemberUUID)
                  this.setState({
                    campaignRequest: campaignRequest
                  })
                }}
              >
                <Icon name="delete" />
              </Button>
            </Cell>
          </Grid>
        </ListItem>
      )
    })
    return (
      <Card shadow={2} className="clientDetails" style={{ overflow: "visible" }}>
        <CardActions border>
          <Grid>
            <Cell col={6}>
              <Grid>
                <Cell col={6}>
                  <label className="floatLabel">Start Date</label>
                  <DatePicker
                    isClearable={true}
                    dateFormat="MM/dd/yyyy"
                    selected={!this.state.campaignRequest.startDate ? null : this.state.campaignRequest.startDate.toDate()}
                    startDate={!this.state.campaignRequest.startDate ? null : this.state.campaignRequest.startDate.toDate()}
                    withPortal
                    onChange={dateMoment => this.handleCampaignRequestValueChange('startDate', moment(dateMoment))}
                  />
                </Cell>
                <Cell col={6}>
                  <label className="floatLabel">End Date</label>
                  <DatePicker
                    isClearable={true}
                    dateFormat="MM/dd/yyyy"
                    selected={!this.state.campaignRequest.endDate ? null : this.state.campaignRequest.endDate.toDate()}
                    startDate={!this.state.campaignRequest.endDate ? null : this.state.campaignRequest.endDate.toDate()}
                    withPortal
                    onChange={dateMoment => this.handleCampaignRequestValueChange('endDate', moment(dateMoment))}
                  />
                </Cell>
                <Cell col={12}>
                  <Select
                    placeholder={"Campaign Type"}
                    isMulti={false}
                    value={this.offerTypes[this.state.campaignRequest.offerType] ? { label: this.offerTypes[this.state.campaignRequest.offerType], value: this.state.campaignRequest.offerType } : null}
                    onChange={selected => this.handleCampaignRequestValueChange('offerType', selected.value)}
                    options={this.offerTypes.map((e, idx) => {
                      return { label: e, value: idx }
                    })}
                  />
                </Cell>
                <Cell col={12}>
                  <Textfield
                    floatingLabel
                    label="Number Of Leads In Contract"
                    onChange={e => this.handleCampaignRequestValueChange('leadsInContract', e.target.value)}
                    value={this.state.campaignRequest.leadsInContract || ''}
                  />
                </Cell>
                <Cell col={12}>
                  <Textfield
                    floatingLabel
                    label="Daily Client Cap"
                    onChange={e => this.handleCampaignRequestValueChange('clientCap', e.target.value)}
                    value={this.state.campaignRequest.clientCap || ''}
                  />
                </Cell>
                <Cell col={12}>
                  <Grid>
                    <Cell col={5}>
                      <Switch
                        onChange={() => this.handleDayPartingToggle()}
                        checked={this.state.dayParting}
                      >Day Parting</Switch>
                    </Cell>
                    <Cell col={12}>
                      {!this.state.dayParting ? null :
                        <Card shadow={2} className={"MeridianChooser"} style={{ overflow: "visible" }}>
                          <CardActions>
                            <Grid>
                              <Cell col={4}>
                                <Textfield
                                  disabled={!this.state.dayParting}
                                  pattern="-?[0-9]*(\.[0-9]+)?"
                                  error="Input is not a number!"
                                  value={this.state.hour}
                                  floatingLabel
                                  label="Hour"
                                  style={{ width: '100px' }}
                                  onChange={e => this.handleTimeChange('hour', e.target.value)}
                                />
                              </Cell>
                              <Cell col={4}>
                                <Textfield
                                  disabled={!this.state.dayParting}
                                  pattern="-?[0-9]*(\.[0-9]+)?"
                                  error="Input is not a number!"
                                  value={this.state.minute}
                                  floatingLabel
                                  label="Minute"
                                  style={{ width: '100px' }}
                                  onChange={e => this.handleTimeChange('minute', e.target.value)}
                                />
                              </Cell>
                              <Cell col={4}>
                                <Select
                                  isMulti={false}
                                  value={{ label: this.state.ampm, value: this.state.ampm }}
                                  onChange={(s) => this.handleAMPMChange('ampm', s.value)}
                                  options={[{ label: 'AM', value: 'AM' }, { label: 'PM', value: 'PM' }]}
                                />
                              </Cell>
                              <Cell col={4}>
                                <Textfield
                                  disabled={!this.state.dayParting}
                                  pattern="-?[0-9]*(\.[0-9]+)?"
                                  error="Input is not a number!"
                                  value={this.state.end_hour}
                                  floatingLabel
                                  label="Hour"
                                  style={{ width: '100px' }}
                                  onChange={e => this.handleTimeChange('end_hour', e.target.value)}
                                />
                              </Cell>
                              <Cell col={4}>
                                <Textfield
                                  disabled={!this.state.dayParting}
                                  pattern="-?[0-9]*(\.[0-9]+)?"
                                  error="Input is not a number!"
                                  value={this.state.end_minute}
                                  floatingLabel
                                  label="Minute"
                                  style={{ width: '100px' }}
                                  onChange={e => this.handleTimeChange('end_hour', e.target.value)}
                                />
                              </Cell>
                              <Cell col={4}>
                                <Select
                                  isMulti={false}
                                  value={{ label: this.state.end_ampm, value: this.state.end_ampm }}
                                  onChange={(s) => this.handleAMPMChange('end_ampm', s.value)}
                                  options={[{ label: 'AM', value: 'AM' }, { label: 'PM', value: 'PM' }]}
                                />
                              </Cell>
                            </Grid>
                          </CardActions>
                        </Card>}
                    </Cell>
                  </Grid>
                </Cell>
                <Cell col={12}>
                  <Grid>
                    <Cell col={6}>
                      <Switch
                        onChange={() => this.handleWeekPartingToggle('weekParting', !this.state.weekParting)}
                        checked={this.state.weekParting || this.state.campaignRequest.weekPart.length > 0}
                      >Week Parting</Switch>
                    </Cell>
                    <Cell col={6} >
                      {this.state.days.map((d, i) => {
                        return (
                          <Checkbox
                            disabled={!this.state.weekParting}
                            key={d.Name}
                            ripple
                            label={d.Name}
                            checked={this.state.campaignRequest.weekPart.includes(d.Day)}
                            onChange={() => this.handleWeekdaysChange(d, i)}
                          />
                        )
                      })}
                    </Cell>
                  </Grid>
                </Cell>
                <Cell col={12}>
                  <Textfield
                    floatingLabel
                    label="Lead Acceptance Percantage"
                    onChange={(e) => this.handleCampaignRequestValueChange('leadAcceptancePercentage', e.target.value)}
                    value={this.state.campaignRequest.leadAcceptancePercentage || ''}
                  />
                </Cell>
                <Cell col={12}>
                  <Textfield
                    floatingLabel
                    label="Max Scrub Percantage"
                    onChange={(e) => this.handleCampaignRequestValueChange('maxScrubPercentage', e.target.value)}
                    value={this.state.campaignRequest.maxScrubPercentage || ''}
                  />
                </Cell>
                <Cell col={12}>
                  <Switch
                    onChange={() => this.handleCampaignRequestValueChange('welcomeEmail', !this.state.campaignRequest.welcomeEmail)}
                    checked={this.state.campaignRequest.welcomeEmail || false}
                  >Welcome Email</Switch>
                </Cell>
                <Cell col={12}>
                  <Switch
                    onChange={() => this.handleCampaignRequestValueChange('tcpa', !this.state.campaignRequest.tcpa)}
                    checked={this.state.campaignRequest.tcpa || false}
                  >TCPA</Switch>
                </Cell>
              </Grid>
            </Cell>
            <Cell col={6}>
              <Card style={{ width: '100%' }} shadow={2}>
                <CardTitle>Campaign Group</CardTitle>
                <CardMenu>
                  <FABButton
                    onClick={() => {
                      this.setState({
                        adding: true
                      })
                    }}
                    mini
                    colored
                    className="fabPos"
                  >
                    <Icon name="add" />
                  </FABButton>
                </CardMenu>
                <CardActions style={{ maxHeight: '240px', overflowY: 'scroll' }}>
                  <List className="fullWidth">
                    {listItems}
                  </List>
                </CardActions>
                {this.state.adding
                  ? <CardActions border>
                    <FilterList
                      label="Campaign list..."
                      additionalFieldList={['UUID', 'shortCode']}
                      title="Campaigns"
                      selectItem={(c) => this.handleSelectCampaign(c)}
                      items={this.props.campaigns.filter(
                        c => !this.state.campaignRequest.groupMemberUuids.some(
                          mem => mem == c.UUID,
                        ),
                      )}
                    />
                  </CardActions>
                  : null}
              </Card>
            </Cell>
          </Grid>
        </CardActions>
      </Card >
    )
  }
}
const CampaignDetails = connect(null, mapDispatchToProps)(
  campaignDetails
)

export default CampaignDetails