import {
    generateActivityLog,
    generateActivityLogFailure,
    generateActivityLogSuccess
} from '../reducers/activityLogReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

const url = getUrlFor("reporting", "activity-log")
//const url = "http://localhost:9001/activity-log/"
//const url = "http://localhost:9001/activity-log-mock/"

export function generateActivityLogThunk(criteria) {
    return function (dispatch, getState) {
        dispatch(generateActivityLog())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), "format=json", DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generateActivityLogSuccess(res.data))
            }).catch(e => {
                dispatch(generateActivityLogFailure(`Getting report`))
                exceptionHandling(e, dispatch, "Getting report")
            })
    }
}