const SAVE_STATE_OF_BROKERED_FAILED_LEADS = 'SAVE_STATE_OF_BROKERED_FAILED_LEADS'
const GENERATE_BROKERED_FAILED_LEADS = 'GENERATE_BROKERED_FAILED_LEADS'
const GENERATE_BROKERED_FAILED_LEADS_FAILURE = 'GENERATE_BROKERED_FAILED_LEADS_FAILURE'
const GENERATE_BROKERED_FAILED_LEADS_SUCCESS = 'GENERATE_BROKERED_FAILED_LEADS_SUCCESS'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_BROKERED_FAILED_LEADS, data })

export const generateBrokeredFailedLeads = () =>
    ({ type: GENERATE_BROKERED_FAILED_LEADS })

export const generateBrokeredFailedLeadsSuccess = (criteria, report) =>
    ({ type: GENERATE_BROKERED_FAILED_LEADS_SUCCESS, data: { criteria: criteria, report: report } })

export const generateBrokeredFailedLeadsFailure = error =>
    ({ type: GENERATE_BROKERED_FAILED_LEADS_FAILURE, error })



const BROKERED_FAILED_LEADS_LEAD_VIEWER_START_LOADING = 'BROKERED_FAILED_LEADS_LEAD_VIEWER_START_LOADING'
const BROKERED_FAILED_LEADS_LEAD_VIEWER_LOADED = 'BROKERED_FAILED_LEADS_LEAD_VIEWER_LOADED'
const BROKERED_FAILED_LEADS_LEAD_VIEWER_DIALOG_CLOSED = 'BROKERED_FAILED_LEADS_LEAD_VIEWER_DIALOG_CLOSED'

export const startLoadingLeadViewer = () =>
    ({ type: BROKERED_FAILED_LEADS_LEAD_VIEWER_START_LOADING })

export const loadedLeadViewer = (criteria, report) =>
    ({ type: BROKERED_FAILED_LEADS_LEAD_VIEWER_LOADED, data: { criteria: criteria, report: report } })

export const closedLeadViewer = () =>
    ({ type: BROKERED_FAILED_LEADS_LEAD_VIEWER_DIALOG_CLOSED })



const initialState = {
    leadViewerReport: {
        report: null,
        isLoading: false,
        showPopup: false,
        criteria: null,
    },
    report: null,
    criteria: null,
    isLoading: false,
    error: null,
    componentState: null,
}

export const brokeredFailedLeadsState = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_STATE_OF_BROKERED_FAILED_LEADS:
            return {
                ...state,
                componentState: action.data,
            }
        case GENERATE_BROKERED_FAILED_LEADS:
            return {
                ...state,
                isLoading: true,
                report: null,
                error: null,
            }
        case GENERATE_BROKERED_FAILED_LEADS_SUCCESS:
            console.dir(action.data)
            return {
                ...state,
                isLoading: false,
                report: action.data.report.report,
                slug: action.data.criteria.slug,
                request: action.data.criteria,
                criteria: action.data.criteria,
                error: null,
            }
        case GENERATE_BROKERED_FAILED_LEADS_FAILURE:
            return {
                ...state,
                isLoading: false,
                report: null,
                error: { message: action.error },
            }

        case BROKERED_FAILED_LEADS_LEAD_VIEWER_START_LOADING:
            return {
                ...state,
                leadViewerReport: {
                    ...state.leadViewerReport,
                    isLoading: true,
                    showPopup: true,
                    criteria: null,
                    report: null,
                }
            }
        case BROKERED_FAILED_LEADS_LEAD_VIEWER_LOADED:
            return {
                ...state,
                leadViewerReport: {
                    ...state.leadViewerReport,
                    isLoading: false,
                    criteria: action.data.criteria,
                    report: action.data.report.report,
                }
            }
        case BROKERED_FAILED_LEADS_LEAD_VIEWER_DIALOG_CLOSED:
            return {
                ...state,
                leadViewerReport: {
                    ...state.leadViewerReport,
                    showPopup: false,
                    criteria: null,
                    report: null,
                }
            }

        default:
            return state
    }
}
