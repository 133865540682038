
import { generateBrokeredFailedLeadsThunk, leadViewerReportThunk } from "../../services/brokeredFailedLeads"
import { saveState, closedLeadViewer } from "../../reducers/brokeredFailedLeadsReducer"
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Select from 'react-select'
import { Grid, Cell, Button, Icon, Card, CardActions } from 'react-mdl'
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import NumberFormat from 'react-number-format'
import { defaults } from 'react-chartjs-2'
import { Collapsible } from "../common/components"
import { filterForSelectable, reportReady } from '../common/func'
import Modal from "react-modal"
import BrokeredLeadViewerReport from '../brokered-lead-viewer/report.js'

defaults.animation = false

function mapStateToProps(state) {
  return {
    brokeredFailedLeads: state.brokeredFailedLeads,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadBrokeredFailedLeads: function (criteria, slug) {
      dispatch(generateBrokeredFailedLeadsThunk(criteria, slug))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
    openLeadViewerPopup: function (criteria) {
      dispatch(leadViewerReportThunk(criteria))
    },
    closedLeadViewerPopup: function () {
      dispatch(closedLeadViewer())
    },
  }
}

const modalStyles = {
  height: "90%"
}

const commentStyle = {
  color: "#666",
  paddingBotton: "5px",
  fontSize: "80%",
}

class brokeredFailedLeads extends React.Component {
  constructor(props) {
    super(props)

    this.timeZone = "America/New_York"

    this.colors = []
    for (let step = 0; step < 1000; step++) {
      var randomColor = `rgba(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)}, 0.6)`
      this.colors.push(randomColor)
    }

    this.minimalLeadCount = [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 20, label: "20" },
      { value: 50, label: "50" },
      { value: 100, label: "100" },
      { value: 200, label: "200" },
    ]

    this.state = {
      leadViewerPage: 1,
      startDate: moment().startOf("day"),
      endDate: moment(),
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      newValues: '',
      newValuesReplace: false,
      page: 1,
      records: [],
      allRowsExpanded: false,
      selectedMinimalLeadCount: this.minimalLeadCount[5],
      isLoading: false,
      isCriteriaCollapsed: false,
      filterUpdated: false,
    }

    if (props.brokeredFailedLeads.componentState) {
      this.state = props.brokeredFailedLeads.componentState
    }

    if (props.slug && props.slug.length > 0) {
      let criteria = this.getCriteria()
      this.props.loadBrokeredFailedLeads(criteria, props.slug)
    }
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }


  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  componentDidUpdate2(prevProps) {
    if (prevProps.associatedAddressValidations !== this.props.associatedAddressValidations ||
      prevProps.brokeredFailedLeads.report !== this.props.brokeredFailedLeads.report) {
      let records = []
      if (this.props.brokeredFailedLeads.report && this.props.brokeredFailedLeads.report.records) {
        records = this.props.brokeredFailedLeads.report.records.map((e) => {
          e.rowExpanded = false
          return e
        })
      }
      this.setState({
        records: records,
      })
    }
  }

  componentDidUpdate(prevProps) {
    let update = reportReady(
      prevProps.brokeredFailedLeads.report, this.props.brokeredFailedLeads.report,
      prevProps.campaigns, prevProps.placements,
      this.props.campaigns, this.props.placements)

    if (update) {
      let associations = []
      if (this.props.brokeredFailedLeads.report && this.props.brokeredFailedLeads.report.leads) {
        associations = this.props.brokeredFailedLeads.report.leads.map((e) => {
          e.rowExpanded = false
          return e
        })
      }
      let state = this.state
      state.associations = associations
      if (this.props.brokeredFailedLeads.request) {
        let criteria = this.getCriteria(this.props.brokeredFailedLeads.request)
        state.selectedMinimalLeadCount = { value: criteria.minimalLeadCount, label: criteria.minimalLeadCount.toString() }
        state.selectedCampaigns = filterForSelectable(this.props.campaigns, criteria.campaignUUIDs)
        state.selectedPlacements = filterForSelectable(this.props.placements, criteria.placementUUIDs)
        state = { ...state, ...criteria }
        state.startDate = moment(state.startDate)
        state.endDate = moment(state.endDate)
      }
      this.setState(state)
    }
  }
  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      filterUpdated: true,
      startDate: startDate,
      endDate: endDate
    })
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleRunButtonClick() {
    let page = 1
    let criteria = this.getCriteria()
    this.props.loadBrokeredFailedLeads(criteria, this.state.filterUpdated ? "" : this.props.brokeredFailedLeads.slug)
    this.setState({
      page: page,
      filterUpdated: false,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  getCriteria(request) {
    request = request || {}
    return {
      timeZone: request.timeZone || this.timeZone,
      startDate: request.startDate || this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: request.endDate || this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: request.campaignUUIDs || this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: request.placementUUIDs || this.state.selectedPlacements.map(e => e.value),
      minimalLeadCount: request.minimalLeadCount || this.state.selectedMinimalLeadCount.value,
    }
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true,
    })
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      filterUpdated: true,
      selectedPlacements: placements,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."

    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
          <Modal
            appElement={this.props.parent}
            isOpen={this.props.brokeredFailedLeads.leadViewerReport.showPopup}
            onRequestClose={this.handleSessionPopupClose}
            contentLabel="Lead Viewer"
            style={modalStyles}
          >
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button
                  style={{ position: "fixed", backgroundColor: "white", right: "5%" }}
                  onClick={() => this.handleCloseSessionsModal()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              <div>
                {this.props.brokeredFailedLeads.leadViewerReport.showPopup && this.renderSessionsModalContent()}
              </div>
            </Card>
          </Modal>
          <Grid>
            <Cell col={12}>
              <Collapsible title="Brokered Failed Leads" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={11}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={4}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={2}>
                        <label className="floatLabel">Min. Lead Count</label>
                        <Select
                          isMulti={false}
                          value={this.state.selectedMinimalLeadCount}
                          onChange={(s) => {
                            this.setState({
                              filterUpdated: true,
                              selectedMinimalLeadCount: s
                            })
                          }}
                          options={this.minimalLeadCount}
                        />
                      </Cell>
                      <Cell col={6}></Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              filterUpdated: true,
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                {
                  this.props.brokeredFailedLeads.slug ? <Cell col={12}>
                    <a target="_blank" rel="noreferrer"
                      href={window.location.origin + window.location.pathname + `?format=json&report=brokered-failed-leads&slug=${this.props.brokeredFailedLeads.slug}`}>
                      Share Report</a>
                  </Cell> : null
                }
                <Cell col={12}>
                  {this.renderReport()}
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }


  handleRowToggleClick(i) {
    const records = this.state.records
    records[i].rowExpanded = !records[i].rowExpanded
    this.setState({
      records: records,
    })
  }

  handleAllRowsToggleClick() {
    let flag = !this.state.allRowsExpanded
    const records = this.state.records.map((e) => {
      e.rowExpanded = flag
      return e
    })
    this.setState({
      records: records,
      allRowsExpanded: flag,
    })
  }

  renderReport() {
    if (this.props.brokeredFailedLeads.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.brokeredFailedLeads.error != null) {
      return <div>Error: {this.props.brokeredFailedLeads.error.message}</div>
    }
    if (this.props.brokeredFailedLeads.report == null) {
      return <div></div>
    }
    return <div className="report" style={{ borderWidth: 0 }}>
      <div>{this.renderStatsByPlacement()}</div>
      <br></br>
      <div>{this.renderDetailsByPlacement()}</div>
      <br></br>
      <br></br>
      <br></br>
    </div>
  }


  renderStatsByPlacement() {
    let section = this.props.brokeredFailedLeads.report.placementStats

    if (section.records.length == 0) {
      return <div>{"No records found"}</div>
    }

    section.records.sort((f1, f2) => {
      if (f1.failedLeadPercentage < f2.failedLeadPercentage) return 1
      if (f1.failedLeadPercentage > f2.failedLeadPercentage) return -1
      return 0
    })

    return (
      <React.Fragment>
        <Grid style={{ "width": "100%" }}>
          <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.brokeredFailedLeads.header1`}>
                  <th className="th dimension" colSpan={3} style={{ textAlign: "center" }}>{section.title}</th>
                </tr>
                <tr className="th" key={`viewer.brokeredFailedLeads.header2`}>
                  <th className="th dimension">Placement</th>
                  <th className="th dimension">Failed / Attempted</th>
                  <th className="th dimension">Failure Percentage</th>
                </tr>
              </thead>
              <tbody>
                {section.records.map((e, i) => {
                  return (
                    <React.Fragment key={`viewer.brokeredFailedLeads.wrapper.${i}`}>
                      <tr className="td" key={`viewer.brokeredFailedLeads.${i}`}>
                        <td className="td dimension">
                          {e.placementName}
                          <div style={commentStyle}>{e.placementUUID}</div>
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(null, e.placementUUID, true, null, null)}>{e.failedLeadCount}</span> / {e.leadCount}
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(null, e.placementUUID, true, null, null)}>
                            <NumberFormat value={e.failedLeadPercentage * 100.00} displayType={'text'} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }

  renderStatsByCampaign() {
    let section = this.props.brokeredFailedLeads.report.campaignStats

    if (section.records.length == 0) {
      return <div>{"No records found"}</div>
    }

    section.records.sort((f1, f2) => {
      if (f1.failedLeadPercentage < f2.failedLeadPercentage) return 1
      if (f1.failedLeadPercentage > f2.failedLeadPercentage) return -1
      return 0
    })

    return (
      <React.Fragment>
        <Collapsible title={section.title} defaultOpen={true}>
          <Grid style={{ "width": "100%" }}>
            <Cell col={12}>
              <table className="report">
                <thead>
                  <tr className="th" key={`viewer.brokeredFailedLeads.header1`}>
                    <th className="th dimension" colSpan={3} style={{ textAlign: "center" }}>{section.title}</th>
                  </tr>
                  <tr className="th" key={`viewer.brokeredFailedLeads.header2`}>
                    <th className="th dimension">Campaign</th>
                    <th className="th dimension">Failed / Attempted</th>
                    <th className="th dimension">Failure Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {section.records.map((e, i) => {
                    return (
                      <React.Fragment key={`viewer.brokeredFailedLeads.wrapper.${i}`}>
                        <tr className="td" key={`viewer.brokeredFailedLeads.${i}`}>
                          <td className="td dimension">
                            {e.campaignName}
                            <div style={commentStyle}>{e.campaignUUID}</div>
                          </td>
                          <td className="td metric">
                            <span className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(e.campaignUUID, null, true, null, null)}>{e.failedLeadCount}</span> / {e.leadCount}
                          </td>
                          <td className="td metric">
                            <span className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(e.campaignUUID, null, true, null, null)}>
                              <NumberFormat value={e.failedLeadPercentage * 100.00} displayType={'text'} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </Cell>
          </Grid>
        </Collapsible>
      </React.Fragment>
    )
  }

  renderStatsByAssociation() {
    let section = this.props.brokeredFailedLeads.report.associationStats

    if (section.records.length == 0) {
      return <div>{"No records found"}</div>
    }

    section.records.sort((f1, f2) => {
      if (f1.failedLeadPercentage < f2.failedLeadPercentage) return 1
      if (f1.failedLeadPercentage > f2.failedLeadPercentage) return -1
      return 0
    })

    return (
      <React.Fragment>
        <Collapsible title={section.title} defaultOpen={true}>
          <Grid style={{ "width": "100%" }}>
            <Cell col={12}>
              <table className="report">
                <thead>
                  <tr className="th" key={`viewer.brokeredFailedLeads.header1`}>
                    <th className="th dimension" colSpan={4} style={{ textAlign: "center" }}>{section.title}</th>
                  </tr>
                  <tr className="th" key={`viewer.brokeredFailedLeads.header2`}>
                    <th className="th dimension">Placement</th>
                    <th className="th dimension">Campaign</th>
                    <th className="th dimension">Failed / Attempted</th>
                    <th className="th dimension">Failure Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {section.records.map((e, i) => {
                    return (
                      <React.Fragment key={`viewer.brokeredFailedLeads.wrapper.${i}`}>
                        <tr className="td" key={`viewer.brokeredFailedLeads.${i}`}>
                          <td className="td dimension">
                            {e.placementName}
                            <div style={commentStyle}>{e.placementUUID}</div>
                          </td>
                          <td className="td dimension">
                            {e.campaignName}
                            <div style={commentStyle}>{e.campaignUUID}</div>
                          </td>
                          <td className="td metric">
                            <span className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(e.campaignUUID, e.placementUUID, true, null, null)}>{e.failedLeadCount}</span> / {e.leadCount}
                          </td>
                          <td className="td metric">
                            <span className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(e.campaignUUID, e.placementUUID, true, null, null)}>
                              <NumberFormat value={e.failedLeadPercentage * 100.00} displayType={'text'} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </Cell>
          </Grid>
        </Collapsible>
      </React.Fragment>
    )
  }

  renderDetailsByPlacement() {
    let section = this.props.brokeredFailedLeads.report.placementDetails

    if (section.records.length == 0) {
      return <div>{"No records found"}</div>
    }

    section.records.sort((f1, f2) => {
      if (f1.placementName > f2.placementName) return 1
      if (f1.placementName < f2.placementName) return -1
      return 0
    })

    return (
      <React.Fragment>
        <Grid style={{ "width": "100%" }}>
          <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.brokeredFailedLeadsByType.header1`}>
                  <th className="th dimension" colSpan={3} style={{ textAlign: "center" }}>{section.title}</th>
                </tr>
                <tr className="th" key={`viewer.brokeredFailedLeadsByType.header2`}>
                  <th className="th dimension">Placement</th>
                  <th className="th dimension">Errors</th>
                  <th className="th dimension">Failed Leads</th>
                </tr>
              </thead>
              <tbody>
                {section.records.map((e, i) => {
                  return (
                    <React.Fragment key={`viewer.brokeredFailedLeadsByType.wrapper.${i}`}>
                      <tr className="td" key={`viewer.brokeredFailedLeadsByType.${i}`}>
                        <td className="td dimension">
                          {e.placementName}
                          <div style={commentStyle}>{e.placementUUID}</div>
                        </td>
                        <td className="td metric">
                          {this.renderErrors(e.placementUUID, null, e.errors, i)}
                        </td>
                        <td className="td metric">
                          <div className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(null, e.placementUUID, true, null)}>{e.failedLeadCount}</div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }

  renderErrors(placementUUID, campaignUUID, errors, parentIndex) {
    if (errors.length == 0) {
      return <div>{"No records found"}</div>
    }
    return (
      <table className="report">
        <tbody>
          {errors.map((errorRecord, j) => {
            return (<tr key={`viewer.brokeredFailedLeadsByType.${parentIndex}.${j}`}>
              <td className="td dimension">
                <div>{errorRecord.errorMessage}</div>
                <div style={commentStyle}>{errorRecord.errorCode}</div>
              </td>
              <td className="td dimension">
                <div className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(campaignUUID, placementUUID, true, errorRecord.errorCode, errorRecord.errorMessage)}>{errorRecord.errorCount}</div>
              </td>
            </tr>)
          })}
        </tbody>
      </table>)
  }

  renderDetailsByCampaign() {
    let section = this.props.brokeredFailedLeads.report.campaignDetails

    if (section.records.length == 0) {
      return <div>{"No records found"}</div>
    }

    section.records.sort((f1, f2) => {
      if (f1.campaignName > f2.campaignName) return 1
      if (f1.campaignName < f2.campaignName) return -1
      return 0
    })

    return (
      <React.Fragment>
        <Collapsible title={section.title} defaultOpen={false}>
          <Grid style={{ "width": "100%" }}>
            <Cell col={12}>
              <table className="report">
                <thead>
                  <tr className="th" key={`viewer.brokeredFailedLeadsByType.header1`}>
                    <th className="th dimension" colSpan={3} style={{ textAlign: "center" }}>{section.title}</th>
                  </tr>
                  <tr className="th" key={`viewer.brokeredFailedLeadsByType.header2`}>
                    <th className="th dimension">Campaign</th>
                    <th className="th dimension">Errors</th>
                    <th className="th dimension">Failed Leads</th>
                  </tr>
                </thead>
                <tbody>
                  {section.records.map((e, i) => {
                    return (
                      <React.Fragment key={`viewer.brokeredFailedLeadsByType.wrapper.${i}`}>
                        <tr className="td" key={`viewer.brokeredFailedLeadsByType.${i}`}>
                          <td className="td dimension">
                            {e.campaignName}
                            <div style={commentStyle}>{e.campaignUUID}</div>
                          </td>
                          <td className="td metric">
                            {this.renderErrors(null, e.campaignUUID, e.errors, i)}
                          </td>
                          <td className="td metric">
                            <div className={"content clickable"} onClick={() => this.handleOpenLeadViewerPopup(e.campaignUUID, null, true, null, null)}>{e.failedLeadCount}</div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </Cell>
          </Grid>
        </Collapsible>
      </React.Fragment>
    )
  }


  handleOpenLeadViewerPopup(campaingUUID, placementUUID, errorsOnly, errorCode, errorMessage) {
    let campaignUUIDs = this.props.brokeredFailedLeads.criteria.campaignUUIDs
    if (campaingUUID) {
      campaignUUIDs.push(campaingUUID)
    }
    campaignUUIDs = [...new Set(campaignUUIDs)]

    let placementUUIDs = this.props.brokeredFailedLeads.criteria.placementUUIDs
    if (placementUUID) {
      placementUUIDs.push(placementUUID)
    }
    placementUUIDs = [...new Set(placementUUIDs)]

    let criteria = {
      rowLimit: 1000,
      timeZone: this.timeZone,
      startDate: this.props.brokeredFailedLeads.criteria.startDate,
      endDate: this.props.brokeredFailedLeads.criteria.endDate,
      campaignUUIDs: campaignUUIDs,
      placementUUIDs: placementUUIDs,
      validationStatusCodes: errorsOnly ? [2] : [],
      validationCodes: errorCode ? [errorCode] : [],
      validationMessages: errorMessage ? [errorMessage] : [],
    }

    this.props.openLeadViewerPopup(criteria)
  }

  handleCloseSessionsModal() {
    this.props.closedLeadViewerPopup()
  }

  handleLeadViewerPageChange(page) {
    let criteria = this.props.singleValidationsReportV2.sessionReport.criteria
    criteria.rowOffset = (page - 1) * this.leadViewerPageSize,
      this.props.openSessionPopupDialog(criteria)
    this.setState({
      leadViewerPage: page,
    })
  }

  renderSessionsModalContent() {
    if (this.props.brokeredFailedLeads.leadViewerReport.isLoading) {
      return (
        <Grid>
          <Cell col={12}>Loading lead viewer ...</Cell>
        </Grid>
      )
    }

    let report = this.props.brokeredFailedLeads.leadViewerReport.report
    let criteria = this.props.brokeredFailedLeads.leadViewerReport.criteria

    let campaignsMap = new Map()
    this.props.campaigns.forEach(e => campaignsMap.set(e.UUID, e))
    let placementsMap = new Map()
    this.props.placements.forEach(e => placementsMap.set(e.UUID, e))

    return (
      <Grid>
        <Cell col={6}>
          <Card>
            <h4>Criteria:</h4>
            <div>Dates: {criteria.startDate} - {criteria.endDate}</div>
            <div>
              Campaigns:
              {criteria.campaignUUIDs.map(e => {
                let campaign = campaignsMap.get(e)
                if (campaign) {
                  return `${campaign.name} - ${campaign.shortCode} (${campaign.UUID})`
                } else {
                  return e
                }
              })}
            </div>
            <div>
              Placements:
              {criteria.placementUUIDs.map(e => {
                let placement = placementsMap.get(e)
                if (placement) {
                  return `${placement.name} - ${placement.legacyID} (${placement.UUID})`
                } else {
                  return e
                }
              })}
            </div>
            <div>
              Error codes:
              {criteria.validationCodes.map(e => {
                return e
              })}
            </div>
            <div>
              Error messages:
              {criteria.validationMessages.map(e => {
                return e
              })}
            </div>
          </Card>
        </Cell>
        <Cell col={6}>
          <h4>Stats:</h4>
          <div>{report.leads.length} leads (display limit: {criteria.rowLimit})</div>
        </Cell>
        <Cell className="reportColumn" col={12}>
          <BrokeredLeadViewerReport report={report}></BrokeredLeadViewerReport>
        </Cell>
      </Grid>
    )
  }
}

const BrokeredFailedLeads = connect(mapStateToProps, mapDispatchToProps)(brokeredFailedLeads)

export default BrokeredFailedLeads