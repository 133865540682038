const { union, derivations } = require('folktale/adt/union')

const GENERATE_PUB_PREVAL_REPORT_BY_DATE = 'GENERATE_PUB_PREVAL_REPORT_BY_DATE'
const GENERATE_PUB_PREVAL_REPORT_BY_DATE_FAILURE = 'GENERATE_PUB_PREVAL_REPORT_BY_DATE_FAILURE'
const GENERATE_PUB_PREVAL_REPORT_BY_DATE_SUCCESS = 'GENERATE_PUB_PREVAL_REPORT_BY_DATE_SUCCESS'

export const generatePubPrevalByDateReport = () =>
    ({ type: GENERATE_PUB_PREVAL_REPORT_BY_DATE })

export const generatePubPrevalByDateReportSuccess = data =>
    ({ type: GENERATE_PUB_PREVAL_REPORT_BY_DATE_SUCCESS, data })

export const generatePubPrevalByDateReportFailure = error =>
    ({ type: GENERATE_PUB_PREVAL_REPORT_BY_DATE_FAILURE, error })

export const PubPrevalByDateReportState = union('PubPrevalByDateReportState', {
    PubPrevalByDateReportInitial() { return {} }
    , LoadingPubPrevalByDateReport() { return {} }
    , PubPrevalByDateReportLoaded(data) { return { data } }
    , PubPrevalByDateReportError(error) { return { error } }
  })
.derive(derivations.debugRepresentation)
export const { 
    PubPrevalByDateReportInitial,
    LoadingPubPrevalByDateReport, 
    PubPrevalByDateReportLoaded, 
    PubPrevalByDateReportError 
} = PubPrevalByDateReportState

export const pubPrevalByDateReport = (state=PubPrevalByDateReportInitial(), action) => {
    switch(action.type) {
        case GENERATE_PUB_PREVAL_REPORT_BY_DATE:
            return LoadingPubPrevalByDateReport()
        case GENERATE_PUB_PREVAL_REPORT_BY_DATE_FAILURE:
            return PubPrevalByDateReportError(action.error)
        case GENERATE_PUB_PREVAL_REPORT_BY_DATE_SUCCESS:
            return PubPrevalByDateReportLoaded(action.data)
        default:
            return state
    }
}
