import {
    startLoadingSessions,
    loadedSessions
} from '../reducers/pubPrevalReportReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

//const url = "http://localhost:9001/pub-prevalidation-sessions-mock/"
//const url = "http://localhost:9001/pub-prevalidation-sessions/"
const url = getUrlFor("reporting", "pub-prevalidation-sessions")
//const url = getUrlFor("reporting", "pub-prevalidation-sessions-mock")

export function loadSessionsThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(startLoadingSessions())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(loadedSessions(criteria, res.data))
            }).catch(e => {
                exceptionHandling(e, dispatch, "Getting pub prevalidation sessions")
            })
    }
}
