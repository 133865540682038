import React from 'react'
import { connect } from 'react-redux'
import { deleteMasterTag, retrieveTagMasterList, saveTagToMasterList, uploadSuppressionList } from '../../services'
import DeliverableViewer from '../deliverable-viewer'
import { Tab, Tabs } from 'react-mdl'
import PubPrevalReport from '../pub-preval-report'
import SingleValidationReport from '../single-validation-report'
import PubReactivationReport from '../pub-reactivation-report'
import OptinReviewer from '../optin-reviewer'
import BuySheet from '../buy-sheet'
import CampaignQuickStatus from '../campaign-quick-status'
import InternalBudget from '../internal-budget'
import BrokeredLeadViewer from '../brokered-lead-viewer'
import BrokeredFailedLeads from '../brokered-failed-leads'
import AdqPerformance from '../adq-performance'
import ActivityLog from '../activity-log'
import SessionLookup from '../session-lookup'
import TruIntent from '../tru-intent'

function mapStateToProps(state) {
  return {
    campaigns: state.campaignData.records,
    tagMasterList: state.tagMasterList,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    uploadSuppression(emails, campaignUUID) {
      dispatch(uploadSuppressionList(emails, campaignUUID))
    },
    loadMasterList() {
      dispatch(retrieveTagMasterList())
    },
    saveTagToMasterList(value) {
      dispatch(saveTagToMasterList(value))
    },
    deleteTagFromMasterList(value) {
      dispatch(deleteMasterTag(value))
    }
  }
}

class reporting extends React.Component {
  constructor(props) {
    super(props)
    let urlParams = new URLSearchParams(window.location.search)
    let report = urlParams.get('report')
    this.state = {
      campaign: {},
      emails: [],
      fileName: "",
      activeTab: 0,
      baseNewManagerURL: process.env.NODE_ENV == "production" ? "https://new.manager.adquire.com" : "https://dev.new.manager.adquire.com",
    }
    this.state[report] = urlParams.get("slug")
    switch (report) {
      case "adq-performance":
        this.state.activeTab = 1
        break
      case "tru-intent":
        this.state.activeTab = 2
        break
      case "brokered-lead-viewer":
        this.state.activeTab = 3
        break
      case "brokered-failed-leads":
        this.state.activeTab = 4
        break
      case "buy-sheet":
        this.state.activeTab = 5
        break
      case "quick-status":
        this.state.activeTab = 6
        break
      case "internal-budget":
        this.state.activeTab = 7
        break
      case "validations":
        this.state.activeTab = 9
        break
      case "pub-preval":
        this.state.activeTab = 10
        this.state["pubpreval-by-date"] = urlParams.get("slug")
        this.state["pubpreval-by-message"] = urlParams.get("slug2")
        break
    }
    this.props.loadMasterList()
  }

  suppressionFileChange(event) {
    let file = event.target.files[0]
    var reader = new FileReader()
    let onread = () => {
      let rawText = reader.result
      var emails = []
      var rows = rawText.split(/\n/)
      for (var rowIndex = 0; rowIndex < rows.length; ++rowIndex) {
        var rowArray = rows[rowIndex].split(',')
        for (var i = 0; i < rowArray.length; ++i) {
          let value = (rowArray[i] || '').trim()
          if (value.length > 0) {
            emails.push(value)
          }
        }
      }
      this.setState({
        fileName: file.name,
        emails: emails
      })
    }
    reader.onload = onread
    reader.readAsBinaryString(file)
  }

  clearSuppression() { }
  render() {
    return (
      <div
        className="reportTabs">
        <Tabs
          activeTab={this.state.activeTab}
          onChange={tabId => {
            history.pushState({}, null, window.location.origin + window.location.pathname)
            this.setState({ activeTab: tabId })
            // 
            switch (tabId) {
              case 1:
                window.open(`${this.state.baseNewManagerURL}/reports/adq-performance`)
                break
              case 3:
                window.open(`${this.state.baseNewManagerURL}/reports/brokered_lead_viewer`)
                break
              case 4:
                window.open(`${this.state.baseNewManagerURL}/reports/ampe_failed_leads`)
                break
              case 9:
                window.open(`${this.state.baseNewManagerURL}/reports/validation`)
                break
              case 10:
                window.open(`${this.state.baseNewManagerURL}/reports/pub_prevalidations`)
                break
            }
          }}
          ripple
          style={{ width: '100%' }}
        >
          <Tab>ACTIVITY-LOG</Tab>
          <Tab>ADQ-PERFORMANCE</Tab>
          <Tab>TRU-INTENT</Tab>
          <Tab>LEAD-VIEWER</Tab>
          <Tab>FAILED-LEADS</Tab>
          <Tab>BUY-SHEET</Tab>
          <Tab>CAMPAIGN-QUICK-STATUS</Tab>
          <Tab>INTERNAL-BUDGET</Tab>
          <Tab>QUARANTINE</Tab>
          <Tab>VALIDATIONS</Tab>
          <Tab>PUB-PREPING</Tab>
          <Tab>DELIVERABLE-VIEWER</Tab>
          <Tab>PUB-REACTIVATION</Tab>
          <Tab>SESSION-VIEWER</Tab>
        </Tabs>
        <section>
          <div className="content">
            {this.state.activeTab == 0 ? <ActivityLog /> : null}
            {this.state.activeTab == 1 ? <AdqPerformance slug={this.state["adq-performance"]} /> : null}
            {this.state.activeTab == 2 ? <TruIntent slug={this.state["tru-intent"]} /> : null}
            {this.state.activeTab == 3 ? <BrokeredLeadViewer slug={this.state["brokered-lead-viewer"]} /> : null}
            {this.state.activeTab == 4 ? <BrokeredFailedLeads slug={this.state["brokered-failed-leads"]} /> : null}
            {this.state.activeTab == 5 ? <BuySheet slug={this.state["buy-sheet"]} /> : null}
            {this.state.activeTab == 6 ? <CampaignQuickStatus slug={this.state["quick-status"]} /> : null}
            {this.state.activeTab == 7 ? <InternalBudget slug={this.state["internal-budget"]} /> : null}
            {this.state.activeTab == 8 ? <OptinReviewer /> : null}
            {this.state.activeTab == 9 ? <SingleValidationReport slug={this.state["validations"]} /> : null}
            {this.state.activeTab == 10 ? <PubPrevalReport byDateSlug={this.state["pubpreval-by-date"]} byMessageSlug={this.state["pubpreval-by-message"]} /> : null}
            {this.state.activeTab == 11 ? <DeliverableViewer /> : null}
            {this.state.activeTab == 12 ? <PubReactivationReport /> : null}
            {this.state.activeTab == 13 ? <SessionLookup /> : null}

          </div>
        </section>
      </div>
    )
  }
}

const Reporting = connect(mapStateToProps, mapDispatchToProps)(reporting)
export default Reporting