import {applyMiddleware, compose, createStore} from 'redux'
import {
  routerMiddleware
} from 'react-router-redux'

import reducer from './reducers'
import thunkMiddleware from 'redux-thunk'
import history from './history'

const initialState = {}

const createStoreWithMiddleware = compose(
  applyMiddleware(
    routerMiddleware(history),
    thunkMiddleware,
  ),
)(createStore)
const store = createStoreWithMiddleware(reducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export default store
