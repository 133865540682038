const SAVE_STATE_OF_PUB_PREVAL_SESSIONS = 'SAVE_STATE_OF_PUB_PREVAL_SESSIONS'

const PUB_PREVAL_SESSIONS_START_LOADING = 'PUB_PREVAL_SESSIONS_START_LOADING'
const PUB_PREVAL_SESSIONS_LOADED = 'PUB_PREVAL_SESSIONS_LOADED'
const PUB_PREVAL_SESSIONS_DIALOG_CLOSED = 'PUB_PREVAL_SESSIONS_DIALOG_CLOSED'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_PUB_PREVAL_SESSIONS, data })

export const startLoadingSessions = () =>
    ({ type: PUB_PREVAL_SESSIONS_START_LOADING })

export const loadedSessions = (criteria, sessions) =>
    ({ type: PUB_PREVAL_SESSIONS_LOADED, data: {criteria:criteria, sessions:sessions} })

export const closedSessionPopup = () =>
    ({ type: PUB_PREVAL_SESSIONS_DIALOG_CLOSED})


const initialState = { 
    sessionReport:{
      sessions: null, 
      isLoadingSessions: false,
      showSessionPopup: false,
      criteria: null,
      componentState: null,
    }
   }
export function pubPrevalReport(state = initialState, action) {
    switch(action.type) {
      case SAVE_STATE_OF_PUB_PREVAL_SESSIONS:
            return {
                ...state,
                componentState: action.data,
            }    
      case PUB_PREVAL_SESSIONS_START_LOADING:
        return {
          ...state,
          sessionReport: {
            ...state.sessionReport,
            isLoadingSessions: true,
            showSessionPopup: true,
            criteria: null,
            sessions: null,
          }
        }
        case PUB_PREVAL_SESSIONS_LOADED:
          return {
            ...state,
            sessionReport: {
              ...state.sessionReport,
              isLoadingSessions: false,
              criteria: action.data.criteria,
              sessions: action.data.sessions,
            }
        }
        case PUB_PREVAL_SESSIONS_DIALOG_CLOSED:
          return {
            ...state,
            sessionReport: {
              ...state.sessionReport,
              showSessionPopup: false,
              criteria: null,
              sessions: null,
            }
          }
        default:
        return state
    }
  }

  