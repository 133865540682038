import {
  startLoadingOptinReviewer,
  loadedOptinReviewer,
  reviewablesUpdatedOptinReviewer,
  startSavingOptinReviewer,
  savedOptinReviewer,
  saveDialogClosedOptinReviewer,
} from '../reducers/optinReviewerReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'
import moment from 'moment'

const optinReviewerReviewablesUrl = getUrlFor("optin-reviewer", "reviewables/report")
//const optinReviewerReviewablesUrl = "http://localhost:9002/reviewables/report/"
const optinReviewerStatusUpdateUrl = getUrlFor("optin-reviewer", "reviewables/bulkReviewStatusUpdate")
//const optinReviewerStatusUpdateUrl = "http://localhost:9002/reviewables/bulkReviewStatusUpdate/"

export function loadOptinReviewerReviewables(criteria) {
  return function (dispatch, getState) {
    dispatch(startLoadingOptinReviewer)
    return save(
      getOpts(
        dispatch,
        criteria,
        "POST",
        optinReviewerReviewablesUrl,
        null,
        null,
        getState(),
        null,
        DISABLE_SNACK,
        AVOID_DISABLE_IS_DIRTY
      )
    )
      .then(res => {
        res.data.reviewables.records = res.data.reviewables.records.map(obj => ({ ...obj, updatedReviewStatus: obj.reviewStatus }))
        dispatch(loadedOptinReviewer(res.data))
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)

        exceptionHandling(e, dispatch, "Loading reviewables")
      })
  }
}

export function updateBulkOptinReviewerStatusCodes(reportCriteria, updateCriteria, status, user) {
  return function (dispatch, getState) {
    updateCriteria.reviewCodes = [1]
    
    return save(
      getOpts(
        dispatch,
        updateCriteria,
        "POST",
        optinReviewerReviewablesUrl,
        null,
        null,
        getState(),
        null,
        DISABLE_SNACK,
        AVOID_DISABLE_IS_DIRTY
      )
    )
      .then(res => {
        let requestData = res.data.reviewables

        requestData.records.forEach(e => {
          if (e.reviewStatus == "1") { e.updatedReviewStatus = status }
        })

        let requests = requestData.records
          .map(e => {
            return {
              id: e.id,
              reviewStatus: status,
              note: e.note,
              reviewedBy: user,
              reviewedAt: moment().unix()
            }
          })

      dispatch(startSavingOptinReviewer())
        return save(
          getOpts(
            dispatch,
            { records: requests },
            "PUT",
            optinReviewerStatusUpdateUrl,
            null,
            null,
            getState(),
            null,
            DISABLE_SNACK,
            AVOID_DISABLE_IS_DIRTY
          )
        )
          .then(res => {
            dispatch(savedOptinReviewer(res.data))
            dispatch(loadOptinReviewerReviewables(reportCriteria))
          })
          .catch(e => {
            exceptionHandling(e, dispatch, "Saving reviewables")
          })
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)

        exceptionHandling(e, dispatch, "Saving reviewables (loading batch)")
      })

  }
}

export function updateOptinReviewerStatusCodes(requestData) {
  return function (dispatch, getState) {
    dispatch(startSavingOptinReviewer())
    return save(
      getOpts(
        dispatch,
        requestData,
        "PUT",
        optinReviewerStatusUpdateUrl,
        null,
        null,
        getState(),
        null,
        DISABLE_SNACK,
        AVOID_DISABLE_IS_DIRTY
      )
    )
      .then(res => {
        dispatch(savedOptinReviewer(res.data))
      })
      .catch(e => {
        exceptionHandling(e, dispatch, "Saving reviewables")
      })
  }
}

export function closeOptinReviewerSaveDialog() {
  return function (dispatch) {
    dispatch(saveDialogClosedOptinReviewer())
  }
}

export function updateOptinReviewerReviewableList(report) {
  return function (dispatch) {
    dispatch(reviewablesUpdatedOptinReviewer(report))
  }
}
