import React from 'react'
import Draggable from '../draggable'
import {Grid, Cell, Icon, Button, List, Tooltip} from 'react-mdl'


export default class Answers extends React.Component {

  safeValueLookup(arr) {
    arr = arr || []
    if (arr.length <= 0) {
      return { value: '' }
    }
    let obj = this.props.questions.find(q => q.UUID == arr[0]) || { value: '' }
    return obj
  }

  render() {
    return <React.Fragment>
      <Grid className="headerGrid" style={{ width: '90%', marginBottom: "20px" }}>
        <Cell col={2}>Display</Cell>
        <Cell col={2}>Value</Cell>
        <Cell col={1}>Disqualifying</Cell>
        <Cell col={1}>Preselected</Cell>
        <Cell col={1}>Hidden</Cell>
        <Cell col={3}>Sub Question</Cell>
      </Grid>
      <List>
        <Draggable
          items={this.props.answers
            .map((a, index) => {
              a.element = (
                <Grid style={{ width: '90%' }}>
                  <Cell col={2}>{a.display}</Cell>
                  <Cell col={2}>{a.value}</Cell>
                  <Cell col={1}>
                    <Icon name={a.isDisqualifying ? 'check_circle' : ''} />
                  </Cell>
                  <Cell col={1}>
                    <Icon
                      name={!a.isPreselected ? '' : 'check_circle'}
                    />
                  </Cell>
                  <Cell col={1}>
                    <Icon
                      name={!a.isHidden ? '' : 'check_circle'}
                    />
                  </Cell>
                  <Cell col={3}>{this.safeValueLookup(a.correctPathQuestions).value}</Cell>
                  <Cell col={2} style={{display:"flex", justifyContent: "space-between" }}>
                    <Button
                      onClick={() => {
                        this.props.selected(index)
                      }}
                    >
                      <Icon name="more_horiz" />
                    </Button>
                    <Tooltip label="Remove">
                      <Button
                        accent
                        onClick={() =>
                          this.props.onDelete(index)}>
                        <Icon name="remove" />
                      </Button>
                    </Tooltip>
                  </Cell>
                </Grid>
              )
              return a
            })}
          onEnd={items => {
            this.props.reWeighted(items)
          }}
        />
      </List>
    </React.Fragment>
  }
}
