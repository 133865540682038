const SAVE_STATE_OF_OPTIN_REVIEWER = 'SAVE_STATE_OF_OPTIN_REVIEWER'
const OPTIN_REVIEWER_START_LOADING = 'OPTIN_REVIEWER_START_LOADING'
const OPTIN_REVIEWER_LOADED = 'OPTIN_REVIEWER_LOADED'
const OPTIN_REVIEWER_REVIEWABLES_UPDATED = 'OPTIN_REVIEWER_REVIEWABLES_UPDATED'
const OPTIN_REVIEWER_START_SAVING = 'OPTIN_REVIEWER_START_SAVING'
const OPTIN_REVIEWER_SAVED = 'OPTIN_REVIEWER_SAVED'
const OPTIN_REVIEWER_CLOSED_SAVE_DIALOG = 'OPTIN_REVIEWER_CLOSED_SAVE_DIALOG'



export const saveState = data =>
({ type: SAVE_STATE_OF_OPTIN_REVIEWER, data })

export const startLoadingOptinReviewer = () =>
({ type: OPTIN_REVIEWER_START_LOADING})

export const loadedOptinReviewer = data =>
({ type: OPTIN_REVIEWER_LOADED, data })

export const reviewablesUpdatedOptinReviewer = data =>
({ type: OPTIN_REVIEWER_REVIEWABLES_UPDATED, data })

export const startSavingOptinReviewer = () =>
({ type: OPTIN_REVIEWER_START_SAVING })

export const savedOptinReviewer = data =>
({ type: OPTIN_REVIEWER_SAVED, data })

export const saveDialogClosedOptinReviewer = () =>
({ type: OPTIN_REVIEWER_CLOSED_SAVE_DIALOG})

export const optinReviewerState = (state={
        report: null,
        isLoading: false,
        saveResults: null,
        isSaving: false,
        showSaveDialog: false,
        componentState: null
      }, action) => {
        switch (action.type) {
          case SAVE_STATE_OF_OPTIN_REVIEWER:
              return {
                  ...state,
                  componentState: action.data,
              }    
          case OPTIN_REVIEWER_START_LOADING:
            return {
              ...state,
              isLoading: true,
              report: null
            }
          case OPTIN_REVIEWER_LOADED:
            return {
              ...state,
              isLoading: false,
              report: action.data
            }
          case OPTIN_REVIEWER_REVIEWABLES_UPDATED:
            return {
              ...state,
              report: action.data
            }
          case OPTIN_REVIEWER_START_SAVING:
            return {
              ...state,
              isSaving: true,
              showSaveDialog: true,
              saveResults: null
            }
          case OPTIN_REVIEWER_SAVED:
            return {
              ...state,
              isSaving: false,
              saveResults: action.data
            }
          case OPTIN_REVIEWER_CLOSED_SAVE_DIALOG:
            return {
              ...state,
              showSaveDialog: false,
              saveResults: null
            }
          default:
            return state
        }
      }