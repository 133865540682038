const { union, derivations } = require('folktale/adt/union')

const GENERATE_PUB_REACTIVATION_REPORT_BY_DATE = 'GENERATE_PUB_REACTIVATION_REPORT_BY_DATE'
const GENERATE_PUB_REACTIVATION_REPORT_BY_DATE_FAILURE = 'GENERATE_PUB_REACTIVATION_REPORT_BY_DATE_FAILURE'
const GENERATE_PUB_REACTIVATION_REPORT_BY_DATE_SUCCESS = 'GENERATE_PUB_REACTIVATION_REPORT_BY_DATE_SUCCESS'

export const generatePubReactivationByDateReport = () =>
    ({ type: GENERATE_PUB_REACTIVATION_REPORT_BY_DATE })

export const generatePubReactivationByDateReportSuccess = data =>
    ({ type: GENERATE_PUB_REACTIVATION_REPORT_BY_DATE_SUCCESS, data })

export const generatePubReactivationByDateReportFailure = error =>
    ({ type: GENERATE_PUB_REACTIVATION_REPORT_BY_DATE_FAILURE, error })

export const PubReactivationByDateReportState = union('PubReactivationByDateReportState', {
    PubReactivationByDateReportInitial() { return {} }
    , LoadingPubReactivationByDateReport() { return {} }
    , PubReactivationByDateReportLoaded(data) { return { data } }
    , PubReactivationByDateReportError(error) { return { error } }
  })
.derive(derivations.debugRepresentation)
export const { 
    PubReactivationByDateReportInitial,
    LoadingPubReactivationByDateReport, 
    PubReactivationByDateReportLoaded, 
    PubReactivationByDateReportError 
} = PubReactivationByDateReportState

export const pubReactivationByDateReport = (state=PubReactivationByDateReportInitial(), action) => {
    switch(action.type) {
        case GENERATE_PUB_REACTIVATION_REPORT_BY_DATE:
            return LoadingPubReactivationByDateReport()
        case GENERATE_PUB_REACTIVATION_REPORT_BY_DATE_FAILURE:
            return PubReactivationByDateReportError(action.error)
        case GENERATE_PUB_REACTIVATION_REPORT_BY_DATE_SUCCESS:
            return PubReactivationByDateReportLoaded(action.data)
        default:
            return state
    }
}
