import {
  generateBrokeredLeadViewerReportThunk,
  fetchBrokeredLeadViewerCsvReportThunk
} from "../../services/brokeredLeadViewerReport"

import {
  generateBrokeredLeadViewerCsvReport,
  generateBrokeredLeadViewerCsvReportSuccess,
  generateBrokeredLeadViewerCsvReportFailure,
} from "../../reducers/brokeredLeadViewerCsvReportReducer"


import {
  saveState,
} from "../../reducers/brokeredLeadViewerReportReducer"

import fileDownload from "js-file-download"
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Select from 'react-select'
import { Grid, Cell, Button, Icon } from 'react-mdl'
import { filterForSelectable, reportReady } from '../common/func'
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import BrokeredLeadViewerReport from './report.js'
import { Pie } from "react-chartjs-2"
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
  return {
    brokeredLeadViewer: state.brokeredLeadViewer,
    brokeredLeadViewerCsv: state.brokeredLeadViewerCsv,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user,
  }
}


const chartColors = [
  "#00cc00",
  "#ff0000"
]


function mapDispatchToProps(dispatch) {
  return {
    loadBrokeredLeadViewer: function (criteria, slug) {
      dispatch(generateBrokeredLeadViewerReportThunk(criteria, slug))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
    startDownloadingCsvReport: function () {
      dispatch(generateBrokeredLeadViewerCsvReport())
    },
    downloadedCsvReport: function () {
      dispatch(generateBrokeredLeadViewerCsvReportSuccess())
    },
    failedToDownloadCsvReport: function (err) {
      dispatch(generateBrokeredLeadViewerCsvReportFailure(err))
    },
  }
}

class brokeredLeadViewer extends React.Component {
  constructor(props) {
    super(props)

    this.timeZone = "America/New_York"

    this.limits = [
      { value: 100, label: "100" },
      { value: 500, label: "500" },
      { value: 1000, label: "1000" },
    ]

    this.statusFilters = [
      { value: 1, label: "ACCEPTED" },
      { value: 2, label: "REJECTED" },
    ]

    this.state = {
      startDate: moment().startOf("day"),
      endDate: moment(),
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      newValues: '',
      newValuesReplace: false,
      page: 1,
      associations: [],
      allRowsExpanded: false,
      selectedLimit: this.limits[0],
      isLoading: false,
      selectedStatusFilters: [],
      isCriteriaCollapsed: false,
    }

    if (props.brokeredLeadViewer.componentState) {
      this.state = props.brokeredLeadViewer.componentState
    }

    if (props.slug && props.slug.length > 0) {
      let criteria = this.getCriteria()
      this.props.loadBrokeredLeadViewer(criteria, props.slug)
    }
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }


  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  componentDidUpdate(prevProps) {
    let update = reportReady(
      prevProps.brokeredLeadViewer.report, this.props.brokeredLeadViewer.report,
      prevProps.campaigns, prevProps.placements,
      this.props.campaigns, this.props.placements)

    if (update) {
      let associations = []
      if (this.props.brokeredLeadViewer.report && this.props.brokeredLeadViewer.report.leads) {
        associations = this.props.brokeredLeadViewer.report.leads.map((e) => {
          e.rowExpanded = false
          return e
        })
      }
      let state = this.state
      state.associations = associations
      if (this.props.brokeredLeadViewer.request) {
        let criteria = this.getCriteria(this.props.brokeredLeadViewer.request)
        state.selectedStatusFilters = criteria.validationStatusCodes.map(s => { return this.statusFilters[s - 1] })
        state.selectedLimit = { value: criteria.rowLimit, label: criteria.rowLimit.toString() }
        state.selectedCampaigns = filterForSelectable(this.props.campaigns, criteria.campaignUUIDs)
        state.selectedPlacements = filterForSelectable(this.props.placements, criteria.placementUUIDs)
        state = { ...state, ...criteria }
        state.startDate = moment(state.startDate)
        state.endDate = moment(state.endDate)
      }
      this.setState(state)
    }
  }


  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      filterUpdated: true,
      startDate: startDate,
      endDate: endDate
    })
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleRunButtonClick() {
    let page = 1
    let criteria = this.getCriteria()
    this.props.loadBrokeredLeadViewer(criteria, this.state.filterUpdated ? "" : this.props.brokeredLeadViewer.slug)
    this.setState({
      page: page,
      filterUpdated: false,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  handleDownloadButtonClick() {
    this.props.startDownloadingCsvReport()
    let criteria = this.getCriteria()
    fetchBrokeredLeadViewerCsvReportThunk(criteria)
      .then(blob => {
        this.props.downloadedCsvReport()
        fileDownload(new Blob([blob]), "report.zip")
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)
        this.props.failedToDownloadCsvReport(new Error(`Getting CSV report - ${e.statusText}`))
      })
  }

  getCriteria(request) {
    request = request || {}
    return {
      timeZone: request.timeZone || this.timeZone,
      startDate: request.startDate || this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: request.endDate || this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: request.campaignUUIDs || this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: request.placementUUIDs || this.state.selectedPlacements.map(e => e.value),
      rowLimit: request.rowLimit || this.state.selectedLimit.value,
      validationStatusCodes: request.validationStatusCodes || this.state.selectedStatusFilters.map(e => e.value),
    }
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true,
    })
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      filterUpdated: true,
      selectedPlacements: placements,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."
    let total = 0
    let accepted = 0
    let rejected = 0
    if (this.props.brokeredLeadViewer.report) {
      let leads = this.props.brokeredLeadViewer.report.leads
      if (leads && leads.length > 0) {
        total = leads[0].totalLeads
        accepted = leads[0].acceptedLeads
        rejected = leads[0].rejectedLeads
      }
    }
    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
          <Grid>
            <Cell col={12}>
              <Collapsible title="Brokered Lead Viewer" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={11}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={4}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={2}>
                        <label className="floatLabel">Limit</label>
                        <Select
                          isMulti={false}
                          value={this.state.selectedLimit}
                          onChange={(s) => {
                            this.setState({
                              filterUpdated: true,
                              selectedLimit: s
                            })
                          }}
                          options={this.limits}
                        />
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Validation Status</label>
                        <Select
                          id="status-select"
                          placeholder={"Status"}
                          isMulti={true}
                          value={this.state.selectedStatusFilters}
                          onChange={(s) => {
                            this.setState({
                              filterUpdated: true,
                              selectedStatusFilters: s
                            })
                          }}
                          options={this.statusFilters}
                        />
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              filterUpdated: true,
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => this.handleDownloadButtonClick()}
                              disabled={this.props.brokeredLeadViewerCsv.isLoading}
                              raised
                              colored
                              ripple>
                              <Icon name="cloud_download" />
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>{this.renderCsvReport()}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  <div className="report">
                    <Grid>
                      <Cell col={6}>
                        {this.props.brokeredLeadViewer.report ? <div><p style={{ lineHeight: "14px" }}>Total Leads: {total}</p>
                          <p style={{ lineHeight: "14px" }}>Accepted Leads: {accepted}</p>
                          <p style={{ lineHeight: "14px" }}>Rejected Leads: {rejected}</p></div> : null}
                      </Cell>
                      <Cell col={6}>
                        {this.props.brokeredLeadViewer.report ? <div className="pie-chart-container" style={{
                          width: "25%",
                          height: "25%",
                        }} >
                          <Pie
                            data={{
                              maintainAspectRatio: false,
                              responsive: false,
                              labels: ["Accepted", "Rejected"],
                              datasets: [
                                {
                                  data: [accepted, rejected],
                                  backgroundColor: chartColors,
                                  hoverBackgroundColor: chartColors
                                }
                              ]
                            }}
                            options={{
                              legend: {
                                display: false,
                                position: "right"
                              },
                              elements: {
                                arc: {
                                  borderWidth: 0
                                }
                              }
                            }}
                          />
                        </div> : null}
                      </Cell>
                      {
                        this.props.brokeredLeadViewer.slug ? <Cell col={12}>
                          <a target="_blank" rel="noreferrer"
                            href={window.location.origin + window.location.pathname + `?format=json&report=brokered-lead-viewer&slug=${this.props.brokeredLeadViewer.slug}`}>
                            Share Report</a>
                        </Cell> : null
                      }
                    </Grid>
                    <hr />
                    <div>{this.renderReport()}</div>
                  </div>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div >
      </section >
    )
  }


  renderCsvReport() {
    if (this.props.brokeredLeadViewerCsv.isLoading) {
      return <div>{"Downloading ..."}</div>
    }
    if (this.props.brokeredLeadViewerCsv.error) {
      return <div style={{ color: "#FF0000" }}>&quot;Failed: can not load&quot;</div>
    }
  }

  handleRowToggleClick(i) {
    const associations = this.state.associations
    associations[i].rowExpanded = !associations[i].rowExpanded
    this.setState({
      associations: associations,
    })
  }

  handleAllRowsToggleClick() {
    let flag = !this.state.allRowsExpanded
    const associations = this.state.associations.map((e) => {
      e.rowExpanded = flag
      return e
    })
    this.setState({
      associations: associations,
      allRowsExpanded: flag,
    })
  }


  renderReport() {
    if (this.props.brokeredLeadViewer.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.brokeredLeadViewer.error != null) {
      return <div>Error: {this.props.brokeredLeadViewer.error.message}</div>
    }
    if (this.props.brokeredLeadViewer.report == null) {
      return <div></div>
    }
    if (this.props.brokeredLeadViewer.report.leads.length == 0) {
      return <div>{"No records found"}</div>
    }

    let campaignsMap = new Map()
    this.props.campaigns.forEach(e => campaignsMap.set(e.UUID, e))

    let placementsMap = new Map()
    this.props.placements.forEach(e => placementsMap.set(e.UUID, e))


    return <React.Fragment>
      <BrokeredLeadViewerReport report={this.props.brokeredLeadViewer.report}></BrokeredLeadViewerReport>
    </React.Fragment>
  }
}


const BrokeredLeadViewer = connect(mapStateToProps, mapDispatchToProps)(brokeredLeadViewer)

export default BrokeredLeadViewer
