import { generateBuySheetReportThunk } from "../../services/buySheetReport"
import { saveState } from "../../reducers/buySheetReportReducer"
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Grid, Cell, Button, Switch, Card, CardActions, Icon } from 'react-mdl'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import Select from 'react-select'
import Modal from "react-modal"
import PlacementEditor from "../placement/placementEditor.js"
import { 
  setCrud, 
  setPlacementManagerValidationErrors,
  resetAssociationEditor,
  retrieveAssociation,
  selectAssociationPlacement,
  unselectAssociationPlacement,
  selectAssociationCampaign,
  unselectAssociationCampaign,
 } from "../../services"
import DatePicker from "react-datepicker"
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
  return {
    buySheet: state.buySheet,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user,
    salespeople: state.salespeople,
    isDirtyValue: state.isDirty,
    crud: state.crud,
    associationManager: state.associationManager,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadBuySheet: function (criteria, slug) {
      dispatch(generateBuySheetReportThunk(criteria, slug))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
    setCrud: function (crud) {
      dispatch(setCrud(crud))
    },
    selectAssociationPlacement: function (uuid) {
      dispatch(selectAssociationPlacement(uuid))
    },
    selectAssociationCampaign: function (uuid) {
      dispatch(selectAssociationCampaign(uuid))
    },
    unselectAssociationCampaign: function () {
      dispatch(unselectAssociationCampaign())
    },
    unselectAssociationPlacement: function () {
      dispatch(unselectAssociationPlacement())
    },
    retrieveAssociation: function (placementUUID, campaignUUID) {
      dispatch(retrieveAssociation(placementUUID, campaignUUID))
    },
    resetAssociationEditor: function () {
      dispatch(resetAssociationEditor())
    },
    setPlacementManagerValidationErrors: function (errors) {
      dispatch(setPlacementManagerValidationErrors(errors))
    },
  }
}

const modalStyles = {
  height: "90%"
}

const commentStyle = {
  color: "#666",
  paddingBotton: "5px",
  fontSize: "80%",
}

const activeStyle = {
  color: "green",
}
const inactiveStyle = {
  color: "red",
}

class buySheet extends React.Component {
  constructor(props) {
    super(props)

    this.limits = [
      { value: 50, label: "50" },
      { value: 100, label: "100" },
      { value: 500, label: "500" },
      { value: 1000, label: "1000" },
    ]

    this.state = {
      selectedSalespeople: [],
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      newValues: '',
      newValuesReplace: false,
      page: 1,
      isLoading: false,
      selectedLimit: this.limits[0],

      startDate: moment().startOf("day"),
      endDate: moment(),
      associationIsActive: true,

      workflowHasScreenShot: false,
      workflowHasTestLead: false,
      workflowIsLive: false,

      isAssociationPopupOpen: false,
      selectedAssociationPlacement: null,
      selectedAssociationCampaign: null,

      associationEditor: null,
      isCriteriaCollapsed: true,
    }

    if (props.buySheet.componentState) {
      this.state = props.buySheet.componentState
    }

    if (props.slug && props.slug.length > 0) {
      let criteria = this.getCriteria()
      this.props.loadBuySheet(criteria, props.slug)
    }
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
    this.togglePlacementList = this.togglePlacementList.bind(this)

  }

  componentDidUpdate(prevProps) {
    if (prevProps.associationManager.selectedPlacement !== this.props.associationManager.selectedPlacement || prevProps.associationManager.selectedCampaign !== this.props.associationManager.selectedCampaign) {
      if (this.props.associationManager.selectedPlacement && this.props.associationManager.selectedCampaign) {
        this.retrieveAssociation(this.props.associationManager.selectedPlacement.value, this.props.associationManager.selectedCampaign.value)
      }
    }
    if (prevProps.associationManager.associationSaved !== this.props.associationManager.associationSaved && this.props.associationManager.associationSaved){
      this.setState({
        filterUpdated: true,
      })
    }
    if (prevProps.associationManager.association !== this.props.associationManager.association) {
      if (this.props.associationManager.association) {
        this.showAssociationEditor(this.props.associationManager.association)
      } else {
        this.setState({
          associationEditor: null,
        })
      }
    }
  }

  showAssociationEditor(association) {
    let whitelist = this.props.associationManager.association.placement.whitelistCampaignAssociations.find((e) => e.campaignUuid === association.campaign.UUID)
    if (!whitelist) {
      this.setState({
        associationEditor: {
          isNew: true,
          isDirty: true,
          placementUUID: association.placement.UUID,
          campaignUUID: association.campaign.UUID,
          endDate: association.campaign.endDate,
          inactive: true,
          screenShot: false,
          testLead: false,
          live: false,
        },
      })
      return
    }

    this.setState({
      associationEditor: {
        isNew: false,
        isDirty: false,
        placementUUID: association.placement.UUID,
        campaignUUID: association.campaign.UUID,
        screenShot: whitelist.screenShot,
        testLead: whitelist.testLead,
        live: whitelist.live,
        endDate: whitelist.endDate,
        inactive: whitelist.inactive,
      },
    })
  }

  togglePlacementList() {
    //non-ops
  }

  toggleCampaignList() {
    //non-ops
  }

  componentDidMount() {
    Modal.setAppElement('body')
    this.handleRunButtonClick()
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      filterUpdated: true,
      startDate: startDate,
      endDate: endDate
    })
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleRunButtonClick() {
    let page = 1
    let criteria = this.getCriteria(page)
    this.props.loadBuySheet(criteria, this.state.filterUpdated ? null : this.props.buySheet.slug)
    this.setState({
      filterUpdated: false,
      page: page,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  getCriteria(page, request) {
    request = request || {}
    return {
      timeZone: request.timeZone || this.timeZone,
      startDate: request.startDate || this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: request.endDate || this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: request.campaignUUIDs || this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: request.placementUUIDs || this.state.selectedPlacements.map(e => e.value),
      rowLimit: request.rowLimit || this.state.selectedLimit.value,
      rowOffset: request.rowOffset || (page - 1) * this.state.selectedLimit.value,

      salespersonUUIDs: request.salespersonUUIDs || this.state.selectedSalespeople.map(e => e.value),
      workflowHasTestLead: request.workflowHasTestLead || this.state.workflowHasTestLead,
      workflowHasScreenShot: request.workflowHasScreenShot || this.state.workflowHasScreenShot,
      workflowIsLive: request.workflowIsLive || this.state.workflowIsLive,

      associationIsActive: request.associationIsActive || this.state.associationIsActive,
    }
  }

  handlePageChange(page) {
    let criteria = this.getCriteria(page)
    this.props.loadBuySheet(criteria)
    this.setState({
      page: page,
    })
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true,
    })
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      filterUpdated: true,
      selectedPlacements: placements,
    })
  }

  setPlacementCrudHandler(crudOptions) {
    this.props.setCrud(crudOptions)
  }

  setCampaignCrudHandler(crudOptions) {
    this.props.setCrud(crudOptions)
  }

  setAssociationCrudHandler(crudOptions) {
    this.props.setCrud(crudOptions)
  }

  placementClickHandler(placementUUID, campaignUUID) {
    this.props.selectAssociationPlacement({value:placementUUID})
    this.props.selectAssociationCampaign({value:campaignUUID})
    this.setState({
      isAssociationPopupOpen: true,
    })
  }

  associationPlacementSelectorOnChangeHandler(selection) {
    this.props.selectAssociationPlacement(selection)
  }

  associationCampaignSelectorOnChangeHandler(selected) {
    this.props.selectAssociationCampaign(selected)
  }

  retrieveAssociation(placementUUID, campaignUUID) {
    this.props.retrieveAssociation(placementUUID, campaignUUID)
  }

  normalizeDate(date) {
    let resultDate = parseInt(date)
    if (!resultDate || isNaN(resultDate)) {
      return null
    }
    return moment.unix(resultDate)
  }

  handleAssociationInactiveToggle() {
    this.setState({
      associationEditor: {
        ...this.state.associationEditor,
        inactive: !this.state.associationEditor.inactive,
        isDirty: true,
      }
    })
  }

  handleAssociationEndDateChange(date) {
    date = moment(date)
    this.setState({
      associationEditor: {
        ...this.state.associationEditor,
        endDate: date ? date.unix() : 0,
        isDirty: true,
      }
    })
  }

  handleScreenshotToggle() {
    this.setState({
      associationEditor: {
        ...this.state.associationEditor,
        screenShot: !this.state.associationEditor.screenShot,
        isDirty: true,
      }
    })
  }

  handleTestLeadToggle() {
    this.setState({
      associationEditor: {
        ...this.state.associationEditor,
        testLead: !this.state.associationEditor.testLead,
        isDirty: true,
      }
    })
  }

  handleLiveToggle() {
    this.setState({
      associationEditor: {
        ...this.state.associationEditor,
        live: !this.state.associationEditor.live,
        isDirty: true,
      }
    })
  }

  renderAssociation() {
    if (this.props.associationManager.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.associationManager.loadingError) {
      return <div>Error {this.props.associationManager.loadingError.message}</div>
    }

    if (this.state.associationEditor) {
      if(!this.state.associationEditor.isNew)return null

      let endDate = this.normalizeDate(this.state.associationEditor.endDate)

      return <React.Fragment>
        <Grid style={{ padding: "1px" }}>
          <Cell col={12}>
            <div style={{ fontWeight: 'bolder', fontSize: 'x-large' }}>Create Association</div>
          </Cell>
          <Cell col={2}>
            <Switch
              onChange={() => this.handleAssociationInactiveToggle()}
              checked={!this.state.associationEditor.inactive}
            >Active</Switch>
          </Cell>
          <Cell col={4}>
            End Date: <DatePicker
              isClearable={true}
              dateFormat="MM/dd/yyyy"
              selected={!endDate ? null : endDate.toDate()}
              withPortal
              onChange={dateMoment => this.handleAssociationEndDateChange(dateMoment)}
            />
          </Cell>
          <Cell col={2}>
            <Switch
              onChange={() => this.handleScreenshotToggle()}
              checked={this.state.associationEditor.screenShot}
            >Screen shot</Switch>
          </Cell>
          <Cell col={2}>
            <Switch
              onChange={() => this.handleTestLeadToggle()}
              checked={this.state.associationEditor.testLead}
            >Test Lead</Switch>
          </Cell>
          <Cell col={2}>
            <Switch
              onChange={() => this.handleLiveToggle()}
              checked={this.state.associationEditor.live}
            >Live</Switch>
          </Cell>
        </Grid>
      </React.Fragment>
    }
    return null
  }

  saveAssociationHandler() {
    this.props.crud.onSave()
  }

  resetAssociationEditorHandler() {
    this.props.resetAssociationEditor()
  }

  render() {
    if (this.state.isLoading) return "Loading ..."
    
    let dailyCapCounts={}
    if(this.props.associationManager.selectedCampaign){
      dailyCapCounts[this.props.associationManager.selectedCampaign.value]= this.props.associationManager.dailyCapCount
    }

    let currentCapCounts={}
    if(this.props.associationManager.selectedCampaign){
      currentCapCounts[this.props.associationManager.selectedCampaign.value]= this.props.associationManager.currentCapCount
    }

    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
          <Modal
            appElement={this.props.parent}
            isOpen={this.state.isAssociationPopupOpen}
            contentLabel="Association"
            style={modalStyles}
          >
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
              <div>
                  {!this.props.isDirtyValue ? (
                    <Button colored raised ripple disabled>
                      <Icon name="save"> Save</Icon>
                    </Button>
                  ) : (
                    <Button id="save-button" colored raised ripple onClick={() => this.saveAssociationHandler()}>
                      <Icon name="save"> Save</Icon>
                    </Button>
                  )}
                  <Button
                    raised
                    accent
                    ripple
                    id="cancel-button"
                    onClick={() => {
                      this.props.crud.onCancel()
                    }}
                    className="mdl-button--raised mdl-js-ripple-effect mdl-button--accent">
                    <Icon name="clear"> cancel</Icon> Cancel
                  </Button>

                  <Button
                    onClick={() => this.setState({
                      ...this.state,
                      isAssociationPopupOpen: false,
                    })}>
                    <Icon name="close" />
                  </Button>
                </div>

              </CardActions>
              <div>
                <Grid style={{ padding: "1px" }}>
                  <Cell col={6} style={{ zIndex: "2" }}>
                    <label className="floatLabel">Campaign</label>
                    <CampaignSelector
                      isActive={true}
                      isMulti={false}
                      selectedCampaigns={this.props.associationManager.selectedCampaign}
                      campaigns={this.props.campaigns}
                      onChange={(e) => this.associationCampaignSelectorOnChangeHandler(e)}
                    ></CampaignSelector>
                  </Cell>
                  <Cell col={6} style={{ zIndex: "2" }}>
                    <label className="floatLabel">Placement</label>
                    <PlacementSelector
                      isMulti={false}
                      selectedPlacements={this.props.associationManager.selectedPlacement}
                      placements={this.props.placements}
                      placementFilter={"brokered_only"}
                      showOnlyActiveFilter={true}
                      onChange={(e) => this.associationPlacementSelectorOnChangeHandler(e)}
                    ></PlacementSelector>
                  </Cell>
                  <hr style={{ "width": "100%" }} />
                  <Cell col={12}>
                    {this.state.associationEditor ?
                      
                      <React.Fragment>
                        {this.state.associationEditor.isNew && <div style={{ fontWeight: 'bolder', fontSize: 'x-large' }}>Create Association</div>}
                        {!this.state.associationEditor.isNew && <div style={{ fontWeight: 'bolder', fontSize: 'x-large' }}>Edit Association</div>}
                        <PlacementEditor
                        isModal={true}
                        placementFilter={"brokered_only"}
                        match={{ params: { uuid: !this.props.associationManager.selectedPlacement ? "" : this.props.associationManager.selectedPlacement.value } }}
                        toggleList={this.togglePlacementList}
                        setCrudHandler={(crudOptions) => this.setPlacementCrudHandler(crudOptions)}
                        associatedCampaignFilter={!this.props.associationManager.selectedCampaign ? "" : this.props.associationManager.selectedCampaign.value}
                        dailyCapCounts={dailyCapCounts}
                        currentCapCounts={currentCapCounts}
                        ></PlacementEditor>
                      </React.Fragment> :
                      <><p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p></>}
                  </Cell>
                </Grid>
              </div>
            </Card>
          </Modal>
          <Grid>
            <Cell col={12}>
              <Collapsible title="Buy Sheet" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={11}>
                    <Grid style={{ padding: "1px", zIndex: "1" }}>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              filterUpdated: true,
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6} style={{ zIndex: "1" }}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          placementFilter={"brokered_only"}
                          showOnlyActiveFilter={true}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                      <Cell col={3}>
                        <label className="floatLabel">Salespeople</label>
                        <Select
                          id="salespeople-select"
                          placeholder={"Salespeople"}
                          isMulti={true}
                          value={this.state.selectedSalespeople}
                          onChange={(s) => {
                            this.setState({
                              filterUpdated: true,
                              selectedSalespeople: s
                            })
                          }}
                          options={this.props.salespeople.map(e => { return { value: e.UUID, label: e.name } })}
                        />
                      </Cell>
                      <Cell col={3}>
                        <label className="floatLabel">Limit</label>
                        <Select
                          isMulti={false}
                          value={this.state.selectedLimit}
                          onChange={(s) => {
                            this.setState({
                              filterUpdated: true,
                              selectedLimit: s
                            })
                          }}
                          options={this.limits}
                        />
                      </Cell>
                      <Cell col={3} style={{ zIndex: "0" }}>
                        <Grid>
                          <Cell col={12}>
                            <Switch
                              onChange={() => {
                                this.setState({
                                  filterUpdated: true,
                                  workflowHasScreenShot: !this.state.workflowHasScreenShot
                                })
                              }}
                              checked={this.state.workflowHasScreenShot}
                            >Has Screenshot?</Switch>
                          </Cell>
                          <Cell col={12}>
                            <Switch
                              onChange={() => {
                                this.setState({
                                  filterUpdated: true,
                                  workflowHasTestLead: !this.state.workflowHasTestLead
                                })
                              }}
                              checked={this.state.workflowHasTestLead}
                            >Has Test Lead?</Switch>
                          </Cell>
                          <Cell col={12}>
                            <Switch
                              onChange={() => {
                                this.setState({
                                  filterUpdated: true,
                                  workflowIsLive: !this.state.workflowIsLive
                                })
                              }}
                              checked={this.state.workflowIsLive}
                            >Is Live?</Switch>
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell col={3} style={{ zIndex: "0" }}>
                        <Switch
                          onChange={() => {
                            this.setState({
                              filterUpdated: true,
                              associationIsActive: !this.state.associationIsActive
                            })
                          }}
                          checked={this.state.associationIsActive}
                        >Only active associations</Switch>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  <Button id="save-button" colored raised ripple onClick={() => {
                    this.resetAssociationEditorHandler()
                    this.setState({
                      isAssociationPopupOpen: true,
                    })
                  }}>
                    Association Editor
                  </Button>
                </Cell>
                <Cell col={12}>
                  <div className="report">
                    <div>{this.renderReport()}</div>
                  </div>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }


  renderReport() {
    if (this.props.buySheet.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.buySheet.error != null) {
      return <div>Error: {this.props.buySheet.error.message}</div>
    }
    if (this.props.buySheet.report == null) {
      return <div></div>
    }
    if (this.props.buySheet.report.associations.length == 0) {
      return <div>{"No records found"}</div>
    }
    let campaignsMap = new Map()
    this.props.campaigns.forEach(e => campaignsMap.set(e.UUID, e))

    let placementsMap = new Map()
    this.props.placements.forEach(e => placementsMap.set(e.UUID, e))

    return (
      <React.Fragment>
        <Grid style={{ "width": "100%" }}>

          {
            this.props.buySheet.slug ? <Cell col={12}>
              <a target="_blank" rel="noreferrer"
                href={window.location.origin + window.location.pathname + `?format=json&report=buy-sheet&slug=${this.props.buySheet.slug}`}>
                Share Report</a>
            </Cell> : null
          }
          <Cell col={12}>
            <table className="report">
              <tbody>
                <tr className="th" key={`viewer.buysheet.header2`}>
                  <th className="th dimension">Campaign</th>
                  <th className="th dimension">Placement</th>
                  <th className="th dimension">Assoc. Status</th>
                  <th className="th dimension">Workflow Controls</th>
                  <th className="th dimension">Lead Numbers</th>
                  <th className="th dimension">Assoc.Rate</th>
                  <th className="th dimension">Campaign Rate</th>
                  <th className="th dimension">Dates</th>
                </tr>
                {this.props.buySheet.report.associations.map((e, i) => {
                  let campaign = campaignsMap.get(e.CampaignUUID)
                  let placement = placementsMap.get(e.PlacementUUID)
                  return (
                    <tr className="td" key={`viewer.buysheet.${i}`}>
                      <td className="td dimension clickable" onClick={() => this.placementClickHandler(e.PlacementUUID, e.CampaignUUID)} >
                        {campaign && <div>{`${campaign.name} - ${campaign.shortCode}`}</div>}
                        <div>Salesperson: {e.SalespersonName.length == 0 ? 'NA' : e.SalespersonName}</div>
                        <div style={commentStyle}>{e.CampaignUUID}</div>
                      </td>
                      <td className="td dimension clickable" onClick={() => this.placementClickHandler(e.PlacementUUID, e.CampaignUUID)} >
                        {placement && <div>{`${placement.name} - ${placement.legacyID}`}</div>}
                        <div style={commentStyle}>{e.PlacementUUID}</div>
                      </td>
                      <td className="td dimension">
                        {e.AssociationInactive ? <div style={inactiveStyle}>Inactive</div> : <div style={activeStyle}>Active</div>}
                      </td>
                      <td className="td dimension">
                        <div>Screen Shot:{e.AssociationScreenShot.toString()}</div>
                        <div>Live:{e.AssociationLive ? <span style={activeStyle}>True</span> : <span style={inactiveStyle}>False</span>}</div>
                        <div>Test Lead:{e.AssociationTestLead.toString()}</div>
                      </td>
                      <td className="td dimension">
                        <div>Cap:{e.AssociationEffectiveOverallCap.toString()}</div>
                        <div>Left:{e.AssociationLeadsToDeliver.toString()} {e.AssociationIsLegacy && ` (legacy)`}</div>
                      </td>
                      <td className="td dimension">{e.AssociationRate}</td>
                      <td className="td dimension">{e.CampaignRate}</td>
                      <td className="td dimension">
                        <div>
                          Start:
                          {e.AssociationStartDate == 0 ? "N/A" : moment(e.AssociationStartDate * 1000).format('MM/DD/YYYY')}
                        </div>
                        <div>
                          End:
                          {e.AssociationEndDate == 0 ? "N/A" : moment(e.AssociationEndDate * 1000).format('MM/DD/YYYY')}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }
}


const BuySheet = connect(mapStateToProps, mapDispatchToProps)(buySheet)

export default BuySheet
