import {
    generateBrokeredLeadViewerReport,
    generateBrokeredLeadViewerReportFailure,
    generateBrokeredLeadViewerReportSuccess
} from '../reducers/brokeredLeadViewerReportReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'
import store from '../store'

const url = getUrlFor("reporting", "brokered-lead-viewer")
//const url = "http://localhost:9001/brokered-lead-viewer/"
//const url = "http://localhost:9001/brokered-lead-viewer-mock/"
//const url = getUrlFor("reporting", "brokered-lead-viewer-mock")

export function generateBrokeredLeadViewerReportThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generateBrokeredLeadViewerReport())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generateBrokeredLeadViewerReportSuccess(res.data))
            }).catch(e => {
                dispatch(generateBrokeredLeadViewerReportFailure(`Getting brokered lead viewer report - ${e.statusText}`))
                exceptionHandling(e, dispatch, "Getting brokered lead viewer report")
            })
    }
}

export const fetchBrokeredLeadViewerCsvReportThunk = (criteria) => {
    return fetch(`${url}?format=csv`, {
        method: "post",
        headers: {
            'Accept': 'application/octet-stream',
            'Authorization': store.getState().jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(criteria)
    }).then(response => {
        if (response.ok && response.status === 200) {
            return response.blob()
        }
        return Promise.reject(new Error(response.statusText))
    })
}