import { ActionTypes } from "../services"
import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import { phoneVerificationCampaignConfig } from "./phoneVerifCampaignConfigReducer"
import { pubPrevalReport } from "./pubPrevalReportReducer"
import { pubReactivationReport } from "./pubReactivationReportReducer"
import { pubReactivationByDateReport } from "./pubReactivationByDateReportReducer"
import { pubReactivationByDateCsvReport } from "./pubReactivationByDateCsvReportReducer"

import { sessionLookup } from "./sessionLookupReducer"
import { sessionDetails } from "./sessionDetailsReducer"
import { pubPrevalByDateReport } from "./pubPrevalByDateReportReducer"
import { pubPrevalByMessageReport } from "./pubPrevalByMessageReportReducer"
import { pubPrevalByMessageCsvReport } from "./pubPrevalByMessageCsvReportReducer"
import { pubPrevalByDateCsvReport } from "./pubPrevalByDateCsvReportReducer"
import { singleValidationsReport } from "./singleValidationsReportReducer"
import { singleValidationsReportV2 } from "./singleValidationsReportV2Reducer"
import { singleValidationsCsvReport } from "./singleValidationsCsvReportReducer"
import { buySheetReportState } from "./buySheetReportReducer"
import { campaignQuickStatusState } from "./campaignQuickStatusReducer"
import { internalBudgetState } from "./internalBudgetReducer"
import { brokeredLeadViewerReportState } from "./brokeredLeadViewerReportReducer"
import { brokeredLeadViewerCsvReportState } from "./brokeredLeadViewerCsvReportReducer"
import { brokeredFailedLeadsState } from "./brokeredFailedLeadsReducer"
import { optinReviewerState } from "./optinReviewerReducer"
import { deliverableViewerState } from "./deliverableViewerReducer"
import { adqPerformanceState } from "./adqPerformanceReducer"
import { truIntentState } from "./truIntentReducer"
import { activityLogState } from "./activityLogReducer"



import { customers } from "./customerReducer"
import moment from "moment"
const NULLUUID = "00000000-0000-0000-0000-000000000000"

const initialDirtyState = false
function IsDirty(state = initialDirtyState, action) {
  switch (action.type) {
    case ActionTypes.IS_DIRTY:
      return action.data
    default:
      return state
  }
}
const initialJobState = []
function Job(state = initialJobState, action) {
  switch (action.type) {
    case ActionTypes.JOB_SELECTED:
      return action.data
    default:
      return state
  }
}

const initialTransferConfigurationsState = []
function TransferConfigurations(state = initialTransferConfigurationsState, action) {
  switch (action.type) {
    case ActionTypes.LEAD_TRANSFER_CONFIGURATIONS_LOADED:
      return action.data
    default:
      return state
  }
}

const initialSentHistoryState = []
function LeadSentHistory(state = initialSentHistoryState, action) {
  switch (action.type) {
    case ActionTypes.LEAD_HISTORY_LOADED:
      return action.data
    default:
      return state
  }
}

const initialTagMasterListState = { records: [] }
function TagMasterList(state = initialTagMasterListState, action) {
  switch (action.type) {
    case ActionTypes.TAGMASTERLIST_LOADED:
      return action.data
    default:
      return state
  }
}

const initialCampaignCriteriaState = { criteriaFilter: {} }
function CampaignCriteria(state = initialCampaignCriteriaState, action) {
  switch (action.type) {
    case ActionTypes.CAMPAIGN_CRITERIA_LOADED:
      return action.data
    default:
      return state
  }
}

const initialItemStatsState = { stats: null }
function ItemStats(state = initialItemStatsState, action) {
  switch (action.type) {
    case ActionTypes.ITEM_STATS_LOADED:
      return Object.assign({}, state, {
        stats: action.data,
        error: false
      })
    case ActionTypes.ITEM_STATS_LOADING:
      return Object.assign({}, state, {
        stats: null,
        error: false
      })
    case ActionTypes.ITEM_STATS_ERROR:
      return Object.assign({}, state, {
        stats: null,
        error: true
      })
    default:
      return state
  }
}

const initialItemQuarantineStatsState = { stats: null }
function QuarantineStats(state = initialItemQuarantineStatsState, action) {
  switch (action.type) {
    case ActionTypes.QUARANTINE_SUMMARY_STATS_LOADED:
      return Object.assign({}, state, {
        stats: Object.assign({}, ...action.data.map((x) => ({ [x.campaignUUID + x.placementUUID]: x.count }))),
        error: false
      })
    case ActionTypes.QUARANTINE_SUMMARY_STATS_LOADING:
      return Object.assign({}, state, {
        stats: null,
        error: false
      })
    case ActionTypes.QUARANTINE_SUMMARY_STATS_ERROR:
      return Object.assign({}, state, {
        stats: null,
        error: true
      })
    default:
      return state
  }
}

const initialTagChildrenListState = { records: [] }
function TagChildrenList(state = initialTagChildrenListState, action) {
  switch (action.type) {
    case ActionTypes.TAGCHILDRENLIST_LOADED:
      return action.data
    default:
      return state
  }
}
const initialAltParent = []
function AltParentTag(state = initialAltParent, action) {
  switch (action.type) {
    case ActionTypes.ALTPARENTTAG_LOADED: {
      const newState = Object.assign([], state)
      newState.push(action.data)
      return newState
    }
    case ActionTypes.ALTPARENTTAG_RESET:
      return initialAltParent
    case ActionTypes.TAG_LOADING:
      return state
    case ActionTypes.ALTPARENTTAG_UNLOAD:
      return state.filter(altParentTag => altParentTag.UUID !== action.data)
    default:
      return state
  }
}

const initialAssociatedTags = { records: [] }
function AssociatedTags(state = initialAssociatedTags, action) {
  switch (action.type) {
    case ActionTypes.ASSOCIATEDTAGS_LOADED:
      return action.data
    case ActionTypes.ASSOCIATEDTAGS_RESET:
      return initialAssociatedTags
    default:
      return state
  }
}

const initialTagState = []
function Tags(state = initialTagState, action) {
  switch (action.type) {
    case ActionTypes.TAGS_LOADED:
      var tagMap = {}
      action.data.records.forEach(t => {
        if (!t.tag || t.tag.length == 0) {
          return
        }
        tagMap[t.tag] = (tagMap[t.tag] || 0) + 1
      })
      return Object.keys(tagMap).map(key => {
        return { value: key, count: tagMap[key] }
      })
    default:
      return state
  }
}

const initialAssociatedLeadAcceptance = { records: null, isLoading: false }
function AssociatedLeadAcceptance(state = initialAssociatedLeadAcceptance, action) {
  switch (action.type) {
    case ActionTypes.ASSOCIATED_LEAD_ACCEPTANCE_CONFIGS_LOAD_START:
      return {
        ...initialAssociatedLeadAcceptance,
        isLoading: true,
      }
    case ActionTypes.ASSOCIATED_LEAD_ACCEPTANCE_CONFIGS_LOAD_SUCCESS:
      return {
        ...initialAssociatedLeadAcceptance,
        records: action.data.records,
        isLoading: false,
      }
    default:
      return state
  }
}

const initialAssociatedCriteria = { records: [] }
function AssociatedCriteria(state = initialAssociatedCriteria, action) {
  switch (action.type) {
    case ActionTypes.ASSOCIATEDCRITERIA_LOADED: {
      const data = action.data || initialAssociatedCriteria
      return data
    }
    default:
      return state
  }
}

const initialAssociatedCriteriaSaveMsg = null
function AssociatedCriteriaSaveMsg(state = initialAssociatedCriteriaSaveMsg, action) {
  switch (action.type) {
    case ActionTypes.ASSOCIATEDCRITERIA_SAVE_MSG:
      return action.data
    case ActionTypes.ASSOCIATEDCRITERIA_SAVE_MSG_RESET:
      return initialAssociatedCriteriaSaveMsg
    default:
      return state
  }
}

const initialBWState = { words: { FullMatch: [], PartialMatch: [] }, testResult: false, isLoading: false }
function BadWords(state = initialBWState, action) {
  switch (action.type) {
    case ActionTypes.BADWORDS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    case ActionTypes.TESTING_BADWORD_LOADING:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialUserData = {}
function User(state, action) {
  switch (action.type) {
    case ActionTypes.USER_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state || (sessionStorage["user"] ? JSON.parse(sessionStorage["user"]) : null) || initialUserData
  }
}

const initialGrpState = { uuid: NULLUUID, memberUuids: [] }
function Group(state = initialGrpState, action) {
  switch (action.type) {
    case ActionTypes.GROUP_SELECTED:
      return JSON.parse(JSON.stringify(action.data))
    case ActionTypes.GROUP_UPDATED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialBState = []
function Buckets(state = initialBState, action) {
  switch (action.type) {
    case ActionTypes.BUCKETS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialCustomValidationPreviewState = {}
function CustomValidationPreview(state = initialCustomValidationPreviewState, action) {
  switch (action.type) {
    case ActionTypes.CUSTOM_VALIDATION_RESULTS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}
const initialCustomValidationState = { enabled: false, script: "" }
function CustomValidation(state = initialCustomValidationState, action) {
  switch (action.type) {
    case ActionTypes.CUSTOM_VALIDATION_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}
const initialCampState = { criteriaFilter: {} }
function Campaign(state = initialCampState, action) {
  switch (action.type) {
    case ActionTypes.CAMPAIGN_NOT_FOUND:
      return null
    case ActionTypes.CAMPAIGN_SELECTED:
      return JSON.parse(JSON.stringify(action.data))
    case ActionTypes.CAMPAIGN_UPDATED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialCampsState = { isLoading: false, records: null, recordsMap: null }
function Campaigns(state = initialCampsState, action) {
  switch (action.type) {
    case ActionTypes.CAMPAIGNS_START_LOADING:
      return {
        ...state,
        isLoading: true,
        records: [],
        recordsMap: {},
      }
    case ActionTypes.CAMPAIGNS_BATCH_LOADED:
      return {
        ...state,
        isLoading: true,
        records: JSON.parse(JSON.stringify(state.records.concat(action.data))),
      }
    case ActionTypes.CAMPAIGNS_LOADED:
      return {
        ...state,
        isLoading: false,
        recordsMap: state.records.reduce((prev, curr) => {
          prev[curr.UUID] = curr
          return prev
        }, {}),
      }
    default:
      return state
  }
}

const initialSalespeopleState = []
function Salespeople(state = initialSalespeopleState, action) {
  switch (action.type) {
    case ActionTypes.SALESPEOPLE_LOADED:
      return JSON.parse(JSON.stringify(action.data.records))
    default:
      return state
  }
}

const initialCampaignRequestsState = []
function CampaignRequests(state = initialCampaignRequestsState, action) {
  switch (action.type) {
    case ActionTypes.CAMPAIGN_REQUESTS_LOADED:
      if (action.data) {
        return action.data.records.slice()
      }
      return []
    default:
      return state
  }
}

const initialCampaignRequestState = { competitorUuids: [], approvedPlacementUuids: [] }
function CampaignRequest(state = initialCampaignRequestState, action) {
  switch (action.type) {
    case ActionTypes.CAMPAIGN_REQUEST_SELECTED: {
      let camp = action.data
      if (!camp) {
        return initialCampaignRequestState
      }
      let start = parseInt(camp.startDate)
      let end = parseInt(camp.endDate)
      if (!start || isNaN(start)) {
        start = null
      } else {
        start = moment.unix(start)
      }
      if (!end || isNaN(end)) {
        end = null
      } else {
        end = moment.unix(end)
      }
      camp.startDate = start
      camp.endDate = end
      return camp
    }
    case ActionTypes.RESET_CAMPAIGN_REQUEST:
      return null
    default:
      return state
  }
}

const defaultFields = [
  { displayName: "Email", fieldName: "email", order: 0, visible: true },
  { displayName: "Title", fieldName: "title", order: 1, visible: true },
  { displayName: "First Name", fieldName: "firstName", order: 2, visible: true },
  { displayName: "Last Name", fieldName: "lastName", order: 3, visible: true },
  { displayName: "Date of Birth", fieldName: "dob", order: 4, visible: true },
  { displayName: "Gender", fieldName: "gender", order: 5, visible: true },
  { displayName: "City", fieldName: "city", order: 6, visible: true },
  { displayName: "State", fieldName: "state", order: 7, visible: true },
  { displayName: "Country", fieldName: "country", order: 8, visible: true },
  { displayName: "Address", fieldName: "address1", order: 9, visible: true },
  { displayName: "Apt/Unit", fieldName: "address2", order: 10, visible: true },
  { displayName: "Zipcode", fieldName: "zipcode", order: 11, visible: true },
  { displayName: "Phone", fieldName: "phone", order: 12, visible: true }
]

const initialCreState = { textStrings: {}, imageUrls: {}, linkUrls: {} }
function Creative(state = initialCreState, action) {
  let setFieldsIfEmpty = () => {
    if (!action.data.fields || action.data.fields.length == 0) {
      action.data.fields = defaultFields
    }
    defaultFields.forEach(df => {
      let fs = action.data.fields.filter(f => df.fieldName == f.fieldName)
      if (fs && fs.length && fs.length > 0) {
        fs[0].displayName = df.displayName
      } else {
        fs.push(df)
      }
    })
  }
  switch (action.type) {
    case ActionTypes.CREATIVE_SELECTED:
      action.data.textStrings = action.data.textStrings || {}
      action.data.linkUrls = action.data.linkUrls || {}
      action.data.imageUrls = action.data.imageUrls || {}
      setFieldsIfEmpty()
      return JSON.parse(JSON.stringify(action.data))
    case ActionTypes.CREATIVE_UPDATED:
      action.data.textStrings = action.data.textStrings || {}
      action.data.linkUrls = action.data.linkUrls || {}
      action.data.imageUrls = action.data.imageUrls || {}
      setFieldsIfEmpty()
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialCrePreState = { data: { renderedCreative: "<div></div>" } }
function CreativePreview(state = initialCrePreState, action) {
  switch (action.type) {
    case ActionTypes.CREATIVEPREVIEW_UPDATED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialZipsState = []
function Zips(state = initialZipsState, action) {
  switch (action.type) {
    case ActionTypes.ZIPS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialValsState = []
function Validators(state = initialValsState, action) {
  switch (action.type) {
    case ActionTypes.VALIDATORS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialDefaultValState = {}
function DefaultValidators(state = initialDefaultValState, action) {
  switch (action.type) {
    case ActionTypes.DEFAULT_VALIDATORS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialValidatorsDataState = []
function ValidatorsData(state = initialValidatorsDataState, action) {
  switch (action.type) {
    case ActionTypes.VALIDATORS_DATA_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialSnaxState = {}
function Snack(state = initialSnaxState, action) {
  switch (action.type) {
    case ActionTypes.SNACK_UPDATED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const leadDeliverySchedule = { isLoading: false, data: null }
function LeadDeliverySchedule(state = leadDeliverySchedule, action) {
  switch (action.type) {
    case ActionTypes.LEAD_DELIVERY_SCHEDULE_START_LOADING:
      return { isLoading: true, data: {} }
    case ActionTypes.LEAD_DELIVERY_SCHEDULE_LOADED:
      return { isLoading: false, data: JSON.parse(JSON.stringify(action.data)) }
    case ActionTypes.LEAD_DELIVERY_SCHEDULE_SET:
      return { isLoading: false, data: action.data }
    default:
      return state
  }
}

const initialLeadDeliveryConfigurationTestState = {}
function LeadDeliveryConfigurationTest(state = initialLeadDeliveryConfigurationTestState, action) {
  switch (action.type) {
    case ActionTypes.LEAD_DELIVERY_CONFIG_TEST:
      return action.data
    default:
      return state
  }
}

const leadDeliveryConfig = { isLoading: false, data: null, latestDeliveries: null, latestDeliveriesLoading: false }
function LeadDeliveryConfig(state = leadDeliveryConfig, action) {
  switch (action.type) {
    case ActionTypes.LEAD_DELIVERY_CONFIG_START_LOADING:
      return {
        ...state,
        isLoading: true,
        data: {},
      }
    case ActionTypes.LEAD_DELIVERY_CONFIG_LOADED:
      return {
        ...state,
        isLoading: false,
        data: JSON.parse(JSON.stringify(action.data)),
      }
    case ActionTypes.LEAD_DELIVERY_LATEST_DELIVERIES_START_LOADING:
      return {
        ...state,
        latestDeliveriesLoading: false,
        latestDeliveries: action.data,
      }
    case ActionTypes.LEAD_DELIVERY_LATEST_DELIVERIES_LOADED:
      return {
        ...state,
        latestDeliveriesLoading: false,
        latestDeliveries: JSON.parse(JSON.stringify(action.data)),
      }
    case ActionTypes.LEAD_DELIVERY_CONFIG_NEW:
      return {
        ...state,
        isLoading: false,
        data: JSON.parse(JSON.stringify(action.data)),
      }
    case ActionTypes.LEAD_DELIVERY_CONFIG_CANCEL:
      return leadDeliveryConfig
    default:
      return state
  }
}

const initialLeadDeliveryConfigsState = []
function LeadDeliveryConfigs(state = initialLeadDeliveryConfigsState, action) {
  switch (action.type) {
    case ActionTypes.LEAD_DELIVERY_CONFIGS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialLeadDeliveryConfigsErrorState = { Err: null }
function LeadDeliveryConfigError(state = initialLeadDeliveryConfigsErrorState, action) {
  switch (action.type) {
    case ActionTypes.LEAD_DELIVERY_CONFIGS_ERROR:
      return { Err: JSON.parse(JSON.stringify(action.data)) }
    default:
      return state
  }
}

const initialWinbacksState = []
function Winbacks(state = initialWinbacksState, action) {
  switch (action.type) {
    case ActionTypes.WINBACK_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialWinbackCampaignsState = []
function WinbackCampaigns(state = initialWinbackCampaignsState, action) {
  switch (action.type) {
    case ActionTypes.WINBACK_CAMPAIGN_UPDATED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialWinbackErrorState = { message: "" }
function WinbackError(state = initialWinbackErrorState, action) {
  switch (action.type) {
    case ActionTypes.WINBACK_ERROR:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialRankersState = []
function Rankers(state = initialRankersState, action) {
  switch (action.type) {
    case ActionTypes.RANKERS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialCresState = []
function Creatives(state = initialCresState, action) {
  switch (action.type) {
    case ActionTypes.CREATIVE_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialQsState = []
function Questions(state = initialQsState, action) {
  switch (action.type) {
    case ActionTypes.QUESTIONS_LOADED:
      if (!action.data) {
        return []
      }
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialQuestionState = { isDirty: false }
function Question(state = initialQuestionState, action) {
  switch (action.type) {
    case ActionTypes.QUESTION_IS_DIRTY:
      return {
        ...state,
        isDirty: true
      }
    default:
      return state
  }
}

const initialPrePreState = {}
function PrepingPreview(state = initialPrePreState, action) {
  switch (action.type) {
    case ActionTypes.PREPINGPREVIEW_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialPlacesState = { isLoading: false, records: null }
function Placements(state = initialPlacesState, action) {
  switch (action.type) {
    case ActionTypes.PLACEMENTS_START_LOADING:
      return {
        ...state,
        isLoading: true,
        records: [],
      }
    case ActionTypes.PLACEMENTS_LOADED:
      return {
        ...state,
        isLoading: false,
        records: JSON.parse(JSON.stringify(action.data)),
      }
    default:
      return state
  }
}

const initialPlacementManagerState = { placement: null, isLookupListOpened: true, validationErrors: [] }
function PlacementManager(state = initialPlacementManagerState, action) {
  switch (action.type) {
    case ActionTypes.PLACEMENT_RESET:
      return initialPlacementManagerState
    case ActionTypes.PLACEMENT_NOT_FOUND:
      return {
        ...state,
        placement: null,
        validationErrors: [],
      }
    case ActionTypes.PLACEMENT_SELECTED:
      return {
        ...state,
        placement: action.data,
        validationErrors: [],
      }
    case ActionTypes.PLACEMENT_UPDATED:
      return {
        ...state,
        placement: action.data,
        validationErrors: [],
      }
    case ActionTypes.PLACEMENT_MANAGER_OPEN_LOOKUP_LIST:
        return {
          ...state,
          isLookupListOpened: true,
        }
     case ActionTypes.PLACEMENT_MANAGER_CLOSE_LOOKUP_LIST:
      return {
        ...state,
        isLookupListOpened: false,
      }
     case ActionTypes.SET_PLACEMENT_MANAGER_VALIDATION_ERROR:
        return {
          ...state,
          validationErrors: action.data || [],
        }
    default:
      return state
  }
}

const initialAssociationManagerState = { 
  selectedPlacement: null, 
  selectedCampaign: null, 
  association: null, isLoading: false, loadingError: null, associationSaved: false,
  dailyCapCount: {count: null, isLoading: false, loadingError: null},
  currentCapCount: {count: null, isLoading: false, loadingError: null},

}
function AssociationManager(state = initialAssociationManagerState, action) {
  switch (action.type) {
    case ActionTypes.ASSOCIATION_RESET:
      return initialAssociationManagerState
    case ActionTypes.ASSOCIATION_START_LOADING:
      return {
        ...state,
        association: null,
        isLoading: true,
        loadingError: null,
        associationSaved: false,
      }
    case ActionTypes.ASSOCIATION_LOADED:
      return {
        ...state,
        association: action.data,
        isLoading: false,
      }
    case ActionTypes.ASSOCIATION_LOADING_ERROR:
      return {
        ...state,
        association: null,
        isLoading: false,
        loadingError: action.data,
      }
    case ActionTypes.ASSOCIATION_PLACEMENT_SELECTED:
      return {
        ...state,
        selectedPlacement: action.data,
      }
     case ActionTypes.ASSOCIATION_PLACEMENT_SAVED:
        return {
          ...state,
          associationSaved: true,
        }
    case ActionTypes.ASSOCIATION_CAMPAIGN_SELECTED:
      return {
        ...state,
        selectedCampaign: action.data,
      }
    

    case ActionTypes.ASSOCIATION_DAILY_CAP_COUNT_START_LOADING:
      return {
        ...state,
        dailyCapCount: {
          count: null,
          isLoading: true,
          error: null,
        },
      }
    case ActionTypes.ASSOCIATION_DAILY_CAP_COUNT_LOADED:
      return {
        ...state,
        dailyCapCount: {
          count: action.data,
          isLoading: false,
          error: null,
        },
      }
    case ActionTypes.ASSOCIATION_DAILY_CAP_COUNT_LOADING_ERROR:
      return {
        ...state,
        dailyCapCount: {
          count: null,
          isLoading: false,
          error: action.data,
        },
      }



      case ActionTypes.ASSOCIATION_CURRENT_CAP_COUNT_START_LOADING:
        return {
          ...state,
          currentCapCount: {
            count: null,
            isLoading: true,
            error: null,
          },
        }
      case ActionTypes.ASSOCIATION_CURRENT_CAP_COUNT_LOADED:
        return {
          ...state,
          currentCapCount: {
            count: action.data,
            isLoading: false,
            error: null,
          },
        }
      case ActionTypes.ASSOCIATION_CURRENT_CAP_COUNT_LOADING_ERROR:
        return {
          ...state,
          currentCapCount: {
            count: null,
            isLoading: false,
            error: action.data,
          },
        }
    
    default:
      return state
  }
}

const initialDataSetsState = []
function DataSets(state = initialDataSetsState, action) {
  switch (action.type) {
    case ActionTypes.DATASETS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialInspectorState = {
  impressions: [],
  sessions: [],
  optIns: [],
  leads: [],
  traces: []
}
function Inspector(state = initialInspectorState, action) {
  switch (action.type) {
    case ActionTypes.INSPECTOR_UPDATED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialBlockedAreaCodeStateState = { data: [], available: 0 }
function BlockedAreaCodes(state = initialBlockedAreaCodeStateState, action) {
  switch (action.type) {
    case ActionTypes.BLOCKED_AREA_CODES_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialBlockedEmailDomainState = { data: [], available: 0 }
function BlockedEmailDomains(state = initialBlockedEmailDomainState, action) {
  switch (action.type) {
    case ActionTypes.BLOCKED_EMAIL_DOMAINS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialBlockedEmailState = { data: [], available: 0 }
function BlockedEmails(state = initialBlockedEmailState, action) {
  switch (action.type) {
    case ActionTypes.BLOCKED_EMAILS_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialBlockedEmailResultState = { validationResult: {} }
function BlockedEmailResult(state = initialBlockedEmailResultState, action) {
  switch (action.type) {
    case ActionTypes.BLOCKED_EMAIL_VALIDATION_START:
      return initialBlockedEmailResultState
    case ActionTypes.BLOCKED_EMAIL_VALIDATION_RESULT:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}
const initialBlockedAreaCodeResultState = { validationResult: {} }
function BlockedAreaCodeResult(state = initialBlockedAreaCodeResultState, action) {
  switch (action.type) {
    case ActionTypes.BLOCKED_AREA_CODE_VALIDATION_RESULT:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialEmailValidationConfigurationState = {
  campaignUUID: "",
  briteVerify: false,
  emailOversight: true,
  strictEmailOversight: false
}
function EmailValidationConfiguration(state = initialEmailValidationConfigurationState, action) {
  switch (action.type) {
    case ActionTypes.EMAIL_VALIDATION_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialAddressValidationDefaultConfigState = {
  melissa: { enabled: true, suiteLevelEnabled: false, militaryZipcodeAccepted: false, uniqueZipcodeAccepted: false }
}
function AddressValidationDefaultConfig(state = initialAddressValidationDefaultConfigState, action) {
  switch (action.type) {
    case ActionTypes.ADDRESS_VALIDATION_DEFAULT_CONFIG_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}
const initialAddressValidationCampaignConfigState = {
  campaignUUID: "",
  config: { melissa: { suiteLevelEnabled: false, militaryZipcodeAccepted: false, uniqueZipcodeAccepted: false } }
}
function AddressValidationCampaignConfig(state = initialAddressValidationCampaignConfigState, action) {
  switch (action.type) {
    case ActionTypes.ADDRESS_VALIDATION_CAMPAIGN_CONFIG_RESET: {
      let tmpState = JSON.parse(JSON.stringify(initialAddressValidationCampaignConfigState))
      tmpState.campaignUUID = action.data.campaignUUID
      return tmpState
    }
    case ActionTypes.ADDRESS_VALIDATION_CAMPAIGN_CONFIG_LOADED:
      if (action.data.length == 0) {
        return initialAddressValidationCampaignConfigState
      }
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialAddressValidationPlacementAssociatedConfigsState = { validationList: null, isLoading: false }
function AddressValidationPlacementAssociatedConfigs(state = initialAddressValidationPlacementAssociatedConfigsState, action) {
  switch (action.type) {
    case ActionTypes.ADDRESS_VALIDATION_PLACEMENT_ASSOCIATED_CONFIGS_RESET: {
      return initialAddressValidationPlacementAssociatedConfigsState
    }
    case ActionTypes.ADDRESS_VALIDATION_PLACEMENT_ASSOCIATED_CONFIGS_LOAD_START:
      return {
        ...initialAddressValidationPlacementAssociatedConfigsState,
        isLoading: true,
      }
    case ActionTypes.ADDRESS_VALIDATION_PLACEMENT_ASSOCIATED_CONFIGS_LOAD_SUCCESS:
      return {
        ...initialAddressValidationPlacementAssociatedConfigsState,
        validationList: action.data,
        isLoading: false,
      }
    default:
      return state
  }
}

const initialDarwinGroupList = []
function DarwinGroupList(state = initialDarwinGroupList, action) {
  switch (action.type) {
    case ActionTypes.DARWIN_GROUP_LIST_LOADED:
      return JSON.parse(JSON.stringify(action.data.records))
    default:
      return state
  }
}

const initialDarwinGroup = null
function DarwinGroup(state = initialDarwinGroup, action) {
  switch (action.type) {
    case ActionTypes.DARWIN_GROUP_LOADED:
      return JSON.parse(JSON.stringify(action.data))
    default:
      return state
  }
}

const initialDarwinGroupMemberList = []
function DarwinGroupMemberList(state = initialDarwinGroupMemberList, action) {
  switch (action.type) {
    case ActionTypes.DARWIN_GROUP_MEMBER_LIST_LOADED:
      return JSON.parse(JSON.stringify(action.data.records))
    default:
      return state
  }
}

const initialDarwinAllGroupsMembersList = []
function DarwinAllGroupsMembersList(state = initialDarwinAllGroupsMembersList, action) {
  switch (action.type) {
    case ActionTypes.DARWIN_ALL_GROUPS_MEMBERS_LIST_LOADED:
      return JSON.parse(JSON.stringify(action.data.records))
    default:
      return state
  }
}

const initialJWT = {}
function JWT(state = initialJWT, action) {
  switch (action.type) {
    case ActionTypes.JWT_LOADED:
      return action.data
    default:
      return sessionStorage["jwt"] || state
  }
}

const initialCrudState = { onSave: () => { }, onCancel: () => { }, onNew: () => { }, visible: false }
function Crud(state = initialCrudState, action) {
  switch (action.type) {
    case ActionTypes.CRUD_UPDATED:
      return {
        onSave: action.data.onSave,
        onCancel: action.data.onCancel,
        onNew: action.data.onNew,
        visible: action.data.visible
      }
    default:
      return state
  }
}

const initialUploadSuppressionListState = false
function UploadingSuppressionListFile(state = initialUploadSuppressionListState, action) {
  switch (action.type) {
    case ActionTypes.START_SUPPRESSION_LIST_FILE_UPLOAD:
      return true
    case ActionTypes.FINISH_SUPPRESSION_LIST_FILE_UPLOAD:
      return false
    default:
      return state
  }
}

const initialUploadProgressState = 0
function UpdateProgress(state = initialUploadProgressState, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_SUPPRESSION_LIST_FILE_UPLOAD_BAR:
      return action.data > 100 ? 99 : action.data
    default:
      return state
  }
}

const initialEmailDomainsReviewState = []
function EmailDomainsReview(state = initialEmailDomainsReviewState, action) {
  switch (action.type) {
    case ActionTypes.EMAIL_DOMAINS_LIST_LOADED:
      return action.data
    default:
      return state
  }
}

const initialkeycloakResults = {}
function KeyCloakUserCreate(state = initialkeycloakResults, action) {
  switch (action.type) {
    case ActionTypes.KEYCLOAK_CREATE_USER_LOADED:
      return action.data
    default:
      return state
  }
}

const initialAgentSourceURL = ""
function AgentSourceURL(state = initialAgentSourceURL, action) {
  switch (action.type) {
    case ActionTypes.AGENT_SOURCE_URL_LOADED:
      return action.data
    default:
      return state
  }
}

const initialCampaignAssociations = { loading: false, associations: [] }
function CampaignAssociations(state = initialCampaignAssociations, action) {
  switch (action.type) {
    case ActionTypes.CAMPAIGN_ASSOCIATIONS_LOADED:
      return { loading: false, associations: action.data }
    case ActionTypes.LOADING_CAMPAIGN_ASSOCIATIONS:
      return { loading: true }
    default:
      return state
  }
}

const initialUploadSuppressionListData = {
  campaignName: "",
  fileName: "",
  emailsCount: 0,
  done: false,
  errors: [],
  failed: false
}
function UploadSuppresionListData(state = initialUploadSuppressionListData, action) {
  switch (action.type) {
    case ActionTypes.START_SUPPRESSION_LIST_FILE_UPLOAD:
      return Object.assign({}, state, {
        campaignName: action.data.campaignName,
        fileName: action.data.fileName,
        emailsCount: action.data.emailsCount,
        done: false
      })
    case ActionTypes.FINISH_SUPPRESSION_LIST_FILE_UPLOAD:
      return Object.assign({}, state, {
        done: true
      })
    case ActionTypes.RESET_SUPPRESSION_LIST_UPLOAD:
      return initialUploadSuppressionListData
    case ActionTypes.UPDATE_SUPPRESSION_UPLOAD_ERRORS: {
      const stateErrors = state.errors
      return Object.assign({}, state, {
        errors: stateErrors.concat(action.data)
      })
    }
    default:
      return state
  }
}



const reducer = combineReducers({
  isDirty: IsDirty,
  crud: Crud,
  jwt: JWT,
  routing: routerReducer,
  campaignRequests: CampaignRequests,
  campaignRequest: CampaignRequest,
  customValidation: CustomValidation,
  customValidationPreview: CustomValidationPreview,
  job: Job,
  transferConfigurations: TransferConfigurations,
  leadHistory: LeadSentHistory,
  tags: Tags,
  tagMasterList: TagMasterList,
  tagChildrenList: TagChildrenList,
  altParentTag: AltParentTag,
  associatedTags: AssociatedTags,
  user: User,
  campaign: Campaign,
  campaignData: Campaigns,
  customers,
  salespeople: Salespeople,
  placementManager: PlacementManager,
  associationManager: AssociationManager,
  prepingPreview: PrepingPreview,
  placementData: Placements,
  validators: Validators,
  validatorsData: ValidatorsData,
  defaultValidators: DefaultValidators,
  badwords: BadWords,
  associatedCriteria: AssociatedCriteria,
  associatedCriteriaSaveMsg: AssociatedCriteriaSaveMsg,
  associatedLeadAcceptance: AssociatedLeadAcceptance,
  group: Group,
  zips: Zips,
  rankers: Rankers,
  winbacks: Winbacks,
  winbackError: WinbackError,
  winbackCampaigns: WinbackCampaigns,
  datasets: DataSets,
  inspector: Inspector,
  questions: Questions,
  question: Question,
  creative: Creative,
  creatives: Creatives,
  creativePreview: CreativePreview,
  buckets: Buckets,
  snack: Snack,
  blockedEmailDomains: BlockedEmailDomains,
  blockedEmails: BlockedEmails,
  blockedEmailResult: BlockedEmailResult,
  blockedAreaCodeResult: BlockedAreaCodeResult,
  blockedAreaCodes: BlockedAreaCodes,
  leadDeliveryConfigs: LeadDeliveryConfigs,
  leadDeliveryConfigError: LeadDeliveryConfigError,
  leadDeliverySchedule: LeadDeliverySchedule,
  leadDeliveryConfig: LeadDeliveryConfig,
  leadDeliveryConfigTest: LeadDeliveryConfigurationTest,
  deliverableViewer: deliverableViewerState,
  pubPrevalReport,
  pubReactivationReport,
  pubReactivationByDateCsvReport,
  pubReactivationByDateReport,
  sessionDetails,
  sessionLookup,
  pubPrevalByDateReport,
  pubPrevalByMessageReport,
  pubPrevalByMessageCsvReport,
  pubPrevalByDateCsvReport,
  optinReviewer: optinReviewerState,
  singleValidationsReport,
  singleValidationsReportV2,
  singleValidationsCsvReport,
  buySheet: buySheetReportState,
  campaignQuickStatus: campaignQuickStatusState,
  internalBudget: internalBudgetState,
  brokeredLeadViewer: brokeredLeadViewerReportState,
  brokeredLeadViewerCsv: brokeredLeadViewerCsvReportState,
  brokeredFailedLeads: brokeredFailedLeadsState,
  adqPerformance: adqPerformanceState,
  truIntent: truIntentState,
  activityLog: activityLogState,
  emailValidationConfiguration: EmailValidationConfiguration,
  darwinGroups: DarwinGroupList,
  darwinGroup: DarwinGroup,
  darwinGroupMemberList: DarwinGroupMemberList,
  darwingAllGroupsMembersList: DarwinAllGroupsMembersList,
  uploadingSuppressionListFile: UploadingSuppressionListFile,
  uploadSuppresionListData: UploadSuppresionListData,
  updateProgress: UpdateProgress,
  emailDomainsReview: EmailDomainsReview,
  keyCloakUserCreate: KeyCloakUserCreate,
  agentSourceURL: AgentSourceURL,
  addressValidationDefaultConfig: AddressValidationDefaultConfig,
  addressValidationCampaignConfig: AddressValidationCampaignConfig,
  addressValidationPlacementAssociatedConfigs: AddressValidationPlacementAssociatedConfigs,
  campaignAssociations: CampaignAssociations,
  campaignCriteria: CampaignCriteria,
  itemStats: ItemStats,
  quarantineStats: QuarantineStats,
  phoneVerificationCampaignConfig,
})

export default reducer
