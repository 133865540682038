import React from 'react'
import { CellMeasurer } from 'react-virtualized'
import classNames from 'classnames'

class CellRendererByMessageByDate extends React.Component {
        constructor(props) {
                super(props)
        }

        render() {
                let { columnIndex, key, rowIndex, parent, cache } = this.props

                return (<CellMeasurer
                        cache={cache}
                        columnIndex={columnIndex}
                        key={key}
                        parent={parent}
                        rowIndex={rowIndex}>
                        {this.renderContent()}
                </CellMeasurer>)

        }

        renderContent() {
                let { columnIndex, rowIndex, style, comp, dateList } = this.props
                let dataRowNumber = rowIndex - 2
                let key = `${comp.serviceName}_${rowIndex}-${columnIndex}`

                let pivotNumber = Math.floor((columnIndex - 1) / 3)

                if (rowIndex === 0) {
                        if (columnIndex == 0) {
                                return <div key={key} style={style} className="th dimension">{""}</div>
                        } else {
                                //first row - pivots/metrics
                                if ((columnIndex - 1) % 3 === 0) {
                                        return (
                                                <div key={key} style={{ ...style, width: style.width * 3 }} className="th pivot">
                                                        {dateList[pivotNumber]}
                                                </div>
                                        )
                                } else {
                                        return <div key={key} style={{ display: 'none' }}>{' '}</div>
                                }
                        }
                } else if (rowIndex === 1) {
                        //second row - dimensions
                        if (columnIndex == 0) {
                                return <div key={key} style={style} className="th dimension">{"Message"}</div>
                        } else {
                                //second row - metrics
                                let mod = (columnIndex - 1) % 3
                                if (mod === 0) {
                                        return <div key={key} style={style} className="th metric">{"Rejections"}</div>
                                } else if (mod === 1) {
                                        return <div key={key} style={style} className="th metric">{"Failures"}</div>
                                } else if (mod === 2) {
                                        return <div key={key} style={style} className="th metric">{"Timeouts"}</div>
                                }
                        }
                } else {
                        var cellClasses = classNames({
                                'oddrow': rowIndex % 2 === 1,
                        })

                        if (columnIndex == 0) {
                                return (
                                        <div key={key} style={style} className={"td dimension " + cellClasses}>
                                                {comp.messageRecords[dataRowNumber].message}
                                        </div>
                                )
                        } else {
                                let date = dateList[pivotNumber]

                                let dateRecord = comp.messageRecords[dataRowNumber].dateRecords.find(rec => rec.date == date)
                                let mod = (columnIndex - 1) % 3
                                if (mod === 0) {
                                        return <div key={key} style={style} className={"td metric " + cellClasses}>{dateRecord.rejectionCount}{' '}</div>
                                } else if (mod === 1) {
                                        return <div key={key} style={style} className={"td metric " + cellClasses}>{dateRecord.failureCount}{' '}</div>
                                } else if (mod === 2) {
                                        return <div key={key} style={style} className={"td metric " + cellClasses}>{dateRecord.timeoutFailureCount}{' '}</div>
                                }
                        }

                }
        }
}

export default CellRendererByMessageByDate