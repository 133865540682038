import Modal from 'react-modal'
import React from 'react'
import { connect } from 'react-redux'
import { testPreping, clearPreview, ActionTypes } from '../../../services'
import DummyLead from '../../../utils/dummyLead'
import {Grid, Cell, Card, CardActions, CardTitle, CardMenu, Icon, Button} from 'react-mdl'

function mapStateToProps(state) {
  return {
    prepingPreview: state.prepingPreview,
    campaign: state.campaign,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    test: function (campaign, lead) {
      dispatch(clearPreview())
      lead.campaign_uuid = campaign.UUID
      dispatch(testPreping(lead))
    },
    clearPreview: function () {
      dispatch({
        type: ActionTypes.PREPINGPREVIEW_LOADED,
        data: {}
      })
    }
  }
}

class prepingPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowingModal: true,
      lead: { user: {} },
    }
    this.handleClose = () => {
      this.state.isShowingModal = false
      this.setState(this.state)
      this.props.clearPreview()
      this.props.onClose()
    }
  }

  render() {
    let styleMessage = this.props.prepingPreview.responseOK
      ? { color: '#d32f2f' }
      : {}
    return (
      <Modal
        appElement={this.props.parent}
        isOpen={this.state.isShowingModal}
        onRequestClose={this.handleClose}
        contentLabel="Pre Ping Test">
        <Card shadow={2}>
          <CardTitle>Pre Ping Test</CardTitle>
          <CardActions border>
            <Grid>
              <Cell col={12}>
                <DummyLead lead={this.state.lead || { user: {} }} update={lead => {
                  this.setState({
                    lead: lead
                  })
                }} />
              </Cell>
              <Cell col={3}>
                {this.props.prepingPreview.message
                  ? <div>
                    {this.props.prepingPreview.responseOK
                      ? <div>
                        {this.props.prepingPreview.isValid
                          ? <Icon
                            name="check_circle"
                            style={{
                              fontSize: '48px',
                              color: '#4caf50',
                            }}
                            title="New user"
                          />
                          : <Icon
                            name="highlight_off"
                            style={{
                              fontSize: '48px',
                              color: '#d32f2f',
                            }}
                            title="User already exists"
                          />}
                      </div>
                      : <div>
                        <Icon
                          name="error"
                          style={{ fontSize: '48px', color: '#d32f2f' }}
                          title="Error"
                        />
                      </div>}
                  </div>
                  : null}
              </Cell>
              <Cell col={12}>
                {this.props.prepingPreview.rawResults || this.props.prepingPreview.validationMessage
                  ? <div>
                    <div style={styleMessage}>Message:</div>
                    <pre>{this.props.prepingPreview.validationMessage}</pre>
                    <div style={styleMessage}>Raw Results:</div>
                    <pre>{this.props.prepingPreview.rawResults}</pre>
                  </div>
                  : null}
              </Cell>
            </Grid>
          </CardActions>
          <CardMenu>
            <Button
              raised
              colored
              onClick={() =>
                this.props.test(this.props.campaign, this.state.lead)}
            >
              Ping
                  </Button>
          </CardMenu>
          {this.props.prepingPreview.message ? <div className="mdl-card__subtitle-text" style={{ marginLeft: "20px", fontWeight: "bolder" }}>
            Request Preview
                </div> : null}
          <Grid>
            <Cell col={12}>
              <pre>
                {this.props.prepingPreview.message}
              </pre>
            </Cell>
          </Grid>
        </Card>
      </Modal>
    )
  }
}

const PrepingPopup = connect(mapStateToProps, mapDispatchToProps)(prepingPopup)

export default PrepingPopup
