const SAVE_STATE_OF_ACTIVITY_LOG = 'SAVE_STATE_OF_ACTIVITY_LOG'
const GENERATE_ACTIVITY_LOG = 'GENERATE_ACTIVITY_LOG'
const GENERATE_ACTIVITY_LOG_FAILURE = 'GENERATE_ACTIVITY_LOG_FAILURE'
const GENERATE_ACTIVITY_LOG_SUCCESS = 'GENERATE_ACTIVITY_LOG_SUCCESS'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_ACTIVITY_LOG, data })

export const generateActivityLog = () =>
    ({ type: GENERATE_ACTIVITY_LOG })

export const generateActivityLogSuccess = data =>
    ({ type: GENERATE_ACTIVITY_LOG_SUCCESS, data })

export const generateActivityLogFailure = error =>
    ({ type: GENERATE_ACTIVITY_LOG_FAILURE, error })

export const activityLogState = (state={report: null,isLoading: false, error: null, componentState: null}, action) => {
    switch (action.type) {
        case SAVE_STATE_OF_ACTIVITY_LOG:
            return {
                ...state,
                componentState: action.data,
            }    
        case GENERATE_ACTIVITY_LOG:
        return {
            ...state,
            isLoading: true,
            report: null,
            error: null,
        }
        case GENERATE_ACTIVITY_LOG_SUCCESS:
        return {
            ...state,
            isLoading: false,
            report: action.data.report,
            error: null,
        }
        case GENERATE_ACTIVITY_LOG_FAILURE:
        return {
            ...state,
            isLoading: false,
            report: null,
            error: {message: action.error},
        }
        default:
        return state
  }
}
