import {
    generatePubPrevalByMessageReport,
    generatePubPrevalByMessageReportFailure,
    generatePubPrevalByMessageReportSuccess
} from '../reducers/pubPrevalByMessageReportReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'
import store from '../store'

const url = getUrlFor("reporting", "pub-prevalidation-by-message")
//const url = "http://reporting.d.adquire.com/pub-prevalidation-by-message-mock/"
//const url = "http://localhost:9001/pub-prevalidation-by-message/"
//const url = "http://localhost:9001/pub-prevalidation-by-message-mock/"


export function generatePubPrevalByMessageReportThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generatePubPrevalByMessageReport())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generatePubPrevalByMessageReportSuccess(res.data))
            }).catch(e => {
                dispatch(generatePubPrevalByMessageReportFailure(new Error(`Getting pub prevalidation by message report - ${e.statusText}`)))
                exceptionHandling(e, dispatch, "Getting pub prevalidation by message report")
            })
    }
}


export const fetchPubPrevalByMessageCsvReport = (criteria) => {
    return fetch(`${url}?format=csv`, {
        method: "post",
        headers: {
            'Accept': 'application/octet-stream',
            'Authorization': store.getState().jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(criteria)
    }).then(response => {
        if (response.ok && response.status === 200) {
            return response.blob()
        }
        return Promise.reject(new Error(response.statusText))
    })
}
