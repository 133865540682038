import { generatePubPrevalByDateReportThunk, fetchPubPrevalByDateCsvReport } from "../../services/pubPrevalByDateReport"
import {
  generatePubPrevalByMessageReportThunk,
  fetchPubPrevalByMessageCsvReport
} from "../../services/pubPrevalByMessageReport"

import {
  generatePubPrevalByDateCsvReport,
  generatePubPrevalByDateCsvReportSuccess,
  generatePubPrevalByDateCsvReportFailure
} from "../../reducers/pubPrevalByDateCsvReportReducer"

import {
  generatePubPrevalByMessageCsvReport,
  generatePubPrevalByMessageCsvReportSuccess,
  generatePubPrevalByMessageCsvReportFailure
} from "../../reducers/pubPrevalByMessageCsvReportReducer"

import { saveState } from "../../reducers/pubPrevalReportReducer"

import { closedSessionPopup } from "../../reducers/pubPrevalReportReducer"
import { loadSessionsThunk } from "../../services/pubPrevalSessionService"
import { loadSessionDetailsThunk } from "../../services/sessionDetailsService"

import React from "react"
import { connect } from "react-redux"
import Modal from "react-modal"
import moment from "moment"
import { Grid, Cell, Card, CardActions, Button, Icon } from "react-mdl"
import { AutoSizer, MultiGrid, CellMeasurerCache } from "react-virtualized"
import CellRendererByDate from "./CellRendererByDate.js"
import CellRendererByMessage from "./CellRendererByMessage.js"
import fileDownload from "js-file-download"
import SessionDetails from "../session-details"
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import { Collapsible } from "../common/components"
import { filterForSelectable } from '../common/func'

function mapStateToProps(state) {
  return {
    sessionDetails: state.sessionDetails,
    pubPrevalReport: state.pubPrevalReport,
    pubPrevalByDateReport: state.pubPrevalByDateReport,
    pubPrevalByDateCsvReport: state.pubPrevalByDateCsvReport,
    pubPrevalByMessageReport: state.pubPrevalByMessageReport,
    pubPrevalByMessageCsvReport: state.pubPrevalByMessageCsvReport,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    generatePubPrevalByDateReport: function (criteria, slug) {
      dispatch(generatePubPrevalByDateReportThunk(criteria, slug))
    },
    startDownloadingByDateCsvReport: function () {
      dispatch(generatePubPrevalByDateCsvReport())
    },
    downloadedByDateCsvReport: function () {
      dispatch(generatePubPrevalByDateCsvReportSuccess())
    },
    failedToDownloadByDateCsvReport: function (err) {
      dispatch(generatePubPrevalByDateCsvReportFailure(err))
    },

    generatePubPrevalByMessageReport: function (criteria, slug) {
      dispatch(generatePubPrevalByMessageReportThunk(criteria, slug))
    },
    startDownloadingByMessageCsvReport: function () {
      dispatch(generatePubPrevalByMessageCsvReport())
    },
    downloadedByMessageCsvReport: function () {
      dispatch(generatePubPrevalByMessageCsvReportSuccess())
    },
    failedToDownloadByMessageCsvReport: function (err) {
      dispatch(generatePubPrevalByMessageCsvReportFailure(err))
    },
    openSessionPopupDialog: function (criteria) {
      dispatch(loadSessionsThunk(criteria))
    },
    closeSessionPopupDialog: function () {
      dispatch(closedSessionPopup())
    },
    loadSessionDetails: function (criteria) {
      dispatch(loadSessionDetailsThunk(criteria))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
  }
}

const sessionsModalStyles = {
  content: {}
}

const sessionDetailsModalStyles = {
  content: {}
}

const STYLE = {
  border: "1px solid #ddd"
}
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: "2px solid #aaa",
  backgroundColor: "#f7f7f7"
}
const STYLE_TOP_LEFT_GRID = {
  borderBottom: "2px solid #aaa",
  borderRight: "2px solid #aaa",
  fontWeight: "bold"
}
const STYLE_TOP_RIGHT_GRID = {
  borderBottom: "2px solid #aaa",
  fontWeight: "bold"
}

class pubPrevalReport extends React.Component {
  constructor(props) {
    super(props)

    this.rowLimit = 5000

    this.cellCacheByDate = new CellMeasurerCache({
      defaultHeight: 30,
      minWidth: 80,
      //maxWidth: 300,
      defaultWidth: 80,
      fixedHeight: true
    })

    this.cellCacheByMessage = new CellMeasurerCache({
      defaultHeight: 30,
      minWidth: 100,
      //maxWidth: 300,
      defaultWidth: 100,
      fixedHeight: true
    })


    this.state = {
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      startDate: moment().startOf("day"),
      endDate: moment(),
      newValues: "",
      limit: 0,
      timeZone: "America/New_York",
      newValuesReplace: false,
      isLoading: false,
      reportCriteria: null, //criteria that was used to generate the currently displayed report
      showSessionDetailsModal: false,
      isCriteriaCollapsed: false,
    }

    if (props.pubPrevalReport.componentState) {
      this.state = props.pubPrevalReport.componentState
    }

    if (props.byDateSlug && props.byDateSlug.length > 0) {
      let criteria = this.getCriteria()
      this.props.generatePubPrevalByDateReport(criteria, props.byDateSlug)
      this.props.generatePubPrevalByMessageReport(criteria, props.byMessageSlug)
    }
    this.handleCloseSessionsModal = this.handleCloseSessionsModal.bind(this)
    this.handleOpenSessionPopupDialog = this.handleOpenSessionPopupDialog.bind(this)
    this.handleOpenSessionDetailsClick = this.handleOpenSessionDetailsClick.bind(this)
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      filterUpdated: true,
      startDate: startDate,
      endDate: endDate
    })
  }

  handleRunButtonClick() {
    this.disableRunButton()
    let criteria = this.getCriteria()
    this.props.generatePubPrevalByDateReport(criteria, this.state.filterUpdated ? "" : this.byDateSlug)
    this.props.generatePubPrevalByMessageReport(criteria, this.state.filterUpdated ? "" : this.byMessageSlug)
    this.setState({
      filterUpdated: false,
      reportCriteria: criteria,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  handleDownloadButtonClick() {
    this.props.startDownloadingByDateCsvReport()
    this.props.startDownloadingByMessageCsvReport()
    this.disableDownloadButton()
    let criteria = this.getCriteria()

    let promises = []
    promises.push(fetchPubPrevalByDateCsvReport(criteria))
    promises.push(fetchPubPrevalByMessageCsvReport(criteria))

    return Promise.all(promises)
      .then(res => {
        this.props.downloadedByDateCsvReport()
        this.props.downloadedByMessageCsvReport()

        res.map((blob, i) => {
          let fileName = "reportByDate.csv"
          if (i == 1) {
            fileName = "reportByMessage.csv"
          }
          fileDownload(new Blob([blob]), fileName)
        })
      })
      .catch(e => {
        this.props.failedToDownloadByDateCsvReport(new Error(`Getting "by date" CSV report - ${e.statusText}`))
        this.props.failedToDownloadByMessageCsvReport(new Error(`Getting "by message" CSV reports - ${e.statusText}`))
      })
  }

  getCriteria() {
    return {
      timeZone: this.state.timeZone,
      rowLimit: this.rowLimit,
      startDate: this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: this.state.selectedPlacements.map(e => e.value)
    }
  }

  handleOpenSessionPopupDialog(campaingUUID, placementUUID, validationMessage) {
    let criteria = {
      rowLimit: this.rowLimit,
      timeZone: this.state.reportCriteria.timeZone,
      startDate: this.state.reportCriteria.startDate,
      endDate: this.state.reportCriteria.endDate,
      campaignUUIDs: [campaingUUID],
      placementUUIDs: [placementUUID],
      validationMessages: [validationMessage]
    }

    this.props.openSessionPopupDialog(criteria)
  }

  handleCloseSessionsModal() {
    this.props.closeSessionPopupDialog()
  }

  handleCloseSessionDetailsModal() {
    this.setState({
      showSessionDetailsModal: false
    })
  }

  handleOpenSessionDetailsClick(sessionUUID) {
    let criteria = {
      timeZone: this.state.reportCriteria.timeZone,
      startDate: this.state.reportCriteria.startDate,
      endDate: this.state.reportCriteria.endDate,
      sessionUUID: sessionUUID
    }

    this.props.loadSessionDetails(criteria)
    this.setState({
      showSessionDetailsModal: true
    })
  }

  componentDidMount() {
    Modal.setAppElement("body")
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true
    })
  }

  disableDownloadButton() {
    this.setState({
      isDownloadButtonDisabled: true
    })
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {}
    {
      let gotHtmlReportResponse = false
      props.pubPrevalByDateReport.matchWith({
        PubPrevalByDateReportInitial: () => { },
        LoadingPubPrevalByDateReport: () => { },
        PubPrevalByDateReportError: () => {
          gotHtmlReportResponse = true
        },
        PubPrevalByDateReportLoaded: () => {
          gotHtmlReportResponse = true
        }
      })
      props.pubPrevalByMessageReport.matchWith({
        PubPrevalByMessageReportInitial: () => { },
        LoadingPubPrevalByMessageReport: () => { },
        PubPrevalByMessageReportError: () => {
          gotHtmlReportResponse = true
        },
        PubPrevalByMessageReportLoaded: () => {
          gotHtmlReportResponse = true
          let gc = function (rr) {
            rr = rr || {}
            return {
              timeZone: rr.timeZone || state.timeZone,
              startDate: rr.startDate || state.startDate.format("YYYY-MM-DD HH:mm:ss"),
              endDate: rr.endDate || state.endDate.format("YYYY-MM-DD HH:mm:ss"),
              campaignUUIDs: rr.campaignUUIDs || state.selectedCampaigns.map(e => e.value),
              placementUUIDs: rr.placementUUIDs || state.selectedPlacements.map(e => e.value),
              rowLimit: rr.rowLimit || state.limit,
            }
          }

          newState.isRunButtonDisabled = false
          let request = props.pubPrevalByMessageReport.data.Request
          if (!state.filterUpdated && request
            && ((request.campaignUUIDs.length == 0 || (request.campaignUUIDs.length > 0 && state.selectedCampaigns.length == 0)) ||
              (request.placementUUIDs.length == 0 || (request.placementUUIDs.length > 0 && state.selectedPlacements.length == 0)))) {
            let criteria = gc(props.pubPrevalByMessageReport.data.Request)
            newState.selectedLimit = { value: criteria.rowLimit, label: criteria.rowLimit.toString() }
            newState.selectedCampaigns = filterForSelectable(props.campaigns, criteria.campaignUUIDs)
            newState.selectedPlacements = filterForSelectable(props.placements, criteria.placementUUIDs)

            newState = { ...newState, ...criteria }
            newState.startDate = moment(newState.startDate)
            newState.endDate = moment(newState.endDate)
          }
        }
      })
      if (gotHtmlReportResponse) {
        newState.isRunButtonDisabled = false
      }
    }

    {
      let gotCsvReportResponse = false
      props.pubPrevalByDateCsvReport.matchWith({
        PubPrevalByDateCsvReportInitial: () => { },
        LoadingPubPrevalByDateCsvReport: () => { },
        PubPrevalByDateCsvReportError: () => {
          gotCsvReportResponse = true
        },
        PubPrevalByDateCsvReportLoaded: () => {
          gotCsvReportResponse = true
        }
      })
      props.pubPrevalByMessageCsvReport.matchWith({
        PubPrevalByMessageCsvReportInitial: () => { },
        LoadingPubPrevalByMessageCsvReport: () => { },
        PubPrevalByMessageCsvReportError: () => {
          gotCsvReportResponse = true
        },
        PubPrevalByMessageCsvReportLoaded: () => {
          gotCsvReportResponse = true
        }
      })
      if (gotCsvReportResponse) {
        newState.isDownloadButtonDisabled = false
      }
    }
    return newState
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      selectedPlacements: placements,
      filterUpdated: true,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."
    return (
      <section ref={t => (this.contentSection = t)}>
        <div className="content">
          <Modal
            appElement={this.props.parent}
            isOpen={this.props.pubPrevalReport.sessionReport.showSessionPopup}
            onRequestClose={this.handleSessionPopupClose}
            contentLabel="Sessions"
            style={sessionsModalStyles}>
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button onClick={() => this.handleCloseSessionsModal()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              <div>
                {this.props.pubPrevalReport.sessionReport.showSessionPopup && this.renderSessionsModalContent()}
              </div>
            </Card>
          </Modal>
          <Modal
            appElement={this.props.parent}
            isOpen={this.state.showSessionDetailsModal}
            onRequestClose={this.handleSessionPopupClose}
            contentLabel="Session Details"
            style={sessionDetailsModalStyles}>
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button onClick={() => this.handleCloseSessionDetailsModal()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              <div>{this.state.showSessionDetailsModal && this.renderSessionDetailsModalContent()}</div>
            </Card>
          </Modal>
          <Grid>
            <Cell col={12}>
              <Collapsible title="Pub Prevalidations" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={10}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={12}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              selectedCampaigns: campaigns,
                              filterUpdated: true,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={2}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => this.handleDownloadButtonClick()}
                              disabled={this.state.isDownloadButtonDisabled}
                              raised
                              colored
                              ripple>
                              <Icon name="cloud_download" />
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <div>{this.renderByDateCsvReport()}</div>
                            <div>{this.renderByMessageCsvReport()}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>

            <Cell col={12}>
              <Grid>
                {
                  (this.byDateSlug && this.byMessageSlug) ? <Cell col={12}>
                    <a target="_blank" rel="noreferrer"
                      href={window.location.origin + window.location.pathname + `?format=json&report=pub-preval&slug=${this.byDateSlug}&slug2=${this.byMessageSlug}`}>
                      Share Report</a>
                  </Cell> : null
                }
                <Cell col={12}>
                  <div className="report">
                    <h4 className="reportTitle">{"Pub Prevalidation - by Date"}</h4>
                    <div>{this.renderReportByDate()}</div>
                  </div>
                </Cell>
              </Grid>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  <div className="report">
                    <h4 className="reportTitle">{"Pub Prevalidation - by Failure Reasons"}</h4>
                    <div>{this.renderReportByMessage()}</div>
                  </div>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }

  renderReportByDate() {
    return this.props.pubPrevalByDateReport.matchWith({
      PubPrevalByDateReportInitial: () => "Click 'Run' to generate",
      LoadingPubPrevalByDateReport: () => "Loading ...",
      PubPrevalByDateReportError: () => <div style={{ color: "#FF0000" }}>&quot;Failed: can not load&quot;</div>,
      PubPrevalByDateReportLoaded: ({ data }) => {
        this.byDateSlug = data.Request.slug
        let comp = data.Report.Components[0]
        let rowCluster = comp.RowCluster || []

        if (rowCluster.length == 0) {
          return <div>{"No records found"}</div>
        }

        let gridColumnCount = 3 + comp.PivotSettings.PivotDimensionValues.length * 3
        let gridRowCount = 2 + rowCluster.length

        return (
          <AutoSizer disableHeight>
            {({ width }) => (
              <MultiGrid
                fixedColumnCount={2}
                fixedRowCount={2}
                scrollToColumn={0}
                scrollToRow={0}
                cellRenderer={props => <CellRendererByDate cache={this.cellCacheByDate} comp={comp} {...props} />}
                columnWidth={this.cellCacheByDate.columnWidth}
                deferredMeasurementCache={this.cellCacheByDate}
                columnCount={gridColumnCount}
                enableFixedColumnScroll
                enableFixedRowScroll
                height={480}
                rowHeight={30}
                rowCount={gridRowCount}
                style={STYLE}
                styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
                width={width}
                hideTopRightGridScrollbar
                hideBottomLeftGridScrollbar
              />
            )}
          </AutoSizer>
        )
      }
    })
  }

  renderSessionDetailsModalContent() {
    if (this.props.sessionDetails.isLoading) {
      return (
        <Grid>
          <Cell col={12}>Loading session details ...</Cell>
        </Grid>
      )
    }
    return <React.Fragment> <h4>Session:</h4><SessionDetails data={this.props.sessionDetails.data.Data}></SessionDetails></React.Fragment>
  }

  renderSessionsModalContent() {
    if (this.props.pubPrevalReport.sessionReport.isLoadingSessions) {
      return (
        <Grid>
          <Cell col={12}>Loading sessions ...</Cell>
        </Grid>
      )
    }

    let report = this.props.pubPrevalReport.sessionReport.sessions.Report
    let reportCriteria = this.props.pubPrevalReport.sessionReport.criteria
    let campaignsMap = new Map()
    this.props.campaigns.forEach(e => campaignsMap.set(e.UUID, e))
    let placementsMap = new Map()
    this.props.placements.forEach(e => placementsMap.set(e.UUID, e))

    return (
      <Grid>
        <Cell col={6}>
          <h4>Criteria:</h4>
          <div>
            Campaign:
            {reportCriteria.campaignUUIDs.map(e => {
              let campaign = campaignsMap.get(e)
              if (campaign) {
                return `${campaign.name} - ${campaign.shortCode} (${campaign.UUID})`
              } else {
                return e
              }
            })}
          </div>
          <div>
            Placement:
            {reportCriteria.placementUUIDs.map(e => {
              let placement = placementsMap.get(e)
              if (placement) {
                return `${placement.name} - ${placement.legacyID} (${placement.UUID})`
              } else {
                return e
              }
            })}
          </div>
          <div>
            Message:
            {reportCriteria.validationMessages.map(e => {
              return e
            })}
          </div>
        </Cell>
        <Cell col={6}>
          <h4>Stats:</h4>
          <div>{report.Components[0].RowCluster.length} sessions</div>
          {report.Components[0].RowCluster.length >= this.state.reportCriteria.rowLimit && "; more available"}
        </Cell>
        <Cell className="reportColumn" col={12}>
          <table className="report">
            <tr className="th" key={`viewer.sessions.header`}>
              {report.Components[0].Dimensions.map((e, i) => {
                return (
                  <td className="th dimension" key={`viewer.sessions.header.cell.${i}`}>
                    {e.DisplayName}
                  </td>
                )
              })}
            </tr>
            {report.Components[0].RowCluster.map((e, i) => {
              return (
                <tr key={`viewer.sessions.row.${i}`} className="td">
                  {e.RowKeys.map((cell, j) => {
                    var dimensionName = report.Components[0].Dimensions[j].ConceptName
                    if (dimensionName == "session.uuid") {
                      return (
                        <td
                          className="td dimension clickable"
                          key={`viewer.sessions.row.${i}.cell.${j}`}
                          onClick={() => this.handleOpenSessionDetailsClick(cell.DisplayKey)}>
                          {cell.DisplayKey}
                        </td>
                      )
                    }
                    return (
                      <td className="td dimension" key={`viewer.sessions.row.${i}.cell.${j}`}>
                        {cell.DisplayKey}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </table>
        </Cell>
      </Grid>
    )
  }

  renderReportByMessage() {
    return this.props.pubPrevalByMessageReport.matchWith({
      PubPrevalByMessageReportInitial: () => "Click 'Run' to generate",
      LoadingPubPrevalByMessageReport: () => "Loading ...",
      PubPrevalByMessageReportError: () => <div style={{ color: "#FF0000" }}>&quot;Failed: can not load&quot;</div>,
      PubPrevalByMessageReportLoaded: ({ data }) => {
        this.byMessageSlug = data.Request.slug
        let comp = data.Report.Components[0]
        let rowCluster = comp.RowCluster || []

        if (rowCluster.length == 0) {
          return <div>{"No records found"}</div>
        }

        let gridColumnCount = 3 + comp.PivotSettings.PivotDimensionValues.length * 1
        let gridRowCount = 2 + rowCluster.length

        return (
          <div className="report">
            <AutoSizer disableHeight>
              {({ width }) => (
                <MultiGrid
                  fixedColumnCount={2}
                  fixedRowCount={2}
                  scrollToColumn={0}
                  scrollToRow={0}
                  cellRenderer={props => (
                    <CellRendererByMessage
                      openSessionPopup={this.handleOpenSessionPopupDialog}
                      cache={this.cellCacheByMessage}
                      comp={comp}
                      {...props}
                    />
                  )}
                  columnWidth={this.cellCacheByMessage.columnWidth}
                  deferredMeasurementCache={this.cellCacheByMessage}
                  columnCount={gridColumnCount}
                  enableFixedColumnScroll
                  enableFixedRowScroll
                  height={500}
                  rowHeight={30}
                  rowCount={gridRowCount}
                  style={STYLE}
                  styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                  styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                  styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
                  width={width}
                  hideTopRightGridScrollbar
                  hideBottomLeftGridScrollbar
                />
              )}
            </AutoSizer>
          </div>
        )
      }
    })
  }

  renderByDateCsvReport() {
    return this.props.pubPrevalByDateCsvReport.matchWith({
      PubPrevalByDateCsvReportInitial: () => null,
      LoadingPubPrevalByDateCsvReport: () => <div>{"'By date' report ..."}</div>,
      PubPrevalByDateCsvReportError: () => <div style={{ color: "#FF0000" }}>{"'By date' report failed"}</div>,
      PubPrevalByDateCsvReportLoaded: () => null
    })
  }

  renderByMessageCsvReport() {
    return this.props.pubPrevalByMessageCsvReport.matchWith({
      PubPrevalByMessageCsvReportInitial: () => null,
      LoadingPubPrevalByMessageCsvReport: () => <div>{"'By message' report ..."}</div>,
      PubPrevalByMessageCsvReportError: () => <div style={{ color: "#FF0000" }}>{"'By failure' report failed"}</div>,
      PubPrevalByMessageCsvReportLoaded: () => null
    })
  }
}

const PubPrevalReport = connect(mapStateToProps, mapDispatchToProps)(pubPrevalReport)

export default PubPrevalReport
