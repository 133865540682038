import { truIntentThunk, truIntentRejectedThunk, truIntentRejectedRtsThunk } from "../../services/truIntentService"
import { saveState, closedRejectedLeadViewer, closedRejectedRtsViewer } from "../../reducers/truIntentReducer"
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Grid, Cell, Button, Textfield, Icon, Card, CardActions, Switch} from 'react-mdl'
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import NumberFormat from 'react-number-format'
import { filterForSelectable, reportReady } from '../common/func'
import { Collapsible } from "../common/components"
import Select from 'react-select'
import Modal from "react-modal"
import RejectionsReport from './rejectionsReport.js'
import RtsReport from './rtsReport.js'

function mapStateToProps(state) {
  return {
    truIntent: state.truIntent,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadTruIntent: function (criteria, slug) {
      dispatch(truIntentThunk(criteria, slug))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
    openLeadViewerPopup: function (criteria) {
      dispatch(truIntentRejectedThunk(criteria))
    },
    closedLeadViewerPopup: function () {
      dispatch(closedRejectedLeadViewer())
    },
    openRejectedRtsViewerPopup: function (criteria) {
      dispatch(truIntentRejectedRtsThunk(criteria))
    },
    closedRejectedRtsViewerPopup: function () {
      dispatch(closedRejectedRtsViewer())
    },
  }
}

const modalStyles = {
  height: "90%"
}

class truIntent extends React.Component {
  constructor(props) {
    super(props)

    this.timeZone = "America/New_York"

    this.customDataOptions = [
      { value: "", label: "Select Custom Data" },
      { value: "pub_key", label: "PUK KEY" },
    ]

    this.state = {
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      newValues: '',
      newValuesReplace: false,
      page: 1,
      records: [],
      allRowsExpanded: false,
      isLoading: false,
      isCriteriaCollapsed: false,
      selectedCustomFieldName: this.customDataOptions[0],
      selectedCustomFieldValue: "",
      filterUpdated: true,
      showPubKeys: false,
    }

    if (props.truIntent.componentState) {
      this.state = props.truIntent.componentState
    }

    if (props.slug && props.slug.length > 0) {
      let criteria = this.getCriteria()
      this.props.loadTruIntent(criteria, props.slug)
    }
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  componentDidUpdate(prevProps) {
    let update = reportReady(
      prevProps.truIntent.report, this.props.truIntent.report,
      prevProps.campaigns, prevProps.placements,
      this.props.campaigns, this.props.placements)

    if (update) {
      let records = []
      let state = this.state
      if (this.props.truIntent.report && this.props.truIntent.report.records) {
        records = this.props.truIntent.report.records.map((e) => {
          e.rowExpanded = false
          return e
        })
      }
      if (this.props.truIntent.request) {
        let criteria = this.getCriteria(this.props.truIntent.request)
        state.selectedCampaigns = filterForSelectable(this.props.campaigns, criteria.campaignUUIDs)
        state.selectedPlacements = filterForSelectable(this.props.placements, criteria.placementUUIDs)
        state = { ...state, ...criteria }
        state.startDate = moment(state.startDate)
        state.endDate = moment(state.endDate)
        state.records = records
      }
      this.setState(state)
    }
  }

  handleOpenRejectedPopup(campaingUUID, placementUUID, pubKey) {
    let customDataFilter = this.props.truIntent.request.customDataFilter
    if(pubKey){
      customDataFilter = {fieldName: "PUB_KEY", fieldValue:pubKey}
    }

    let campaignUUIDs = []
    if (campaingUUID) {
      campaignUUIDs.push(campaingUUID)
    }
    campaignUUIDs = [...new Set(campaignUUIDs)]

    let placementUUIDs = []
    if (placementUUID) {
      placementUUIDs.push(placementUUID)
    }
    placementUUIDs = [...new Set(placementUUIDs)]

    let criteria = {
      rowLimit: 0,
      timeZone: this.timeZone,
      startDate: this.props.truIntent.request.startDate,
      endDate: this.props.truIntent.request.endDate,
      campaignUUIDs: campaignUUIDs,
      placementUUIDs: placementUUIDs,
      customDataFilter: customDataFilter,
    }

    this.props.openLeadViewerPopup(criteria)
  }

  handleCloseRejectedPopup() {
    this.props.closedLeadViewerPopup()
  }


  handleOpenRejectedRtsPopup(campaingUUID, placementUUID, pubKey) {
    let customDataFilter = this.props.truIntent.request.customDataFilter
    if(pubKey){
      customDataFilter = {fieldName: "PUB_KEY", fieldValue:pubKey}
    }
    
    let campaignUUIDs = []
    if (campaingUUID) {
      campaignUUIDs.push(campaingUUID)
    }
    campaignUUIDs = [...new Set(campaignUUIDs)]

    let placementUUIDs = []
    if (placementUUID) {
      placementUUIDs.push(placementUUID)
    }
    placementUUIDs = [...new Set(placementUUIDs)]

    let criteria = {
      rowLimit: 0,
      timeZone: this.timeZone,
      startDate: this.props.truIntent.request.startDate,
      endDate: this.props.truIntent.request.endDate,
      campaignUUIDs: campaignUUIDs,
      placementUUIDs: placementUUIDs,
      customDataFilter: customDataFilter,
    }

    this.props.openRejectedRtsViewerPopup(criteria)
  }

  handleCloseRejectedRtsPopup() {
    this.props.closedRejectedRtsViewerPopup()
  }


  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      filterUpdated: true,
      startDate: startDate,
      endDate: endDate
    })
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleRunButtonClick() {
    let criteria = this.getCriteria()
    this.props.loadTruIntent(criteria, this.state.filterUpdated ? "" : this.props.truIntent.slug)
    this.setState({
      filterUpdated: false,
      isCriteriaCollapsed: true,
    })
  }

  getCriteria(request) {
    request = request || {}
    
    let customDataFilter = null

    if(this.state.selectedCustomFieldName.value != ''){
      customDataFilter = {fieldName: this.state.selectedCustomFieldName.value, fieldValue: this.state.selectedCustomFieldValue}
    }
    
    return {
      timeZone: request.timeZone || this.timeZone,
      startDate: request.startDate || this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: request.endDate || this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: request.campaignUUIDs || this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: request.placementUUIDs || this.state.selectedPlacements.map(e => e.value),
      customDataFilter: customDataFilter,
      showPubKeys: this.state.showPubKeys,
    }
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true,
    })
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      filterUpdated: true,
      selectedPlacements: placements,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  renderRejectionsModalContent() {
    if (this.props.truIntent.rejectedReport.isLoading) {
      return (
        <Grid>
          <Cell col={12}>Loading lead viewer ...</Cell>
        </Grid>
      )
    }

    let report = this.props.truIntent.rejectedReport.report
    let criteria = this.props.truIntent.rejectedReport.criteria

    let campaignsMap = new Map()
    this.props.campaigns.forEach(e => campaignsMap.set(e.UUID, e))
    let placementsMap = new Map()
    this.props.placements.forEach(e => placementsMap.set(e.UUID, e))

    return (
      <Grid>
        <Cell col={12}>
          <Card>
            <h4>Criteria:</h4>
            <div>Dates: {criteria.startDate} - {criteria.endDate}</div>
            <div>
              Campaigns:
              {criteria.campaignUUIDs.map(e => {
                let campaign = campaignsMap.get(e)
                if (campaign) {
                  return `${campaign.name} - ${campaign.shortCode} (${campaign.UUID}), `
                } else {
                  return e
                }
              })}
            </div>
            <div>
              Placements:
              {criteria.placementUUIDs.map(e => {
                let placement = placementsMap.get(e)
                if (placement) {
                  return `${placement.name} - ${placement.legacyID} (${placement.UUID}), `
                } else {
                  return e
                }
              })}
            </div>
            <div>
              Custom Data Filter:
              {criteria.customDataFilter && criteria.customDataFilter.fieldName.length > 0 && `${criteria.customDataFilter.fieldName} = ${criteria.customDataFilter.fieldValue} `}
              {!criteria.customDataFilter && 'None'}
            </div>
          </Card>
        </Cell>
        <Cell className="reportColumn" col={12}>
          <RejectionsReport report={report}></RejectionsReport>
        </Cell>
      </Grid>
    )
  }

  renderRejectedRtsModalContent() {
    if (this.props.truIntent.rejectedRtsReport.isLoading) {
      return (
        <Grid>
          <Cell col={12}>Loading lead viewer ...</Cell>
        </Grid>
      )
    }

    let report = this.props.truIntent.rejectedRtsReport.report
    let criteria = this.props.truIntent.rejectedRtsReport.criteria

    let campaignsMap = new Map()
    this.props.campaigns.forEach(e => campaignsMap.set(e.UUID, e))
    let placementsMap = new Map()
    this.props.placements.forEach(e => placementsMap.set(e.UUID, e))

    return (
      <Grid>
        <Cell col={12}>
          <Card>
            <h4>Criteria:</h4>
            <div>Dates: {criteria.startDate} - {criteria.endDate}</div>
            <div>
              Campaigns:
              {criteria.campaignUUIDs.map(e => {
                let campaign = campaignsMap.get(e)
                if (campaign) {
                  return `${campaign.name} - ${campaign.shortCode} (${campaign.UUID}), `
                } else {
                  return e
                }
              })}
            </div>
            <div>
              Placements:
              {criteria.placementUUIDs.map(e => {
                let placement = placementsMap.get(e)
                if (placement) {
                  return `${placement.name} - ${placement.legacyID} (${placement.UUID}), `
                } else {
                  return e
                }
              })}
            </div>
            <div>
              Custom Data Filter:
              {criteria.customDataFilter && criteria.customDataFilter.fieldName.length > 0 && `${criteria.customDataFilter.fieldName} = ${criteria.customDataFilter.fieldValue} `}
              {!criteria.customDataFilter && 'None'}
            </div>
          </Card>
        </Cell>
        <Cell className="reportColumn" col={12}>
          <RtsReport report={report}></RtsReport>
        </Cell>
      </Grid>
    )
  }

  render() {
    if (this.state.isLoading) return "loading ..."

    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
        <Modal
            appElement={this.props.parent}
            isOpen={this.props.truIntent.rejectedReport.showPopup}
            onRequestClose={this.handleSessionPopupClose}
            contentLabel="Lead Viewer"
            style={modalStyles}
          >
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button
                  style={{ position: "fixed", backgroundColor: "white", right: "5%" }}
                  onClick={() => this.handleCloseRejectedPopup()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              <div>
                {this.props.truIntent.rejectedReport.showPopup && this.renderRejectionsModalContent()}
              </div>
            </Card>
          </Modal>
          <Modal
            appElement={this.props.parent}
            isOpen={this.props.truIntent.rejectedRtsReport.showPopup}
            onRequestClose={this.handleSessionPopupClose}
            contentLabel="Lead Viewer"
            style={modalStyles}
          >
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button
                  style={{ position: "fixed", backgroundColor: "white", right: "5%" }}
                  onClick={() => this.handleCloseRejectedRtsPopup()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              <div>
                {this.props.truIntent.rejectedRtsReport.showPopup && this.renderRejectedRtsModalContent()}
              </div>
            </Card>
          </Modal>
          <Grid>
            <Cell col={12}>
              <Collapsible title="Tru Intent" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={11}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={12}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              filterUpdated: true,
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Custom data</label>
                        <Grid>
                          <Cell col={5}>
                            <Select
                              isMulti={false}
                              value={this.state.selectedCustomFieldName}
                              onChange={(s) => {
                                this.setState({
                                  filterUpdated: true,
                                  selectedCustomFieldName: s
                                })
                              }}
                              options={this.customDataOptions}
                            />
                          </Cell>
                          <Cell col={2} style={{textAlignVertical: 'bottom', display:"flex"}}><div>contains</div></Cell>
                          <Cell col={5}>
                            <Textfield
                              floatingLabel
                              label="Text Filter"
                              onChange={e => {
                                this.setState({
                                  filterUpdated: true,
                                  selectedCustomFieldValue: e.target.value,
                                })
                              }}
                              value={this.state.selectedCustomFieldValue || ""}
                            />
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell col={6}>
                        <Switch
                          onChange={() => {
                            this.setState({
                              filterUpdated: true,
                              showPubKeys: !this.state.showPubKeys
                            })
                          }}
                          checked={this.state.showPubKeys}
                          >Show Pub Key</Switch>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  {this.renderReport()}
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }


  handleRowToggleClick(i) {
    const records = this.state.records
    records[i].rowExpanded = !records[i].rowExpanded
    this.setState({
      records: records,
    })
  }

  handleAllRowsToggleClick() {
    let flag = !this.state.allRowsExpanded
    const records = this.state.records.map((e) => {
      e.rowExpanded = flag
      return e
    })
    this.setState({
      records: records,
      allRowsExpanded: flag,
    })
  }

  renderReport() {
    if (this.props.truIntent.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.truIntent.error != null) {
      return <div>Error: {this.props.truIntent.error.message}</div>
    }
    if (this.props.truIntent.report == null) {
      return <div></div>
    }
    return <div className="report">
      <div>{this.renderReportByPlacement()}</div>
      <br></br>
      <div>{this.renderReportByCampaign()}</div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  }


  renderReportByPlacement() {
    let section = this.props.truIntent.report.placementSection

    section.records.sort((f1, f2) => {
      if (f1.placementType < f2.placementType) return 1
      if (f1.placementType > f2.placementType) return -1
      if (f1.placementName < f2.placementName) return -1
      if (f1.placementName > f2.placementName) return 1
      return 0
    })

    return (
      <React.Fragment>
        <Grid style={{ "width": "100%" }}>
          <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.truIntent.header1`}>
                  <th className="th dimension" colSpan={11} style={{ textAlign: "center" }}>{section.title}</th>
                </tr>
                <tr className="th" key={`viewer.truIntent.header2`}>
                  <th className="th dimension">Placement<br></br>Type</th>
                  <th className="th dimension">Placement</th>
                  <th className="th dimension">Placement Code</th>
                  <th className="th dimension">Attempted Leads</th>
                  <th className="th dimension">Leads</th>
                  <th className="th dimension">Rejected Leads Live</th>
                  <th className="th dimension">Rejected Rate Live</th>
                  <th className="th dimension">RT Reject</th>
                  <th className="th dimension">RT Reject %</th>
                  <th className="th dimension">Estimated Revenue</th>
                </tr>
              </thead>
              <tbody>
                {section.records.length == 0 && <tr className="td" key={`viewer.truIntent.row1`}>
                  <th className="td metric" colSpan={11} style={{ textAlign: "center" }}>No records found</th>
                </tr>}
                {section.records.map((e, i) => {

                  return (
                    <React.Fragment key={`viewer.truIntent.wrapper.${i}`}>
                      <tr className={`tr ${this.props.truIntent.request.showPubKeys ? "oddrow":""}`} key={`viewer.truIntent.${i}`}>
                        <td className="td dimension">
                          {e.placementType}
                        </td>
                        <td className="td dimension">
                          {e.placementLegacyCode} - {e.placementName}
                        </td>
                        <td className="td metric">
                          {e.placementLegacyID}
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.attemptedCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.acceptedCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(null, e.placementUUID)}>
                            <NumberFormat value={e.rejectedCount} displayType={'text'} thousandSeparator={true} />
                          </span>
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(null, e.placementUUID)}>
                            <NumberFormat value={e.rejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                          </span>
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(null, e.placementUUID)}>
                            <NumberFormat value={e.rtRejectedCount} displayType={'text'} thousandSeparator={true} />
                          </span>
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(null, e.placementUUID)}>
                            <NumberFormat value={e.rtRejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                          </span>
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                        </td>
                      </tr>
                      {this.props.truIntent.request.showPubKeys && e.records.filter(subrec=>subrec.pubKey.length > 0).length > 0 && e.records.map((subrec, j) => {
                        return <tr className="td" key={`viewer.truIntent.${i}.${j}`}>
                          <td className="td metric" colSpan={3}>
                            {subrec.pubKey.length == 0 ? <i>[EMPTY]</i>:subrec.pubKey }
                          </td>  
                          <td className="td metric">
                            <NumberFormat value={subrec.attemptedCount} displayType={'text'} thousandSeparator={true} />
                          </td>
                          <td className="td metric">
                            <NumberFormat value={subrec.acceptedCount} displayType={'text'} thousandSeparator={true} />
                          </td>
                          <td className="td metric">
                            <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(null, subrec.placementUUID, subrec.pubKey)}>
                              <NumberFormat value={subrec.rejectedCount} displayType={'text'} thousandSeparator={true} />
                            </span>
                          </td>
                          <td className="td metric">
                            <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(null, subrec.placementUUID, subrec.pubKey)}>
                              <NumberFormat value={subrec.rejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                            </span>
                          </td>
                          <td className="td metric">
                            <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(null, subrec.placementUUID, subrec.pubKey)}>
                              <NumberFormat value={subrec.rtRejectedCount} displayType={'text'} thousandSeparator={true} />
                            </span>
                          </td>
                          <td className="td metric">
                            <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(null, subrec.placementUUID, subrec.pubKey)}>
                              <NumberFormat value={subrec.rtRejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                            </span>
                          </td>
                          <td className="td metric">
                            <NumberFormat value={subrec.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        </tr>
                      })}
                    </React.Fragment>
                  )
                })}
                <tr className="td" key={`viewer.truIntent.byCampaign.totals`}>
                  <td className="td dimension" style={{ textAlign: "right" }} colSpan={3}>
                    Totals:
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.attemptedCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.acceptedCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(null, null)}>
                      <NumberFormat value={section.totals.rejectedCount} displayType={'text'} thousandSeparator={true} />
                    </span>
                  </td>
                  <td className="td metric">
                    <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(null, null)}>
                      <NumberFormat value={section.totals.rejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                    </span>
                  </td>
                  <td className="td metric">
                    <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(null, null)}>
                      <NumberFormat value={section.totals.rtRejectedCount} displayType={'text'} thousandSeparator={true} />
                    </span>
                  </td>
                  <td className="td metric">
                    <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(null, null)}>
                      <NumberFormat value={section.totals.rtRejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                    </span>
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }

  renderReportByCampaign() {
    let section = this.props.truIntent.report.campaignSection

    section.records.sort((f1, f2) => {
      if (f1.failedLeadPercentage < f2.failedLeadPercentage) return 1
      if (f1.failedLeadPercentage > f2.failedLeadPercentage) return -1
      return 0
    })

    return (
      <React.Fragment>
        <Grid style={{ "width": "100%" }}>
          {this.props.truIntent.slug ? <Cell col={12}>
            <a target="_blank" rel="noreferrer"
              href={window.location.origin + window.location.pathname + `?format=json&report=adq-performance&slug=${this.props.truIntent.slug}`}>
              Share Report</a>
          </Cell> : null}
          <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.truIntent.header1`}>
                  <th className="th dimension" colSpan={9} style={{ textAlign: "center" }}>{section.title}</th>
                </tr>
                <tr className="th" key={`viewer.truIntent.header2`}>
                  <th className="th dimension">Campaign</th>
                  <th className="th dimension">Rate</th>
                  <th className="th dimension">Attempted Leads</th>
                  <th className="th dimension">Leads</th>
                  <th className="th dimension">Rejected Leads Live</th>
                  <th className="th dimension">Rejected Rate Live</th>
                  <th className="th dimension">RT Reject</th>
                  <th className="th dimension">RT Reject %</th>
                  <th className="th dimension">Estimated Revenue</th>
                </tr>
              </thead>
              <tbody>
                {section.records.length == 0 && <tr className="td" key={`viewer.truIntent.row1`}>
                  <th className="td metric" colSpan={9} style={{ textAlign: "center" }}>No records found</th>
                </tr>}
                {section.records.map((e, i) => {
                  return (
                    <React.Fragment key={`viewer.truIntent.wrapper.${i}`}>
                      <tr className="td" key={`viewer.truIntent.${i}`}>
                        <td className="td dimension">
                          {e.campaignShortCode} - {e.campaignName}
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.campaignRate} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.attemptedCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.acceptedCount} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(e.campaignUUID, null)}>
                            <NumberFormat value={e.rejectedCount} displayType={'text'} thousandSeparator={true} />
                          </span>
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(e.campaignUUID, null)}>
                            <NumberFormat value={e.rejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                          </span>
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(e.campaignUUID, null)}>
                            <NumberFormat value={e.rtRejectedCount} displayType={'text'} thousandSeparator={true} />
                          </span>
                        </td>
                        <td className="td metric">
                          <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(e.campaignUUID, null)}>
                            <NumberFormat value={e.rtRejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                          </span>
                        </td>
                        <td className="td metric">
                          <NumberFormat value={e.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
                <tr className="td" key={`viewer.truIntent.byCampaign.totals`}>
                  <td className="td dimension" style={{ textAlign: "right" }} colSpan={2}>
                    Totals:
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.attemptedCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.acceptedCount} displayType={'text'} thousandSeparator={true} />
                  </td>
                  <td className="td metric">
                    <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(null, null)}>
                      <NumberFormat value={section.totals.rejectedCount} displayType={'text'} thousandSeparator={true} />
                    </span>
                  </td>
                  <td className="td metric">
                    <span className={"content clickable"} onClick={() => this.handleOpenRejectedPopup(null, null)}>
                      <NumberFormat value={section.totals.rejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                    </span>
                  </td>
                  <td className="td metric">
                    <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(null, null)}>
                      <NumberFormat value={section.totals.rtRejectedCount} displayType={'text'} thousandSeparator={true} />
                    </span>
                  </td>
                  <td className="td metric">
                    <span className={"content clickable"} onClick={() => this.handleOpenRejectedRtsPopup(null, null)}>
                      <NumberFormat value={section.totals.rtRejectedPercentage * 100} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                    </span>
                  </td>
                  <td className="td metric">
                    <NumberFormat value={section.totals.estimatedRevenue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }
}


const TruIntent = connect(mapStateToProps, mapDispatchToProps)(truIntent)

export default TruIntent
