const { union, derivations } = require('folktale/adt/union')

const GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT = 'GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT'
const GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT_FAILURE = 'GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT_FAILURE'
const GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT_SUCCESS = 'GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT_SUCCESS'

export const generatePubPrevalByMessageCsvReport = () =>
    ({ type: GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT })

export const generatePubPrevalByMessageCsvReportSuccess = data =>
    ({ type: GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT_SUCCESS, data })

export const generatePubPrevalByMessageCsvReportFailure = error =>
    ({ type: GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT_FAILURE, error })

export const PubPrevalByMessageCsvReportState = union('PubPrevalByMessageCsvReportState', {
    PubPrevalByMessageCsvReportInitial() { return {} }
    , LoadingPubPrevalByMessageCsvReport() { return {} }
    , PubPrevalByMessageCsvReportLoaded(data) { return { data } }
    , PubPrevalByMessageCsvReportError(error) { return { error } }
  })
.derive(derivations.debugRepresentation)
export const { 
    PubPrevalByMessageCsvReportInitial,
    LoadingPubPrevalByMessageCsvReport, 
    PubPrevalByMessageCsvReportLoaded, 
    PubPrevalByMessageCsvReportError 
} = PubPrevalByMessageCsvReportState

export const pubPrevalByMessageCsvReport = (state=PubPrevalByMessageCsvReportInitial(), action) => {
    switch(action.type) {
        case GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT:
            return LoadingPubPrevalByMessageCsvReport()
        case GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT_FAILURE:
            return PubPrevalByMessageCsvReportError(action.error)
        case GENERATE_PUB_PREVAL_BY_MESSAGE_CSV_REPORT_SUCCESS:
            return PubPrevalByMessageCsvReportLoaded(action.data)
        default:
            return state
    }
}
