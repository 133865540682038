import React from 'react'
import Select from 'react-select'
import {Grid, Cell, Textfield, Icon, Button, Checkbox, Tooltip} from 'react-mdl'


export default class Answer extends React.Component {
  constructor(props) {
    super(props)
  }

  handleAnswerSave(){
    if (!this.props.answer.value) {
      this.props.answer.value = this.props.answer.display
    }
    this.props.save()
  }

  safeValueLookup(arr, defaultValue) {
    arr = arr || []
    if (arr.length <= 0) {
      return defaultValue
    }
    let obj = this.props.questions.find(q => q.UUID == arr[0]) || defaultValue
    return obj
  }

  renderFollowUpDropdown(){
    const question = this.props.question || {UUID: ''}
    const defaultFollowUpQuestionOption = { label: 'None', value: '' }
    let followUpQuestionOptions = [defaultFollowUpQuestionOption] //default
    const selectedFollowUpOption = this.safeValueLookup(this.props.answer.correctPathQuestions, defaultFollowUpQuestionOption)
    followUpQuestionOptions = followUpQuestionOptions.concat(this.props.questions
      .filter(q => q.UUID != question.UUID)
      .map(q => {
        return { label: q.value, value: q }
      }))
    return (<Select
      placeholder={"Follow Up Question"}
      isMulti={false}
      value={selectedFollowUpOption.value ?          { 
            label: selectedFollowUpOption.value, 
            value: selectedFollowUpOption.value,
          } : defaultFollowUpQuestionOption}
      onChange={this.props.handleAnswerFollowUpChange}
      options={followUpQuestionOptions}
    />)
  }

  render() {
    return <Grid style={{width : "100%"}}>
      <Cell col={2}>
        <Textfield
          value={this.props.answer.display || ""}
          onChange={this.props.handleAnswerDisplay}
          label="Display Value"
          floatingLabel
          style={{ width: '200px' }}
        />
      </Cell>
      <Cell col={2}>
        <Checkbox
          ripple
          label="Is Disqualifying?"
          checked={this.props.answer.isDisqualifying}
          onChange={this.props.handleAnswerIsCorrentChange}
        />
        <Checkbox
          ripple
          label="Default Answer?"
          checked={this.props.answer.isPreselected}
          onChange={this.props.handleAnswerIsPreselected}
        />
        <Checkbox
          ripple
          label="Is Hidden?"
          checked={this.props.answer.isHidden}
          onChange={this.props.handleAnswerIsHidden}
        />
      </Cell>
      <Cell col={2}>
        <Textfield
          value={this.props.answer.value || ""}
          onChange={this.props.handleAnswerPlacementChange}
          label="Replacement Value"
          floatingLabel
          style={{ width: '200px' }}
        />
      </Cell>
      <Cell col={4}>
        {this.renderFollowUpDropdown()}
      </Cell>
      <Cell col={1}>
        <Tooltip label="Commit">
          <Button colored
            onClick={ () => this.handleAnswerSave() }
            className="mdl-button--icon"
          >
            <Icon name="check" />
          </Button>
        </Tooltip>
        <Tooltip label="Remove">
          <Button
            disabled={!(this.props.answer.UUID)}
            onClick={this.props.delete}
            className="mdl-button--icon mdl-button--accent"
          >
            <Icon name="remove" />
          </Button>
        </Tooltip>
        <Tooltip label="Clear">
          <Button
            onClick={this.props.close}
            className="mdl-button--icon"
          >
            <Icon name="clear" />
          </Button>
        </Tooltip>
      </Cell>
    </Grid>
  }
}
