import React from 'react'
import Select from 'react-select'
import {Grid, Cell, Card, CardActions, CardTitle, Textfield, Icon, Button, Checkbox, CardMenu} from 'react-mdl'

const InputType = 0
const PhoneType = 6
const AddressType = 7
const TextAreaType = 8

const commentStyle = {
  color:"#666",
  paddingBotton: "5px",
  fontSize: "80%",
}

export default class Question extends React.Component {
  constructor(props) {
    super(props)
    this.questionType = [
      'Input',
      'Multiple Choice',
      'Date',
      'Password',
      'Radio',
      'Check',
      'Phone',
      'Address',
      'TextArea',
      'MultiSelect'
    ]
    this.state = {regexpTestValue: "", regexpValidity: true}
  }

  getValidators(type) {
    return [{ name: "None", value: -1 }].concat(this.props.validators.filter(v => {
      if (!this.props.question) {
        return false
      }
      switch (type) {
        case TextAreaType:
          return v.name == 'bad-words'
        case InputType:
          return v.name == 'email-validation' || v.name == 'bad-words'
        case PhoneType:
          return v.name == 'phone-verification'
        case AddressType:
          return v.name == 'address-verification'
        default:
          return false
      }
    }))
  }

  render() {
    return <Card shadow={2} style={{ marginTop: "20px", overflow: "visible" }}>
      <CardActions>
        <Grid>
           <CardTitle>{this.props.title}</CardTitle>
           <CardMenu>
            <Button colored raised onClick={this.props.save} disabled={!this.props.isDirty}>
              <Icon name="save" />
            </Button>
            <Button disabled={!(this.props.question.UUID)} accent raised onClick={this.props.delete}>
              <Icon name="delete" />
            </Button>
            <Button onClick={this.props.close}>
              <Icon name="close" />
            </Button>
           </CardMenu>
           <Grid style={{ width: '100%' }}>
            <Cell col={12}><div style={commentStyle}>{this.props.question.UUID}</div></Cell>
            <Cell col={3} style={{display: "flex", height: "auto", alignItems: "flex-end"}}>
              <Textfield
                value={this.props.question.value || ''}
                onChange={this.props.handleQuestionValueChange}
                label="Question Value"
                floatingLabel
              />
            </Cell>
            <Cell col={3}>
              <p>UI Type</p>
              <Select
                placeholder={"Question Type"}
                isMulti={false}
                value={this.questionType[this.props.question.type] ? { label: this.questionType[this.props.question.type], value: this.props.question.type } : null}
                onChange={this.props.handleQuestionTypeChange}
                options={this.questionType.map((e, idx) => {
                  return { label: e, value: idx }
                })}
              />
            </Cell>
            {this.props.question.type !== InputType ? null : <Cell col={3}>
                <p>Regexp Validation</p>
                <Checkbox
                ripple
                label="Apply Regexp"
                checked={this.props.question.regexpUsed || false}
                onChange={this.props.handleRegexpUsedChange}
              />
              <Textfield
                value={this.props.question.regexp || ''}
                onChange={this.props.handleRegexpValueChange}
                label="Regexp"
                floatingLabel
              />
              <Textfield
                value={this.state.regexpTestValue || ""}
                onChange={e => {
                  this.setState({regexpTestValue: e.target.value})
                }}
                // eslint-disable-next-line no-useless-escape
                pattern={this.props.question.regexp || "[\s\S]*"}
                error="Input does not match supplied regular expression"
                label="Test Value"
                floatingLabel
              />
              </Cell>}

            {this.getValidators().length <= 0 ? <div /> :
              <Cell col={3}>
                <p>Validation Service</p>
                <Select
                  placeholder={"Validation Service"}
                  isDisabled={!this.props.question.isAnswerRequired}
                  isMulti={false}
                  value={this.props.question.validationService ? { label: this.props.question.validationService, value: this.props.question.validationService } : null}
                  onChange={this.props.handleQuestionValidationChange}
                  options={this.getValidators(this.props.question.type).map((e) => {
                    return { label: e.name, value: e.name }
                  })}
                />
              </Cell>}
            <Cell col={2} style={{display: "flex", justifyContent: "space-between", height: "auto", alignItems: "flex-end"}}>
                <Checkbox
                  ripple
                  label="Is Required?"
                  checked={this.props.question.isAnswerRequired}
                  onChange={this.props.handleQuestionIsAnswerRequiredChange}
                />
              <Checkbox
                ripple
                label="Is Hidden?"
                checked={this.props.question.isHidden || false}
                onChange={this.props.handleQuestionIsHiddenChange}
              />
            </Cell>
            <Cell col={1} style={{display: "flex", justifyContent: "center", height: "auto", alignItems: "flex-end"}}>
              <Button
                style={{float: "right"}}
                onClick={ () => this.props.addAnswer() }
                accent>
                <Icon name="add_circle" />Answers
              </Button>
            </Cell>
           </Grid>
        </Grid>
      </CardActions>
      {this.props.children}
    </Card>
  }
}
