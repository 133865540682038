import { setCrud } from "../../services"
import FilterList from "../filterList"
import React from "react"
import { connect } from "react-redux"
import { activeDates } from "../../utils"
import moment from "moment"
import { Card, CardMenu, CardTitle, Tooltip, IconButton, CardActions, Button, Icon } from "react-mdl"
import CampaignEditor from "./campaignEditor.js"

function mapStateToProps(state) {
  return {
    campaign: state.campaign,
    campaigns: state.campaignData.records,
    campaignMap: state.campaignData.recordsMap,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setCrud: function (crud) {
      dispatch(setCrud(crud))
    },
  }
}

const filterByActive = item => {
  return (
    !item.isArchived &&
    !item.isPaused &&
    activeDates(item)
  )
}
const filterByNotArchived = item => {
  return item.isArchived
}
const filterByIPV6Allowed = item => {
  return item.ipv6Allowed
}
const filterPreping = item => {
  return item.prepingConfiguration && item.prepingConfiguration.requestUrl && (item.endDate == 0 || item.endDate > parseInt(moment.now() / 1000))
}

class campaignManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      campaignUUID: null,
      isVisibleCampaignList: true,
    }

    this.toggleList = this.toggleList.bind(this)

  }

  setCrudHandler(crudOptions) {
    this.props.setCrud(crudOptions)
  }

  toggleList() {
    this.setState({
      isVisibleCampaignList: !this.state.isVisibleCampaignList
    })
  }

  campaignSelected(campaignUUID, e) {
    if (e.button === 1) {
      window.open("/campaignmanager/" + campaignUUID.UUID, '_blank').focus()
      return
    }
    this.setState({
      campaignUUID: campaignUUID,
    })
  }

  isCampaignSelected() {
    return "UUID" in this.props.campaign || "leadCounts" in this.props.campaign
  }

  getIcon(campaign) {
    let c = this.props.campaignMap[campaign.UUID]
    if (!c) {
      return { msg: "?", icon: "question" }
    }
    return c.isArchived
      ? { msg: "Archived", icon: "archive" }
      : c.isPaused
        ? { msg: "Paused", icon: "pause_circle_outline" }
        : !filterByActive(c)
          ? { msg: "Inactive", icon: "highlight_off" }
          : { msg: "Active", icon: "donut_large" }
  }

  render() {
    let left = "-400px"
    if (this.state.isVisibleCampaignList) {
      left = "0px"
    }
    const lookupList = this.props.campaigns.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    return <React.Fragment>
      <Card style={{ width: "450px", position: "fixed", zIndex: 100001, left: left }} shadow={2}>
        <CardMenu>
          {this.state.isVisibleCampaignList ?
            <Tooltip label="Close">
              <Button className="close-campaign-list-button" onClick={() => this.toggleList()}>
                <Icon name="arrow_back">Close</Icon>
              </Button>
            </Tooltip> :
            (<Tooltip label="Open">
              <IconButton className="open-campaign-list-button" name="arrow_forward" onClick={() => this.toggleList()} />
            </Tooltip>)}
        </CardMenu>
        <CardTitle>Campaign List</CardTitle>
        <CardActions border>
          <FilterList
            label="Campaign list..."
            selected={this.props.campaign?.UUID}
            additionalFieldList={["UUID", "shortCode"]}
            title="Campaigns"
            selectItem={(c, e) => this.campaignSelected(c, e)}
            items={lookupList}
            checkboxFilters={[filterByActive, filterByNotArchived, filterByIPV6Allowed, filterPreping]}
            checkboxFilterLabels={["Is Active", "Is Archived", "IPV6 Allowed", "Preping"]}
            checkboxFilterDefaults={[false, false, false, false]}
            getIcon={c => {
              return this.getIcon(c)
            }}
          />
        </CardActions>
      </Card>
      <CampaignEditor {...this.props} campaignUUID={this.state.campaignUUID} toggleList={this.toggleList} setCrudHandler={(crudOptions) => this.setCrudHandler(crudOptions)}></CampaignEditor>
    </React.Fragment>
  }
}

const CampaignManager = connect(mapStateToProps, mapDispatchToProps)(campaignManager)

export default CampaignManager
