import {
  generateSingleValidationsReportThunk,
  fetchSingleValidationsCsvReport
} from "../../services/singleValidationsReport"

import {
  generateSingleValidationsCsvReport,
  generateSingleValidationsCsvReportSuccess,
  generateSingleValidationsCsvReportFailure
} from "../../reducers/singleValidationsCsvReportReducer"

import { saveState } from "../../reducers/singleValidationsReportV2Reducer"

import { closedSessionPopup } from "../../reducers/singleValidationsReportV2Reducer"
import { loadSessionsThunk } from "../../services/singleValidationsSessionService"
import { loadSessionDetailsThunk } from "../../services/sessionDetailsService"

import React from "react"
import { connect } from "react-redux"
import Modal from "react-modal"
import moment from "moment"
import { Grid, Cell, Button, Icon, Card, CardText, CardTitle, CardActions, RadioGroup, Radio } from "react-mdl"
import { AutoSizer, CellMeasurerCache, MultiGrid } from "react-virtualized"
import CellRendererByMessage from "./CellRendererByMessage.js"
import CellRendererByMessageByDate from "./CellRendererByMessageByDate.js"
import fileDownload from "js-file-download"
import SessionDetails from "../session-details"
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import { ValidatorSelector } from "../validation-selector/validation-selector"
import { Collapsible } from "../common/components"
import { filterForSelectable } from '../common/func'

function mapStateToProps(state) {
  return {
    sessionDetails: state.sessionDetails,
    validators: state.validators,
    singleValidationsReport: state.singleValidationsReport,
    singleValidationsReportV2: state.singleValidationsReportV2,
    singleValidationsCsvReport: state.singleValidationsCsvReport,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    generateSingleValidationsReport: function (criteria, slug) {
      dispatch(generateSingleValidationsReportThunk(criteria, slug || ""))
    },
    startDownloadingCsvReport: function () {
      dispatch(generateSingleValidationsCsvReport())
    },
    downloadedCsvReport: function () {
      dispatch(generateSingleValidationsCsvReportSuccess())
    },
    failedToDownloadCsvReport: function (err) {
      dispatch(generateSingleValidationsCsvReportFailure(err))
    },
    openSessionPopupDialog: function (criteria) {
      dispatch(loadSessionsThunk(criteria))
    },
    closeSessionPopupDialog: function () {
      dispatch(closedSessionPopup())
    },
    loadSessionDetails: function (criteria) {
      dispatch(loadSessionDetailsThunk(criteria))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
  }
}

const sessionsModalStyles = {
  content: {}
}

const sessionDetailsModalStyles = {
  content: {}
}

const STYLE = {
  border: "1px solid #ddd"
}
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: "2px solid #aaa",
  backgroundColor: "#f7f7f7"
}
const STYLE_TOP_LEFT_GRID = {
  borderBottom: "2px solid #aaa",
  borderRight: "2px solid #aaa",
  fontWeight: "bold"
}
const STYLE_TOP_RIGHT_GRID = {
  borderBottom: "2px solid #aaa",
  fontWeight: "bold"
}

class singleValidationReport extends React.Component {
  constructor(props) {
    super(props)

    this.sessionPageSize = 100
    this.rowLimit = 5000

    this.cellCacheByMessage = new CellMeasurerCache({
      defaultHeight: 30,
      minWidth: 80,
      maxWidth: 300,
      defaultWidth: 80,
      //fixedWidth: true,
      fixedHeight: true
    })

    this.cellCacheByDate = new CellMeasurerCache({
      defaultHeight: 60,
      minWidth: 80,
      maxWidth: 600,
      defaultWidth: 400,
      fixedHeight: true
    })

    this.state = {
      currentReportByMessageState: null,
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      startDate: moment().startOf("day"),
      endDate: moment(),
      selectedValidationType: "",
      groupByDate: false,
      newValues: "",
      limit: 0,
      timeZone: "America/New_York",
      newValuesReplace: false,
      isLoading: false,
      reportCriteria: null, //criteria that was used to generate the currently displayed report
      sessionPage: 1,
      serviceNames: [],
      isCriteriaCollapsed: false,
    }

    if (props.singleValidationsReportV2.singleValidationsReport.componentState) {
      this.state = props.singleValidationsReportV2.singleValidationsReport.componentState
    }

    if (props.slug && props.slug.length > 0) {
      let criteria = this.getCriteria()
      this.props.generateSingleValidationsReport(criteria, props.slug)
    }
    this.handleCloseSessionsModal = this.handleCloseSessionsModal.bind(this)
    this.handleOpenSessionPopupDialog = this.handleOpenSessionPopupDialog.bind(this)
    this.handleOpenSessionDetailsClick = this.handleOpenSessionDetailsClick.bind(this)
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      filterUpdated: true,
      startDate: startDate,
      endDate: endDate
    })
  }

  handleRunButtonClick() {
    this.disableRunButton()
    let criteria = this.getCriteria()
    this.props.generateSingleValidationsReport(criteria, this.state.filterUpdated ? "" : this.slug)
    this.setState({
      reportCriteria: criteria,
      filterUpdated: false,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  handleDownloadButtonClick() {
    this.props.startDownloadingCsvReport()
    this.disableDownloadButton()
    let criteria = this.getCriteria()
    fetchSingleValidationsCsvReport(criteria)
      .then(blob => {
        this.props.downloadedCsvReport()
        fileDownload(new Blob([blob]), "report.zip")
      })
      .catch(e => {
        this.props.failedToDownloadCsvReport(new Error(`Getting single validations CSV report - ${e.statusText}`))
      })
  }

  handleOpenSessionPopupDialog(serviceName, validationMessage) {
    let criteria = {
      rowLimit: this.sessionPageSize,
      rowOffset: 0,
      timeZone: this.state.reportCriteria.timeZone,
      startDate: this.state.reportCriteria.startDate,
      endDate: this.state.reportCriteria.endDate,
      campaignUUIDs: this.state.reportCriteria.campaignUUIDs || [],
      placementUUIDs: this.state.reportCriteria.placementUUIDs,
      serviceNames: [serviceName],
      validationMessages: [validationMessage],
      validationType: this.state.reportCriteria.validationType,
    }

    this.props.openSessionPopupDialog(criteria)
    this.setState({
      sessionPage: 1,
    })
  }

  handleCloseSessionsModal() {
    this.props.closeSessionPopupDialog()
  }

  handleCloseSessionDetailsModal() {
    this.setState({
      showSessionDetailsModal: false
    })
  }

  handleOpenSessionDetailsClick(sessionUUID) {
    let criteria = {
      timeZone: this.state.reportCriteria.timeZone,
      startDate: this.state.reportCriteria.startDate,
      endDate: this.state.reportCriteria.endDate,
      sessionUUID: sessionUUID
    }

    this.props.loadSessionDetails(criteria)
    this.setState({
      showSessionDetailsModal: true
    })
  }

  getCriteria(request) {
    request = request || {}

    return {
      timeZone: request.TimeZone || this.state.timeZone,
      startDate: request.StartDate || this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: request.EndDate || this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: request.CampaignUUIDs || this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: request.PlacementUUIDs || this.state.selectedPlacements.map(e => e.value),
      rowLimit: request.RowLimit || this.state.limit,

      validationType: request.ValidationType || this.state.selectedValidationType,
      serviceNames: request.ServiceNames || this.state.serviceNames,
      groupByDate: request.groupByDate || this.state.groupByDate,
    }
  }

  componentDidMount() {
    Modal.setAppElement("body")
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true
    })
  }

  disableDownloadButton() {
    this.setState({
      isDownloadButtonDisabled: true
    })
  }

  enableDownloadButton() {
    this.setState({
      isDownloadButtonDisabled: false
    })
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {}
    {
      let gotHtmlReportResponse = false
      props.singleValidationsReport.matchWith({
        SingleValidationsReportInitial: () => { },
        LoadingSingleValidationsReport: () => { },
        SingleValidationsReportError: () => {
          gotHtmlReportResponse = true
        },
        SingleValidationsReportLoaded: () => {
          gotHtmlReportResponse = true
        }
      })
      if (gotHtmlReportResponse) {
        let gc = function (rr) {
          rr = rr || {}
          return {
            timeZone: rr.TimeZone || state.timeZone,
            startDate: rr.StartDate || state.startDate.format("YYYY-MM-DD HH:mm:ss"),
            endDate: rr.EndDate || state.endDate.format("YYYY-MM-DD HH:mm:ss"),
            campaignUUIDs: rr.CampaignUUIDs || state.selectedCampaigns.map(e => e.value),
            placementUUIDs: rr.PlacementUUIDs || state.selectedPlacements.map(e => e.value),
            rowLimit: rr.RowLimit || state.limit,

            validationType: rr.ValidationType || state.selectedValidationType,
            groupByDate: rr.groupByDate || state.groupByDate,
            serviceNames: rr.ServiceNames || state.serviceNames,
          }
        }

        newState.isRunButtonDisabled = false
        
        let request = props.singleValidationsReport.data.Request
        if (!state.filterUpdated && request
          && ((request.CampaignUUIDs.length == 0 || (request.CampaignUUIDs.length > 0 && state.selectedCampaigns.length == 0)) ||
            (request.PlacementUUIDs.length == 0 || (request.PlacementUUIDs.length > 0 && state.selectedPlacements.length == 0)))) {
          let criteria = gc(props.singleValidationsReport.data.Request)
          newState.selectedLimit = { value: criteria.rowLimit, label: criteria.rowLimit.toString() }
          newState.selectedCampaigns = filterForSelectable(props.campaigns, criteria.campaignUUIDs)
          newState.selectedPlacements = filterForSelectable(props.placements, criteria.placementUUIDs)
          newState.selectedValidationType = criteria.validationType
          newState.groupByDate = criteria.groupByDate

          newState = { ...newState, ...criteria }
          newState.startDate = moment(newState.startDate)
          newState.endDate = moment(newState.endDate)
        }
      }
    }
    {
      let gotCsvReportResponse = false
      props.singleValidationsCsvReport.matchWith({
        SingleValidationsCsvReportInitial: () => { },
        LoadingSingleValidationsCsvReport: () => { },
        SingleValidationsCsvReportError: () => {
          gotCsvReportResponse = true
        },
        SingleValidationsCsvReportLoaded: () => {
          gotCsvReportResponse = true
        }
      })
      if (gotCsvReportResponse) {
        newState.isDownloadButtonDisabled = false
      }
    }



    return newState
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      filterUpdated: true,
      selectedPlacements: placements,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."
    return (
      <section ref={t => (this.contentSection = t)}>
        <div className="content">
          <Modal
            appElement={this.props.parent}
            isOpen={this.props.singleValidationsReportV2.sessionReport.showSessionPopup}
            onRequestClose={this.handleSessionPopupClose}
            contentLabel="Sessions"
            style={sessionsModalStyles}>
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button
                  style={{ position: "fixed", backgroundColor: "white", right: "5%" }}
                  onClick={() => this.handleCloseSessionsModal()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              <div>
                {this.props.singleValidationsReportV2.sessionReport.showSessionPopup &&
                  this.renderSessionsModalContent()}
              </div>
            </Card>
          </Modal>
          <Modal
            appElement={this.props.parent}
            isOpen={this.state.showSessionDetailsModal}
            onRequestClose={this.handleSessionPopupClose}
            contentLabel="Session Details"
            style={sessionDetailsModalStyles}>
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button onClick={() => this.handleCloseSessionDetailsModal()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              <div>{this.state.showSessionDetailsModal && this.renderSessionDetailsModalContent()}</div>
            </Card>
          </Modal>
          <Grid>
            <Cell col={12}>
              <Collapsible title="Validations" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={10}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={4}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={8}>
                        <label className="floatLabel">Validation Types</label>
                        <RadioGroup name="validationType" value={this.state.selectedValidationType} onChange={e => {
                          this.setState({
                            filterUpdated: true,
                            selectedValidationType: e.target.value
                          })
                        }}>
                          <Radio value="">ALL</Radio>
                          <Radio value="prevalidation" ripple>Pre-validation</Radio>
                          <Radio value="postvalidation">Post-validation</Radio>
                        </RadioGroup>
                      </Cell>
                      <Cell col={4}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              filterUpdated: true,
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={4}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                      <Cell col={4}>
                        <label className="floatLabel">Services</label>
                        <ValidatorSelector
                          defaultServiceNames={this.state.serviceNames}
                          validators={this.props.validators}
                          onChange={(validators) => {
                            this.setState({
                              filterUpdated: true,
                              serviceNames: validators.map(v => v.value),
                            })
                          }}
                        ></ValidatorSelector>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={2}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => this.handleDownloadButtonClick()}
                              disabled={this.state.isDownloadButtonDisabled}
                              raised
                              colored
                              ripple>
                              <Icon name="cloud_download" />
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>{this.renderCsvReport()}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>{this.renderHtmlReport()}</Cell>
          </Grid>
        </div>
      </section>
    )
  }

  getColumnWidth({ index }) {
    switch (index) {
      case 0:
        return 330
      default:
        return 80
    }
  }


  renderReportComponentByDate(comp, dateList) {
    let gridColumnCount = 1 + dateList.length * 3
    let gridRowCount = 2 + comp.messageRecords.length
    
    let body = (
      <div id={'wrapper'+comp.serviceName}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <MultiGrid
            id={comp.serviceName}
            fixedColumnCount={1}
            fixedRowCount={2}
            scrollToColumn={0}
            scrollToRow={0}
            cellRenderer={props => <CellRendererByMessageByDate cache={this.cellCacheByDate} comp={comp} dateList={dateList} {...props} />}
            columnWidth={this.cellCacheByDate.columnWidth}
            deferredMeasurementCache={this.cellCacheByDate}
            columnCount={gridColumnCount}
            enableFixedColumnScroll
            enableFixedRowScroll
            height={480}
            rowHeight={30}
            rowCount={gridRowCount}
            style={STYLE}
            styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
            styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
            styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
            width={width}
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
          />
        )}
      </AutoSizer>
      </div>
    )

    return (
      <div className="report">
        <h4 className="reportTitle">Rejections By {comp.serviceName}</h4>
        {this.state.reportCriteria.validationType == "postvalidation" && <div>({comp.RejectionRate})</div>}
        <div>{body}</div>
      </div>
    )
  }

  renderReportComponent(comp) {
    let height = 400

    let gridColumnCount = 2
    let gridRowCount = 1 + comp.messageRecords.length
    if (comp.messageRecords.length == 0) {
      gridRowCount = 0
    }

    let body = (
      <AutoSizer disableHeight>
        {({ width }) => (
          <MultiGrid
            fixedRowCount={1}
            scrollToColumn={0}
            scrollToRow={0}
            cellRenderer={props => (
              <CellRendererByMessage
                openSessionPopup={this.handleOpenSessionPopupDialog}
                cache={this.cellCacheByMessage}
                comp={comp}
                getColumnWidth={this.getColumnWidth}
                {...props}
              />
            )}
            columnWidth={this.getColumnWidth}
            //columnWidth={this.cellCacheByMessage.columnWidth}
            //deferredMeasurementCache={this.cellCacheByMessage}
            noContentRenderer={() => <div>{"No records found"}</div>}
            columnCount={gridColumnCount}
            enableFixedColumnScroll
            enableFixedRowScroll
            height={height}
            rowHeight={30}
            rowCount={gridRowCount}
            width={width}
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
          />
        )}
      </AutoSizer>
    )

    return (
      <div className="report">
        <h4 className="reportTitle">Rejections By {comp.serviceName}</h4>
        {this.state.reportCriteria.validationType == "postvalidation" && <div>({comp.RejectionRate})</div>}
        <div>{body}</div>
      </div>
    )
  }

  renderHtmlReport() {
    return this.props.singleValidationsReport.matchWith({
      SingleValidationsReportInitial: () => "Click 'Run' to generate",
      LoadingSingleValidationsReport: () => "Loading ...",
      SingleValidationsReportError: () => <div style={{ color: "#FF0000" }}>&quot;Failed: can not load&quot;</div>,
      SingleValidationsReportLoaded: ({ data }) => {
        this.slug = data.request.slug
        let dateList = data.report.dateList.sort().reverse()

        return (
          <Grid>
            <Cell col={12}>
              <a target="_blank" rel="noreferrer"
                href={window.location.origin + window.location.pathname + `?format=json&report=validations&slug=${this.slug}`}>
                Share Report</a>
            </Cell>
            <Cell col={12}>
              <Card shadow={2}>
                <CardTitle>Rejections By Validator</CardTitle>
                <CardActions>
                  <Grid>
                    <Cell col={4}>
                      <Card style={{ minHeight: "0" }} shadow={2}>
                        <CardTitle>{`Optins`}</CardTitle>
                        <CardText>{data.report.optinCount}</CardText>
                      </Card>
                    </Cell>
                    {data.report.summarySection.serviceRecords.map((comp, i) => {
                      return (
                        <Cell col={4} key={`validation-summary-${i}`}>
                          <Card style={{ minHeight: "0" }} shadow={2}>
                            <CardTitle>{`${comp.serviceName}`}</CardTitle>
                            <CardText>
                              {comp.rejectionCount}
                              {this.state.reportCriteria.validationType == "postvalidation" && ` (${comp.RejectionRate})`}
                            </CardText>
                          </Card>
                        </Cell>
                      )
                    })}
                  </Grid>
                </CardActions>
              </Card>
            </Cell>
            {data.request.groupByDate && 
              data.report.mainSection.serviceRecords.map((comp, i) => {
                return (
                  <Cell key={'report-by-date'+i} col={12}>
                    {this.renderReportComponentByDate(comp, dateList)}
                  </Cell>
                )
              })
            }
            {!data.request.groupByDate && 
              data.report.mainSection.serviceRecords.map((comp, i) => {
                return (
                  <Cell key={i} col={3}>
                    {this.renderReportComponent(comp)}
                  </Cell>
                )
              })
            }
          </Grid >
        )
      }
    })
  }

  renderCsvReport() {
    return this.props.singleValidationsCsvReport.matchWith({
      SingleValidationsCsvReportInitial: () => null,
      LoadingSingleValidationsCsvReport: () => <div>{"Downloading ..."}</div>,
      SingleValidationsCsvReportError: () => <div style={{ color: "#FF0000" }}>&quot;Failed: can not load&quot;</div>,
      SingleValidationsCsvReportLoaded: () => null
    })
  }

  renderSessionDetailsModalContent() {
    if (this.props.sessionDetails.isLoading) {
      return (
        <Grid>
          <Cell col={12}>Loading session details ...</Cell>
        </Grid>
      )
    }
    return <React.Fragment> <h4>Session:</h4><SessionDetails data={this.props.sessionDetails.data.report.sessionDetailsRecord}></SessionDetails></React.Fragment>
  }

  handleSessionPageChange(sessionPage) {
    let criteria = this.props.singleValidationsReportV2.sessionReport.criteria
    criteria.rowOffset = (sessionPage - 1) * this.sessionPageSize,
      this.props.openSessionPopupDialog(criteria)
    this.setState({
      sessionPage: sessionPage,
    })
  }

  renderSessionsModalContent() {
    if (this.props.singleValidationsReportV2.sessionReport.isLoadingSessions) {
      return (
        <Grid>
          <Cell col={12}>Loading sessions ...</Cell>
        </Grid>
      )
    }

    let report = this.props.singleValidationsReportV2.sessionReport.sessions.Report

    return (
      <Grid>
        <Cell col={6}>
          <Card>
            <h4>Criteria:</h4>
            <div>
              <b>Campaigns:</b>
              {report.Summary.Campaigns.length == 0 && <div>ALL</div>}
              {report.Summary.Campaigns.map((e, i) => {
                return <div key={`viewer.sessions.summary.campaigns.${i}`}>{`${e.Name} - ${e.ShortCode} (${e.UUID})`}</div>
              })}
            </div>
            <div>
              <b>Placements:</b>
              {report.Summary.Placements.length == 0 && <div>ALL</div>}
              {report.Summary.Placements.map((e, i) => {
                return <div key={`viewer.sessions.summary.placements.${i}`}>{`${e.Name} - ${e.LegacyID} (${e.UUID})`}</div>
              })}
            </div>
            <div>
              <b>Service Names:</b>
              {report.Summary.ServiceNames.length == 0 && <div>ALL</div>}
              {report.Summary.ServiceNames.map((e, i) => {
                return <div key={`viewer.sessions.summary.services.${i}`}>{e}</div>
              })}
            </div>
            <div>
              <b>Messages:</b>
              {report.Summary.ValidationMessages.length == 0 && <div>ALL</div>}
              {report.Summary.ValidationMessages.map((e, i) => {
                return <div key={`viewer.sessions.summary.messages.${i}`}>{e}</div>
              })}
            </div>
          </Card>
        </Cell>
        <Cell col={6}>
          <h4>Stats:</h4>
          <div>{report.Summary.SessionCount} sessions (current page)</div>
        </Cell>
        <Cell col={12}>
          <div style={{ display: "flex", justifyContent: "space-between", margingBottom: "10px" }}>
            <Button accent onClick={() => this.handleSessionPageChange(1)}>
              <Icon name="first_page" />
            </Button>
            <Button accent onClick={() => {
              this.handleSessionPageChange(this.state.sessionPage - (this.state.sessionPage <= 1 ? 0 : 1))
            }}>
              <Icon name="chevron_left" />
            </Button>
            <span style={{ paddingTop: "10px" }}>Page No. {this.state.sessionPage}</span>
            <Button accent onClick={() => {
              this.handleSessionPageChange(this.state.sessionPage + 1)
            }}>
              <Icon name="chevron_right" />
            </Button>
            <Button accent disabled>
              <Icon name="last_page" />
            </Button>
          </div>
        </Cell>
        <Cell className="reportColumn" col={12}>
          <table className="report">
            <tr className="th" key={`viewer.sessions.header`}>
              <td className="th dimension">No.</td>
              <td className="th dimension">Session UUID</td>
              <td className="th dimension">Session Created At</td>
              <td className="th dimension">Email</td>
              <td className="th dimension">First Name</td>
              <td className="th dimension">Last Name</td>
              <td className="th dimension">Address1</td>
              <td className="th dimension">Address2</td>
              <td className="th dimension">City</td>
              <td className="th dimension">State</td>
              <td className="th dimension">Zip</td>
              <td className="th dimension">Phone</td>
              <td className="th dimension">DOB</td>
              <td className="th dimension">Gender</td>
              <td className="th dimension">User IP</td>
            </tr>
            {report.Sessions.map((e, i) => {
              return (
                <tr key={`viewer.sessions.row.${i}`} className="td">
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.rowNumber`}>{e.RowNumber}</td>
                  <td
                    className="td dimension clickable"
                    key={`viewer.sessions.row.${i}.cell.sessionUUID`}
                    onClick={() => this.handleOpenSessionDetailsClick(e.SessionUUID)}>
                    {e.SessionUUID}
                  </td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.sessionCreatedAt`}>{e.SessionCreatedAt}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.email`}>{e.Email}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.firstName`}>{e.FirstName}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.lastName`}>{e.LastName}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.address1`}>{e.Address1}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.address2`}>{e.Address2}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.city`}>{e.City}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.state`}>{e.State}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.zipcode`}>{e.Zipcode}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.phone`}>{e.Phone}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.dob`}>{e.DOB}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.gender`}>{e.Gender}</td>
                  <td className="td dimension" key={`viewer.sessions.row.${i}.cell.userIP`}>{e.UserIP}</td>
                </tr>
              )
            })}
          </table>
        </Cell>
      </Grid>
    )
  }
}

const SingleValidationReport = connect(mapStateToProps, mapDispatchToProps)(singleValidationReport)

export default SingleValidationReport
