const SAVE_STATE_OF_TRU_INTENT = 'SAVE_STATE_OF_TRU_INTENT'
const GENERATE_TRU_INTENT = 'GENERATE_TRU_INTENT'
const GENERATE_TRU_INTENT_FAILURE = 'GENERATE_TRU_INTENT_FAILURE'
const GENERATE_TRU_INTENT_SUCCESS = 'GENERATE_TRU_INTENT_SUCCESS'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_TRU_INTENT, data })

export const generateTruIntent = () =>
    ({ type: GENERATE_TRU_INTENT })

export const generateTruIntentSuccess = data =>
    ({ type: GENERATE_TRU_INTENT_SUCCESS, data })

export const generateTruIntentFailure = error =>
    ({ type: GENERATE_TRU_INTENT_FAILURE, error })


const TRU_INTENT_REJECTED_START_LOADING = 'TRU_INTENT_REJECTED_START_LOADING'
const TRU_INTENT_REJECTED_LOADED = 'TRU_INTENT_REJECTED_LOADED'
const TRU_INTENT_REJECTED_DIALOG_CLOSED = 'TRU_INTENT_REJECTED_DIALOG_CLOSED'

export const startLoadingRejectedLeadViewer = () =>
    ({ type: TRU_INTENT_REJECTED_START_LOADING })

export const loadedRejectedLeadViewer = (criteria, report) =>
    ({ type: TRU_INTENT_REJECTED_LOADED, data: { criteria: criteria, report: report } })

export const closedRejectedLeadViewer = () =>
    ({ type: TRU_INTENT_REJECTED_DIALOG_CLOSED })


const TRU_INTENT_REJECTED_RTS_START_LOADING = 'TRU_INTENT_REJECTED_RTS_START_LOADING'
const TRU_INTENT_REJECTED_RTS_LOADED = 'TRU_INTENT_REJECTED_RTS_LOADED'
const TRU_INTENT_REJECTED_RTS_DIALOG_CLOSED = 'TRU_INTENT_REJECTED_RTS_DIALOG_CLOSED'

export const startLoadingRejectedRtsViewer = () =>
    ({ type: TRU_INTENT_REJECTED_RTS_START_LOADING })

export const loadedRejectedRtsViewer = (criteria, report) =>
    ({ type: TRU_INTENT_REJECTED_RTS_LOADED, data: { criteria: criteria, report: report } })

export const closedRejectedRtsViewer = () =>
    ({ type: TRU_INTENT_REJECTED_RTS_DIALOG_CLOSED })


const initialState = { 
    rejectedReport: {
        report: null,
        isLoading: false,
        showPopup: false,
        criteria: null,
    },
    rejectedRtsReport: {
        report: null,
        isLoading: false,
        showPopup: false,
        criteria: null,
    },
    report: null, 
    isLoading: false, 
    error: null, 
    componentState: null,
}

export const truIntentState = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_STATE_OF_TRU_INTENT:
            return {
                ...state,
                componentState: action.data,
            }
        case GENERATE_TRU_INTENT:
            return {
                ...state,
                isLoading: true,
                report: null,
                error: null,
            }
        case GENERATE_TRU_INTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                report: action.data.report,
                slug: action.data.request.slug,
                request: action.data.request,
                error: null,
            }
        case GENERATE_TRU_INTENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                report: null,
                error: { message: action.error },
            }
        case TRU_INTENT_REJECTED_START_LOADING:
            return {
                ...state,
                rejectedReport: {
                    ...state.rejectedReport,
                    isLoading: true,
                    showPopup: true,
                    criteria: null,
                    report: null,
                }
            }
        case TRU_INTENT_REJECTED_LOADED:
            return {
                ...state,
                rejectedReport: {
                    ...state.rejectedReport,
                    isLoading: false,
                    criteria: action.data.criteria,
                    report: action.data.report.report,
                }
            }
        case TRU_INTENT_REJECTED_DIALOG_CLOSED:
        return {
                ...state,
                rejectedReport: {
                    ...state.rejectedReport,
                    showPopup: false,
                    criteria: null,
                    report: null,
                }
            }
        case TRU_INTENT_REJECTED_RTS_START_LOADING:
            return {
                ...state,
                rejectedRtsReport: {
                    ...state.rejectedRtsReport,
                    isLoading: true,
                    showPopup: true,
                    criteria: null,
                    report: null,
                }
            }
        case TRU_INTENT_REJECTED_RTS_LOADED:
            return {
                ...state,
                rejectedRtsReport: {
                    ...state.rejectedRtsReport,
                    isLoading: false,
                    criteria: action.data.criteria,
                    report: action.data.report.report,
                }
            }
        case TRU_INTENT_REJECTED_RTS_DIALOG_CLOSED:
            return {
                ...state,
                rejectedRtsReport: {
                    ...state.rejectedRtsReport,
                    showPopup: false,
                    criteria: null,
                    report: null,
                }
            }
        default:
        return state
    }
}
