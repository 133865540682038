const SESSION_DETAILS_START_LOADING = 'SESSION_DETAILS_START_LOADING'
const SESSION_DETAILS_LOADED = 'SESSION_DETAILS_LOADED'

export const startLoadingSessionDetails = () =>
    ({ type: SESSION_DETAILS_START_LOADING })

export const loadedSessionDetails = (criteria, data) =>
    ({ type: SESSION_DETAILS_LOADED, data: {criteria:criteria, data:data} })


const initialState = { 
    criteria: null,
    data: null, 
    isLoading: false,
   }
export function sessionDetails(state = initialState, action) {
    switch(action.type) {
      case SESSION_DETAILS_START_LOADING:
        return {
          ...state,
          isLoading: true,
          criteria: null,
          data: null,
        }
        case SESSION_DETAILS_LOADED:
          return {
            ...state,
            isLoading: false,
            criteria: action.data.criteria,
            data: action.data.data,
        }
        default:
        return state
    }
  }

  