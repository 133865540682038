import { generateActivityLogThunk } from "../../services/activityLog"
import { saveState } from "../../reducers/activityLogReducer"
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Grid, Cell, Button, Textfield } from 'react-mdl'
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import Select from 'react-select'
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
  return {
    activityLog: state.activityLog,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadActivityLog: function (criteria) {
      dispatch(generateActivityLogThunk(criteria))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
  }
}

function findDiff(obj1, obj2) {
  var ret = {}
  var retBefore = {}
  for (var i in obj2) {
    if (!Object.prototype.hasOwnProperty.call(obj1, i) || obj2[i] !== obj1[i]) {
      if (Array.isArray(obj1[i]) && Array.isArray(obj2[i])) {
        for (var ii = 0; ii < obj1[i].length && ii < obj2[i].length; ii++) {
          let retInner = findDiff(obj1[i][ii], obj2[i][ii])
          if (retInner == null) {
            continue
          }
          ret[i] = retInner.after
          retBefore[i] = retInner.before
        }
      } else if (Object.isObject(obj1[i])) {
        let retInner = findDiff(obj1[i], obj2[i])
        if (retInner == null) {
          continue
        }
        ret[i] = retInner.after
        retBefore[i] = retInner.before
      } else {
        ret[i] = obj2[i]
        retBefore[i] = obj1[i]
      }
    }
  }
  if (JSON.stringify(ret) == '{}') {
    return null
  }
  return {
    before: retBefore,
    after: ret
  }
}
class activityLog extends React.Component {
  constructor(props) {
    super(props)

    this.timeZone = "America/New_York"

    this.limits = [
      { value: 10, label: "10" },
      { value: 100, label: "100" },
      { value: 500, label: "500" },
      { value: 1000, label: "1000" },
    ]

    this.state = {
      startDate: moment().startOf("day"),
      endDate: moment(),
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      newValues: '',
      newValuesReplace: false,
      page: 1,
      records: [],
      selectedLimit: this.limits[0],
      textFilter: "",
      allRowsExpanded: false,
      isLoading: false,
      isCriteriaCollapsed: false,
    }

    if (props.activityLog.componentState) {
      this.state = props.activityLog.componentState
    }

    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }


  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.associatedAddressValidations !== this.props.associatedAddressValidations ||
      prevProps.activityLog.report !== this.props.activityLog.report) {
      let records = []
      if (this.props.activityLog.report && this.props.activityLog.report.records) {
        records = this.props.activityLog.report.records.map((e) => {
          e.rowExpanded = false
          return e
        })
      }
      this.setState({
        records: records,
      })
    }
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      startDate: startDate,
      endDate: endDate
    })
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleRunButtonClick() {
    let page = 1
    let criteria = this.getCriteria(page)
    this.props.loadActivityLog(criteria)
    this.setState({
      page: page,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  getCriteria() {
    return {
      timeZone: this.timeZone,
      startDate: this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: this.state.selectedPlacements.map(e => e.value),
      rowLimit: this.state.selectedLimit.value,
      textFilter: this.state.textFilter,
    }
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true,
    })
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      selectedPlacements: placements,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."

    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
          <Grid>
            <Cell col={12}>
              <Collapsible title="Activity Log" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={11}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={4}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={2}>
                        <label className="floatLabel">Limit</label>
                        <Select
                          isMulti={false}
                          value={this.state.selectedLimit}
                          onChange={(s) => {
                            this.setState({
                              selectedLimit: s
                            })
                          }}
                          options={this.limits}
                        />
                      </Cell>
                      <Cell col={4}>
                        <Textfield
                          floatingLabel
                          label="Text Filter"
                          onChange={e => {
                            this.setState({
                              textFilter: e.target.value,
                            })

                          }}
                          value={this.state.textFilter || ""}
                        />
                      </Cell>
                      <Cell col={2}></Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  {this.renderReport()}
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }


  handleRowToggleClick(i) {
    const records = this.state.records
    records[i].rowExpanded = !records[i].rowExpanded
    this.setState({
      records: records,
    })
  }

  handleAllRowsToggleClick() {
    let flag = !this.state.allRowsExpanded
    const records = this.state.records.map((e) => {
      e.rowExpanded = flag
      return e
    })
    this.setState({
      records: records,
      allRowsExpanded: flag,
    })
  }

  renderReport() {
    if (this.props.activityLog.isLoading) {
      return <div>Loading ...</div>
    }
    if (this.props.activityLog.error != null) {
      return <div>Error: {this.props.activityLog.error.message}</div>
    }
    if (this.props.activityLog.report == null) {
      return <div></div>
    }
    return <div className="report">
      <div>{this.renderActivityReport()}</div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  }

  renderActivityReport() {
    let section = this.props.activityLog.report.activitySection

    return (
      <React.Fragment>
        <Grid style={{ "width": "100%" }}>
          <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.activityLog.header1`}>
                  <th className="th dimension" colSpan={12} style={{ textAlign: "center" }}>{section.title}</th>
                </tr>
                <tr className="th" key={`viewer.activityLog.header2`}>
                  <th className="th dimension">Activity Time</th>
                  <th className="th dimension">Activity Type</th>
                  <th className="th dimension">User Name</th>
                  <th className="th dimension">Service Name</th>
                  <th className="th dimension">Before</th>
                  <th className="th dimension">After</th>
                  <th className="th dimension">Current</th>
                </tr>
              </thead>
              <tbody>
                {section.records.length == 0 && <tr className="td" key={`viewer.activityLog.row1`}>
                  <th className="td metric" colSpan={12} style={{ textAlign: "center" }}>No records found</th>
                </tr>}
                {section.records.map((e, i) => {
                  let bObjString = e.before
                  let aObjString = e.after
                  if (e.before && e.before.length > 0 && e.after && e.after.length > 0) {
                    let bObj = JSON.parse(e.before)
                    let aObj = JSON.parse(e.after)
                    let diffObj = findDiff(bObj, aObj)
                    if (diffObj == null) {
                      return <React.Fragment></React.Fragment>
                    }
                    bObjString = JSON.stringify(diffObj.before, null, 2)
                    aObjString = JSON.stringify(diffObj.after, null, 2)
                  }
                  return (
                    <React.Fragment key={`viewer.activityLog.wrapper.${i}`}>
                      <tr className="td" key={`viewer.activityLog.${i}`}>
                        <td className="td dimension">
                          {moment.unix(e.activityTime).format('dddd MMMM Do YYYY, h:mm:ss a')}
                        </td>
                        <td className="td metric">
                          {e.activityType}
                        </td>
                        <td className="td metric">
                          {e.userName}
                        </td>
                        <td className="td metric">
                          {e.serviceName}
                        </td>
                        <td className="td metric">
                          <textarea style={{ width: "100%", minHeight: "250px" }} value={bObjString} />
                        </td>
                        <td className="td metric">
                          <textarea style={{ width: "100%", minHeight: "250px" }} value={aObjString} />
                        </td>
                        <td className="td metric">
                          <textarea style={{ width: "100%", minHeight: "250px" }} value={e.after} />
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }

}


const ActivityLog = connect(mapStateToProps, mapDispatchToProps)(activityLog)

export default ActivityLog
