import React from 'react'
import moment from 'moment'
import { Icon, Grid, Cell, Card, CardActions, Button } from 'react-mdl'
import SessionDetails from "../session-details"
import Modal from "react-modal"
import { connect } from 'react-redux'
import { loadSessionDetailsThunk } from "../../services/sessionDetailsService"

function mapStateToProps(state) {
  return {
    brokeredLeadViewer: state.brokeredLeadViewer,
    sessionDetails: state.sessionDetails,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadSessionDetails: function (criteria) {
      dispatch(loadSessionDetailsThunk(criteria))
    },
    //closedLeadViewerPopup: function () {
    //  dispatch(closedLeadViewer())
    //},
  }
}

const modalStyles = {
  height: "90%"
}

const rejectedStyle = {
  color: "red",
}

const acceptedStyle = {
  color: "green",
}

const failedStyle = {
  color: "#8b0000",
}

const commentStyle = {
  color: "#666",
  paddingBotton: "5px",
  fontSize: "80%",
}

class brokeredLeadViewerReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSessionDetailsModal: false,
      sortColumn: "",
      isDescendingSort: false,
      leads: this.props.report.leads.map((e) => {
        e.rowExpanded = false
        return e
      })
    }
    this.handleOpenSessionDetailsClick = this.handleOpenSessionDetailsClick.bind(this)
  }

  handleCloseSessionDetailsModal() {
    //this.props.closedLeadViewerPopup()
    this.setState({
      showSessionDetailsModal: false
    })
  }

  handleOpenSessionDetailsClick(sessionUUID) {
    let criteria = {
      timeZone: this.props.report.metadata.request.timeZone,
      startDate: this.props.report.metadata.request.startDate,
      endDate: this.props.report.metadata.request.endDate,
      sessionUUID: sessionUUID
    }

    this.props.loadSessionDetails(criteria)
    this.setState({
      showSessionDetailsModal: true
    })
  }

  handleRowToggleClick(i) {
    const leads = this.state.leads
    leads[i].rowExpanded = !leads[i].rowExpanded
    this.setState({
      leads: leads,
    })
  }

  handleAllRowsToggleClick() {
    let flag = !this.state.allRowsExpanded
    const leads = this.state.leads.map((e) => {
      e.rowExpanded = flag
      return e
    })
    this.setState({
      leads: leads,
      allRowsExpanded: flag,
    })
  }


  //{this.props.brokeredFailedLeads.leadViewerReport.showPopup && this.renderSessionsModalContent()}

  renderSessionDetailsModalContent() {
    if (this.props.sessionDetails.isLoading) {
      return (
        <Grid>
          <Cell col={12}>Loading session details ...</Cell>
        </Grid>
      )
    }
    return <React.Fragment> <h4>Session:</h4><SessionDetails data={this.props.sessionDetails.data.report.sessionDetailsRecord}></SessionDetails></React.Fragment>
  }

  sortReport(column) {
    if (column == 'pub_key') {
      let isDescendingSort = false
      if (this.state.sortColumn == column) {
        isDescendingSort = !this.state.isDescendingSort
      }
      let leads = this.state.leads.sort((a, b) => (a.pubKey > b.pubKey) ? 1 : ((b.pubKey > a.pubKey) ? -1 : 0))
      if (isDescendingSort) {
        leads = this.state.leads.sort((a, b) => (a.pubKey < b.pubKey) ? 1 : ((b.pubKey < a.pubKey) ? -1 : 0))
      }
      this.setState({
        sortColumn: column,
        isDescendingSort: isDescendingSort,
        leads: leads,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content">
          <Modal
            appElement={this.props.parent}
            isOpen={this.state.showSessionDetailsModal}
            onRequestClose={this.handleSessionPopupClose}
            contentLabel="Session Viewer"
            style={modalStyles}
          >
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button
                  style={{ position: "fixed", backgroundColor: "white", right: "5%" }}
                  onClick={() => this.handleCloseSessionDetailsModal()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              <div>{this.state.showSessionDetailsModal && this.renderSessionDetailsModalContent()}</div>
            </Card>
          </Modal>
          <Grid style={{ "width": "100%" }}>
            <Cell col={12}>
              <table className="report">
                <thead>
                  <tr className="th" key={`viewer.brokeredLeadViewer.header2`}>
                    <th className="th dimension">
                      <div onClick={() => this.handleAllRowsToggleClick()}>
                        {!this.state.allRowsExpanded && <Icon name="keyboard_arrow_down" />}
                        {this.state.allRowsExpanded && <Icon name="keyboard_arrow_up" />}
                      </div>
                    </th>
                    <th className="th dimension">Status<br></br>(Legacy Status)</th>
                    <th className="th dimension">Email / Session</th>
                    <th className="th dimension clickable" onClick={() => this.sortReport("pub_key")}>
                      Pub Key
                      {this.state.sortColumn == 'pub_key' && this.state.isDescendingSort && <Icon name="keyboard_arrow_down" />}
                      {this.state.sortColumn == 'pub_key' && !this.state.isDescendingSort && <Icon name="keyboard_arrow_up" />}
                    </th>
                    <th className="th dimension">Validation Results</th>
                    <th className="th dimension">Misc</th>
                    <th className="th dimension">Placement</th>
                    <th className="th dimension">Campaign</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.leads.map((e, i) => {
                    let statusStyle = {}
                    if (e.status == "accepted" || e.legacyStatus == "accepted") {
                      statusStyle = acceptedStyle
                    } else if (e.status == "rejected" || e.legacyStatus == "rejected") {
                      statusStyle = rejectedStyle
                    } else if (e.status == "failed" || e.legacyStatus == "failed") {
                      statusStyle = failedStyle
                    }

                    return (
                      <React.Fragment key={`viewer.brokeredLeadViewer.wrapper.${i}`}>
                        <tr className="td" key={`viewer.brokeredLeadViewer.${i}`}>
                          <td className="td dimension">
                            <div onClick={() => this.handleRowToggleClick(i)}>
                              {!e.rowExpanded && <Icon name="keyboard_arrow_down" />}
                              {e.rowExpanded && <Icon name="keyboard_arrow_up" />}
                            </div>
                          </td>
                          <td className="td dimension">
                            <div style={statusStyle}>{e.status}</div>
                            {e.legacyStatus != "" && <div style={statusStyle}>({e.legacyStatus})</div>}
                            {e.reviewStatus.length > 0 && <div style={commentStyle}>Post-quarantine</div>}
                          </td>
                          <td className="td dimension">
                            {e.sessionDetails.session.email}
                            <div className="clickable" onClick={() => this.handleOpenSessionDetailsClick(e.sessionDetails.session.sessionUUID)}>{e.sessionDetails.session.sessionUUID}</div>
                          </td>
                          <td className="td dimension">
                            {e.pubKey}
                          </td>
                          <td className="td dimension">
                            <div>{e.validationResults.map(e => {
                              return <div key={e.code}>{e.message}<div style={commentStyle}>{e.code}</div></div>
                            })}</div>
                          </td>
                          <td className="td dimension">
                            <div>Is New PDLE?: {e.isNewPdle ? "Yes" : "No"}</div>
                            <div>Submit Time: {moment(e.requestCreatedAt * 1000).format('MM/DD/YYYY hh:mm A')}</div>
                            <div>Pub Time: {e.sessionDetails.session.localTime}</div>
                            <div>Pub Key: {e.pubKey}</div>
                          </td>
                          <td className="td dimension">
                            <div>{`${e.placementName}`}</div>
                            <div>{`${e.placementLegacyID}`}</div>
                            <div style={commentStyle}>{e.placementUUID}</div>
                          </td>
                          <td className="td dimension">
                            <div>{`${e.campaignName}`}</div>
                            <div>{`${e.campaignShortCode}`}</div>
                            <div style={commentStyle}>{e.campaignUUID}</div>
                          </td>
                        </tr>
                        {!e.rowExpanded && <tr><td colSpan={8}></td></tr>}
                        {e.rowExpanded && <tr>
                          <td className="td" colSpan={8}>
                            {((e.message.length > 0 || e.details.length > 0) || e.missingOptin || e.missingResponse) && <div>
                              <div>Notes:</div>
                              {e.missingOptin && <div>Missing optin event</div>}
                              {e.missingResponse && <div>Missing response event</div>}
                              {(e.message.length > 0 || e.details.length > 0) && <div>{`${e.message} ${e.details}`}</div>}
                            </div>}
                            {e.reviewStatus.length > 0 && <div>
                              <label>Quarantine info: {`status:${e.reviewStatus}  message:${e.reviewMessage} details:${e.reviewDetails}`}</label>
                            </div>}
                            <div>
                              <label>Session: </label>
                              <SessionDetails data={e.sessionDetails} showPrevalidations={false} showPostvalidations={false}></SessionDetails>
                            </div>
                            <div>
                              <label>Request data: </label>
                              <textarea style={{ width: "100%", minHeight: "250px" }}
                                value={e.requestString}
                              />
                            </div>
                            <br></br>
                            <div>
                              <label>Response body: </label>
                              <textarea style={{ width: "100%", minHeight: "160px" }}
                                value={e.responseString}
                              />
                            </div>
                          </td>
                        </tr>}
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Cell>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}


const BrokeredLeadViewerReport = connect(mapStateToProps, mapDispatchToProps)(brokeredLeadViewerReport)

export default BrokeredLeadViewerReport
