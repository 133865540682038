import React from "react"
import { connect } from "react-redux"
import FilterList from "../../filterList"
import {
  isDirty,
 } from '../../../services'
 import {Grid, Cell, Card, CardActions, CardTitle, Switch, Textfield} from 'react-mdl'

function mapDispatchToProps(dispatch) {
  return {
    isDirty: function () {
      dispatch(isDirty(true))
    }
  }
}

class clientDetails extends React.Component {
  constructor(props) {
    super(props)
    const {campaignRequest} = this.props
    campaignRequest.approvedPlacementUuids = campaignRequest.approvedPlacementUuids || []
    this.state = {
       campaignRequest: campaignRequest,
       approvalRequired: campaignRequest.approvedPlacementUuids.length > 0
    }
  }

  handleCreditApplicationSharePointSavedChange(){
    const campaignRequest = this.state.campaignRequest
    if (!campaignRequest.creditApplicationSharePointSaved) {
      campaignRequest.noCreditApplicationReason = ""
    }
    campaignRequest.creditApplicationSharePointSaved = !this.state.campaignRequest.creditApplicationSharePointSaved
    this.props.isDirty()
    this.setState({
      campaignRequest: campaignRequest
    })
  }

  handleCampaignRequestValueChange(fieldName, value){
    const campaignRequest = this.state.campaignRequest
    campaignRequest[fieldName] = value
    this.props.isDirty()
    this.setState({
      campaignRequest: campaignRequest
    })
  }
  
  handleCampaignRequestValueToggle(fieldName){
    const campaignRequest = this.state.campaignRequest
    campaignRequest[fieldName] = !campaignRequest[fieldName]
    this.props.isDirty()
    this.setState({
      campaignRequest: campaignRequest
    })
  }

  handleIoSignedChange(){
    this.handleCampaignRequestValueToggle('ioSigned')
    const campaignRequest = this.state.campaignRequest
    if (campaignRequest.ioSigned) {
      campaignRequest.ioSharePointSaved = false
      this.setState({
        campaignRequest: campaignRequest
      })
    }
  }

  handleTemrsAndConditionsSignedChange(){
    this.handleCampaignRequestValueToggle('termsAndConditionsSigned')
    if (this.state.campaignRequest.termsAndConditionsSigned) {
      const campaignRequest = this.state.campaignRequest
      campaignRequest.termsAndConditionsSaved = false
      this.setState({
        campaignRequest: campaignRequest
      })
    }
  }

  handleApprovalRequiredChange(){
    this.props.isDirty()
    this.setState({
      approvalRequired: !this.state.approvalRequired
    })
  }

  handleSelectPlacement(placement){
    let idx = this.state.campaignRequest.approvedPlacementUuids.findIndex(apu => {
      return apu == placement.UUID
    })
    if (idx != -1) {
      this.state.campaignRequest.approvedPlacementUuids.splice(idx, 1)
    }
    this.props.isDirty()
    this.setState(this.state)
  }

  handleSelectApprovedPlacement(placement){
    this.state.campaignRequest.approvedPlacementUuids.push(placement.UUID)
    this.props.isDirty()
    this.setState(this.state)
  }

  render() {
    return (
      <Card shadow={2} className="clientDetails">
        <CardActions border>
          <Grid>
            <Cell col={12}>
              <Grid>
                <Cell col={6}>
                  <Switch
                    onChange={()=>this.handleCreditApplicationSharePointSavedChange()}
                    checked={this.state.campaignRequest.creditApplicationSharePointSaved}
                  >Credit Application Saved In Sharepoint</Switch>
                </Cell>
                <Cell col={6}>
                  <Textfield
                    disabled={this.state.campaignRequest.creditApplicationSharePointSaved}
                    floatingLabel
                    label="Reason for no saved credit application"
                    onChange={e => this.handleCampaignRequestValueChange('noCreditApplicationReason', e.target.value)}
                    value={this.state.campaignRequest.noCreditApplicationReason || ''}
                  />
                </Cell>
              </Grid>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={4}>
                  <Switch
                    onChange={()=>this.handleCampaignRequestValueToggle('creditApplicationProcessed')}
                    checked={this.state.campaignRequest.creditApplicationProcessed}
                  >Credit Application Processed</Switch>
                </Cell>
                <Cell col={4}>
                  <Textfield
                    floatingLabel
                    label="Manager Approved Amount"
                    onChange={e => this.handleCampaignRequestValueChange('approvedAmount', e.target.value)}
                    value={this.state.campaignRequest.approvedAmount || ''}
                  />
                </Cell>
                <Cell col={4}>
                  <Textfield
                    floatingLabel
                    label="Credit Remaining"
                    onChange={e => this.handleCampaignRequestValueChange('creditRemaining', e.target.value)}
                    value={this.state.campaignRequest.creditRemaining || ''}
                  />
                </Cell>
              </Grid>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={6}>
                  <Switch
                    onChange={()=> this.handleIoSignedChange()}
                    checked={this.state.campaignRequest.ioSigned || false}
                  >I/O Signed</Switch>
                </Cell>
                <Cell col={6}>
                  <Switch
                    disabled={!this.state.campaignRequest.ioSigned}
                    onChange={()=>this.handleCampaignRequestValueToggle('ioSharePointSaved')}
                    checked={this.state.campaignRequest.ioSharePointSaved || false}
                  >I/O Saved In Sharepoint</Switch>
                </Cell>
              </Grid>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={6}>
                  <Switch
                    onChange={()=> this.handleTemrsAndConditionsSignedChange()}
                    checked={this.state.campaignRequest.termsAndConditionsSigned || false}
                  >T&amp;C Signed</Switch>
                </Cell>
                <Cell col={6}>
                  <Switch
                    disabled={!this.state.campaignRequest.termsAndConditionsSigned}
                    onChange={()=>this.handleCampaignRequestValueToggle('termsAndConditionsSaved')}
                    checked={this.state.campaignRequest.termsAndConditionsSaved || false}
                  >T&amp;C Saved In Sharepoint</Switch>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
          <Grid>
            <Cell col={12}>
              <Grid>
                <Cell col={6}>
                  <Cell col={12}>
                    <Textfield
                      floatingLabel
                      label="How's the client evaluating the campaign?"
                      onChange={e=>this.handleCampaignRequestValueChange('evaluationMethod', e.target.value)}
                      value={this.state.campaignRequest.evaluationMethod || ''}
                    />
                  </Cell>
                  <Cell col={12}>
                    <Textfield
                      floatingLabel
                      label="What are the client goals?"
                      onChange={e=>this.handleCampaignRequestValueChange('clientGoals', e.target.value)}
                      value={this.state.campaignRequest.clientGoals || ''}
                    />
                  </Cell>
                  <Cell col={12}>
                    <Textfield
                      floatingLabel
                      label="Link To Campaign Vault"
                      onChange={e=>this.handleCampaignRequestValueChange('legacyVaultUrl', e.target.value)}
                      value={this.state.campaignRequest.legacyVaultUrl || ''}
                    />
                  </Cell>
                  <Cell col={12}>
                    <Switch
                      onChange={()=>this.handleApprovalRequiredChange()}
                      checked={this.state.approvalRequired || false}
                    >Site Approval Required</Switch>
                  </Cell>
                  {(!this.state.campaignRequest.approvedPlacementUuids || this.state.campaignRequest.approvedPlacementUuids.length == 0) ? null
                    :
                    <Cell col={12}>
                      <Card>
                        <CardTitle>Approved Placements</CardTitle>
                        <CardActions style={{ maxHeight: '240px', overflowY: 'scroll' }}>
                          <FilterList
                            label="Approved Placements"
                            additionalFieldList={['name', 'UUID']}
                            primaryField={'name'}
                            title="Approved Placements"
                            selectItem={p=>this.handleSelectPlacement(p)}
                            items={this.props.placements.filter(p => {
                              return this.state.campaignRequest.approvedPlacementUuids.indexOf(p.UUID) > -1
                            })}
                          />
                        </CardActions>
                      </Card>
                    </Cell>
                  }
                </Cell>
                <Cell col={6}>
                  <Grid>
                    <Cell col={12}>
                      <Card>
                        <CardTitle>Competitors</CardTitle>
                        <CardActions style={{ maxHeight: '240px', overflowY: 'scroll' }}>
                          <FilterList
                            label="Competitors"
                            selected={this.props.campaignRequest.UUID}
                            additionalFieldList={['name']}
                            primaryField={'name'}
                            title="Competitors"
                            selectItem={() => {
                              this.props.isDirty()
                            }}
                            items={[]}
                          />
                        </CardActions>
                      </Card>
                    </Cell>
                    {!this.state.approvalRequired ? null :
                      <Cell col={12}>
                        <Card>
                          <CardTitle>Placements</CardTitle>
                          <CardActions style={{ maxHeight: '240px', overflowY: 'scroll' }}>
                            <FilterList
                              label="Placements"
                              additionalFieldList={['name', 'UUID']}
                              primaryField={'name'}
                              title="Placements"
                              selectItem={p=>this.handleSelectApprovedPlacement(p)}
                              items={this.props.placements.filter(p => {
                                return this.state.campaignRequest.approvedPlacementUuids.indexOf(p.UUID) == -1
                              })}
                            />
                          </CardActions>
                        </Card>
                      </Cell>
                    }
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </CardActions>
      </Card>
    )
  }
}

const ClientDetails = connect(null, mapDispatchToProps)(
  clientDetails
)

export default ClientDetails
