import React from 'react'
import classNames from 'classnames'

class CellRendererByMessage extends React.Component {
        constructor(props) {
          super(props)
        }

        render() {
                let {columnIndex, key, rowIndex, style, comp, openSessionPopup} = this.props

                let dataRowNumber = rowIndex - 1
                
                if (rowIndex === 0) {
                        //first row - dimensions
                        if(columnIndex == 0){
                                return <div key={key} style={style} className="th dimension">{"Message"}</div>
                        }else{
                                return <div key={key} style={style} className="th metric">{"Rejections"}</div>
                        }
                }else{
                        var cellClasses = classNames({
                                'oddrow': rowIndex % 2 === 1,
                        })

                        if (columnIndex == 0) {
                                return (
                                <div key={key} style={style} className={"td dimension "+cellClasses}>
                                        {comp.messageRecords[dataRowNumber].message}
                                </div>
                                )
                        }else{
                                let cellStyle = {} 
                                return (
                                <div key={key} className={"td metric "+cellClasses} style={Object.assign(cellStyle, style)}>
                                        <div className={"content clickable"} onClick={()=> openSessionPopup(
                                                comp.serviceName,
                                                comp.messageRecords[dataRowNumber].message,
                                                )}>
                                                {comp.messageRecords[dataRowNumber].rejectionCount}
                                        </div>
                                </div>
                                )
                        }
                }
        }
}

export default CellRendererByMessage