const SAVE_STATE_OF_INTERNAL_BUDGET = 'SAVE_STATE_OF_INTERNAL_BUDGET'
const GENERATE_INTERNAL_BUDGET = 'GENERATE_INTERNAL_BUDGET'
const GENERATE_INTERNAL_BUDGET_FAILURE = 'GENERATE_INTERNAL_BUDGET_FAILURE'
const GENERATE_INTERNAL_BUDGET_SUCCESS = 'GENERATE_INTERNAL_BUDGET_SUCCESS'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_INTERNAL_BUDGET, data })

export const generateInternalBudget = () =>
    ({ type: GENERATE_INTERNAL_BUDGET })

export const generateInternalBudgetSuccess = data =>
    ({ type: GENERATE_INTERNAL_BUDGET_SUCCESS, data })

export const generateInternalBudgetFailure = error =>
    ({ type: GENERATE_INTERNAL_BUDGET_FAILURE, error })


export const internalBudgetState = (state = { report: null, isLoading: false, error: null, componentState: null }, action) => {
    switch (action.type) {
        case SAVE_STATE_OF_INTERNAL_BUDGET:
            return {
                ...state,
                componentState: action.data,
            }
        case GENERATE_INTERNAL_BUDGET:
            return {
                ...state,
                isLoading: true,
                report: null,
                error: null,
            }
        case GENERATE_INTERNAL_BUDGET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                report: {
                    highDeliveryPercentageCampaigns: action.data.report.highDeliveryPercentageCampaigns,
                    expiringSoonCampaigns: action.data.report.expiringSoonCampaigns,
                },
                slug: action.data.request.slug,
                request: action.data.request,
                error: null,
            }
        case GENERATE_INTERNAL_BUDGET_FAILURE:
            return {
                ...state,
                isLoading: false,
                report: null,
                error: { message: action.error },
            }
        default:
            return state
    }
}
