const SAVE_STATE_OF_BROKERED_LEAD_VIEWER_REPORT = 'SAVE_STATE_OF_BROKERED_LEAD_VIEWER_REPORT'
const GENERATE_BROKERED_LEAD_VIEWER_REPORT = 'GENERATE_BROKERED_LEAD_VIEWER_REPORT'
const GENERATE_BROKERED_LEAD_VIEWER_REPORT_FAILURE = 'GENERATE_BROKERED_LEAD_VIEWER_REPORT_FAILURE'
const GENERATE_BROKERED_LEAD_VIEWER_REPORT_SUCCESS = 'GENERATE_BROKERED_LEAD_VIEWER_REPORT_SUCCESS'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_BROKERED_LEAD_VIEWER_REPORT, data })

export const generateBrokeredLeadViewerReport = () =>
    ({ type: GENERATE_BROKERED_LEAD_VIEWER_REPORT })

export const generateBrokeredLeadViewerReportSuccess = data =>
    ({ type: GENERATE_BROKERED_LEAD_VIEWER_REPORT_SUCCESS, data })

export const generateBrokeredLeadViewerReportFailure = error =>
    ({ type: GENERATE_BROKERED_LEAD_VIEWER_REPORT_FAILURE, error })

export const brokeredLeadViewerReportState = (state = { report: null, isLoading: false, error: null, componentState: null }, action) => {
    switch (action.type) {
        case SAVE_STATE_OF_BROKERED_LEAD_VIEWER_REPORT:
            return {
                ...state,
                componentState: action.data,
            }
        case GENERATE_BROKERED_LEAD_VIEWER_REPORT:
            return {
                ...state,
                isLoading: true,
                report: null,
                error: null,
            }
        case GENERATE_BROKERED_LEAD_VIEWER_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                report: action.data.report,
                slug: action.data.request.slug,
                request: action.data.request,
                error: null,
            }
        case GENERATE_BROKERED_LEAD_VIEWER_REPORT_FAILURE:
            return {
                ...state,
                isLoading: false,
                report: null,
                error: { message: action.error },
            }
        default:
            return state
    }
}
