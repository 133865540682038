import React from "react"
import { Grid, Textfield, Cell, Card, CardTitle, List, ListItem, Switch, Button, Icon, IconButton } from "react-mdl"

class ValidationRow extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { validator } = this.props
    return (
      <ListItem key={validator.name}>
        <Grid style={{ width: "100%" }}>
          <Cell col={4}>
            <span>{validator.name}</span>
          </Cell>
          <Cell id={`${validator.name}-check-cell`} col={2}>
            <Switch
              id={this.props.id}
              onChange={() => this.props.onChange(!validator.enabled)}
              checked={validator.enabled}></Switch>
          </Cell>
          <Cell col={5}>{this.props.children}</Cell>
          <Cell col={1}>
            {validator.prevalidation ? (
              <Icon name="local_parking" title="Prevalidation" />
            ) : (
              <Icon name="check_circle" title="Postvalidation" />
            )}
            {validator.publisherSupported ? <Icon name="public" title="Publisher supported" /> : null}
          </Cell>
        </Grid>
      </ListItem>
    )
  }
}

class Validation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rolledUp: true
    }
  }

  normalizeValidatorList(validators, campaignValidators) {
    return validators
      .map(v => {
        let val = campaignValidators.find(cv => cv.name == v.name)
        return {
          name: v.name,
          prevalidation: v.prevalidation,
          publisherSupported: v.publisherSupported,
          enabled: val ? true : false
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  toggleRollUp(e) {
    e.preventDefault()
    this.setState({
      rolledUp: !this.state.rolledUp
    })
  }

  handleLookBackLimitChange(value) {
    this.props.handlePropertyChange("dedupeLookback", parseInt(value))
    this.setState(this.state)
  }

  handleThrottlePrepingChange() {
    this.props.handlePropertyToggle("cacheInitialPreping")
    this.setState(this.state)
  }

  handleDedupleSecondaryAddressChange() {
    this.props.handlePropertyToggle("dedupeSecondaryAddress")
    this.setState(this.state)
  }

  handleEmailValidationSaveButton() {
    if (this.props.emailValidationConfiguration.campaignUUID.length == 0) {
      this.props.addSaveCampaignCallBack(c => {
        const config = this.props.emailValidationConfiguration
        config.campaignUUID = c.UUID
        this.props.saveEmailValidation(config)
      })
    } else {
      this.props.saveEmailValidation(this.props.emailValidationConfiguration)
    }
    this.setState({
      editEmailValidation: !this.state.editEmailValidation
    })
  }

  handleEmailValidationCloseButton() {
    this.setState({
      editEmailValidation: !this.state.editEmailValidation
    })
  }

  handleEditButton() {
    this.setState({
      editEmailValidation: !this.state.editEmailValidation
    })
  }

  handleAddressValidationSaveButton() {
    const addressValidation = this.props.addressValidation
    
    this.props.addSaveCampaignCallBack(c => {
      addressValidation.campaignUUID = c.UUID
      this.props.saveAddressValidation(addressValidation)
    })

    this.setState({
      editAddressValidation: !this.state.editAddressValidation
    })
    this.props.isDirty()
  }

  handleAddressValidationCloseButton() {
    this.setState({
      editAddressValidation: !this.state.editAddressValidation
    })
  }

  handleAddressEditButton() {
    this.setState({
      editAddressValidation: !this.state.editAddressValidation
    })
  }

  handlePhoneVerificationSaveButton() {
    const phoneVerificationData = this.props.phoneVerification.data
          
    this.props.addSaveCampaignCallBack(c => {
      phoneVerificationData.campaignUUID = c.UUID
      this.props.savePhoneVerification(phoneVerificationData)
    })

    this.setState({
      editPhoneVerification: !this.state.editPhoneVerification
    })
    this.props.isDirty()
  }

  handlePhoneVerificationCloseButton() {
    this.setState({
      editPhoneVerification: !this.state.editPhoneVerification
    })
  }

  handlePhoneVerificationEditButton() {
    this.setState({
      editPhoneVerification: !this.state.editPhoneVerification
    })
  }

  handleNoneRadioButtonChange(value) {
    this.props.handlePropertyChange("phoneRequirement", parseInt(value))
    this.setState(this.state)
  }

  handleMobileRadionButtonChange(value) {
    this.props.handlePropertyChange("phoneRequirement", parseInt(value))
    this.props.onChange(this.state.campaign)
    this.setState(this.state)
  }

  handlePotsRadioButtonChange(value) {
    this.props.handlePropertyChange("phoneRequirement", parseInt(value))
    this.setState(this.state)
  }

  render() {
    const { validators } = this.props
    const campaignValidators = this.props.campaign.validators || []
    const normalizedCampaignValidators = this.normalizeValidatorList(validators, campaignValidators)
    let rollButton = (
      <Button id="validators-roll-button" onClick={e => this.toggleRollUp(e)}>
        <Icon name={this.state.rolledUp ? "keyboard_arrow_down" : "keyboard_arrow_up"} />
      </Button>
    )
    let listItems = normalizedCampaignValidators
      .slice(0, this.state.rolledUp ? 4 : this.props.validators.length)
      .map(v => {
        switch (v.name) {
          case "lead-deduplication":
            return (
              <ValidationRow
                id={v.name}
                key={v.name}
                validator={v}
                onChange={enabled => {
                  this.props.onChange(v.name, enabled)
                  this.setState(this.state)
                }}>
                <Textfield
                  style={{ maxWidth: "150px" }}
                  disabled={!v.enabled}
                  pattern="-?[0-9]*(\.[0-9]+)?"
                  error="Input is not a number!"
                  floatingLabel
                  label="Look-back limit"
                  onChange={e => this.handleLookBackLimitChange(e.target.value)}
                  value={this.props.campaign.dedupeLookback || ""}
                  maxLength={10}
                />
              </ValidationRow>
            )
          case "client-preping-verification":
            return (
              <ValidationRow
                id={v.name}
                key={v.name}
                validator={v}
                onChange={enabled => {
                  this.props.onChange(v.name, enabled)
                  this.setState(this.state)
                }}>
                <Switch
                  disabled={!v.enabled}
                  onChange={() => this.handleThrottlePrepingChange()}
                  checked={this.props.campaign.cacheInitialPreping || false}>
                  Throttle Preping
                </Switch>
              </ValidationRow>
            )
          case "household-deduplication":
            return (
              <ValidationRow
                id={v.name}
                key={v.name}
                validator={v}
                onChange={enabled => {
                  this.props.onChange(v.name, enabled)
                  this.setState(this.state)
                }}>
                <Switch
                  disabled={!v.enabled}
                  onChange={() => this.handleDedupleSecondaryAddressChange()}
                  checked={this.props.campaign.dedupeSecondaryAddress || false}>
                  Full Address
                </Switch>
              </ValidationRow>
            )
          case "email-validation":
            return (
              <ValidationRow
                id={v.name}
                key={v.name}
                validator={v}
                onChange={enabled => {
                  this.props.onChange(v.name, enabled)
                  this.setState(this.state)
                }}>
                {this.state.editEmailValidation ? (
                  <div>
                    <IconButton
                      id="save-email-validation-button"
                      name="save"
                      onClick={() => this.handleEmailValidationSaveButton()}
                    />
                    <IconButton name="close" onClick={() => this.handleEmailValidationCloseButton()} />
                  </div>
                ) : (
                  <IconButton
                    id="edit-email-validation-button"
                    disabled={!v.enabled}
                    name="edit"
                    onClick={() => this.handleEditButton()}
                  />
                )}
                {!this.state.editEmailValidation ? null : (
                  <div
                    style={{ position: "relative", top: "10px", left: "-140px", minWidth: "280px", padding: "10px" }}>
                    <div style={{ background: "lightgrey", padding: "5px" }}>
                      <Switch
                        disabled={!v.enabled}
                        onChange={() => {
                          this.props.emailValidationConfiguration.emailOversight = !this.props
                            .emailValidationConfiguration.emailOversight
                          this.setState(this.state)
                        }}
                        checked={this.props.emailValidationConfiguration.emailOversight || false}>
                        EmailOversight
                      </Switch>

                      <div style={{ margin: "20px" }}>
                        {this.props.emailValidationConfiguration.emailOversight ? (
                          <Switch
                            disabled={!v.enabled}
                            onChange={() => {
                              this.props.emailValidationConfiguration.strictEmailOversight = !this.props
                                .emailValidationConfiguration.strictEmailOversight
                              this.setState(this.state)
                            }}
                            checked={this.props.emailValidationConfiguration.strictEmailOversight || false}>
                            Strict
                          </Switch>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ padding: "5px" }}>
                      <Switch
                        disabled={!v.enabled}
                        onChange={() => {
                          this.props.emailValidationConfiguration.towerData = !this.props.emailValidationConfiguration
                            .towerData
                          this.setState(this.state)
                        }}
                        checked={this.props.emailValidationConfiguration.towerData || false}>
                        Tower Data
                      </Switch>
                    </div>
                  </div>
                )}
              </ValidationRow>
            )
          case "address-validation":
            return (
              <ValidationRow
                id={v.name}
                key={v.name}
                validator={v}
                onChange={enabled => {
                  this.props.onChange(v.name, enabled)
                  this.setState(this.state)
                }}>
                {this.state.editAddressValidation ? (
                  <div>
                    <IconButton
                      id="save-address-validation-button"
                      name="save"
                      onClick={() => this.handleAddressValidationSaveButton()}
                    />
                    <IconButton name="close" onClick={() => this.handleAddressValidationCloseButton()} />
                  </div>
                ) : (
                  <IconButton
                    id="edit-address-validation-button"
                    disabled={!v.enabled}
                    name="edit"
                    onClick={() => this.handleAddressEditButton()}
                  />
                )}
                {!this.state.editAddressValidation ? null : (
                  <div
                    style={{ position: "relative", top: "10px", left: "-140px", minWidth: "230px", padding: "10px" }}>
                    <Switch
                      disabled={!v.enabled}
                      onChange={() => {
                        this.props.addressValidation.config.melissa.suiteLevelEnabled = !this.props.addressValidation
                          .config.melissa.suiteLevelEnabled
                        this.setState(this.state)
                      }}
                      checked={this.props.addressValidation.config.melissa.suiteLevelEnabled}>
                      Suite Level
                    </Switch>
                    <Switch
                      disabled={!v.enabled}
                      onChange={() => {
                        this.props.addressValidation.config.melissa.militaryZipcodeAccepted = !this.props
                          .addressValidation.config.melissa.militaryZipcodeAccepted
                        this.setState(this.state)
                      }}
                      checked={this.props.addressValidation.config.melissa.militaryZipcodeAccepted}>
                      Military Zipcode Accepted
                    </Switch>
                    <Switch
                      disabled={!v.enabled}
                      onChange={() => {
                        this.props.addressValidation.config.melissa.uniqueZipcodeAccepted = !this.props
                          .addressValidation.config.melissa.uniqueZipcodeAccepted
                        this.setState(this.state)
                      }}
                      checked={this.props.addressValidation.config.melissa.uniqueZipcodeAccepted}>
                      Unique Zipcode Accepted
                    </Switch>
                  </div>
                )}
              </ValidationRow>
            )
          case "phone-verification":
            return this.renderPhoneVerification(v)
          case "required-fields":
            return null
          default:
            return (
              <ValidationRow
                id={v.name}
                key={v.name}
                validator={v}
                onChange={enabled => {
                  this.props.onChange(v.name, enabled)
                  this.setState(this.state)
                }}
              />
            )
        }
      })
    return (
      <Card style={{ overflow: "visible", width: "100%" }}>
        <CardTitle
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "5px" }}>
          <h2 className="mdl-card__title-text">Validations</h2>
          <div style={{ display: "flex", fontSize: "8px", justifyContent: "right", paddingRight: "0px" }}>
            <span style={{ margin: "0px 7px 0px 0px" }}>
              <Icon name="local_parking" />
              Prev
            </span>
            <span style={{ margin: "0px 7px 0px 0px" }}>
              <Icon name="check_circle" />
              Post
            </span>
            <span>
              <Icon name="public" />
              Publisher
            </span>
          </div>
        </CardTitle>
        <List style={{ width: "100%", paddingBottom: "0px" }}>{listItems}</List>
        {rollButton}
      </Card>
    )
  }

  renderPhoneVerificationConfigs(v) {
    return this.props.phoneVerification.matchWith({
      LoadingPhoneVerificationCampaignConfig: () => "Loading ...",
      PhoneVerificationCampaignConfigError: () => (
        <div style={{ color: "#FF0000" }}>&quot;Failed: can not load configs&quot;</div>
      ),
      PhoneVerificationCampaignConfigLoaded: ({ data }) => {
        data.config.emailOversight = data.config.emailOversight || {}
        data.config.twilio = data.config.twilio || {}
        data.config.targus = data.config.targus || {}
        return (
          <div>
            {!v.enabled ? null : (
              <div>
                {data.config.targus.enabled || data.config.twilio.enabled || data.config.emailOversight.enabled
                  ? "Premium Validation Enabled"
                  : "Basic Validation Enabled"}
              </div>
            )}
            {this.state.editPhoneVerification ? (
              <div>
                <IconButton
                  id="save-phone-verification-button"
                  name="save"
                  onClick={() => this.handlePhoneVerificationSaveButton()}
                />
                <IconButton name="close" onClick={() => this.handlePhoneVerificationCloseButton()} />
              </div>
            ) : (
              <IconButton
                id="edit-phone-verification-button"
                disabled={!v.enabled}
                name="edit"
                onClick={() => this.handlePhoneVerificationEditButton()}
              />
            )}
            <span style={{ fontSize: "10px" }}>Configure Premium Validation</span>
            {!this.state.editPhoneVerification ? null : (
              <div style={{ position: "relative", top: "10px", left: "-140px", minWidth: "230px", padding: "10px" }}>
                <Switch
                  disabled={!v.enabled}
                  onChange={() => {
                    data.config.emailOversight.enabled = !data.config.emailOversight.enabled
                    this.setState(this.state)
                  }}
                  checked={data.config.emailOversight.enabled}>
                  Email Oversight
                </Switch>
                {!data.config.emailOversight.enabled ? null : (
                  <React.Fragment>
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="0">
                      <input
                        onChange={ev => {
                          data.config.emailOversight.lineType = parseInt(ev.target.value)
                          this.setState(this.state)
                        }}
                        type="radio"
                        checked={data.config.emailOversight.lineType == 0}
                        name="phoneReq"
                        value="0"
                        className="mdl-radio__button"
                      />
                      <span className="mdl-radio__label">All</span>
                    </label>
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="1">
                      <input
                        onChange={ev => {
                          data.config.emailOversight.lineType = parseInt(ev.target.value)
                          this.setState(this.state)
                        }}
                        type="radio"
                        checked={data.config.emailOversight.lineType == 1}
                        name="phoneReq"
                        value="1"
                        className="mdl-radio__button"
                      />
                      <span className="mdl-radio__label">Land Line</span>
                    </label>
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="2">
                      <input
                        onChange={ev => {
                          data.config.emailOversight.lineType = parseInt(ev.target.value)
                          this.setState(this.state)
                        }}
                        type="radio"
                        checked={data.config.emailOversight.lineType == 2}
                        name="phoneReq"
                        value="2"
                        className="mdl-radio__button"
                      />
                      <span className="mdl-radio__label">Mobile</span>
                    </label>
                  </React.Fragment>
                )}
                <Switch
                  disabled={!v.enabled}
                  onChange={() => {
                    data.config.targus.enabled = !data.config.targus.enabled
                    this.setState(this.state)
                  }}
                  checked={data.config.targus.enabled}>
                  Targus
                </Switch>
                <Switch
                  disabled={!v.enabled}
                  onChange={() => {
                    data.config.twilio.enabled = !data.config.twilio.enabled
                    this.setState(this.state)
                  }}
                  checked={data.config.twilio.enabled}>
                  Twilio
                </Switch>
              </div>
            )}
          </div>
        )
      }
    })
  }

  renderPhoneVerification(v) {
    return (
      <ValidationRow
        id={v.name}
        key={v.name}
        validator={v}
        summary={"summarized"}
        onChange={enabled => {
          this.props.onChange(v.name, enabled)
          this.setState(this.state)
        }}>
        {this.renderPhoneVerificationConfigs(v)}
      </ValidationRow>
    )
  }
}

export default Validation
