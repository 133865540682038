import React from 'react'
import { CellMeasurer } from 'react-virtualized'
import classNames from 'classnames'

class CellRendererByDate extends React.Component {
        constructor(props) {
                super(props)
        }

        render() {
                let { columnIndex, key, rowIndex, parent, cache } = this.props

                return (<CellMeasurer
                        cache={cache}
                        columnIndex={columnIndex}
                        key={key}
                        parent={parent}
                        rowIndex={rowIndex}>
                        {this.renderContent()}
                </CellMeasurer>)

        }

        renderContent() {
                let { columnIndex, key, rowIndex, style, comp } = this.props
                let dataRowNumber = rowIndex - 2
                let dataColumnNumber = columnIndex - 3
                if (rowIndex === 0) {
                        if (columnIndex <= 2) {
                                //first row - dimensions
                                //if(columnIndex == 0){
                                //        return (
                                //                <div key={key} style={{...style, width: style.width*2 }} className="th dimension">
                                //                        {``}
                                //                </div>
                                //                )
                                //}else{
                                //        return <div style={{ display: 'none' }}/>
                                //}
                                return <div key={key} style={style} className="th dimension">{""}</div>
                        } else {
                                //first row - pivots/metrics
                                if ((columnIndex - 3) % 3 === 0) {
                                        let pivotNumber = (columnIndex - 3) / 3
                                        return (
                                                <div key={key} style={{ ...style, width: style.width * 3 }} className="th pivot">
                                                        {comp.PivotSettings.PivotDimensionValues[pivotNumber]}
                                                </div>
                                        )
                                } else {
                                        return <div style={{ display: 'none' }} />
                                }
                        }
                } else if (rowIndex === 1) {
                        //second row - dimensions
                        if (columnIndex == 0) {
                                return <div key={key} style={style} className="th dimension">{"Placement"}</div>
                        } else if (columnIndex == 1) {
                                return <div key={key} style={style} className="th dimension">{"Campaign"}</div>
                        } else if (columnIndex == 2) {
                                return <div key={key} style={style} className="th dimension">{"Short Code"}</div>
                        } else {
                                //second row - metrics
                                let mod = (columnIndex - 3) % 3
                                if (mod === 0) {
                                        return <div key={key} style={style} className="th metric">{"Passed"}</div>
                                } else if (mod === 1) {
                                        return <div key={key} style={style} className="th metric">{"Failed"}</div>
                                } else if (mod === 2) {
                                        return <div key={key} style={style} className="th metric">{"Percent Valid"}</div>
                                }
                        }
                } else {
                        var cellClasses = classNames({
                                'oddrow': rowIndex % 2 === 1,
                        })

                        if (columnIndex == 0) {
                                return (
                                        <div key={key} style={style} className={"td dimension " + cellClasses}>
                                                {comp.RowCluster[dataRowNumber].RowKeys[0].DisplayKey}
                                        </div>
                                )
                        } else if (columnIndex == 1) {
                                return (
                                        <div key={key} style={style} className={"td dimension " + cellClasses}>
                                                {comp.RowCluster[dataRowNumber].RowKeys[2].DisplayKey}
                                        </div>
                                )
                        } else if (columnIndex == 2) {
                                return (
                                        <div key={key} style={style} className={"td dimension " + cellClasses}>
                                                {comp.RowCluster[dataRowNumber].RowKeys[3].DisplayKey}
                                        </div>
                                )
                        } else {
                                let cellStyle = {}
                                let bgColor = comp.RowCluster[dataRowNumber].RowValueSets[0].RowValues[dataColumnNumber].BackgroundColor
                                if (bgColor) {
                                        cellStyle = { backgroundColor: bgColor }
                                }
                                return (
                                        <div key={key} className={"td metric " + cellClasses} style={Object.assign(cellStyle, style)}>
                                                <div className={"content"}>{comp.RowCluster[dataRowNumber].RowValueSets[0].RowValues[dataColumnNumber].DataValue}</div>
                                        </div>
                                )
                        }

                }
        }
}
export default CellRendererByDate