import {
  removeCampaignGroupMember,
  saveCampaignToGroup,
} from '../../services'
import React from 'react'
import { connect } from 'react-redux'
import Groups from '../common/groups'
import { Grid, Cell } from 'react-mdl'


function mapDispatchToProps(dispatch) {
  return {
    removeGroupMember: function (uuid) {
      dispatch(removeCampaignGroupMember(uuid))
    },
    saveCampaignToGroup: function (targetCampaign, group) {
      dispatch(saveCampaignToGroup(targetCampaign, group))
    },
  }
}

class grouping extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <Grid style={{ width: '100%' }}>
        <Cell col={12}>
          <Grid style={{ width: '100%' }}>
            <Cell col={6}>
              <Groups 
                itemName={"Campaign"}
                item={this.props.campaign}
                group={this.props.group}
                items={this.props.campaigns}
                saveItemToGroup={this.props.saveCampaignToGroup}
                removeGroupMember={this.props.removeGroupMember}
              />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    )
  }
}

const Grouping = connect(null, mapDispatchToProps)(grouping)
export default Grouping
