import {
    startLoadingSessionDetails,
    loadedSessionDetails
} from '../reducers/sessionDetailsReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

//const url = "http://localhost:9001/session-details/"
//const url = "http://localhost:9001/session-details-mock/"
const url = getUrlFor("reporting", "session-details")
//const url = getUrlFor("reporting", "session-details-mock")

export function loadSessionDetailsThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(startLoadingSessionDetails())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(loadedSessionDetails(criteria, res.data))
            }).catch(e => {
                exceptionHandling(e, dispatch, "Getting session details")
            })
    }
}
