import React from 'react'
import { connect } from 'react-redux'
import { deleteQuestion, saveQuestion, reorderQuestions, ActionTypes } from '../../services'
import Question from '../question/question'
import Questions from '../question/questions'
import Answers from '../question/answers'
import Answer from '../question/answer'
import { deepClone } from '../../utils'
import { Card, Grid, Cell, Textfield, Icon, FABButton, CardTitle, CardMenu } from 'react-mdl'


function mapDispatchToProps(dispatch) {
  return {
    deleteQuestion: function (q, campaignUUID) {
      dispatch(deleteQuestion(q.UUID, campaignUUID))
    },
    isDirty: function () {
      dispatch({ type: ActionTypes.IS_DIRTY, data: true })
    },
    saveQuestion: function (q, campaignUUID) {
      dispatch(saveQuestion(q, campaignUUID))
    },
    reorderQuestions: function (campaignUUID, questions) {
      dispatch(reorderQuestions(campaignUUID, questions))
    },
  }
}

const INDEX_INIT_VALUE = -1
const EMPTY_QUESTION_OBJECT = {answers: { records: [] }, type: 0, isHidden: false, isAnswerRequired: false}
const EMPTY_ANSWER_OBJECT = {display: "",
                             value: "",
                             isPreselected: false,
                             isDisqualifying: false,
                             isHidden: false,
                             correctPathQuestions: []
                            }

class question extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedQuestionIndex: INDEX_INIT_VALUE,
      questionHeader: props.campaign.questionHeader || "",
      questionFooter: props.campaign.questionFooter || "",
      questionImageUrl: props.campaign.questionImageUrl || "",
      selectedQuestion: null,
      selectedAnswer: null,
      selectedAnswerIndex: null,
      questionTitle: null,
      isQuestionDirty: false,
    }
    this.props.setGetCampaign(() => {
      let camp = this.props.campaign
      camp.questionHeader = this.state.questionHeader
      camp.questionFooter = this.state.questionFooter
      camp.questionImageUrl = this.state.questionImageUrl
      return camp
    })

    this.handleQuestionSave = this.handleQuestionSave.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.questions !== nextProps.questions) {
      this.setIsDirty(false)
    }
    return true
  }

  setIsDirty(flag){
    this.setState({
      isQuestionDirty : flag,
    })
  }

  handleQuestionSave(){
    this.props.saveQuestion(this.state.selectedQuestion, this.props.campaign.UUID)
    this.setState({
      selectedQuestionIndex : INDEX_INIT_VALUE,
      selectedQuestion : null,
    })
    this.setIsDirty(true)
  }

  handleQuestionDelete(){
    this.props.deleteQuestion(this.state.selectedQuestion, this.props.campaign.UUID)
    this.setState({
      selectedQuestionIndex : INDEX_INIT_VALUE,
      selectedQuestion : null,
    })
    this.setIsDirty(true)
  }
  
  handleQuestionChange(field, value){
    const {selectedQuestion} = this.state
    selectedQuestion[field] = value
    this.setState({
      selectedQuestion : selectedQuestion,
    })
    this.setIsDirty(true)
  }

  handleQuestionTypeChange(value){
    const {selectedQuestion} = this.state
    selectedQuestion.type = value
    selectedQuestion.validationService = null
    this.setState({
      selectedQuestion : selectedQuestion,
    })
    this.setIsDirty(true)
  }

  handleRegexpValueChange(value){
    const {selectedQuestion} = this.state
    selectedQuestion.regexp = value
    this.setState({
      selectedQuestion : selectedQuestion,
    })
    this.setIsDirty(true)
  }

  handleRegexpUsedChange(){
    const {selectedQuestion} = this.state
    selectedQuestion.regexpUsed = !selectedQuestion.regexpUsed 
    this.setState({
      selectedQuestion : selectedQuestion,
    })
    this.setIsDirty(true)
  }


  handleQuestionIsAnswerRequiredChange(){
    const {selectedQuestion} = this.state
    selectedQuestion.isAnswerRequired = !selectedQuestion.isAnswerRequired
    selectedQuestion.validationService = null
    this.setState({
      selectedQuestion : selectedQuestion,
    })
    this.setIsDirty(true)
  }

  handleQuestionIsHiddenChange(){
    const {selectedQuestion} = this.state
    selectedQuestion.isHidden = !selectedQuestion.isHidden
    this.setState({
      selectedQuestion : selectedQuestion,
    })
    this.setIsDirty(true)
  }
  
  handleAnswerChange(field, value){
    const {selectedAnswer} = this.state
    selectedAnswer[field] = value
    this.setState({
      selectedAnswer : selectedAnswer,
    })
    this.setIsDirty(true)
  }
  
  handleAnswerFieldToggle(field){
    const {selectedAnswer} = this.state
    selectedAnswer[field] = !selectedAnswer[field]
    this.setState({
      selectedAnswer : selectedAnswer,
    })
  }

  handleAnswerFollowUpChange(question){
    const {selectedAnswer} = this.state
    selectedAnswer.correctPathQuestions = [question.UUID]
    this.setState({
      selectedAnswer : selectedAnswer,
    })
  }

  save(question, answer) {
    if (answer && answer.UUID && answer.UUID.length > 0) {
      question.answers.records[
        question.answers.records.findIndex(a => a.UUID == answer.UUID)
      ] = answer
    } else if (answer && answer.value && answer.value.length > 0) {
      question.answers.records.push(answer)
    }
    if (question.UUID){
      this.props.saveQuestion(question, this.props.campaign.UUID)
    }
  }

  handleQuestionSelected(index){
    const questionCopy = Object.assign({}, this.props.questions[index])
    delete questionCopy.element
    const questionPosition = index + 1
    this.setState({
      selectedQuestionIndex : index,
      selectedQuestion : questionCopy,
      questionTitle : `Question number ${questionPosition}`
    })
    this.setIsDirty(false)
  }

  handleQuestionHeaderChange(header){
    this.setState({
      questionHeader : header
    })
    this.props.isDirty()
  }
  
  handleQuestionFooterChange(footer){
    this.setState({
      questionFooter : footer 
    })
    this.props.isDirty()
  }

  handleQuestionImageUrlChange(imageUrl){
    this.setState({
      questionImageUrl : imageUrl
    })
    this.props.isDirty()
  }

  handleAddQuestionButton(){
    this.setState({
      selectedQuestionIndex : INDEX_INIT_VALUE,
      selectedQuestion : deepClone(EMPTY_QUESTION_OBJECT),
      selectedAnswer : null,
      questionTitle : "New question"
    })
    this.setIsDirty(true)
  }

  handleCloseQuestionForm(){
    this.setState({
      selectedQuestionIndex : null,
      selectedQuestion : null,
      selectedAnswer : null,
      questionTitle : null, 
    })

  }

  handleAddAnswerButton(){
    this.setState({
      selectedAnswer : deepClone(EMPTY_ANSWER_OBJECT),
    })
    this.setIsDirty(true)
  }

  handleAnswerFormClose(){
    this.setState({
      selectedAnswer : null,
    })
  }

  handleDeleteAnswer(index){
    const answers = this.state.selectedQuestion.answers.records
    answers.splice(index, 1)
    this.setState({
      selectedAnswer : null,
      selectedAnswerIndex : null,
    })
    this.setIsDirty(true)
  }

  handleSaveAnswer(){
    let answerRecords = this.state.selectedQuestion.answers.records
    if(this.state.selectedAnswerIndex != null){
      answerRecords[this.state.selectedAnswerIndex] = this.state.selectedAnswer
    }else{
      answerRecords.push(this.state.selectedAnswer)
    }
    this.setState({
      selectedAnswer : null,
      selectedAnswerIndex : null,
      selectedQuestion: {
        ...this.state.selectedQuestion,
        answers:{
          ...this.state.selectedQuestion.answers,
          records: answerRecords,
        }
      }
    })
    this.setIsDirty(true)
  }

  handleAnswerSelected(index){
    const answerCopy = Object.assign({},this.state.selectedQuestion.answers.records[index])
    delete answerCopy.element
    this.setState({
      selectedAnswer : answerCopy,
      selectedAnswerIndex : index,
    })
  }

  handleDeleteQuestion(index){
    const question = this.props.questions[index]
    this.props.deleteQuestion(question, this.props.campaign.UUID)
  }

  handleAnswersReWeighted(answers){
    const {selectedQuestion} = this.state
    selectedQuestion.answers.records = answers
    this.props.saveQuestion(this.state.selectedQuestion, this.props.campaign.UUID)
    this.setState({
      selectedQuestion : selectedQuestion,
    })
  }

  handleQuestionsReWeighted(questions){
    this.props.reorderQuestions(this.props.campaign.UUID, questions)
  }

  render() {
    return (
      <Grid style={{ width: '99%' }}>
        <Cell col={12}>
          <Card shadow={2} style={{ overflow: 'visible', width: '100%' }}>
          <CardTitle>Questions</CardTitle>
            <CardMenu>
              <Textfield
                style={{marginRight: "30px"}}
                floatingLabel
                label="Questions Header"
                onChange={ e => this.handleQuestionHeaderChange(e.target.value) }
                value={this.state.questionHeader}
            />
            <Textfield
              style={{marginRight: "30px"}}
              floatingLabel
              label="Questions Footer"
              onChange={ e => this.handleQuestionFooterChange(e.target.value) }
              value={this.state.questionFooter}
            />
            <Textfield
              style={{marginRight: "30px"}}
              floatingLabel
              label="Questions Image URL"
              onChange={ e => this.handleQuestionImageUrlChange(e.target.value) }
              value={this.state.questionImageUrl}
            />
            <FABButton
              className="fabPosition"
              onClick={() => this.handleAddQuestionButton() }
              mini
              colored>
              <Icon name="add" />
            </FABButton>
          </CardMenu>
          <Grid style={{ width: '99%' }}>
            <Cell col={12}>
              {this.state.selectedQuestion ? <Question
                title={this.state.questionTitle}
                isDirty={this.state.isQuestionDirty}
                validators={this.props.validators}
                question={this.state.selectedQuestion}
                save={this.handleQuestionSave}
                delete={ () => this.handleQuestionDelete() }
                close={ () => this.handleCloseQuestionForm() }
                addAnswer={ () => this.handleAddAnswerButton() }
                handleQuestionValueChange={ (e) => this.handleQuestionChange('value', e.target.value) }
                handleQuestionTypeChange={ (selected) => this.handleQuestionTypeChange(selected.value) }
                handleQuestionValidationChange={ (selected) => this.handleQuestionChange('validationService', selected.value)}
                handleQuestionIsAnswerRequiredChange={ () => this.handleQuestionIsAnswerRequiredChange() }
                handleQuestionIsHiddenChange={ () => this.handleQuestionIsHiddenChange() }
                handleRegexpValueChange={ (e) => this.handleRegexpValueChange(e.target.value)}
                handleRegexpUsedChange={ () => this.handleRegexpUsedChange()}
              >
                <div style={{padding: "5px", borderTop: "1px solid black", borderBottom: "1px solid black", margin: "0px 10px", textAlign : "center", backgroundColor : "#E0E0E0"}}>Answers</div>
                {this.state.selectedAnswer ? <Answer
                      delete={ () => this.handleDeleteAnswer(this.state.selectedAnswerIndex) }
                      save={ () => this.handleSaveAnswer()}
                      close={ () => this.handleAnswerFormClose() }
                      questions={this.props.questions.filter((q) => q.UUID != this.state.selectedQuestion.UUID)}
                      validators={this.props.validators}
                      question={this.state.question}
                      answer={this.state.selectedAnswer}
                      selectedIndex={this.state.selectedAnswerIndex}
                      handleAnswerDisplay={ e => this.handleAnswerChange('display', e.target.value) }
                      handleAnswerIsCorrentChange={ () => this.handleAnswerFieldToggle('isDisqualifying') }
                      handleAnswerIsPreselected={ () => this.handleAnswerFieldToggle('isPreselected') }
                      handleAnswerIsHidden={ () => this.handleAnswerFieldToggle('isHidden') }
                      handleAnswerPlacementChange={ e => this.handleAnswerChange('value', e.target.value)}
                      handleAnswerFollowUpChange={ selected => this.handleAnswerFollowUpChange(selected.value)}
                      />  : null}
                <Answers
                  questions={this.props.questions}
                  answers={'UUID' in this.state.selectedQuestion ? this.props.questions[this.state.selectedQuestionIndex].answers.records : this.state.selectedQuestion.answers.records}
                  selected={ index => this.handleAnswerSelected(index) }
                  onDelete={ index => this.handleDeleteAnswer(index) }
                  reWeighted={ answers => this.handleAnswersReWeighted(answers) } 
                />
              </Question>
              : null } 
            </Cell>
            <Cell col={12}>
              <Questions
                questions={this.props.questions}
                selected={ index => this.handleQuestionSelected(index)}
                onDelete={ index => this.handleDeleteQuestion(index) }
                reWeighted={ (questions) =>  this.handleQuestionsReWeighted(questions) }
              />
            </Cell>
            </Grid>
        </Card>
      </Cell>
    </Grid>
  )
}
}

const CampaignQuestions = connect(null, mapDispatchToProps)(question)

export default CampaignQuestions
