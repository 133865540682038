import CriteriaFilter from "../criteria/criteriaFilter"
import Stats from "./stats"
import RequiredFields from "./fieldRequirements"
import DatePicker from "react-datepicker"
import React from "react"
import Validation from "./validators"
import moment from "moment"
import Select from "react-select"
import { deepClone } from "../../utils"
import ItemsCollection from "../common/itemsCollection"
import { Checkbox, Card, CardActions, Grid, Cell, Textfield, Switch, Spinner } from "react-mdl"
import { USStates } from "../../utils/states"
import { Prompt } from "react-router"

const USstatesCodes = new USStates().States.map(c => c.code)

export default class CampaignDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start_date: this.normalizeDate(props.campaign.startDate),
      end_date: this.normalizeDate(props.campaign.endDate),
      campaign: deepClone(props.campaign)
    }
    this.props.updateCampaignName(props.campaign.name)
    this.props.setGetCampaign(() => {
      const { campaign } = this.state
      if (this.state.start_date) {
        campaign.startDate = this.state.start_date.unix()
      }
      if (this.state.end_date) {
        campaign.endDate = this.state.end_date.unix()
      }
      return campaign
    })
  }

  handleAddAddressValidation(suiteLevel) {
    let x = this.props.addressValidation
    x.config.melissa.enabled = true
    x.config.melissa.suiteLevelEnabled = suiteLevel
    this.props.saveAddressValidation(x)
    let campaign = this.state.campaign
    let currentValidators = campaign.validators || []
    currentValidators.push({ name: "address-validation" })
    campaign.validators = currentValidators
    this.setState({
      campaign: campaign
    })
  }

  handlePhoneValidationChange() {
    const { config } = this.props.phoneVerificationCampaignConfig
    this.props.phoneVerificationCampaignConfig.config.targus.enabled = !config.targus.enabled
    this.props.phoneVerificationCampaignConfig.config.twilio.enabled = !config.twilio.enabled
    this.props.isDirty(true)
    this.setState({})
  }

  handleNameChange(name) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.name = name
    this.props.updateCampaignName(name)
    this.setState({
      campaign: campaign
    })
  }

  handleIsArchivedChange() {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.isArchived = !campaign.isArchived
    this.setState({
      campaign: campaign
    })
  }

  handleIsPausedChange() {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.isPaused = !campaign.isPaused
    this.setState({
      campaign: campaign
    })
  }
  handleIsMicrositeChange() {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.isMicrosite = !campaign.isMicrosite
    this.setState({
      campaign: campaign
    })
  }
  handleSalesPersonChange(salesUserKey) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.salesUserKey = salesUserKey
    this.setState({
      campaign: campaign
    })
  }
  handleCustomerChange(customerUUID) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.customerUUID = customerUUID
    this.setState({
      campaign: campaign
    })
  }
  handleDailyCapChange(dailyCapConfig) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.dailyCapConfig = dailyCapConfig
    this.setState({
      campaign: campaign
    })
  }
  handleExpression(filterExpression) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.filterExpression = filterExpression
    this.setState({
      campaign: campaign
    })
  }
  handleLeadTTLOffset(offsetInDays) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.leadTTLOffset = offsetInDays * 24 * 60 * 60 * 1000
    this.setState({
      campaign: campaign
    })
  }

  handleCustomSettings(customSettings) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.customSettings = customSettings
    this.setState({
      campaign: campaign
    })
  }

  handleOptinTTLOffset(offsetInDays) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.optinTTLOffset = offsetInDays * 24 * 60 * 60 * 1000
    this.setState({
      campaign: campaign
    })
  }
  handleIPV6Change(ipv6Allowed) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign.ipv6Allowed = ipv6Allowed
    this.setState({
      campaign: campaign
    })
  }

  handleDateChange(dateFieldName, date) {
    this.props.isDirty(true)
    this.setState({
      [dateFieldName]: moment(date)
    })
  }

  handleCollectionChange(collectionName, item, index) {
    this.props.isDirty(true)
    const { campaign } = this.state
    if (!campaign[collectionName]) {
      campaign[collectionName] = []
    }
    if (index === -1) {
      campaign[collectionName].push(item)
    } else {
      campaign[collectionName][index] = item
    }
    this.setState({
      campaign: campaign
    })
  }

  handleCollectionRemove(collectionName, index) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign[collectionName].splice(index, 1)
    this.setState({
      campaign: campaign
    })
  }

  handleRequiredFieldsChange(requiredFieldName, requiredFieldValue) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign[requiredFieldName] = requiredFieldValue
    this.setState({
      campaign: campaign
    })
  }

  handleCriteriaFilterChange(criteriaName, isInclude, value) {
    this.props.isDirty(true)
    if (criteriaName == "gender") {
      this.props.campaignCriteria.criteriaFilter[criteriaName] = { exclude: [], include: [] }
    }
    const criteriaType = isInclude ? "include" : "exclude"
    let currentCriteriaFilter = this.props.campaignCriteria.criteriaFilter[criteriaName] || { exclude: [], include: [] }
    let criterias = currentCriteriaFilter[criteriaType] || []
    criterias.push(value)
    currentCriteriaFilter[criteriaType] = criterias
    this.props.campaignCriteria.criteriaFilter[criteriaName] = currentCriteriaFilter
  }

  handleCriteriaFilterRemove(criteriaName, criteriaType, index) {
    this.props.isDirty(true)
    this.props.campaignCriteria.criteriaFilter[criteriaName] = this.props.campaignCriteria.criteriaFilter[
      criteriaName
    ] || { include: [], exclude: [] }
    this.props.campaignCriteria.criteriaFilter[criteriaName][criteriaType].splice(index, 1)
    this.setState(this.state)
  }

  handleCriteriaFilterStateChange(criteriaType, index) {
    this.props.isDirty(true)
    let selectedItem = this.props.campaignCriteria
    const removedItem = selectedItem.criteriaFilter["state"][criteriaType].splice(index, 1)[0]
    selectedItem.criteriaFilter["state"][criteriaType].sort()
    const otherCriteriaType = criteriaType === "include" ? "exclude" : "include"
    selectedItem.criteriaFilter["state"][otherCriteriaType].push(removedItem)
    selectedItem.criteriaFilter["state"][otherCriteriaType].sort()
    this.setState(this.state)
  }

  handleValidatorsChange(validatorName, enabled) {
    this.props.isDirty(true)
    const validator = { name: validatorName }
    const { campaign } = this.state
    let currentValidators = campaign.validators || []
    if (enabled) {
      currentValidators.push(validator)
    } else {
      const index = currentValidators.map(v => v.name).indexOf(validatorName)
      if (index >= 0) {
        currentValidators.splice(index, 1)
      }
    }
    campaign.validators = currentValidators
    this.setState({
      campaign: campaign
    })
  }

  handlePropertyToggle(propertyName) {
    this.props.isDirty(true)
    const { campaign } = this.state
    campaign[propertyName] = !campaign[propertyName]
    this.setState({
      campaign: campaign
    })
  }

  handlePropertyChange(propertyName, value) {
    const { campaign } = this.state
    campaign[propertyName] = value
    this.setState({
      campaign: campaign
    })
  }

  handleZipCriteriaFilterChange(zipCodes, isInclude) {
    this.props.isDirty(true)
    const criteriaType = isInclude ? "include" : "exclude"
    let zipCriteriaFilter = this.props.campaignCriteria.criteriaFilter.zip || { exclude: [], include: [] }
    zipCriteriaFilter[criteriaType] = zipCodes
    this.props.campaignCriteria.criteriaFilter.zip = zipCriteriaFilter
  }

  normalizeDate(date) {
    let resultDate = parseInt(date)
    if (!resultDate || isNaN(resultDate)) {
      return null
    }
    return moment.unix(resultDate)
  }

  compareEffectiveDate(a, b) {
    if (a.effectiveDate < b.effectiveDate) {
      return 1
    }
    if (a.effectiveDate > b.effectiveDate) {
      return -1
    }
    return 0
  }

  mapActive() {
    var found = false
    return (e, i, a) => {
      let scheduled = e.effectiveDate > moment().unix()
      var active = !found && !scheduled && (i + 1 === a.length || this.compareEffectiveDate(a[i], a[i + 1]) < 1)
      found = found || active
      if (active) {
        return e
      }
      return null
    }
  }

  getOverallCount() {
    let active = this.props.campaign.contractedLeadCaps
      .sort(this.compareEffectiveDate)
      .map(this.mapActive())
      .filter(x => x)
    let current = {}
    if (active.length > 0) {
      current = active[0]
    }
    if (!this.props.campaign.contractedLeadCaps || this.props.campaign.contractedLeadCaps.length === 0) {
      return ""
    }
    let historical = this.props.campaign.contractedLeadCaps.map(olc => parseInt(olc.value)).reduce((a, b) => a + b, 0)
    return `Currently contracted - ${current.value} | Total - ${historical}`
  }

  renderSalesUserSelect(customerUUID) {
    if(this.props.customers?.isLoading)return <div><Spinner singleColor /></div>
    const customer = this.props.customers?.data?.find(e=> e.UUID === customerUUID)
    if(this.props.customers?.errorText?.length > 0){
      return <div>Sales person:{customerUUID} <div style={{ color: "red" }}>Err:{this.props.customers?.errorText}</div></div>
    }
    
    return <Select
      id="sales-select"
      placeholder={"Choose Sales person"}
      isMulti={false}
      value={customer ? { label: customer.name, value: customer.UUID } : null}
      onChange={val => this.handleSalesPersonChange(val.value)}
      options={this.props.customers?.data?.filter(c => c.type === 3).map(c => {
        return { label: c.name, value: c.UUID }
      })}
    />
  }
  renderCustomerSelect(customerUUID) {
    if(this.props.customers?.isLoading)return <div><Spinner singleColor /></div>
    const customer = this.props.customers?.data?.find(e=> e.UUID === customerUUID)
    if(this.props.customers?.errorText?.length > 0){
      return <div>Customer:{customerUUID} <div style={{ color: "red" }}>Err:{this.props.customers?.errorText}</div></div>
    }
    
    return <Select
      id="customer-select"
      placeholder={"Choose Customer"}
      isMulti={false}
      value={customer ? { label: customer.name, value: customer.UUID } : null}
      onChange={val => this.handleCustomerChange(val.value)}
      options={this.props.customers?.data?.filter(c => c.type === 0).map(c => {
        return { label: c.name, value: c.UUID }
      })}
    />
  }

  render() {
    const campaignLeadTTLOffesetInDays = moment.duration(this.state.campaign.leadTTLOffset, 'milliseconds').asDays()
    const campaignOptinTTLOffesetInDays = moment.duration(this.state.campaign.optinTTLOffset, 'milliseconds').asDays()

    let detailData = (
      <Card shadow={2} style={{ minWidth: "300px", width: "100%", overflow: "visible", zIndex: "2" }}>
        <CardActions border>
          <Grid style={{ paddingLeft: "0px" }}>
            <Cell col={6}>
              <Textfield
                label="Campaign name"
                floatingLabel
                style={{ width: "100%" }}
                value={this.state.campaign.name || ""}
                onChange={evt => this.handleNameChange(evt.target.value)}
              />
              <div>
                <span className="campDetailUUID">{this.props.campaign.UUID}</span>
                <span className="campDetailExecutionPath"> Execution Path: {this.props.campaign.executionPath}</span>
              </div>
            </Cell>
            <Cell col={3}>
              <Grid style={{ paddingLeft: "0px" }}>
                <Cell col={6}>
                  <Checkbox
                    ripple
                    label="Is archived"
                    checked={this.state.campaign.isArchived}
                    onChange={() => this.handleIsArchivedChange()}
                  />
                  <Checkbox
                    ripple
                    label="Is paused"
                    checked={this.state.campaign.isPaused}
                    onChange={() => this.handleIsPausedChange()}
                  />
                </Cell>
                <Cell col={6}>
                  <Checkbox
                    ripple
                    label="Is microsite"
                    checked={this.state.campaign.isMicrosite}
                    onChange={() => this.handleIsMicrositeChange()}
                  />
                </Cell>
              </Grid>
            </Cell>
            <Cell col={3}>
              <Stats
                stats={this.props.itemStats}
                retrieve={() => this.props.retrieveCampaignStats(this.props.campaign.UUID)}
              />
            </Cell>
          </Grid>
          <Grid>
            <Cell col={6}>
              <div>{this.props.campaign.shortCode}</div>
            </Cell>
            <Cell col={6}>
              <div>{this.getOverallCount()}</div>
            </Cell>
            <Cell col={3} id="start-date-cell">
              <label className="floatLabel">Start Date</label>
              <DatePicker
                isClearable={true}
                dateFormat="MM/dd/yyyy"
                startDate={!this.state.start_date ? null : this.state.start_date.toDate()}
                selected={!this.state.start_date ? null : this.state.start_date.toDate()}
                withPortal
                onChange={dateMoment => this.handleDateChange("start_date", dateMoment)}
              />
            </Cell>
            <Cell col={3}>
              <label className="floatLabel">AdQuire End Date</label>
              <DatePicker
                isClearable={true}
                dateFormat="MM/dd/yyyy"
                selected={!this.state.end_date ? null : this.state.end_date.toDate()}
                startDate={!this.state.end_date ? null : this.state.end_date.toDate()}
                withPortal
                onChange={dateMoment => this.handleDateChange("end_date", dateMoment)}
              />
            </Cell>
            <Cell col={3}>{this.renderCustomerSelect(this.state.campaign.customerUUID)}</Cell>
            <Cell col={3} style={{ zIndex: "0" }}>
              <div style={{ marginLeft: "50%" }}>
                <Switch
                  onChange={() => this.handleIPV6Change(!this.state.campaign.ipv6Allowed)}
                  checked={this.state.campaign.ipv6Allowed}>
                  {this.state.campaign.ipv6Allowed ? "IPV6 Allowed" : "IPV6 Disallowed"}
                </Switch>
              </div>
            </Cell>
            <Cell col={2} style={{ paddingTop: "20px" }}>
              Priority Campaign Upload
              <span>
                <div className="mdl-button mdl-button--primary mdl-button--icon mdl-button--file">
                  <i className="material-icons">attach_file</i>
                  <input
                    style={{ opacity: 0 }}
                    type="file"
                    id="uploadBtn"
                    onChange={this.props.onPriorityUploadChange}
                    accept=".csv, text/csv"
                  />
                </div>
              </span>
            </Cell>
            <Cell col={2}>
              <Switch
                onChange={() => {
                  let camp = this.state.campaign
                  camp.dailyCapConfig = camp.dailyCapConfig || {}
                  camp.dailyCapConfig.inactive = !camp.dailyCapConfig.inactive
                  if (camp.dailyCapConfig.inactive) {
                    camp.dailyCapConfig.cap = 0
                  }
                  this.handleDailyCapChange(camp.dailyCapConfig)
                }}
                checked={!((this.state.campaign.dailyCapConfig || {}).inactive)}>
                Daily Cap Enforced
              </Switch>
            </Cell>
            <Cell col={2}>
              <Textfield
                disabled={(this.state.campaign.dailyCapConfig || {}).inactive}
                label="Daily AdQuire Cap"
                floatingLabel
                style={{ width: "135px" }}
                value={(this.state.campaign.dailyCapConfig || { cap: 0 }).cap}
                onChange={evt => {
                  let camp = this.state.campaign
                  camp.dailyCapConfig = camp.dailyCapConfig || {}
                  camp.dailyCapConfig.cap = evt.target.value
                  this.handleDailyCapChange(camp.dailyCapConfig)
                }}
              />
            </Cell>
            <Cell col={3}>{this.renderSalesUserSelect(this.state.campaign.salesUserKey)}</Cell>
            <Cell col={3}>
              <Textfield
                value={this.state.campaign.filterExpression}
                floatingLabel
                label="Campaign PostFilter Expression"
                onChange={e => this.handleExpression(e.target.value)}
              />
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={4} className="retention">
                  <Textfield
                    pattern="-?[0-9]*(\.[0-9]+)?"
                    error="Input is not a number!"
                    value={campaignOptinTTLOffesetInDays}
                    floatingLabel
                    label="Optin PII - Days Retained ('0' for 6 year default)"
                    style={{ width: '100px' }}
                    onChange={e => this.handleOptinTTLOffset(e.target.value)}
                  />
                </Cell>

                <Cell col={4} className="retention">
                  <Textfield
                    pattern="-?[0-9]*(\.[0-9]+)?"
                    error="Input is not a number!"
                    value={campaignLeadTTLOffesetInDays}
                    floatingLabel
                    label="Lead PII - Days Retained ('0' for 6 year default)"
                    style={{ width: '100px' }}
                    onChange={e => this.handleLeadTTLOffset(e.target.value)}
                  />
                </Cell>
                <Cell col={4} className="customSettings">
                  <Textfield
                    value={this.state.campaign.customSettings}
                    floatingLabel
                    label="Legacy Agent Custom Settings"
                    onChange={e => this.handleCustomSettings(e.target.value)}
                  />
                </Cell>

              </Grid>
            </Cell>
          </Grid>
        </CardActions>
      </Card>
    )
    if (this.props.campaignCriteria && !this.props.campaignCriteria.criteriaFilter.state) {
      this.props.campaignCriteria.criteriaFilter.state = { include: deepClone(USstatesCodes), exclude: [] }
    }

    return (
      <React.Fragment>
        <Prompt when={this.props.isDirtyValue} message="You have unsaved changes, are you sure you want to leave?" />
        <Grid style={{ width: "100%", padding: "0px" }}>
          <Cell shadow={2} col={12}>
            {detailData}
          </Cell>
          <Cell shadow={2} col={4}>
            <CriteriaFilter
              criteriaFilter={this.props.campaignCriteria.criteriaFilter}
              onChange={(criteriaName, isInclude, value) =>
                this.handleCriteriaFilterChange(criteriaName, isInclude, value)
              }
              onRemove={(criteriaName, criteriaType, index) =>
                this.handleCriteriaFilterRemove(criteriaName, criteriaType, index)
              }
              onStateChange={(criteriaType, index) => this.handleCriteriaFilterStateChange(criteriaType, index)}
              onZipChange={(zipCodes, isInclude) => this.handleZipCriteriaFilterChange(zipCodes, isInclude)}
              zips={this.props.zips}
            />
          </Cell>
          <Cell shadow={2} col={4}>
            <Validation
              validators={this.props.validators}
              campaign={this.state.campaign}
              addSaveCampaignCallBack={this.props.addSaveCampaignCallBack}
              emailValidationConfiguration={this.props.emailValidationConfiguration}
              retrieveEmailValidation={this.props.retrieveEmailValidation}
              saveEmailValidation={this.props.saveEmailValidation}
              addressValidation={this.props.addressValidation}
              retrieveAddressValidation={this.props.retrieveAddressValidation}
              saveAddressValidation={this.props.saveAddressValidation}
              onChange={(validatorName, enabled) => this.handleValidatorsChange(validatorName, enabled)}
              handlePropertyToggle={propertyName => this.handlePropertyToggle(propertyName)}
              handlePropertyChange={(propertyName, value) => this.handlePropertyChange(propertyName, value)}
              phoneVerification={this.props.phoneVerification}
              savePhoneVerification={this.props.savePhoneVerification}
              handlePhoneValidationChange={() => this.handlePhoneValidationChange()}
              isDirty={() => this.props.isDirty(true)}
            />
          </Cell>
          <Cell shadow={2} col={4}>
            <RequiredFields
              addAddressValidation={(suiteLevel) => this.handleAddAddressValidation(suiteLevel)}
              campaign={this.state.campaign}
              onChange={(requiredFieldName, requiredFieldValue) =>
                this.handleRequiredFieldsChange(requiredFieldName, requiredFieldValue)
              }
              validatorChange={(validatorName, enabled) => this.handleValidatorsChange(validatorName, enabled)}
            />
          </Cell>
          <Cell shadow={2} col={3} style={{ minWidth: "240px" }}>
            <ItemsCollection
              items={this.state.campaign.leadCounts}
              onChange={(item, index) => this.handleCollectionChange("leadCounts", item, index)}
              onRemove={index => this.handleCollectionRemove("leadCounts", index)}
              normalizeDate={this.normalizeDate}
              itemName={"Lead Limit"}
              valueFieldName={"limit"}
              allowInfiniteValue={true}
            />
          </Cell>
          <Cell shadow={2} col={3} style={{ minWidth: "240px" }}>
            <ItemsCollection
              items={this.state.campaign.rates}
              onChange={(item, index) => this.handleCollectionChange("rates", item, index)}
              onRemove={index => this.handleCollectionRemove("rates", index)}
              normalizeDate={this.normalizeDate}
              itemName={"Rate"}
              valueFieldName={"rate"}
              allowInfiniteValue={true}
            />
          </Cell>
          <Cell shadow={2} col={3} style={{ minWidth: "240px" }}>
            <ItemsCollection
              items={this.state.campaign.leadAcceptancePercentages || []}
              onChange={(item, index) => this.handleCollectionChange("leadAcceptancePercentages", item, index)}
              onRemove={index => this.handleCollectionRemove("leadAcceptancePercentages", index)}
              normalizeDate={this.normalizeDate}
              itemName={"Lead Acceptance Percentage"}
              valueFieldName={"value"}
              valueFieldDisplayFormat={"percent"}
              allowInfiniteValue={true}
            />
          </Cell>
          <Cell shadow={2} col={3} style={{ minWidth: "240px" }}>
            <ItemsCollection
              items={this.state.campaign.contractedLeadCaps}
              onChange={(item, index) => this.handleCollectionChange("contractedLeadCaps", item, index)}
              onRemove={index => this.handleCollectionRemove("contractedLeadCaps", index)}
              normalizeDate={this.normalizeDate}
              itemName={"Contracted Lead"}
              valueFieldName={"value"}
              allowInfiniteValue={true}
            />
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }
}
