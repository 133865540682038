import React from "react"
import Customers from "../customers"
import { Tab, Tabs } from "react-mdl"

export default class ContactManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      tabSeed: Math.random()
    }
    this.setIsTabComponentDirty = isTabComponentDirty => (this.isTabComponentDirty = isTabComponentDirty)
    this.isTabComponentDirty = () => null
  }

  componentDidMount() {
    this.props.retrieveCustomers()
  }

  checkIsDirty(activeTab) {
    const isDirtyMap = {
      "0": () => this.isTabComponentDirty(),
      "1": () => this.isTabComponentDirty(),
      "2": () => this.isTabComponentDirty(),
      "3": () => this.isTabComponentDirty(),
    }
    return isDirtyMap[activeTab]()
  }

  handleTabChange(tabId) {
    if (
      this.checkIsDirty(this.state.activeTab) &&
      !confirm("You have unsaved changes, are you sure you want to leave?")
    ) {
      this.setState({ tabSeed: Math.random() })
      return
    }
    this.setState({ activeTab: tabId })
  }

  render() {
    return (
      <div>
        <Tabs
          key={`tabs-${this.state.tabSeed}`}
          activeTab={this.state.activeTab}
          onChange={tabId => this.handleTabChange(tabId)}
          ripple
          style={{ width: "100%" }}>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Customers</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Earnpath Partners</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Distribution Partners</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Sales People</span>
          </Tab>
        </Tabs>
        <section>
          <div className="content">
            {this.state.activeTab == 0 ? (
              <Customers
              keycloakResults={this.state.keycloakResults}
              customerType={0}
              placements={this.props.placements}
              customers={this.props.customers}
              setCrud={this.props.setCrud}
              saveCustomer={this.props.saveCustomer}
              createKeyCloakAccount={this.props.createKeyCloakAccount}
              isDirty={this.props.isDirty}
              isNotDirty={this.props.isNotDirty}
              retrieveCustomers={this.props.retrieveCustomers}
              isDirtyValue={this.props.isDirtyValue}
            />
            ) : null}
            {this.state.activeTab == 1 ? (
              <Customers
              keycloakResults={this.state.keycloakResults}
              customerType={1}
              placements={this.props.placements}
              customers={this.props.customers}
              setCrud={this.props.setCrud}
              saveCustomer={this.props.saveCustomer}
              createKeyCloakAccount={this.props.createKeyCloakAccount}
              isDirty={this.props.isDirty}
              isNotDirty={this.props.isNotDirty}
              retrieveCustomers={this.props.retrieveCustomers}
              isDirtyValue={this.props.isDirtyValue}
            />
            ) : null}
            {this.state.activeTab == 2 ? (
              <Customers
              keycloakResults={this.state.keycloakResults}
              customerType={2}
              placements={this.props.placements}
              customers={this.props.customers}
              setCrud={this.props.setCrud}
              saveCustomer={this.props.saveCustomer}
              createKeyCloakAccount={this.props.createKeyCloakAccount}
              isDirty={this.props.isDirty}
              isNotDirty={this.props.isNotDirty}
              retrieveCustomers={this.props.retrieveCustomers}
              isDirtyValue={this.props.isDirtyValue}
            />
            ) : null}
            {this.state.activeTab == 3 ? (
              <Customers
              keycloakResults={this.state.keycloakResults}
                customerType={3}
                placements={this.props.placements}
                customers={this.props.customers}
                setCrud={this.props.setCrud}
                saveCustomer={this.props.saveCustomer}
                createKeyCloakAccount={this.props.createKeyCloakAccount}
                isDirty={this.props.isDirty}
                isNotDirty={this.props.isNotDirty}
                retrieveCustomers={this.props.retrieveCustomers}
                isDirtyValue={this.props.isDirtyValue}
              />
            ) : null}
          </div>
        </section>
      </div>
    )
  }
}

