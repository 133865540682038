import React from 'react'
import Draggable from '../draggable'
import { Grid, Cell, Button, Icon, List} from 'react-mdl'

const commentStyle = {
  color:"#666",
  paddingBotton: "5px",
  fontSize: "80%",
}

export default class Questions extends React.Component {
  constructor(props) {
    super(props)
    this.questionType = [
      'Input',
      'Multiple Choice',
      'Date',
      'Password',
      'Radio',
      'Check',
      'Phone',
      'Address',
      'TextArea',
      'MultiSelect'
    ]
  }
  render() {
    return <Grid>
      <Cell col={12}>
        <Grid className="headerGrid" style={{ width: '100%', paddingLeft: "0" }}>
          <Cell col={3}>Question</Cell>
          <Cell col={2}>UI Type</Cell>
          <Cell col={2}>Validator</Cell>
          <Cell col={1}>Required</Cell>
          <Cell col={1}>Hidden</Cell>
          <Cell col={1}>Answers</Cell>
          <Cell col={1}>{}</Cell>
          <Cell col={1}>{}</Cell>
        </Grid>
        <List>
          <Draggable
            items={this.props.questions
              .sort((a, b) => {
                return a.Weight - b.Weight
              })
              .map((q, index) => {
                q.element = (
                  <Grid
                    style={{ width: '100%', padding: "0" }}>
                    <Cell col={3}>
                      <div>{q.value}</div>
                      <div style={commentStyle}>{q.UUID}</div>
                    </Cell>
                    <Cell col={2}>{this.questionType[q.type]}</Cell>
                    <Cell col={2}>{q.validationService || "None"}</Cell>
                    <Cell col={1}>{q.isAnswerRequired ? 'Yes' : 'No'}</Cell>
                    <Cell col={1}>{q.isHidden ? 'Yes' : 'No'}</Cell>
                    <Cell col={1}>{q.answers.records.length}</Cell>
                    <Cell col={1}>
                      <Button
                        onClick={() => {
                          this.props.selected(index)
                        }}
                      >
                        <Icon name="more_horiz" />
                      </Button>
                    </Cell>
                    <Cell col={1}>
                      <Button
                        accent raised
                        onClick={() => this.props.onDelete(index)}
                      >
                        <Icon name="delete" />
                      </Button>
                    </Cell>
                  </Grid>
                )
                return q
              })}
            onEnd={items => {
              for (var i = 0; i < items.length; i++) {
                items[i].Weight = i
              }
              this.props.reWeighted(items)
            }}
          />
        </List>
      </Cell>
    </Grid>
  }
}

