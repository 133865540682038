import React from 'react'
import { connect } from 'react-redux'
import { login } from '../services'
import {Grid, Card, CardActions, CardTitle, Button} from 'react-mdl'


function mapDispatchToProps(dispatch) {
  return {
    login: function () {
      dispatch(login())
    }
  }
}

class landing extends React.Component {
  constructor(props) {
    super(props)
    this.env = 'dev'
    this.state = { loggingIn: false }
  }
  render() {
    return (
      <Grid style={{ textAlign: "center", width: "500px" }}>
        <Card shadow={2} >
          <CardTitle>
            <span >{!this.state.loggingIn ? 'Welcome, please sign-in.' : 'Signing in...'}</span>
          </CardTitle>
          <CardActions border>
            <Button raised accent colored
              onClick={event => {
                event.preventDefault()
                this.props.login()
                this.setState({
                  loggingIn: true
                })
              }}
            >
              SIGN IN
        </Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }
}
const Landing = connect(null, mapDispatchToProps)(landing)

export default Landing
