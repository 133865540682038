import {
    generateInternalBudget,
    generateInternalBudgetFailure,
    generateInternalBudgetSuccess
} from '../reducers/internalBudgetReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'

const url = getUrlFor("reporting", "internal-budget")
//const url = "http://localhost:9001/internal-budget/"
//const url = "http://localhost:9001/internal-budget-mock/"


export function generateInternalBudgetThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generateInternalBudget())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generateInternalBudgetSuccess(res.data))
            }).catch(e => {
                console.log(e)
                dispatch(generateInternalBudgetFailure(`Getting internal budget report - ${e.statusText}`))
                exceptionHandling(e, dispatch, "Getting internal budget report")
            })
    }
}
