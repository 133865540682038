import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import { ListItem, Card, CardMenu, FABButton, List, CardTitle, Grid, Cell, Textfield, Switch, Button, Icon } from 'react-mdl'


function compareEffectiveDate(a, b) {
  if (a.effectiveDate < b.effectiveDate) {
    return 1
  }
  if (a.effectiveDate > b.effectiveDate) {
    return -1
  }
  return 0
}

function mapActive() {
  var found = false
  return function (e, i, a) {
    let scheduled = e.effectiveDate > moment().unix()
    var active = !found &&
      !scheduled &&
      (i + 1 === a.length || compareEffectiveDate(a[i], a[i + 1]) < 1)
    found = found || active
    e.active = active
    e.scheduled = scheduled
    e.color = active ? '#5E8000' : e.scheduled ? '#CEA500' : '#8E8E8E'
    return JSON.parse(JSON.stringify(e))
  }
}

class ItemsCollection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rolledUp: true,
      selected: null,
      id: this.props.itemName.split(' ').join('-')
    }
  }

  toDisplayFormat(value, format, type) {
    if (value == '') return value

    if (format == "percent") {
      value = parseInt(value * 100)
      if (type == "string") {
        return value + "%"
      }
      return value
    }
    return value
  }

  fromDisplayFormat(value, format) {
    if (value == '') return value

    if (format == "percent") {
      return value / 100
    }
    return value
  }

  handleSaveItem() {
    const item = this.state.selected
    delete item.scheduled
    delete item.color
    delete item.active
    this.props.onChange(item, this.state.selected.idx)
    this.handleCloseEditForm()
  }

  handleRemoveItem() {
    this.props.onRemove(this.state.selected.idx)
    this.handleCloseEditForm()
  }

  unvalueToggle() {
    const { selected } = this.state
    let itemValue = -1
    if (selected[this.props.valueFieldName] == -1) {
      itemValue = 0
    }
    selected[this.props.valueFieldName] = itemValue
    this.setState({
      selected: selected,
    })
  }

  toggleRollUp() {
    this.setState({
      rolledUp: !this.state.rolledUp,
    })
  }

  isValueIncorrect(selected) {
    if (selected && selected[this.props.valueFieldName]) {
      const value = parseFloat(selected[this.props.valueFieldName])
      return isNaN(value)
    }
    return true
  }

  isNewItem(selected) {
    if (selected) {
      return selected.idx === -1
    }
    return true
  }

  handleCloseEditForm() {
    this.setState({
      selected: null,
    })
  }

  handleValueChange(value) {
    const { selected } = this.state
    selected[this.props.valueFieldName] = value
    this.setState({
      selected: selected,
    })
  }

  handleDateChange(dateMoment) {
    const { selected } = this.state
    dateMoment = moment(dateMoment)
    selected.effectiveDate = dateMoment.unix()
    this.setState({
      selected: moment(selected),
    })
  }

  handleAddButton() {
    const selected = { effectiveDate: moment().unix(), active: false, [this.props.valueFieldName]: 0, idx: -1 }
    this.setState({
      selected: selected,
    })
  }

  handleItemSelected(item, idx) {
    const selected = Object.assign(item, { idx: idx })
    this.setState({
      selected: selected,
    })
  }

  render() {
    let rollButton = this.props.items.length > 3
      ? <Button id={"rollButton"} onClick={() => this.toggleRollUp()}>
        <Icon
          name={
            this.state.rolledUp ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
          }
        />
      </Button>
      : <div />
    let textBox = <div />
    if (this.state.selected) {
      textBox = (
        <Textfield
          pattern="-?[0-9]*(\.[0-9]+)?"
          error="Input is not a number!"
          value={this.toDisplayFormat(this.state.selected[this.props.valueFieldName] || '', this.props.valueFieldDisplayFormat)}
          onChange={(e) => this.handleValueChange(this.fromDisplayFormat(e.target.value, this.props.valueFieldDisplayFormat))}
          label={this.props.itemName}
          floatingLabel
          style={{ width: '200px' }}
        />
      )
      if (this.props.allowInfiniteValue && this.state.selected[this.props.valueFieldName] < 0) {
        textBox = (
          <Icon name='all_inclusive' />
        )
      }
    }
    let date = this.state.selected ? this.state.selected.effectiveDate : moment().unix().toString()
    let effectiveDate = this.props.normalizeDate(date)
    effectiveDate = !effectiveDate ? null : effectiveDate.toDate()
    let form = (
      <div id="addForm" style={{ backgroundColor: "#FEFEFE", margin: "10px" }}>
        { this.props.allowInfiniteValue && this.state.selected ?
          <Grid>
            <Switch onChange={() => this.unvalueToggle()}
              checked={this.state.selected[this.props.valueFieldName] === -1}>No {this.props.itemName}</Switch>
          </Grid> : null
        }
        <Grid>
          <Cell col={4}>
            {textBox}
          </Cell>
          <Cell col={8}>
            <Grid>
              <label className="floatLabel"> Effective Date </label>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={effectiveDate}
                start_date={effectiveDate}
                onChange={(dateMoment) => this.handleDateChange(dateMoment)}
                withPortal
              />
            </Grid>
          </Cell>
        </Grid>
        <Grid>
          <Button
            id={`${this.state.id}-save-button`}
            onClick={() => this.handleSaveItem()}
            disabled={this.isValueIncorrect(this.state.selected)}>
            <Icon name="save" />
          </Button>
          <Button
            onClick={() => this.handleRemoveItem()}
            disabled={this.isNewItem(this.state.selected)}>
            <Icon name="delete" />
          </Button>
          <Button onClick={() => this.handleCloseEditForm()}>
            <Icon name="close" />
          </Button>
        </Grid>
      </div>
    )
    return (
      <Card style={{ minWidth: '240px', overflow: 'visible', width: '100%' }}>
        <CardTitle>{`${this.props.itemName}s`}</CardTitle>
        <CardMenu>
          <FABButton
            id={`${this.state.id}-add-button`}
            className="fabPosition"
            onClick={() => this.handleAddButton()}
            mini
            colored
          >
            <Icon name="add" />
          </FABButton>
        </CardMenu>
        {this.state.selected ? form : <div />}
        <List id={`${this.state.id}-list`} style={{ width: '100%' }}>
          {this.props.items
            .sort(compareEffectiveDate)
            .map(mapActive())
            .slice(0, this.state.rolledUp ? 3 : this.props.items.length)
            .map((item, idx) => {
              return (
                <ListItem
                  style={{ color: item.color }}
                  key={item.ID || item.effectiveDate}
                  onClick={() => this.handleItemSelected(item, idx)}
                >
                  <Grid style={{ width: '100%' }}>
                    <Cell col={6} style={{ margin: 0, lineHeight: "24px" }}>
                      <span>
                        {this.props.allowInfiniteValue && item[this.props.valueFieldName] <= 0
                          ? <Icon name='all_inclusive' />
                          : this.toDisplayFormat(item[this.props.valueFieldName], this.props.valueFieldDisplayFormat, "string")}
                      </span>
                    </Cell>
                    <Cell col={5} style={{ margin: 0, lineHeight: "24px" }}>
                      <span className="mdl-data-table__cell--non-numeric">
                        {moment.unix(item.effectiveDate).format('l')}
                      </span>
                    </Cell>
                    <Cell col={1} style={{ margin: 0, lineHeight: "24px" }}>
                      <span>
                        {item.active
                          ? <Icon name="alarm_on" alt={"Active"} title={"Active"} />
                          : item.scheduled
                            ? <Icon name="alarm" alt={"Scheduled"} title={"Scheduled"} />
                            : <Icon name="alarm_off" alt={"Past date"} title={"Past date"} />}
                      </span>
                    </Cell>
                  </Grid>
                </ListItem>
              )
            })}
        </List>
        {rollButton}
      </Card>
    )
  }
}

export default ItemsCollection
