import {inspect, setCrud,retrieveTraces} from '../../services'

import React from 'react'
import {connect} from 'react-redux'
import {uuidToColor} from '../../utils'
import {Grid, Cell, Card, CardActions, CardTitle, CardText, Textfield, Icon, Button, Badge} from 'react-mdl'

function mapStateToProps(state) {
  return {
    inspector: state.inspector,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    search: function(search) {
      dispatch(inspect(search))
    },
    retrieveTraces: function(sessionUUID) {
      dispatch(retrieveTraces(sessionUUID))
    },
    setCrud: function(crud) {
      dispatch(setCrud(crud))
    }
  }
}

class Dive extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="inspectorItem">
        {this.props.items.map(item => {
          return (
            <span
              key={item.uuid}
              style={{
                minHeight: 'initial',
                width: '100%',
                marginBottom: '4px',
                backgroundColor: uuidToColor(item.uuid),
              }}
            >
              <CardText onClick={() => this.props.selected(item)}>
                <CardTitle>{item.primaryContent}</CardTitle>
                <span>{item.secondaryContent}</span>
              </CardText>
              <CardText>
                <pre>{item.fullContent}&gt;</pre>
              </CardText>
            </span>
          )
        })}
      </div>
    )
  }
}

class inspector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {search: ''}
  }

  componentDidMount() {
    let crudOptions = { 
      onSave: () => {},
      onCancel: () => {},
      onNew: () => {},
      visible: false,
    }
    this.props.setCrud(crudOptions)
  }

  render() {
    return (
      <Grid>
        <Cell col={12}>
          <Grid>
            <Cell col={12}>
              <Card shadow={2}>
                <CardTitle>
                  Inspect data based on Email, Lead UUID, or Session UUID
                </CardTitle>
                <CardActions border>
                  <Textfield
                    floatingLabel
                    label="search"
                    onChange={e => {
                      this.setState({
                        search: e.target.value
                      })
                    }}
                    value={this.state.search || ''}
                  />
                  <Button
                    onClick={() => this.props.search(this.state.search)}
                    ripple
                    colored
                    raised
                  >
                    <Icon name="search" /> Inspect{' '}
                  </Button>
                </CardActions>
              </Card>
            </Cell>
          </Grid>
        </Cell>
        <Cell col={12}>
          <Grid>
            <Cell col={4}>
              <Card className="fullWidth" >
                <CardTitle>Sessions</CardTitle>
                <Badge text={this.props.inspector.sessions.length} overlap />
                <Dive selected={(i => this.props.retrieveTraces(i.uuid))}items={this.props.inspector.sessions} />
              </Card>
            </Cell>
            <Cell col={4}>

              <Card className="fullWidth" >
                <CardTitle>Impressions</CardTitle>
                <Badge text={this.props.inspector.impressions.length} overlap />
                <Dive items={this.props.inspector.impressions} />
              </Card>
            </Cell>
            <Cell col={4}>

              <Card>
                <CardTitle>Validation Messages</CardTitle>
                <Badge text={this.props.inspector.traces.length} overlap />
                <Dive items={this.props.inspector.traces} />
              </Card>
            </Cell>
            <Cell col={4}>
              <Card className="fullWidth" >
                <CardTitle>Leads</CardTitle>
                <Badge text={this.props.inspector.leads.length} overlap />
                <Dive items={this.props.inspector.leads} />
              </Card>
            </Cell>
            <Cell col={4}>
              <Card className="fullWidth" >
                <CardTitle>Opt-Ins</CardTitle>
                <Badge text={this.props.inspector.optIns.length} overlap />
                <Dive items={this.props.inspector.optIns} />
              </Card>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    )
  }
}

const Inspector = connect(mapStateToProps, mapDispatchToProps)(inspector)

export default Inspector
