import {
  setCrud,
  saveCampaignRequest,
  campaignRequestSelected,
  isDirty,
  resetCampaignRequestSelected,
 } from '../../services'

import FilterList from '../filterList'
import React from 'react'
import { connect } from 'react-redux'
import CampaignRequestForm from './campaignRequestForm'
import { retrieveCustomersThunk } from '../../services/customerService'
import {Grid, Cell, Card, CardActions, CardTitle} from 'react-mdl'
import { Prompt } from 'react-router'
import { deepClone } from '../../utils/'

function mapStateToProps(state) {
  return {
    isDirtyValue: state.isDirty,
    campaigns: state.campaignData.records,
    customers: state.customers,
    campaignRequests: state.campaignRequests,
    campaignRequest: state.campaignRequest,
    campaign: state.campaign,
    placements: state.placementData.records,
    creative: state.creative,
    creativePreview: state.creativePreview,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectCRF: function (crf) {
      if (crf && crf.element) {
        delete crf['element']
      }
      dispatch(campaignRequestSelected(crf))
    },
    resetCRF: function(){
      dispatch(resetCampaignRequestSelected())
    },
    isDirty: function () {
      dispatch(isDirty(true))
    },
    isNotDirty: function () {
      dispatch(isDirty(false))
    },
    setCrud: function (crud) {
      dispatch(setCrud(crud))
    },
    save: function (request) {
      dispatch(saveCampaignRequest(request))
    },
    retrieveCustomers(){
      dispatch(retrieveCustomersThunk())
    }
  }
}

class accountManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      seed: Math.random(),
    }
    this.setGetCampaignRequest = (getCampaignRequest) => {
      this.getCampaignRequest = getCampaignRequest
    }
    this.getCampaignRequest = () => null
    this.setIsTabComponentDirty = (isTabComponentDirty) => this.isTabComponentDirty = isTabComponentDirty
    this.isTabComponentDirty = () => false
  }

  addNew() {
    const newCampaignRequest = {}
    this.props.selectCRF(newCampaignRequest)
  }

  saveCRF() {
    this.props.save(this.getCampaignRequest())
  }

  componentDidMount() {
    let crudOptions = {
      onSave: () => this.saveCRF(),
      onCancel: () => {
        this.props.isNotDirty()
        if (this.props.campaignRequest && !this.props.campaignRequest.UUID){
          this.props.resetCRF()
        }else{
          const checkIsDirty = false
          this.handleCampaignRequestSelection(this.state.selectedCampaignRequest, checkIsDirty)
          this.setState({
            seed: Math.random()
          })
        }
      },
      onNew: () => this.addNew(),
      visible: true,
    }

    this.props.retrieveCustomers()
    this.props.setCrud(crudOptions)
    this.props.isNotDirty()
    this.props.resetCRF()
  }

  isComponentDirty(){
    return this.props.isDirtyValue || this.isTabComponentDirty()
  }

  handleCampaignRequestSelection(campaignRequest, checkIsDirty = true){
    if (checkIsDirty && this.isComponentDirty() && !confirm('[select]You have unsaved changes, are you sure you want to leave?')){
      return
    }
    this.props.selectCRF(deepClone(campaignRequest))
    this.props.isNotDirty()
    this.setState({
      selectedCampaignRequest: campaignRequest,
    })
  }

  handleSaveCreative(creative){
    if (this.props.campaignRequest.creativeUuid == creative.uuid) {
      return
    }
    this.props.campaignRequest.creativeUuid = creative.uuid
    this.props.save(this.props.campaignRequest)
  }

  render() {
    return (
      <React.Fragment>
        <Prompt
            when={this.isComponentDirty()}
            message='[propt]You have unsaved changes, are you sure you want to leave?'
        />
        <Grid style={{ width: "100%" }}>
          <Cell col={3} style={{ maxWidth: '450px' }}>
            <Card style={{ width: "100%" }} shadow={2}>
              <CardTitle>Campaign Request List</CardTitle>
              <CardActions border>
                <FilterList
                  label="Campaign Request list..."
                  selected={this.props.campaignRequest ? this.props.campaignRequest.UUID : null}
                  additionalFieldList={['name', 'ioNumber']}
                  primaryField={'name'}
                  title="Campaign Requests"
                  selectItem={c => this.handleCampaignRequestSelection(c)}
                  items={this.props.campaignRequests}
                />
              </CardActions>
            </Card>
          </Cell>
          <Cell col={9}>
            {
              this.props.campaignRequest ?
            <CampaignRequestForm
              {...this.props}
              key={`${this.props.campaignRequest.UUID || 'new'}-${this.state.seed}`}
              campaignRequest={this.props.campaignRequest}
              customers={this.props.customers}
              setGetCampaignRequest={this.setGetCampaignRequest}
              handleSaveCreative={(creative)=>this.handleSaveCreative(creative)}
              isDirty={this.props.isDirty}
              setIsTabComponentDirty={this.setIsTabComponentDirty}
            />
            : null }
          </Cell>
        </Grid >
      </React.Fragment>
    )
  }
}

const AccountManagement = connect(mapStateToProps, mapDispatchToProps)(accountManagement)

export default AccountManagement
