import React from 'react'
import Select, { components } from 'react-select'
import { Grid, Cell } from "react-mdl"

export class ValidatorSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedValidators: props.defaultServiceNames || [],
            isActiveFilter: false,
        }
    }

    onSelectedHandler(s) {
        this.setState({
            selectedValidators: s,
        })
        this.props.onChange(s)
    }

    componentDidUpdate() {
        if (this.props.defaultServiceNames && this.state.selectedValidators.length != this.props.defaultServiceNames.length)
            this.setState({
                selectedValidators: this.props.defaultServiceNames.map(e => { return { value: e, label: e } }),
            })
    }
    render() {
        let filteredValidators = this.props.validators
        let validatorOptions = filteredValidators.map(e => {
            return { value: e.name, label: e.name }
        })
        validatorOptions.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : 0)
        const Menu = props => {
            return (
                <React.Fragment>
                    <div onMouseDown={(e) => { e.preventDefault(); e.stopPropagation() }}>
                        <Grid>
                            <Cell col={4}>
                                <Grid style={{ padding: "0px", margin: "0px" }}>
                                    <Cell col={12} style={{ whiteSpace: "nowrap" }}>
                                        <input
                                            type="checkbox" name="validator_is-active-filter"
                                            checked={this.state.isActiveFilter}
                                            onChange={() => {
                                                this.setState({
                                                    isActiveFilter: !this.state.isActiveFilter,
                                                })
                                            }}
                                        /> Is Active
                                    </Cell>
                                </Grid>
                            </Cell>
                        </Grid>
                    </div>
                    <components.Menu {...props}>{props.children}</components.Menu>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <Select
                    isMulti={true}
                    closeMenuOnSelect={false}
                    value={this.state.selectedValidators}
                    onChange={s => this.onSelectedHandler(s)}
                    options={validatorOptions}
                    components={{ Menu }}
                />
            </React.Fragment>
        )
    }
}
