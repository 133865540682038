import {
  saveTag,
  deleteTag,
  loadTagChildren,
  loadTag,
  resetAltParentTags,
  updateTag,
  unloadAltParent,
} from '../../services'

import React from 'react'
import ConfirmModal from '../modal'
import { connect } from 'react-redux'
import { deepClone, getTagFamily } from '../../utils'
import Select from 'react-select'
import TagTree from './tree'
import {Grid, Cell, Card, Textfield, Icon, Button} from 'react-mdl'
import { Prompt } from 'react-router'


function mapDispatchToProps(dispatch) {
  return {
    saveTag(tag) {
      dispatch(saveTag(tag))
    },
    updateTag(tag) {
      dispatch(updateTag(tag))
    },
    deleteTag(uuid) {
      dispatch(deleteTag(uuid))
    },
    loadTagChildren(parentUuid){
      dispatch(loadTagChildren(parentUuid))
    },
    loadTag(uuid){
      dispatch(loadTag(uuid))
    },
    resetAltParentTags(){
      dispatch(resetAltParentTags())
    },
    unloadAltParent(uuid){
      dispatch(unloadAltParent(uuid))
    },
  }
}

class tagTaxonomy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTag : null,
      childTag : null,
      isDirty : false,
      showAltParentForm : false,
      altParentFamilyTag : null,
      altParentChildTag : null
    }
    this.props.setIsTabComponentDirty(() => this.state.isDirty)
  }

  handleSaveTagButton(){
    const tag = this.state.childTag ? deepClone(this.state.childTag) : deepClone(this.state.selectedTag)
    tag.name = tag.name.trim() 
    let duplicate = this.props.tags.records.find(t => t.name == tag.name && t.UUID !== tag.UUID && t.parent === tag.parent)
    if (duplicate) {
      this.duplicateWarningDialog()
      return
    }
    if (tag.UUID){
      this.props.updateTag(tag)
    } else {
      this.props.saveTag(tag)
    }
    this.resetAltParentForm()
    this.resetForm()
  }

  handleAddAltParentButton(){
    this.setState({
      showAltParentForm : true
    })
  }

  handleRemoveAltParentButton(altParentUUID){
    this.props.unloadAltParent(altParentUUID)
    const tag = this.state.childTag ? this.state.childTag : this.state.selectedTag
    tag.altParents = tag.altParents.filter((uuid)=> uuid !== altParentUUID)
    this.setState({
      isDirty : true
    })
  }

  handleAddAltParentFormButton(){
    const tag = this.state.childTag ? this.state.childTag : this.state.selectedTag
    if (this.state.altParentChildTag){
      tag.altParents.push(this.state.altParentChildTag.value)
    }else{
      tag.altParents.push(this.state.altParentFamilyTag.value)
    }
    const altParents = tag.altParents
    this.props.loadTag(altParents[altParents.length-1])
    this.resetAltParentForm()
    this.setState({
      isDirty : true
    })
  }

  handleAltParentFamilyTagChange(tag){
    this.props.loadTagChildren(tag.value)
    const altParentFamilyTag = { label: tag.label, value: tag.value} 
    this.setState({
      altParentFamilyTag : altParentFamilyTag
    })
  }
  
  handleAltParentChildTagChange(tag){
    const altParentChildTag = { label: tag.label, value: tag.value} 
    this.setState({
      altParentChildTag : altParentChildTag
    })
  }

  addChildTag(){
    this.props.resetAltParentTags()
    const familyTag = getTagFamily(this.props.tags.records, this.state.selectedTag)
    if (familyTag.altParents.length > 0){
      familyTag.altParents.forEach((uuid)=> this.props.loadTag(uuid))
    }
    const tag = {name: "", parent: this.state.selectedTag.UUID, altParents: deepClone(familyTag.altParents)}
    this.resetAltParentForm()
    this.setState({
      childTag : tag,
      isDirty : false
    })
  }

  duplicateWarningDialog() {
    this.setState({
      showDialog: true,
      dialogTitle: "DUPLICATE TAG",
      dialogText: `Tag ${this.state.selectedTag.name} already exists for this category`,
      onOk: () => {
        this.setState({
          showDialog : false,
          onOk : null,
          cancel : null
        })
      }
    })
  }

  showModal() {
    this.setState({
      showDialog: true,
    })
  }
       
  handleModalOk() {
    this.props.deleteTag(this.state.selectedTag.UUID)
    this.resetForm()
    this.setState({
      showDialog: false,
    })
  }

  hideModal(){
    this.setState({
      showDialog: false,
    })
  }

  selectTag(tag){
    const selectedTag = deepClone(this.state.selectedTag ? tag.UUID == this.state.selectedTag.UUID ? null : tag : tag)
    this.props.resetAltParentTags()
    if (selectedTag && selectedTag.altParents.length > 0){
      selectedTag.altParents.forEach((altParent)=> this.props.loadTag(altParent))
    }
    this.resetAltParentForm()
    this.setState({
      selectedTag: selectedTag,
      tagName: selectedTag ? selectedTag.name : "",
      isDirty: false,
      childTag: null
    })
  }

  resetAltParentForm(){
    this.setState({
      showAltParentForm: false,
      altParentChildTag: null,
      altParentFamilyTag: null
    })
  }

  resetForm(){
    this.resetAltParentForm()
    if (this.state.childTag){
      this.props.resetAltParentTags()
      this.state.selectedTag.altParents.forEach((uuid)=> this.props.loadTag(uuid))
      this.setState({
        childTag: null
      })
      return
    }
    this.setState({
      selectedTag: null,
      tagName: "",
      childTag: null,
      isDirty: false,
      showAltParentForm: false
    })
  }

  newFamilyTag(){
    const tag = {name: '', parent: '', altParents: []}
    this.resetAltParentForm()
    this.setState({
      tagName: "New family tag",
      selectedTag: tag,
      isDirty: false
    })
  }

  handleNameChange(name){
    if (this.state.childTag){
      const {childTag} = this.state
      childTag.name = name
      this.setState({
        isDirty : true,
        childTag : childTag
      })
      return
    }
    const {selectedTag} = this.state
    selectedTag.name =  name
    this.setState({
      isDirty: true,
      tagName: name,
      selectedTag: selectedTag
    })
  }

  isHideNameTextField(){
    return false
  }

  isAddChildButtonDisabled(){
    if (this.state.selectedTag && this.state.selectedTag.UUID && !this.state.childTag){
      return false
    }
    return true
  }

  isNodeSelected(tagUUID){
    return (this.state.selectedTag && this.state.selectedTag.UUID === tagUUID)
  }

  familyNode(result, nodeUUID, tagUUID) {
    result = result || nodeUUID === tagUUID
    if (!tagUUID) {
      return false
    }
    if (result) {
      return true
    }
    let parentUUID = null
    if (tagUUID) {
      let tag = this.props.tags.records.find(t => t.UUID === tagUUID)
      if (tag) {
        parentUUID = tag.parent
      }
    }
    return this.familyNode(result, nodeUUID, parentUUID)
  }

  isNodeOpen(tagUUID){ 
    if (this.state.selectedTag) {
      let isFamilyNode = this.familyNode(false, tagUUID, this.state.selectedTag.UUID)      
      let result = isFamilyNode
      return result
    }
    return false
  }

  renderAltParentForm(tags){
    const selectedTagFamily = getTagFamily(tags, this.state.selectedTag)
    const familyTags = tags.filter((t)=>{
      return t.parent === '' && selectedTagFamily.UUID !== t.UUID
    }).map((t) => {
        return { label: t.name, value: t.UUID }
    })
    const childTags = this.props.childTags.records.map((t)=>{
        return { label: t.name, value: t.UUID }
    })
    return (
      <Grid style={{width: "100%", minHeight: "300px"}}>
        <Cell col={4}>
          <Select
            placeholder={"Family tags"}
            isMulti={false}
            value={this.state.altParentFamilyTag}
            onChange={(s)=>this.handleAltParentFamilyTagChange(s)}
            options={familyTags}
          />
        </Cell>
        <Cell col={4}>
          <Select
            placeholder={"parent tags"}
            isMulti={false}
            value={this.state.altParentChildTag}
            onChange={(s)=> this.handleAltParentChildTagChange(s)}
            options={childTags}
          />
        </Cell>
        <Cell col={4}>
          <Button
            onClick={()=>this.handleAddAltParentFormButton()}>
            <Icon name="add" />
          </Button>
          <Button
            onClick={()=>this.resetAltParentForm()}>
            <Icon name="clear" />
          </Button>
        </Cell>
      </Grid>
    )
  }

  renderTagForm(){
    const nameLabel = this.state.childTag ? "Child name" : "Name"
    const tag = this.state.childTag ? this.state.childTag : this.state.selectedTag
    const subTitle = tag.parent ? "" : "Default"
    return (
      <Grid>
          <Cell col={12}>
          { this.isHideNameTextField() ? null :
            <Textfield
              required
              label={nameLabel}
              floatingLabel
              style={{ width: "50%" }}
              value={tag.name}
              onChange={ evt => this.handleNameChange(evt.target.value) }
            />
          }
          </Cell>
          <Cell col={4}>
            <h4>{subTitle} Alt Parents</h4>
            {
              tag.altParents.length > 0 ?
                this.props.altParentTag.map((altParent, idx)=>{
                  return (
                    <div key={idx}>
                      <h5 style={{backgroundColor: "#d6dbdf", display: "inline-grid", padding: "10px 20px", marginRight: "10px"}}>
                        {altParent.name ? altParent.name : "loading..."}
                      </h5>
                      <Button
                        disabled={this.state.showAltParentForm}
                        onClick={()=>this.handleRemoveAltParentButton(altParent.UUID)}>
                        <Icon name="clear" />
                      </Button>
                    </div>
                  )
                })
                : null
            }
            <Button
              disabled={this.state.showAltParentForm}
              onClick={()=> this.handleAddAltParentButton()}>
              <Icon name="add_circle" />
            </Button>
          </Cell>
          <Cell col={8}>
          {this.state.showAltParentForm ?
            this.renderAltParentForm(this.props.tags.records) : null
          }
          </Cell>
        </Grid>
    )
  }
  
  parentLabel(label, parentUUID) {
    let parent = this.props.tags.records.find(t => t.UUID == parentUUID)
    if (!parent) {
      return label
    }
    label += parent.name + "->"
    return this.parentLabel(label, parent.parent)
  }

  tagLabel(tag) {
    if (!tag) {
      return ""
    }
    return this.parentLabel("", tag.parent) + tag.name
  }

  renderButtons(){
    return (
      <Grid>
          <Cell col={5}>
            <h3>
              {this.tagLabel(this.state.selectedTag)}
            </h3>
          </Cell>
          <Cell col={7}>
            <Button
              accent
              onClick={()=> this.newFamilyTag()}
            >
              <Icon name="add_box" />New family tag
            </Button>
            <Button
              disabled={!this.state.isDirty}
              accent
              onClick={()=>this.handleSaveTagButton()} >
              <Icon name="save" />Save
            </Button>
            <Button
              disabled={this.isAddChildButtonDisabled()}
              accent
              onClick={() => this.addChildTag()}
            >
              <Icon name="playlist_add" />Add child tag
            </Button>
            <Button 
              disabled={!(this.state.selectedTag)} 
              accent 
              onClick={() => this.resetForm()}>
              <Icon name="cancel" />Cancel
            </Button>
            <Button 
              disabled={this.state.selectedTag ? !(this.state.selectedTag.UUID) : true} 
              accent 
              onClick={() => this.showModal()}>
              <Icon name="delete" />Delete
            </Button>
          </Cell>
        </Grid>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Prompt
              when={this.state.isDirty}
              message='You have unsaved changes, are you sure you want to leave?'
        />
        <Card shadow={2} style={{ minWidth: "300px"}} >
          <section ref={t => this.contentSection = t}>
            <ConfirmModal
              showDialog={this.state.showDialog}
              contentSection={this.contentSection}
              dialogTitle={"Delete Tag"}
              dialogText={this.state.selectedTag ? `Delete Tag: ${this.state.selectedTag.name}` : null}
              onOk={ () => this.handleModalOk() }
              dialogCancel={ () => this.hideModal() }
            />
          </section>
          <Grid style={{ width: "100%"}}>
            <Cell col={4} style={{padding:"20px", marginTop: "30px", marginBottom: "150px"}}>
              <TagTree
                tags={this.props.tags}
                selectTag={(t)=>this.selectTag(t)}
                isNodeSelected={(nodeUUID)=> this.isNodeSelected(nodeUUID)}
                isNodeOpen={(nodeUUID)=> this.isNodeOpen(nodeUUID)}
              />
            </Cell>
            <Cell col={8}>
            {this.renderButtons()}
            {this.state.selectedTag ? this.renderTagForm() : null}
            </Cell>
          </Grid>
        </Card>
      </React.Fragment>
        )
      }
    }
    const TagTaxonomy = connect(null, mapDispatchToProps)(tagTaxonomy)

    export default TagTaxonomy
