import React from "react"
import Select from "react-select"
import Creative from "../creative"
import ClientDetails from "./clientDetails"
import CampaignDetails from "./campaignDetails"
import { Card, Spinner, Textfield, Tab, Tabs } from 'react-mdl'

class campaignRequestForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      campaignRequest: this.props.campaignRequest,
      tabSeed: Math.random(),
      activeTab: 0
    }
    this.props.setGetCampaignRequest(() => {
      const request = Object.assign({}, this.state.campaignRequest)
      return request
    })
    this.isCreativeComponentDirty = () => null
  }

  exists(field) {
    return field && field.length && field.length > 0
  }

  valid(req) {
    let budg = parseFloat(req.budget)
    let rate = parseFloat(req.rate)
    return (
      this.exists(req.ioNumber) &&
      this.exists(req.name) &&
      budg % 1 === 0 &&
      rate % 1 === 0 &&
      this.exists(req.customerUuid)
    )
  }

  handleCampaignRequestChange(field, value) {
    const campaignRequest = this.state.campaignRequest
    campaignRequest[field] = value
    if (this.valid(this.state.campaignRequest)) {
      this.props.isDirty()
    }
    this.setState({
      campaignRequest: campaignRequest
    })
  }

  retrieveCustomer(customerUUID) {
    return this.props.customers.matchWith({
      LoadingCustomers: emptyCustomer => emptyCustomer,
      CustomersLoaded: ({ data }) => {
        return data.find(cu => cu.UUID == customerUUID)
      }
    })
  }

  renderCustomerSelect(customerUUID) {
    if(this.props.customers?.isLoading)return <div><Spinner singleColor /></div>
    const customer = this.props.customers?.data?.find(e=> e.UUID === customerUUID)
    if(this.props.customers?.errorText?.length > 0){
      return <div>Customer:{customerUUID} <div style={{ color: "red" }}>Err:{this.props.customers?.errorText}</div></div>
    }
    
    return <Select
      id="customer-select"
      placeholder={"Choose Customer"}
      isMulti={false}
      value={customer ? { label: customer.name, value: customer.UUID } : null}
      onChange={val => this.handleCampaignRequestChange("customerUuid", val.value)}
      options={this.props.customers?.data?.filter(c => c.type === 0).map(c => {
        return { label: c.name, value: c.UUID }
      })}
    />
  }

  handleTabChange(tabId) {
    //isCreativeComponentDirty
    if (this.isCreativeComponentDirty() && !confirm('You have unsaved changes, are you sure you want to leave?')) {
      this.setState({ activeTab: this.state.activeTab, tabSeed: Math.random() })
      return
    }
    this.setState({ activeTab: tabId })
  }

  handleFormSetIsTabComponentDirty(isDirtyFunc) {
    this.isCreativeComponentDirty = () => isDirtyFunc()
    this.props.setIsTabComponentDirty(isDirtyFunc)
  }

  render() {
    const elements = [
      <ClientDetails
        key={this.state.campaignRequest.UUID}
        {...this.props}
        campaignRequest={this.state.campaignRequest}
      />,
      <CampaignDetails
        key={this.state.campaignRequest.UUID}
        {...this.props}
        campaignRequest={this.state.campaignRequest}
      />,
      <Creative
        key={this.state.campaignRequest.UUID}
        item={this.state.campaignRequest}
        creative={this.props.creative}
        creativePreview={this.props.creativePreview}
        setGetPlacement={this.setGetPlacement}
        onSaveItem={creative => this.props.handleSaveCreative(creative)}
        setIsTabComponentDirty={(isDirtyFunc) => this.handleFormSetIsTabComponentDirty(isDirtyFunc)}
      />
    ]
    return (
      <div>
        <Card style={{ overflow: "visible" }} shadow={2}>
          <div style={{ minHeight: "200px", margin: "0px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Textfield
              style={{ margin: "0px 10px" }}
              floatingLabel
              label="IO Number"
              pattern="-?[0-9]*(\.[0-9]+)?"
              error="Input is not a number!"
              onChange={e => this.handleCampaignRequestChange("ioNumber", e.target.value)}
              value={this.state.campaignRequest.ioNumber || ""}
            />
            <Textfield
              style={{ margin: "0px 10px" }}
              floatingLabel
              label="Campaign Name"
              onChange={e => this.handleCampaignRequestChange("name", e.target.value)}
              value={this.state.campaignRequest.name || ""}
            />
            <Textfield
              style={{ margin: "0px 10px" }}
              floatingLabel
              label="Rate"
              pattern="-?[0-9]*(\.[0-9]+)?"
              error="Input is not a number!"
              onChange={e => this.handleCampaignRequestChange("rate", e.target.value)}
              value={this.state.campaignRequest.rate || ""}
            />
            <Textfield
              style={{ margin: "0px 10px" }}
              floatingLabel
              label="Budget"
              pattern="-?[0-9]*(\.[0-9]+)?"
              error="Input is not a number!"
              onChange={e => this.handleCampaignRequestChange("budget", e.target.value)}
              value={this.state.campaignRequest.budget || ""}
            />
            <div style={{ width: "30%", margin: "0px 10px" }}>
              {this.renderCustomerSelect(this.state.campaignRequest.customerUuid)}
            </div>
          </div>
        </Card>
        {!this.props.campaignRequest.UUID ? null : (
          <Tabs
            key={this.state.tabSeed}
            activeTab={this.state.activeTab}
            onChange={tabId => this.handleTabChange(tabId)}
            ripple
            style={{ width: "100%" }}>
            <Tab>CLIENT DETAILS</Tab>
            <Tab>CAMPAIGN DETAILS</Tab>
            <Tab>CREATIVE</Tab>
            <Tab>WELCOME EMAIL</Tab>
            <Tab>DELIVERY</Tab>
            <Tab>PLACEMENT DETAILS</Tab>
          </Tabs>
        )}
        {!this.props.campaignRequest.UUID ? null : (
          <section>
            <div className="content">{elements[this.state.activeTab]}</div>
          </section>
        )}
      </div>
    )
  }
}

export default campaignRequestForm
