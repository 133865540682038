import { generatePubReactivationByDateReportThunk, fetchPubReactivationByDateCsvReport } from "../../services/pubReactivationByDateReport"


import {
  generatePubReactivationByDateCsvReport,
  generatePubReactivationByDateCsvReportSuccess,
  generatePubReactivationByDateCsvReportFailure
} from "../../reducers/pubReactivationByDateCsvReportReducer"

import { saveState } from "../../reducers/pubReactivationReportReducer"

import React from "react"
import { connect } from "react-redux"
import Modal from "react-modal"
import moment from "moment"
import { Grid, Cell, Button, Icon } from "react-mdl"
import { AutoSizer, MultiGrid, CellMeasurerCache } from "react-virtualized"
import CellRendererByDate from "./CellRendererByDate.js"
import fileDownload from "js-file-download"
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
  return {
    pubReactivationReport: state.pubReactivationReport,
    pubReactivationByDateReport: state.pubReactivationByDateReport,
    pubReactivationByDateCsvReport: state.pubReactivationByDateCsvReport,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    generatePubReactivationByDateReport: function (criteria) {
      dispatch(generatePubReactivationByDateReportThunk(criteria))
    },
    startDownloadingByDateCsvReport: function () {
      dispatch(generatePubReactivationByDateCsvReport())
    },
    downloadedByDateCsvReport: function () {
      dispatch(generatePubReactivationByDateCsvReportSuccess())
    },
    failedToDownloadByDateCsvReport: function (err) {
      dispatch(generatePubReactivationByDateCsvReportFailure(err))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
  }
}

const STYLE = {
  border: "1px solid #ddd"
}
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: "2px solid #aaa",
  backgroundColor: "#f7f7f7"
}
const STYLE_TOP_LEFT_GRID = {
  borderBottom: "2px solid #aaa",
  borderRight: "2px solid #aaa",
  fontWeight: "bold"
}
const STYLE_TOP_RIGHT_GRID = {
  borderBottom: "2px solid #aaa",
  fontWeight: "bold"
}

class pubReactivationReport extends React.Component {
  constructor(props) {
    super(props)

    this.rowLimit = 5000

    this.cellCacheByDate = new CellMeasurerCache({
      defaultHeight: 30,
      minWidth: 80,
      //maxWidth: 300,
      defaultWidth: 80,
      fixedHeight: true
    })


    this.state = {
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      startDate: moment().startOf("day"),
      endDate: moment(),
      newValues: "",
      limit: 0,
      timeZone: "America/New_York",
      newValuesReplace: false,
      isLoading: false,
      reportCriteria: null,
      isCriteriaCollapsed: false,
    }
    if (props.pubReactivationReport.componentState) {
      this.state = props.pubReactivationReport.componentState
    }
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      startDate: startDate,
      endDate: endDate
    })
  }

  handleRunButtonClick() {
    this.disableRunButton()
    let criteria = this.getCriteria()
    this.props.generatePubReactivationByDateReport(criteria)
    this.setState({
      reportCriteria: criteria,
      isCriteriaCollapsed: true,
    })
  }

  onToggle() {
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  handleDownloadButtonClick() {
    this.props.startDownloadingByDateCsvReport()
    this.disableDownloadButton()
    let criteria = this.getCriteria()

    let promises = []
    promises.push(fetchPubReactivationByDateCsvReport(criteria))

    return Promise.all(promises)
      .then(res => {
        this.props.downloadedByDateCsvReport()

        res.map((blob) => {
          let fileName = "reportByDate.csv"
          fileDownload(new Blob([blob]), fileName)
        })
      })
      .catch(e => {
        this.props.failedToDownloadByDateCsvReport(new Error(`Getting "by date" CSV report - ${e.statusText}`))
      })
  }

  getCriteria() {
    return {
      timeZone: this.state.timeZone,
      rowLimit: this.rowLimit,
      startDate: this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      campaignUUIDs: this.state.selectedCampaigns.map(e => e.value),
      placementUUIDs: this.state.selectedPlacements.map(e => e.value)
    }
  }

  componentDidMount() {
    Modal.setAppElement("body")
  }

  disableRunButton() {
    this.setState({
      isRunButtonDisabled: true
    })
  }

  disableDownloadButton() {
    this.setState({
      isDownloadButtonDisabled: true
    })
  }

  static getDerivedStateFromProps(props) {
    let newState = {}
    {
      let gotHtmlReportResponse = false
      props.pubReactivationByDateReport.matchWith({
        PubReactivationByDateReportInitial: () => { },
        LoadingPubReactivationByDateReport: () => { },
        PubReactivationByDateReportError: () => {
          gotHtmlReportResponse = true
        },
        PubReactivationByDateReportLoaded: () => {
          gotHtmlReportResponse = true
        }
      })
      if (gotHtmlReportResponse) {
        newState.isRunButtonDisabled = false
      }
    }

    {
      let gotCsvReportResponse = false
      props.pubReactivationByDateCsvReport.matchWith({
        PubReactivationByDateCsvReportInitial: () => { },
        LoadingPubReactivationByDateCsvReport: () => { },
        PubReactivationByDateCsvReportError: () => {
          gotCsvReportResponse = true
        },
        PubReactivationByDateCsvReportLoaded: () => {
          gotCsvReportResponse = true
        }
      })
      if (gotCsvReportResponse) {
        newState.isDownloadButtonDisabled = false
      }
    }
    return newState
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      selectedPlacements: placements,
    })
  }

  render() {
    if (this.state.isLoading) return "loading ..."
    return (
      <section ref={t => (this.contentSection = t)}>
        <div className="content">
          <Grid>
            <Cell col={12}>
              <Collapsible title="Pub Reactivations" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                <Grid>
                  <Cell col={10}>
                    <Grid style={{ padding: "1px" }}>
                      <Cell col={12}>
                        <label className="floatLabel">Date Range</label>
                        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Campaigns</label>
                        <CampaignSelector
                          selectedCampaigns={this.state.selectedCampaigns}
                          campaigns={this.props.campaigns}
                          onChange={(campaigns) => {
                            this.setState({
                              selectedCampaigns: campaigns,
                            })
                          }}
                        ></CampaignSelector>
                      </Cell>
                      <Cell col={6}>
                        <label className="floatLabel">Placements</label>
                        <PlacementSelector
                          selectedPlacements={this.state.selectedPlacements}
                          placements={this.props.placements}
                          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                        ></PlacementSelector>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell col={2}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              id="run-button"
                              colored
                              raised
                              ripple
                              onClick={() => this.handleRunButtonClick()}
                              disabled={this.state.isRunButtonDisabled}>
                              RUN
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => this.handleDownloadButtonClick()}
                              disabled={this.state.isDownloadButtonDisabled}
                              raised
                              colored
                              ripple>
                              <Icon name="cloud_download" />
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <div>{this.renderByDateCsvReport()}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Cell>
                </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Cell col={12}>
                  <div className="report">
                    <h4 className="reportTitle">{"Pub Reactivations - by Date"}</h4>
                    <div>{this.renderReportByDate()}</div>
                  </div>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }

  renderReportByDate() {
    return this.props.pubReactivationByDateReport.matchWith({
      PubReactivationByDateReportInitial: () => "Click 'Run' to generate",
      LoadingPubReactivationByDateReport: () => "Loading ...",
      PubReactivationByDateReportError: () => <div style={{ color: "#FF0000" }}>&quot;Failed: can not load&quot;</div>,
      PubReactivationByDateReportLoaded: ({ data }) => {
        let comp = data.Report.Components[0]
        let rowCluster = comp.RowCluster || []

        if (rowCluster.length == 0) {
          return <div>{"No records found"}</div>
        }

        let gridColumnCount = 3 + comp.PivotSettings.PivotDimensionValues.length
        let gridRowCount = 2 + rowCluster.length

        return (
          <AutoSizer disableHeight>
            {({ width }) => (
              <MultiGrid
                fixedColumnCount={3}
                fixedRowCount={2}
                scrollToColumn={0}
                scrollToRow={0}
                cellRenderer={props => <CellRendererByDate cache={this.cellCacheByDate} comp={comp} {...props} />}
                columnWidth={this.cellCacheByDate.columnWidth}
                deferredMeasurementCache={this.cellCacheByDate}
                columnCount={gridColumnCount}
                enableFixedColumnScroll
                enableFixedRowScroll
                height={480}
                rowHeight={30}
                rowCount={gridRowCount}
                style={STYLE}
                styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
                width={width}
                hideTopRightGridScrollbar
                hideBottomLeftGridScrollbar
              />
            )}
          </AutoSizer>
        )
      }
    })
  }

  renderByDateCsvReport() {
    return this.props.pubReactivationByDateCsvReport.matchWith({
      PubReactivationByDateCsvReportInitial: () => null,
      LoadingPubReactivationByDateCsvReport: () => <div>{"'By date' report ..."}</div>,
      PubReactivationByDateCsvReportError: () => <div style={{ color: "#FF0000" }}>{"'By date' report failed"}</div>,
      PubReactivationByDateCsvReportLoaded: () => null
    })
  }

  renderByMessageCsvReport() {
    return this.props.pubReactivationByMessageCsvReport.matchWith({
      PubReactivationByMessageCsvReportInitial: () => null,
      LoadingPubReactivationByMessageCsvReport: () => <div>{"'By message' report ..."}</div>,
      PubReactivationByMessageCsvReportError: () => <div style={{ color: "#FF0000" }}>{"'By failure' report failed"}</div>,
      PubReactivationByMessageCsvReportLoaded: () => null
    })
  }
}

const PubReactivationReport = connect(mapStateToProps, mapDispatchToProps)(pubReactivationReport)

export default PubReactivationReport
