import {
    generatePubReactivationByDateReport,
    generatePubReactivationByDateReportFailure,
    generatePubReactivationByDateReportSuccess
} from '../reducers/pubReactivationByDateReportReducer'
import { getUrlFor, save, getOpts, exceptionHandling, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY } from '.'
import store from '../store'

const url = getUrlFor("reporting", "pub-reactivation-by-date")
//const url = "http://localhost:9001/pub-prevalidation-mock/"
//const url = "http://reporting.d.adquire.com/pub-prevalidation-mock/"
//const url = "http://localhost:9001/pub-prevalidation-by-date/"


export function generatePubReactivationByDateReportThunk(criteria, slug) {
    return function (dispatch, getState) {
        dispatch(generatePubReactivationByDateReport())
        return save(getOpts(dispatch, criteria, "POST", url, null, null, getState(), `format=json&slug=${slug || ""}`, DISABLE_SNACK, AVOID_DISABLE_IS_DIRTY))
            .then(res => {
                dispatch(generatePubReactivationByDateReportSuccess(res.data))
            }).catch(e => {
                dispatch(generatePubReactivationByDateReportFailure(new Error(`Getting pub prevalidation by date report - ${e.statusText}`)))
                exceptionHandling(e, dispatch, "Getting pub prevalidation by date report")
            })
    }
}


export const fetchPubReactivationByDateCsvReport = (criteria) => {
    return fetch(`${url}?format=csv`, {
        method: "post",
        headers: {
            'Accept': 'application/octet-stream',
            'Authorization': store.getState().jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(criteria)
    }).then(response => {
        if (response.ok && response.status === 200) {
            return response.blob()
        }
        return Promise.reject(new Error(response.statusText))
    })
}
