const { union, derivations } = require('folktale/adt/union')

const GENERATE_SINGLE_VALIDATIONS_REPORT = 'GENERATE_SINGLE_VALIDATIONS_REPORT'
const GENERATE_SINGLE_VALIDATIONS_REPORT_FAILURE = 'GENERATE_SINGLE_VALIDATIONS_REPORT_FAILURE'
const GENERATE_SINGLE_VALIDATIONS_REPORT_SUCCESS = 'GENERATE_SINGLE_VALIDATIONS_REPORT_SUCCESS'

export const generateSingleValidationsReport = () =>
    ({ type: GENERATE_SINGLE_VALIDATIONS_REPORT })

export const generateSingleValidationsReportSuccess = data =>
    ({ type: GENERATE_SINGLE_VALIDATIONS_REPORT_SUCCESS, data })

export const generateSingleValidationsReportFailure = error =>
    ({ type: GENERATE_SINGLE_VALIDATIONS_REPORT_FAILURE, error })

export const SingleValidationsReportState = union('SingleValidationsReportState', {
    SingleValidationsReportInitial() { return {} }
    , LoadingSingleValidationsReport() { return {} }
    , SingleValidationsReportLoaded(data) { return { data } }
    , SingleValidationsReportError(error) { return { error } }
  })
.derive(derivations.debugRepresentation)
export const { 
    SingleValidationsReportInitial,
    LoadingSingleValidationsReport, 
    SingleValidationsReportLoaded, 
    SingleValidationsReportError 
} = SingleValidationsReportState

export const singleValidationsReport = (state=SingleValidationsReportInitial(), action) => {
    switch(action.type) {
        case GENERATE_SINGLE_VALIDATIONS_REPORT:
            return LoadingSingleValidationsReport()
        case GENERATE_SINGLE_VALIDATIONS_REPORT_FAILURE:
            return SingleValidationsReportError(action.error)
        case GENERATE_SINGLE_VALIDATIONS_REPORT_SUCCESS:
            return SingleValidationsReportLoaded(action.data)
        default:
            return state
    }
}
