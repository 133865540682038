import React from "react"
import { connect } from "react-redux"
import { Button, Icon } from "react-mdl"

function mapStateToProps(state) {
  return {
    crud: state.crud,
    isDirty: state.isDirty
  }
}

export class crudMenu extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return !this.props.crud.visible ? null : (
      <div>
        {!this.props.isDirty ? (
          <Button colored raised ripple disabled>
            <Icon name="save"> Save</Icon>
          </Button>
        ) : (
          <Button id="save-button" colored raised ripple onClick={() => this.props.crud.onSave()}>
            <Icon name="save"> Save</Icon>
          </Button>
        )}
        <Button
          raised
          accent
          ripple
          id="cancel-button"
          onClick={() => {
            this.props.crud.onCancel()
          }}
          className="mdl-button--raised mdl-js-ripple-effect mdl-button--accent">
          <Icon name="clear"> cancel</Icon> Cancel
        </Button>
        <Button id="new-button" colored raised ripple onClick={() => this.props.crud.onNew()}>
          <Icon name="add_box"> New</Icon> New
        </Button>
      </div>
    )
  }
}

const CrudMenu = connect(mapStateToProps, null)(crudMenu)

export default CrudMenu
