const { union, derivations } = require('folktale/adt/union')

const GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE = 'GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE'
const GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE_FAILURE = 'GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE_FAILURE'
const GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE_SUCCESS = 'GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE_SUCCESS'

export const generatePubReactivationByDateCsvReport = () =>
    ({ type: GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE })

export const generatePubReactivationByDateCsvReportSuccess = data =>
    ({ type: GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE_SUCCESS, data })

export const generatePubReactivationByDateCsvReportFailure = error =>
    ({ type: GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE_FAILURE, error })

export const PubReactivationByDateCsvReportState = union('PubReactivationByDateCsvReportState', {
    PubReactivationByDateCsvReportInitial() { return {} }
    , LoadingPubReactivationByDateCsvReport() { return {} }
    , PubReactivationByDateCsvReportLoaded(data) { return { data } }
    , PubReactivationByDateCsvReportError(error) { return { error } }
  })
.derive(derivations.debugRepresentation)
export const { 
    PubReactivationByDateCsvReportInitial,
    LoadingPubReactivationByDateCsvReport, 
    PubReactivationByDateCsvReportLoaded, 
    PubReactivationByDateCsvReportError 
} = PubReactivationByDateCsvReportState

export const pubReactivationByDateCsvReport = (state=PubReactivationByDateCsvReportInitial(), action) => {
    switch(action.type) {
        case GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE:
            return LoadingPubReactivationByDateCsvReport()
        case GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE_FAILURE:
            return PubReactivationByDateCsvReportError(action.error)
        case GENERATE_PUB_REACTIVATION_CSV_REPORT_BY_DATE_SUCCESS:
            return PubReactivationByDateCsvReportLoaded(action.data)
        default:
            return state
    }
}
