import "../node_modules/react-mdl/extra/material.js"
import "../node_modules/codemirror/lib/codemirror.css"
import "../node_modules/react-datepicker/dist/react-datepicker.css"
import "../node_modules/codemirror/mode/css/css.js"
import "../node_modules/codemirror/addon/lint/lint.css"
window.CSSLint = require("csslint").CSSLint
import { Layout, Header, HeaderRow, Drawer, Navigation, Content, Snackbar, Cell } from "react-mdl"
import "../node_modules/codemirror/addon/lint/css-lint.js"
import "core-js"

import { Link, Route, Router } from "react-router-dom"
import {
  check,
  retrieveCampaignRequests,
  retrieveCampaigns,
  retrieveSalespeople,
  retrievePlacements,
  retrieveRankers,
  retrieveValidators,
  retrieveDefaultValidators
} from "./services"

import Cake from "./components/cake"
import CampaignManager from "./components/campaign"
import AccountManager from "./components/accountManager"
import Inspector from "./components/inspector"
import Landing from "./components/landing"
import OpsTools from "./components/opsTools"
import PlacementManager from "./components/placement"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import history from "./history"
//import * as Sentry from "@sentry/browser"
import gravatar from "gravatar"
import store from "./store"
import watch from "redux-watch"
import { UUID } from "./utils/index"
import Reporting from "./components/reporting"
import CrudMenu from "./components/crudMenu"
import { Grid } from "@material-ui/core"
const warning =
  "Warning: getDefaultProps is only used on classic React.createClass definitions. Use a static property named `defaultProps` instead."
const consoleError = console.error.bind(console)
// const sentry_key = process.env.SENTRY_KEY
// const sentry_app = process.env.SENTRY_APP
// if (sentry_key && sentry_app) {
//   Sentry.init({ dsn: `https://${sentry_key}@sentry.io/${sentry_app}` })
// }
console.error = (...args) => {
  if (args[0] === warning) return
  return consoleError(...args)
}

export default class App extends React.Component {
  constructor() {
    super()
    store.dispatch(check(history))
    this.state = {
      isSnackbarActive: false,
      snackText: "",
      user: store.getState().user,
      isUploading: false
    }
    this.state.appKey = UUID()
    if (this.state.user && this.state.user.exp) {
      store.dispatch(retrieveCampaignRequests())
      store.dispatch(retrieveCampaigns())
      store.dispatch(retrieveSalespeople())
      store.dispatch(retrievePlacements())
      store.dispatch(retrieveValidators())
      store.dispatch(retrieveDefaultValidators())
      store.dispatch(retrieveRankers())
    }
    history.listen((l, action) => {
      if (action == "POP") {
        this.setState({
          appKey: UUID()
        })
      }
    })
    let uw = watch(store.getState, "user")
    store.subscribe(
      uw(newVal => {
        this.setState({
          user: newVal
        })
      })
    )
    let w = watch(store.getState, "snack")
    store.subscribe(
      w(newVal => {
        this.setState({
          isSnackbarActive: newVal.isActive,
          snackText: newVal.message
        })
      })
    )
    let uploading = watch(store.getState, "uploadingSuppressionListFile")
    store.subscribe(
      uploading(newVal => {
        this.setState({
          isUploading: newVal
        })
      })
    )
  }

  handleTimeoutSnackbar() {
    this.setState({ isSnackbarActive: false })
  }

  warnIfClosingTheBrowser(isUploadingFile) {
    if (isUploadingFile) {
      window.onbeforeunload = function (e) {
        e.returnValue = "irrelevant message"
        return false
      }
      return false
    }
    window.onbeforeunload = null
  }

  render() {
    this.warnIfClosingTheBrowser(this.state.isUploading)
    return (
      <Provider store={store}>
        <Router history={history}>
          <Layout key={this.state.appKey}>
            <Header style={{ backgroundColor: "#00556F", position: "sticky" }}>
              <HeaderRow style={{ paddingRight: 0 }}>
                <Grid className="navBarGrid" container style={{ height: "64px" }}>
                  <Cell col={3}>
                    <img src="/images/AdQuire-Manager-logo-white.png" style={{ maxWidth: "100%", height: "64px" }} />
                  </Cell>
                  <Cell col={3}>
                    <h3>{"WELCOME"}</h3>
                  </Cell>
                  <Cell col={3}>
                    <CrudMenu />
                  </Cell>
                  <Cell col={3}>
                    <div>
                      <span style={{ paddingRight: "1em" }}>
                        {this.state.user.given_name} {this.state.user.family_name}
                      </span>
                      <img
                        src={gravatar.url(
                          this.state.user.preferred_username + "@permissiondata.com",
                          { s: "64", r: "pg", d: "retro" },
                          true
                        )}
                        style={{ height: "64px", width: "64px" }}
                      />
                    </div>
                  </Cell>
                </Grid>
              </HeaderRow>
            </Header>
            <Drawer
              onClick={e => {
                e.target.parentElement.parentElement.classList.remove("is-visible")
                document.getElementsByClassName("mdl-layout__obfuscator")[0].classList.remove("is-visible")
              }}>
              <Header className="drawer-header">
                <span className="mdl-layout-title">AdQuire Manager</span>
              </Header>
              {this.state.user.preferred_username ? (
                <Navigation>
                  <Link className="mdl-navigation__link" to="/opstooling">
                    OPs Tools
                  </Link>
                  <Link className="mdl-navigation__link" to="/accountmanager">
                    Accounts
                  </Link>
                  <Link className="mdl-navigation__link" to="/cake">
                    CAKE Creative Uploader
                  </Link>
                  <Link className="mdl-navigation__link" to="/reporting">
                    Report
                  </Link>
                  <Link className="mdl-navigation__link" to="/placementmanager">
                    Placement
                  </Link>
                  <Link className="mdl-navigation__link" to="/campaignmanager">
                    Campaign
                  </Link>
                  {/* <Link className="mdl-navigation__link" to="/inspector">
                    Inspector
                  </Link> */}
                  <Link
                    onClick={() => {
                      sessionStorage.removeItem("state")
                      sessionStorage.removeItem("nonce")
                      sessionStorage.removeItem("user")
                      sessionStorage.removeItem("jwt")
                      window.location = "/"
                    }}
                    className="mdl-navigation__link"
                    to="/logout">
                    Logout
                  </Link>
                </Navigation>
              ) : (
                <Navigation>
                  <Link className="mdl-navigation__link" to="/">
                    Login
                  </Link>
                </Navigation>
              )}
            </Drawer>

            <Content style={{ width: "100%", height: "90vh" }}>
              {this.state.user.preferred_username ? (
                <div className="page-content">
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/logout" component={Landing} />
                  <Route path="/opstooling" component={OpsTools} />
                  <Route path="/accountmanager" component={AccountManager} />
                  <Route path="/cake" component={Cake} />
                  <Route exact path="/placementmanager" component={PlacementManager} />
                  <Route path="/placementmanager/:uuid/" component={PlacementManager} />
                  <Route exact path="/campaignmanager" component={CampaignManager} />
                  <Route path="/campaignmanager/:uuid/" component={CampaignManager}></Route>
                  <Route path="/inspector" component={Inspector} />
                  <Route path="/reporting/" component={Reporting} />
                </div>
              ) : (
                <div className="page-content">
                  <Route path="/*" component={Landing} />
                </div>
              )}
            </Content>
            <Snackbar active={this.state.isSnackbarActive} onTimeout={() => this.handleTimeoutSnackbar()}>
              <p> {this.state.snackText}</p>
            </Snackbar>
          </Layout>
        </Router>
      </Provider>
    )
  }
}

document.onreadystatechange = function () {
  if (document.readyState == "interactive") {
    ReactDOM.render(<App />, document.getElementById("app"))
  }
}
