import { saveState } from "../../reducers/singleValidationsReportV2Reducer"

import { loadSessionLookupThunk } from "../../services/sessionLookupService"

import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { Grid, Cell, Button, Textfield } from "react-mdl"
import { CellMeasurerCache } from "react-virtualized"
import { DateRange } from '../date-range'
import { validEmail } from "../../services"
import SessionDetails from "../session-details"
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
    return {
        sessionLookup: state.sessionLookup,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadSessionLookup: function (criteria) {
            dispatch(loadSessionLookupThunk(criteria))
        },
        saveState: function (state) {
            dispatch(saveState(state))
        },
    }
}

class sessionLookup extends React.Component {
    constructor(props) {
        super(props)

        this.sessionPageSize = 100
        this.rowLimit = 5000

        this.cellCacheByMessage = new CellMeasurerCache({
            defaultHeight: 30,
            minWidth: 80,
            maxWidth: 300,
            defaultWidth: 80,
            //fixedWidth: true,
            fixedHeight: true
        })

        this.state = {
            currentReportByMessageState: null,
            isRunButtonDisabled: false,
            isDownloadButtonDisabled: false,
            startDate: moment().startOf("day"),
            endDate: moment().endOf("day"),
            selectedValidationType: "",
            newValues: "",
            limit: 0,
            timeZone: "America/New_York",
            newValuesReplace: false,
            isLoading: false,
            reportCriteria: null, //criteria that was used to generate the currently displayed report
            sessionPage: 1,
            errorValue: "",
            isCriteriaCollapsed: false,
            toggleMap: [],
        }

        this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
    }

    componentWillUnmount() {
        this.props.saveState(this.state)
    }

    handleDateRangeCallback(startDate, endDate) {
        this.setState({
            startDate: startDate,
            endDate: endDate
        })


    }
    handleLookupFilterCallback(input) {
        let state = { filterValue: input }

        this.setState(state)
    }

    handleRunButtonClick() {
        let criteria = this.getCriteria()
        
        if (!criteria.sessionUUID && !criteria.email) {
            this.setState({
                errorValue: "Must enter a valid email or session UUID"
            })
            return
        }
        this.disableRunButton()
        this.props.loadSessionLookup(criteria)
        this.setState({
            reportCriteria: criteria,
            isCriteriaCollapsed: true,
        })
    }

    onToggle(){
        this.setState({
          isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
        })
      }

    getCriteria() {
        let state = {
            rowLimit: this.rowLimit,
            timeZone: this.state.timeZone,
            startDate: this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
            endDate: this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
        }

        const uuidTest = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
        if (uuidTest.test(this.state.filterValue)) {
            state.sessionUUID = this.state.filterValue
            // eslint-disable-next-line no-useless-escape
        } else if (validEmail(this.state.filterValue)) {
            state.email = this.state.filterValue
        }
        return state
    }


    disableRunButton() {
        this.setState({
            isRunButtonDisabled: true,
            isLoading: true
        })
    }

    disableDownloadButton() {
        this.setState({
            isDownloadButtonDisabled: true
        })
    }

    enableDownloadButton() {
        this.setState({
            isDownloadButtonDisabled: false
        })
    }

    static getDerivedStateFromProps() {
        let newState = {}
        return newState
    }

    sessionToggleHandler(f, sessionUUID){
        let {toggleMap} = this.state
        toggleMap[sessionUUID] = f
        this.setState({
            toggleMap: toggleMap,
        })
    }

    render() {
        let details = this.state.isLoading ? <React.Fragment>Loading...</React.Fragment> : null
        if (this.props.sessionLookup.data && this.props.sessionLookup.data.report) {
            if (this.state.isRunButtonDisabled) {
                this.setState({
                    isRunButtonDisabled: false,
                    isLoading: false
                })
            }
            if (!this.props.sessionLookup.data.report.data.sessionLookups) {
                details = <React.Fragment>No Session Data Found</React.Fragment>
            } else {
                details = <React.Fragment>{this.props.sessionLookup.data.report.data.sessionLookups.map((rd, idx) => {
                    return <Collapsible key={idx} title={rd.session.sessionUUID} defaultOpen={this.state.toggleMap[rd.session.sessionUUID]} onToggle={(e) => this.sessionToggleHandler(e, rd.session.sessionUUID)}><h4>Session:</h4><SessionDetails data={rd}></SessionDetails></Collapsible>
                })
                }</React.Fragment >
            }
        }
        return (
            <section ref={t => (this.contentSection = t)}>
                <div className="content">
                    <Grid>
                        <Cell col={12}>
                            <Collapsible title="Session Viewer" key={"criteria"}  defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
                            <Grid>
                                <Cell col={10}>
                                    <Grid style={{ padding: "1px" }}>
                                        <Cell col={4}>
                                            <label className="floatLabel">Date Range</label>
                                            <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                                        </Cell>
                                        <Cell col={4}>
                                            <Textfield
                                                floatingLabel
                                                error={this.state.errorValue}
                                                label="Email or Session UUID"
                                                onChange={e => this.handleLookupFilterCallback(e.target.value)}
                                                value={this.state.filterValue}
                                            />
                                        </Cell>
                                    </Grid>
                                </Cell>
                                <Cell col={2}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Button
                                                        id="run-button"
                                                        colored
                                                        raised
                                                        ripple
                                                        onClick={() => this.handleRunButtonClick()}
                                                        disabled={this.state.isRunButtonDisabled}>
                                                        Search
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Cell>
                            </Grid>
                            </Collapsible>
                        </Cell>
                        <Cell col={12}>{details}</Cell>
                    </Grid>
                </div>
            </section>
        )
    }
}

const SessionLookup = connect(mapStateToProps, mapDispatchToProps)(sessionLookup)

export default SessionLookup
