import React from 'react'
import ConfirmModal from '../modal'
import { Card, CardTitle, List, ListItem, Switch, Button, Icon } from 'react-mdl'
import AddressValidationModal from '../modal/addressValidation'


function getRequirements() {
  return {
    "emailRequired": { title: "Email" },
    "titleRequired": { title: "Title" },
    "firstNameRequired": { title: "First Name" },
    "lastNameRequired": { title: "Last Name" },
    "dobRequired": { title: "DOB" },
    "genderRequired": { title: "Gender" },
    "cityRequired": { title: "City", enableValidation: "address-validation" },
    "stateRequired": { title: "State", enableValidation: "address-validation" },
    "countryRequired": { title: "Country", enableValidation: "address-validation" },
    "address1Required": { title: "Address", enableValidation: "address-validation" },
    "zipcodeRequired": { title: "Zip", enableValidation: "address-validation" },
    "phoneRequired": { title: "Phone" },
  }
}
class RequiredFields extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
      validatorName: '',
      rolledUp: true
    }
  }

  toggleRollUp(e) {
    e.preventDefault()
    this.setState({
      rolledUp: !this.state.rolledUp
    })
  }

  resetModal() {
    this.setState({
      validatorName: '',
      showDialog: false
    })
  }

  resetAddressModal() {
    this.setState({
      showAddressDialog: false
    })
  }

  hasToShowModal(field) {
    const requirement = getRequirements()[field]
    return requirement.enableValidation && this.props.campaign[field] &&
      (this.props.campaign.validators.filter(v => v.name == requirement.enableValidation).length == 0)
  }


  onChange(field) {
    this.props.onChange(field, !this.props.campaign[field])
    let addresFields = { "cityRequired": true, "address1Required": true, "zipcodeRequired": true, "countryRequired": true, "stateRequired": true }
    if (addresFields[field]) {
      if (this.props.campaign.validators.filter(v => v.name == "address-validation").length == 0)
        this.setState({
          showAddressDialog: true
        })
      return false
    }
    if (this.hasToShowModal(field)) {
      this.setState({
        showDialog: true,
        validatorName: getRequirements()[field].enableValidation
      })
    }
    return false
  }

  addAddressValidation(suiteLevel) {
    this.props.addAddressValidation(suiteLevel)
    this.resetAddressModal()
  }

  handleValidatorChange(validatorName) {
    this.props.validatorChange(validatorName, true)
    this.resetModal()
  }

  render() {
    let rollButton = <Button onClick={e => this.toggleRollUp(e)}>
      <Icon name={this.state.rolledUp ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
    </Button>
    let requirements = getRequirements()
    let fields = Object.keys(requirements)
    return (
      <section ref={t => (this.contentSection = t)}>
        <Card style={{ minWidth: '240px', overflow: 'visible', width: '100%' }}>
          <CardTitle>User Field Requirements</CardTitle>
          <List id="field-requirements-list" style={{ width: '100%', paddingBottom: "0px" }}>
            {fields
              .slice(0, this.state.rolledUp ? 6 : fields.length)
              .map((key) => {
                return (
                  <ListItem
                    key={key}
                  >
                    <Switch
                      id={`${requirements[key].title}-requirement`}
                      onChange={(e) => this.onChange(key, e)}
                      checked={this.props.campaign[key] || false}
                    >
                      {requirements[key].title}
                    </Switch>
                  </ListItem>
                )
              })}
          </List>
          {rollButton}
          <ConfirmModal
            showDialog={this.state.showDialog}
            contentSection={this.contentSection}
            dialogTitle={`Do you want to enable ${this.state.validatorName}?`}
            onOk={() => this.handleValidatorChange(this.state.validatorName)}
            dialogCancel={() => this.resetModal()}
          />
          <AddressValidationModal
            showDialog={this.state.showAddressDialog}
            contentSection={this.contentSection}
            dialogTitle={`Do you want to enable Address Validation?`}
            onOk={(suiteLevel) => this.addAddressValidation(suiteLevel)}
            dialogCancel={() => this.resetAddressModal()}
          />
        </Card>
      </section>
    )
  }
}

export default RequiredFields
